import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  GetBinEstimateProcessingStateQuery as Result,
  withGetBinEstimateProcessingState as HOC,
} from '../__generated';

export type WithGetBinEstimateProcessingStateHocOwnProps = {
  grain_bin_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetBinEstimateProcessingStateHocChildProps = {
  bin_estimate_processing_state_loading: boolean;
  bin_estimate_processing_state: Result['bin_estimate_processing_state'];
};
export const withGetBinEstimateProcessingStateHoc: AmberHoc<
  WithGetBinEstimateProcessingStateHocOwnProps,
  WithGetBinEstimateProcessingStateHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetBinEstimateProcessingStateHocOwnProps,
      WithGetBinEstimateProcessingStateHocChildProps,
      typeof component
    >,
    WithGetBinEstimateProcessingStateHocChildProps
  >({
    options: ({ grain_bin_id, onError }) => ({
      onError,
      fetchPolicy: 'network-only',
      errorPolicy: onError ? 'all' : 'none',
      variables: { grain_bin_id },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          bin_estimate_processing_state_loading: true,
          bin_estimate_processing_state: {
            grain_bin_id: -1,
            processing: false,
            __typename: 'BinEstimateProcessingStateInfo',
          },
        };
      }
      return {
        bin_estimate_processing_state_loading: loading || false,
        bin_estimate_processing_state: data.bin_estimate_processing_state as Result['bin_estimate_processing_state'],
      };
    },
  })(component as any);
