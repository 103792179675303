import * as d3 from 'd3';
import React, { useEffect, useRef, useState } from 'react';
import { GrainBinEstimate } from '../../../api';
import { ColorBarSVGOptions } from '../../../api/rest/bin-colorbar-svg-download';
import { getTemperatureUnitLabelWithDegreeSuffix } from '../../../util';

const modifySVGColorBar = (
  dom: any,
  svgHTML: string,
  isMoisture: boolean,
  value: number,
  ticksOnly: boolean
) => {
  dom.innerHTML = svgHTML;
  const textElements = d3.select(dom).selectAll('text');

  textElements.each(function(_, i) {
    const suffix = isMoisture ? '%' : getTemperatureUnitLabelWithDegreeSuffix();
    const magnitude = isMoisture ? 3 : 15;
    const textEl = d3.select(this);

    switch (i) {
      case 0:
        textEl.text(ticksOnly ? '' : value - magnitude + suffix);
        break;
      case 1:
        textEl.text(ticksOnly ? `Target: ${suffix}` : `Target: ${value} ${suffix}`);
        break;
      case 2:
        textEl.text(ticksOnly ? '' : value + magnitude + suffix);
        break;
    }
  });
};

const selectSVGString = (
  isMoisture: boolean,
  colorBarSVGOptions: ColorBarSVGOptions,
  useSmallColorBar: boolean
) => {
  if (isMoisture) {
    return useSmallColorBar
      ? colorBarSVGOptions.smallMoistureColorBarSVG
      : colorBarSVGOptions.moistureColorBarSVG;
  }
  return useSmallColorBar
    ? colorBarSVGOptions.smallTemperatureColorBarSVG
    : colorBarSVGOptions.temperatureColorBarSVG;
};

export const selectTemperatureOrMoistureValue = (
  isMoisture: boolean,
  grainBinEstimateMetaData: GrainBinEstimate
): number => {
  if (isMoisture) {
    return grainBinEstimateMetaData.target_moisture_percent
      ? grainBinEstimateMetaData.target_moisture_percent
      : 0;
  }
  return grainBinEstimateMetaData.target_temperature_f
    ? grainBinEstimateMetaData.target_temperature_f
    : 0;
};

export const ColorBar = ({
  isMobile,
  isMoisture,
  colorBarSVGOptions,
  useSmallColorBar,
  value,
  ticksOnly,
  containerStyles = {},
}: {
  value: number;
  isMobile: boolean;
  useSmallColorBar: boolean;
  isMoisture: boolean;
  colorBarSVGOptions: ColorBarSVGOptions;
  containerStyles?: any;
  ticksOnly?: boolean;
}) => {
  const colorBarRef = useRef(null);

  useEffect(() => {
    if (colorBarRef.current) {
      const svgHTML = selectSVGString(isMoisture, colorBarSVGOptions, useSmallColorBar);
      modifySVGColorBar(colorBarRef.current, svgHTML, isMoisture, value, !!ticksOnly);

      if (!useSmallColorBar) {
        d3.select(colorBarRef.current)
          .select('.main-svg')
          .style('position', 'absolute')
          .style('top', '-15px');
      }
    }
  }, [value]);

  return (
    <div
      ref={colorBarRef}
      style={{
        display: 'flex',
        width: '100%',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
        ...containerStyles,
      }}
    />
  );
};
