import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import { GetGrainBinEstimateQuery, withGetGrainBinEstimate as HOC } from '../__generated';

export type WithGetGrainBinEstimatesHocOwnProps = {
  grain_bin_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetGrainBinEstimateHocChildProps = {
  get_grain_bin_estimate_loading: boolean;
  grain_bin_estimate: GetGrainBinEstimateQuery['grain_bin_estimate'];
  refetch_grain_bin_estimates: () => Promise<any>;
};
export const withGetGrainBinEstimatesHoc: AmberHoc<
  WithGetGrainBinEstimatesHocOwnProps,
  WithGetGrainBinEstimateHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetGrainBinEstimatesHocOwnProps,
      WithGetGrainBinEstimateHocChildProps,
      typeof component
    >,
    WithGetGrainBinEstimateHocChildProps
  >({
    options: ({ grain_bin_id, onError }) => ({
      onError,
      errorPolicy: onError ? 'all' : 'none',
      fetchPolicy: 'network-only',
      variables: { grain_bin_id },
      context: { important: true }, // flag to prevent from graphql batching
      notifyOnNetworkStatusChange: true,
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          get_grain_bin_estimate_loading: true,
          refetch_grain_bin_estimates: () => Promise.resolve(),
          grain_bin_estimate: null,
        };
      }

      return {
        get_grain_bin_estimate_loading: loading || false,
        refetch_grain_bin_estimates: data.refetch,
        grain_bin_estimate: data.grain_bin_estimate as GetGrainBinEstimateQuery['grain_bin_estimate'],
      };
    },
  })(component as any);
