import {
  backgroundAccountUpdatePollWatcher,
  backgroundFanGuidanceExtensionPollWatcher,
  backgroundFanStatusPollWatcher,
  backgroundGrainBinEstimateStatePollWatcher,
  backgroundGrainBinUpdatePollWatcher,
  backgroundNotificationUpdatePollWatcher,
  backgroundRecommWindowsPollWatcher,
  loginByTokenSaga,
  navWatcher,
  noLoginInit,
  pollWatcher,
  sessionWatcher,
  uiAnalyticsWatcher,
  versionWatcher,
} from '@amber-ui/core/lib';
import { setServiceWorkerViewer, workerWatcher } from '@amber-ui/service-worker-client/lib';
import { fork, spawn, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { initApp } from './action';

export default function* rootSaga() {
  const {
    payload: { history, apollo_client },
  } = yield take(getType(initApp));
  const path = history.location.pathname.toLowerCase();

  if (!path.startsWith('/otp') && !path.startsWith('reset_password')) {
    yield fork(loginByTokenSaga, apollo_client, history.location.pathname);
  } else {
    yield fork(noLoginInit);
  }
  yield spawn(navWatcher);
  yield spawn(sessionWatcher, apollo_client, setServiceWorkerViewer);
  yield spawn(uiAnalyticsWatcher);
  yield spawn(pollWatcher, apollo_client);
  yield spawn(backgroundFanStatusPollWatcher, apollo_client);
  yield spawn(backgroundGrainBinUpdatePollWatcher, apollo_client);
  yield spawn(backgroundGrainBinEstimateStatePollWatcher, apollo_client);
  yield spawn(backgroundRecommWindowsPollWatcher, apollo_client);
  yield spawn(backgroundFanGuidanceExtensionPollWatcher, apollo_client);
  yield spawn(backgroundAccountUpdatePollWatcher, apollo_client);
  yield spawn(backgroundNotificationUpdatePollWatcher, apollo_client);
  yield spawn(workerWatcher, apollo_client);
  yield spawn(versionWatcher);
}
