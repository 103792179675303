// THIS IS A GENERATED FILE, DO NOT EDIT IT!
import gql from 'graphql-tag';
import * as ReactApollo from 'react-apollo';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: Date;
};

export type Account = {
  __typename: 'Account';
  id: Scalars['Int'];
  account_id: Scalars['Int'];
  barge_support: Scalars['Boolean'];
  grain_bin_support: Scalars['Boolean'];
  pile_support: Scalars['Boolean'];
  primary_user_id: Scalars['Int'];
  system_account: Scalars['Boolean'];
  primary_user: User;
  users: Array<User>;
  organization_users_list: Array<UserModel>;
  updated_at?: Maybe<Scalars['Date']>;
  organization: Scalars['String'];
  grain_bins: Array<GrainBin>;
  grain_bin_links: Array<GrainBinAccountLink>;
  barge_links: Array<BargeAccountLink>;
  pile_links: Array<PileAccountLink>;
};

export type ActiveHubGrainTelemetryHistory = {
  __typename: 'ActiveHubGrainTelemetryHistory';
  period: Scalars['String'];
  active_hubs_grain_telemetry_history: Array<Maybe<HeadspaceTelemetryHistoryPerHub>>;
};

export type ActiveHubsLevelSamples = {
  __typename: 'ActiveHubsLevelSamples';
  hub_id: Scalars['Int'];
  level_samples: Array<Maybe<GrainBinLevelSample>>;
};

export type AerationRunWithinPeriod = {
  __typename: 'AerationRunWithinPeriod';
  period: Scalars['String'];
  aeration_runs?: Maybe<Array<Maybe<GrainContainerAerationRun>>>;
};

export type AerationWindow = {
  __typename: 'AerationWindow';
  start: Scalars['Date'];
  end: Scalars['Date'];
  total_hours_good: Scalars['Int'];
  total_hours: Scalars['Int'];
};

export enum ArchiveUnArchiveType {
  Archive = 'archive',
  Unarchive = 'unarchive',
}

export type AssignFanControllerInput = {
  fan_controller_id: Scalars['String'];
  has_plenum_sensor?: Maybe<Scalars['Boolean']>;
  fan_model_id?: Maybe<Scalars['Int']>;
  fan_alias: Scalars['String'];
  phase?: Maybe<Scalars['Int']>;
  voltage?: Maybe<Scalars['Int']>;
  power_factor?: Maybe<Scalars['Float']>;
  make: Scalars['String'];
  fan_brand_name: Scalars['String'];
  fan_model_type: FanType;
  fan_horsepower: Scalars['String'];
  diameter: Scalars['Float'];
  fan_model_created_type: FanModelCreatedType;
  linked_generic_fan_model_id?: Maybe<Scalars['Int']>;
  no_of_fans_connected: Scalars['Int'];
};

export type AssignHubInput = {
  core_id: Scalars['String'];
  nickname: Scalars['String'];
  hub_type: HubType;
  has_level_sensor: Scalars['Boolean'];
  hub_offset_ft: Scalars['Float'];
};

export type AssignmentStatus = {
  __typename: 'AssignmentStatus';
  device_id?: Maybe<Scalars['String']>;
  is_assigned?: Maybe<Scalars['Boolean']>;
};

export type Barge = {
  __typename: 'Barge';
  barge_id: Scalars['Int'];
  alias: Scalars['String'];
  default_grain_type: GrainType;
  default_grain_type_next: GrainType;
  grain_store_type: Scalars['Int'];
  length_ft: Scalars['Float'];
  width_ft: Scalars['Float'];
  height_ft: Scalars['Float'];
  cover_pellet_links: Array<BargeCoverPelletLink>;
  archived: Scalars['Boolean'];
  updated_at?: Maybe<Scalars['Date']>;
  container: GrainContainer;
  hub_links: Array<HubContainerLink>;
  active_hub_links: Array<HubContainerLink>;
  account_link: BargeAccountLink;
};

export type BargeAccountLink = ContainerAccountLink & {
  __typename: 'BargeAccountLink';
  account_id: Scalars['Int'];
  account: Account;
  container_id: Scalars['Int'];
  container_type: ContainerType;
  container: GrainContainer;
  barge_id: Scalars['Int'];
  barge: Barge;
};

export type BargeCoverPelletLink = {
  __typename: 'BargeCoverPelletLink';
  barge_cover_pellet_link_id: Scalars['Int'];
  barge_id: Scalars['Int'];
  cover_no: Scalars['Int'];
  pellet_id: Scalars['String'];
  start_epoch: Scalars['Date'];
  end_epoch: Scalars['Date'];
};

export type BinEstimateProcessingStateInfo = {
  __typename: 'BinEstimateProcessingStateInfo';
  grain_bin_id: Scalars['Int'];
  processing: Scalars['Boolean'];
};

export type BinEvent = {
  __typename: 'BinEvent';
  grain_bin_id: Scalars['Int'];
  grain_bin: GrainBin;
  epoch_time: Scalars['Date'];
  event_type: BinEventType;
  event_type_next: BinEventType;
  event_value: Scalars['String'];
};

export enum BinEventType {
  MoistureSample = 'MOISTURE_SAMPLE',
  FanStart = 'FAN_START',
  FanStop = 'FAN_STOP',
  LidOpen = 'LID_OPEN',
  LidClose = 'LID_CLOSE',
  HeatStart = 'HEAT_START',
  HeatStop = 'HEAT_STOP',
  GrainAdd = 'GRAIN_ADD',
  GrainRemove = 'GRAIN_REMOVE',
  BinCore = 'BIN_CORE',
  FanSeal = 'FAN_SEAL',
  FanUnseal = 'FAN_UNSEAL',
  FumStart = 'FUM_START',
  FumStop = 'FUM_STOP',
  Other = 'OTHER',
  BinEmpty = 'BIN_EMPTY',
  SensorsDeploy = 'SENSORS_DEPLOY',
  SensorsRemove = 'SENSORS_REMOVE',
}

export enum BinEventTypeNext {
  MoistureSample = 'moisture_sample',
  FanStart = 'fan_start',
  FanStop = 'fan_stop',
  LidOpen = 'lid_open',
  LidClose = 'lid_close',
  HeatStart = 'heat_start',
  HeatStop = 'heat_stop',
  GrainAdd = 'grain_add',
  GrainRemove = 'grain_remove',
  BinCore = 'bin_core',
  FanSeal = 'fan_seal',
  FanUnseal = 'fan_unseal',
  FumStart = 'fum_start',
  FumStop = 'fum_stop',
  Other = 'other',
  BinEmpty = 'bin_empty',
  SensorsDeploy = 'sensors_deploy',
  SensorsRemove = 'sensors_remove',
}

export type BinGrainModelPrerequisitesInfo = {
  __typename: 'BinGrainModelPrerequisitesInfo';
  grain_bin_id: Scalars['Int'];
  has_active_fan_controller: Scalars['Boolean'];
  has_active_hub: Scalars['Boolean'];
  has_active_ongoing_storage_period: Scalars['Boolean'];
  has_active_grain_tickets: Scalars['Boolean'];
  has_acceptable_grain_type: Scalars['Boolean'];
  has_valid_fan_airflow: Scalars['Boolean'];
  is_meeting_all_prerequisites: Scalars['Boolean'];
  has_active_storage_period_in_year: Scalars['Boolean'];
};

export type CfmValuesInput = {
  cfm_scaling: Scalars['Float'];
  cfm_offset: Scalars['Float'];
  cfm_min: Scalars['Float'];
  cfm_max: Scalars['Float'];
  cfm: Scalars['Float'];
};

export type Co2AlertInfo = {
  __typename: 'CO2AlertInfo';
  co2_alert_sent_on: Scalars['Date'];
  co2_alert_type: Scalars['String'];
  co2_ppm: Scalars['Int'];
};

export type ContainerAccountLink = {
  __typename: 'ContainerAccountLink';
  account_id: Scalars['Int'];
  account: Account;
  container_id: Scalars['Int'];
  container_type: ContainerType;
  container: GrainContainer;
};

export type ContainerAerationSchedule = {
  __typename: 'ContainerAerationSchedule';
  grain_bin_id: Scalars['Int'];
  aeration_schedule: Array<FanControllerRunWindow>;
  aeration_schedule_type?: Maybe<FanRunWindowRecommendedOption>;
};

export type ContainerGrainLevel = {
  __typename: 'ContainerGrainLevel';
  grain_bin_id: Scalars['Int'];
  grain_level?: Maybe<GrainLevel>;
};

export type ContainerGrainLevelPercent = {
  __typename: 'ContainerGrainLevelPercent';
  grain_level_percent: Scalars['Float'];
  grain_level_bushels: Scalars['Float'];
};

export type ContainerLastAerationRun = {
  __typename: 'ContainerLastAerationRun';
  grain_bin_id: Scalars['Int'];
  last_aeration_run?: Maybe<GrainContainerAerationRun>;
};

export type ContainerLastWeekAirspaceHistory = {
  __typename: 'ContainerLastWeekAirspaceHistory';
  grain_bin_id: Scalars['Int'];
  last_week_airspace_history: Array<Maybe<HeadspaceHubTelemetryHistory>>;
  last_week_co2_alert_sent_on?: Maybe<Scalars['Date']>;
};

export enum ContainerType {
  Bin = 'bin',
  Pile = 'pile',
  Barge = 'barge',
}

export type ContainerWeather = {
  __typename: 'ContainerWeather';
  grain_bin_id: Scalars['Int'];
  current_weather?: Maybe<CurrentWeatherInfo>;
  today_weather?: Maybe<WeatherInfo>;
  one_week_weather?: Maybe<WeatherInfo>;
};

export type Coordinates2 = {
  __typename: 'Coordinates2';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Count = {
  __typename: 'Count';
  count: Scalars['Int'];
};

export type CurrentWeather = {
  __typename: 'CurrentWeather';
  temp_f: Scalars['Float'];
  humidity_rh: Scalars['Float'];
  emc: Scalars['Float'];
  icon?: Maybe<Scalars['String']>;
};

export type CurrentWeatherInfo = {
  __typename: 'CurrentWeatherInfo';
  temp_f: Scalars['Float'];
  humidity_rh: Scalars['Float'];
  emc: Scalars['Float'];
  icon: Scalars['String'];
};

export type DayNightRange = {
  __typename: 'DayNightRange';
  day: Stats;
  night: Stats;
};

export type DeviceImageInfo = {
  __typename: 'DeviceImageInfo';
  s3_key: Scalars['String'];
  image_type: ImageType;
  version: Scalars['String'];
  updated_at: Scalars['Date'];
  product_id: Scalars['Int'];
};

export enum DistanceUnitType {
  Feet = 'feet',
  Meter = 'meter',
}

export type EmcMinMax = {
  __typename: 'EmcMinMax';
  emc_high: Scalars['Float'];
  emc_low: Scalars['Float'];
};

export type Entitlements = {
  __typename: 'Entitlements';
  is_admin: Scalars['Boolean'];
  export_telemetry: Scalars['Boolean'];
  notifications: Scalars['Boolean'];
};

export type EntitlementsInput = {
  is_admin: Scalars['Boolean'];
  export_telemetry: Scalars['Boolean'];
  notifications: Scalars['Boolean'];
};

export type ExperimentBoundValues = {
  __typename: 'ExperimentBoundValues';
  lower_bound: Scalars['Float'];
  upper_bound: Scalars['Float'];
};

export type ExperimentCfmValues = {
  __typename: 'ExperimentCfmValues';
  cfm_scaling: Scalars['Float'];
  cfm_offset: Scalars['Float'];
  cfm_min: Scalars['Float'];
  cfm_max: Scalars['Float'];
  cfm?: Maybe<Scalars['Float']>;
};

export type ExperimentPsychrometricRange = {
  __typename: 'ExperimentPsychrometricRange';
  name: Scalars['String'];
  experiment: Scalars['String'];
  type: Scalars['String'];
  emc_ranges?: Maybe<Array<PsychrometricRange>>;
  temp_ranges?: Maybe<Array<PsychrometricRange>>;
  emc_reconditioning_ranges?: Maybe<Array<PsychrometricRange>>;
};

export enum FanBrand {
  Aerovent = 'aerovent',
  Behlen = 'behlen',
  Brock = 'brock',
  Butler = 'butler',
  Caldwell = 'caldwell',
  Ceco = 'ceco',
  CircleSteel = 'circle_steel',
  ChicagoBlower = 'chicago_blower',
  Dmc = 'dmc',
  FarmFans = 'farm_fans',
  Gsi = 'gsi',
  Keho = 'keho',
  MiddleState = 'middle_state',
  Mfs = 'mfs',
  Neco = 'neco',
  Rolfes = 'rolfes',
  Scafco = 'scafco',
  Shivvers = 'shivvers',
  Sukup = 'sukup',
  Other = 'other',
  Unknown = 'unknown',
}

export enum FanConfiguration {
  Parallel = 'parallel',
  Serial = 'serial',
}

export type FanController = {
  __typename: 'FanController';
  fan_controller_id: Scalars['String'];
  fan_controller_id_next: Scalars['Int'];
  alias: Scalars['String'];
  core_id: Scalars['String'];
  environment?: Maybe<Scalars['String']>;
  modem?: Maybe<Scalars['String']>;
  hardware_rev?: Maybe<Scalars['String']>;
  firmware?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  created_at: Scalars['Date'];
  updated_at: Scalars['Date'];
  deleted_at?: Maybe<Scalars['Date']>;
  state?: Maybe<FanControllerState>;
  state_next?: Maybe<FanControllerState>;
  latest_fan_runs: Array<FanRun>;
  diag_test_is_enable?: Maybe<Scalars['Boolean']>;
  particle?: Maybe<ParticleDevice>;
  grain_bin_link?: Maybe<GrainBinFanController>;
  grain_bin_link_history: Array<GrainBinFanController>;
};

export type FanControllerConfig = {
  __typename: 'FanControllerConfig';
  config_epoch: Scalars['Date'];
  enabled: Scalars['Boolean'];
  run_window?: Maybe<FanControllerRunWindow>;
  safety_interval: Scalars['Int'];
  vibration_threshold: Scalars['Float'];
};

export type FanControllerDebugResponse = {
  __typename: 'FanControllerDebugResponse';
  fan_controller_id: Scalars['Int'];
  response_epoch: Scalars['Date'];
  event_id: Scalars['Int'];
  sequence_number: Scalars['Int'];
  payload: Scalars['String'];
};

export type FanControllerDiagnosticTestCellMetricTestResultResponse = {
  __typename: 'FanControllerDiagnosticTestCellMetricTestResultResponse';
  fan_controller_id: Scalars['Int'];
  response_epoch: Scalars['Date'];
  event_id: Scalars['Int'];
  sequence_number: Scalars['Int'];
  request_type: Scalars['Int'];
  request_id: Scalars['Int'];
};

export type FanControllerDiagnosticTestRequest = {
  __typename: 'FanControllerDiagnosticTestRequest';
  fan_controller_id: Scalars['Int'];
  request_epoch: Scalars['Date'];
  updated_at: Scalars['Date'];
  sns_message_id?: Maybe<Scalars['String']>;
  delivered?: Maybe<Scalars['Boolean']>;
  test_id: Scalars['Int'];
  test_type: Scalars['String'];
};

export type FanControllerDiagnosticTestSelfTestResultResponse = {
  __typename: 'FanControllerDiagnosticTestSelfTestResultResponse';
  fan_controller_id: Scalars['Int'];
  response_epoch: Scalars['Date'];
  event_id: Scalars['Int'];
  sequence_number: Scalars['Int'];
  request_type: Scalars['Int'];
  request_id: Scalars['Int'];
  test_status: Scalars['String'];
};

export type FanControllerDiagnosticTestStatusResponse = {
  __typename: 'FanControllerDiagnosticTestStatusResponse';
  fan_controller_id: Scalars['Int'];
  response_epoch: Scalars['Date'];
  event_id: Scalars['Int'];
  sequence_number: Scalars['Int'];
  request_type: Scalars['Int'];
  request_id: Scalars['Int'];
  request_status: Scalars['Int'];
};

export type FanControllerDto = {
  __typename: 'FanControllerDto';
  id: Scalars['Int'];
  state: Scalars['String'];
  active: Scalars['Boolean'];
  fan_attributes: GrainBinFanController;
  fan_config_state: FanControllerState;
};

export type FanControllerGetConfigResponse = {
  __typename: 'FanControllerGetConfigResponse';
  fan_controller_id: Scalars['Int'];
  response_epoch: Scalars['Date'];
  event_id: Scalars['Int'];
  sequence_number: Scalars['Int'];
  reason: FanGetConfigReason;
};

export type FanControllerHeartbeatResponse = {
  __typename: 'FanControllerHeartbeatResponse';
  fan_controller_id: Scalars['Int'];
  response_epoch: Scalars['Date'];
  event_id: Scalars['Int'];
  sequence_number: Scalars['Int'];
  reason: FanHeartbeatReason;
  request_epoch?: Maybe<Scalars['Date']>;
  enabled: Scalars['Boolean'];
  safety_interval: Scalars['Int'];
  vibration_threshold: Scalars['Float'];
  error_code?: Maybe<FanHeartbeatErrorCode>;
  run_window?: Maybe<FanControllerRunWindow>;
  is_on: Scalars['Boolean'];
  vibration_magnitude: Scalars['Float'];
  rssi?: Maybe<Scalars['Int']>;
  signal_quality?: Maybe<Scalars['Int']>;
  current_a?: Maybe<Scalars['Float']>;
  is_override: Scalars['Boolean'];
};

export type FanControllerPingRequest = {
  __typename: 'FanControllerPingRequest';
  fan_controller_id: Scalars['Int'];
  request_epoch: Scalars['Date'];
  updated_at: Scalars['Date'];
  sns_message_id?: Maybe<Scalars['String']>;
  delivered?: Maybe<Scalars['Boolean']>;
};

export type FanControllerRequest =
  | FanControllerPingRequest
  | FanControllerResetRequest
  | FanControllerSetConfigRequest
  | FanControllerDiagnosticTestRequest;

export type FanControllerResetRequest = {
  __typename: 'FanControllerResetRequest';
  fan_controller_id: Scalars['Int'];
  request_epoch: Scalars['Date'];
  updated_at: Scalars['Date'];
  sns_message_id?: Maybe<Scalars['String']>;
  delivered?: Maybe<Scalars['Boolean']>;
};

export type FanControllerResetResponse = {
  __typename: 'FanControllerResetResponse';
  fan_controller_id: Scalars['Int'];
  response_epoch: Scalars['Date'];
  event_id: Scalars['Int'];
  sequence_number: Scalars['Int'];
  reason: FanResetReason;
};

export type FanControllerResponse =
  | FanControllerResetResponse
  | FanControllerGetConfigResponse
  | FanControllerHeartbeatResponse
  | FanControllerDebugResponse
  | FanControllerDiagnosticTestStatusResponse
  | FanControllerDiagnosticTestSelfTestResultResponse
  | FanControllerDiagnosticTestCellMetricTestResultResponse;

export type FanControllerRunWindow = {
  __typename: 'FanControllerRunWindow';
  start_epoch: Scalars['Date'];
  end_epoch: Scalars['Date'];
};

export type FanControllerSetConfigRequest = {
  __typename: 'FanControllerSetConfigRequest';
  fan_controller_id: Scalars['Int'];
  request_epoch: Scalars['Date'];
  updated_at: Scalars['Date'];
  sns_message_id?: Maybe<Scalars['String']>;
  delivered?: Maybe<Scalars['Boolean']>;
  enabled: Scalars['Boolean'];
  safety_interval: Scalars['Int'];
  first_request_epoch: Scalars['Date'];
  vibration_threshold: Scalars['Float'];
  error_code?: Maybe<FanHeartbeatErrorCode>;
  run_window?: Maybe<FanControllerRunWindow>;
};

export type FanControllerState = {
  __typename: 'FanControllerState';
  core_id: Scalars['String'];
  fan_controller_id: Scalars['Int'];
  value: FanControllerStateValue;
  value_next: FanControllerStateValueNext;
  config: FanControllerConfig;
  vibration_magnitude?: Maybe<Scalars['Float']>;
  current_a?: Maybe<Scalars['Float']>;
  ping_attempts: Scalars['Int'];
  config_attempts: Scalars['Int'];
  fan_status: FanStatus;
  fan_status_next: FanStatusNext;
  is_on: Scalars['Boolean'];
  is_override: Scalars['Boolean'];
  error_code: FanHeartbeatErrorCode;
  updated_at: Scalars['Date'];
};

export enum FanControllerStateValue {
  Offline = 'offline',
  Updating = 'updating',
  Ready = 'ready',
  Scheduled = 'scheduled',
}

export enum FanControllerStateValueNext {
  Offline = 'offline',
  Updating = 'updating',
  Ready = 'ready',
  Scheduled = 'scheduled',
}

export enum FanGetConfigReason {
  PinReset = 'pin_reset',
  PowerManagement = 'power_management',
  PowerDown = 'power_down',
  PowerBrownout = 'power_brownout',
  Watchdog = 'watchdog',
  FirmwareUpdateSuccess = 'firmware_update_success',
  FirmwareUpdateTimeout = 'firmware_update_timeout',
  FactoryReset = 'factory_reset',
  SafeMode = 'safe_mode',
  DfuMode = 'dfu_mode',
  Panic = 'panic',
  User = 'user',
  Unspecified = 'unspecified',
  Unknown = 'unknown',
  CommandParseError = 'command_parse_error',
  CommandExecuteError = 'command_execute_error',
}

export enum FanHeartbeatErrorCode {
  NoError = 'no_error',
  General = 'general',
  Unknown = 'unknown',
  StartFail = 'start_fail',
  StopFail = 'stop_fail',
  Vibration = 'vibration',
  InvalidHwConfiguration = 'invalid_hw_configuration',
  PanicDefault = 'panic_default',
  PanicFaultHard = 'panic_fault_hard',
  PanicFaultNmi = 'panic_fault_nmi',
  PanicFaultMemManage = 'panic_fault_mem_manage',
  PanicFaultBus = 'panic_fault_bus',
  PanicFaultUsage = 'panic_fault_usage',
  PanicCloudInvalidLength = 'panic_cloud_invalid_length',
  PanicSystemExit = 'panic_system_exit',
  PanicSystemOutofheap = 'panic_system_outofheap',
  PanicSystemSpiOverrun = 'panic_system_spi_overrun',
  PanicSwAssertFailure = 'panic_sw_assert_failure',
  PanicSwInvalidCase = 'panic_sw_invalid_case',
  PanicSwPureVirtualCall = 'panic_sw_pure_virtual_call',
  PanicSystemStackOverflow = 'panic_system_stack_overflow',
  PanicSystemSemaphoreLockTimeout = 'panic_system_semaphore_lock_timeout',
  CommandDefault = 'command_default',
  CommandParse = 'command_parse',
  CommandExecute = 'command_execute',
  CommandValidate = 'command_validate',
  FirmwareUpdateTimeout = 'firmware_update_timeout',
  OutdatedCmdEpoch = 'outdated_cmd_epoch',
}

export enum FanHeartbeatReason {
  PingRequest = 'PING_REQUEST',
  CommandRequest = 'COMMAND_REQUEST',
  ResetRequest = 'RESET_REQUEST',
  SafetyIntervalError = 'SAFETY_INTERVAL_ERROR',
  FanOnSeq = 'FAN_ON_SEQ',
  FanOffSeq = 'FAN_OFF_SEQ',
  Vibration = 'VIBRATION',
  CurrentChange = 'CURRENT_CHANGE',
  CurrentOn = 'CURRENT_ON',
  CurrentOff = 'CURRENT_OFF',
  ScheduleStart = 'SCHEDULE_START',
  ScheduleComplete = 'SCHEDULE_COMPLETE',
  Default = 'DEFAULT',
  PowerLoss = 'POWER_LOSS',
  Error = 'ERROR',
  ExcessiveVibration = 'EXCESSIVE_VIBRATION',
  StartupGeneral = 'STARTUP_GENERAL',
  StartupError = 'STARTUP_ERROR',
  PinReset = 'PIN_RESET',
  PowerLow = 'POWER_LOW',
  PowerDown = 'POWER_DOWN',
  PowerBrownout = 'POWER_BROWNOUT',
  Watchdog = 'WATCHDOG',
  FirmwareUpdated = 'FIRMWARE_UPDATED',
  FactoryReset = 'FACTORY_RESET',
  SafeMode = 'SAFE_MODE',
  DfuMode = 'DFU_MODE',
  StartupMissingPing = 'STARTUP_MISSING_PING',
  StartupWatchdog = 'STARTUP_WATCHDOG',
  HwConfigApplied = 'HW_CONFIG_APPLIED',
  Unknown = 'UNKNOWN',
}

export enum FanModelCreatedType {
  SystemCreated = 'system_created',
  GenericCustomCreated = 'generic_custom_created',
  CustomCreated = 'custom_created',
}

export type FanModelFilter = {
  label_name: Scalars['String'];
  label_value: Scalars['String'];
};

export type FanModelOutput = {
  __typename: 'FanModelOutput';
  fan_model_id: Scalars['Int'];
  fan_model_name: Scalars['String'];
  fan_brand_name: Scalars['String'];
  fan_model_type: FanType;
  fan_horsepower: Scalars['String'];
  make: Scalars['String'];
  diameter: Scalars['Float'];
  fan_model_created_type: FanModelCreatedType;
};

export type FanModelResult = {
  __typename: 'FanModelResult';
  label?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['Int']>;
  values: Array<Maybe<FanModelResultValues>>;
};

export type FanModelResultVal = {
  __typename: 'FanModelResultVal';
  fan_model_id?: Maybe<Scalars['Int']>;
  fan_model_name?: Maybe<Scalars['String']>;
  fan_brand_name?: Maybe<Scalars['String']>;
  fan_model_type?: Maybe<FanType>;
  fan_horsepower?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['Float']>;
  fan_model_created_type?: Maybe<FanModelCreatedType>;
  is_generic?: Maybe<Scalars['Boolean']>;
  linked_generic_fan_model_id?: Maybe<Scalars['Int']>;
};

export type FanModelResultValues = {
  __typename: 'FanModelResultValues';
  label: FanModelResultVal;
  key: Scalars['Int'];
};

export type FanPowerStats = {
  __typename: 'FanPowerStats';
  fan_controller_id_next?: Maybe<Scalars['Int']>;
  alias?: Maybe<Scalars['String']>;
  total_run_hours?: Maybe<Scalars['Float']>;
  total_kwh?: Maybe<Scalars['Float']>;
  start_epoch?: Maybe<Scalars['Date']>;
};

export enum FanResetReason {
  PinReset = 'pin_reset',
  PowerManagement = 'power_management',
  PowerDown = 'power_down',
  PowerBrownout = 'power_brownout',
  Watchdog = 'watchdog',
  FirmwareUpdateSuccess = 'firmware_update_success',
  FirmwareUpdateTimeout = 'firmware_update_timeout',
  FactoryReset = 'factory_reset',
  SafeMode = 'safe_mode',
  DfuMode = 'dfu_mode',
  Panic = 'panic',
  User = 'user',
  Unspecified = 'unspecified',
  Unknown = 'unknown',
}

export type FanRun = {
  __typename: 'FanRun';
  fan_controller_id: Scalars['Int'];
  start_epoch: Scalars['Date'];
  end_epoch?: Maybe<Scalars['Date']>;
  current_avg_a: Scalars['Float'];
  reason_start: FanRunReason;
  reason_end?: Maybe<FanRunReason>;
  updated_at: Scalars['Date'];
};

export enum FanRunEndReason {
  Custom = 'custom',
  Drying = 'drying',
  Cooling = 'cooling',
  Reconditioning = 'reconditioning',
  Manual = 'manual',
}

export enum FanRunReason {
  Schedule = 'schedule',
  Other = 'other',
}

export enum FanRunStartReason {
  Custom = 'custom',
  Drying = 'drying',
  Cooling = 'cooling',
  Reconditioning = 'reconditioning',
  Manual = 'manual',
}

export enum FanRunWindowRecommendedOption {
  Cooling = 'COOLING',
  Drying = 'DRYING',
  Reconditioning = 'RECONDITIONING',
  Custom = 'CUSTOM',
  Manual = 'MANUAL',
}

export enum FanStatus {
  Unknown = 'unknown',
  Stopped = 'stopped',
  Pending = 'pending',
  Running = 'running',
}

export enum FanStatusNext {
  Unknown = 'unknown',
  Stopped = 'stopped',
  Pending = 'pending',
  Running = 'running',
}

export enum FanType {
  AxialFlow = 'AXIAL_FLOW',
  Centrifugal = 'CENTRIFUGAL',
  CentrifugalHigh = 'CENTRIFUGAL_HIGH',
  CentrifugalInline = 'CENTRIFUGAL_INLINE',
  Other = 'OTHER',
  Unknown = 'UNKNOWN',
}

export enum FanTypeNext {
  AxialFlow = 'axial_flow',
  Centrifugal = 'centrifugal',
  CentrifugalInline = 'centrifugal_inline',
  Other = 'other',
  Unknown = 'unknown',
}

export enum FloorType {
  FullFloorAeration = 'full_floor_aeration',
  Ducts = 'ducts',
}

export type ForecastData = {
  __typename: 'ForecastData';
  daily_forecast?: Maybe<Array<Maybe<ForecastDay>>>;
  computed_forecast?: Maybe<HourlyForecastStatus>;
  emc_ranges?: Maybe<Array<PsychrometricRange>>;
  temp_ranges?: Maybe<Array<PsychrometricRange>>;
  emc_reconditioning_ranges?: Maybe<Array<PsychrometricRange>>;
};

export type ForecastDataV2 = {
  __typename: 'ForecastDataV2';
  daily_forecast?: Maybe<Array<Maybe<ForecastDayV2>>>;
  computed_forecast?: Maybe<HourlyForecastStatusV2>;
  emc_ranges?: Maybe<Array<PsychrometricRange>>;
  temp_ranges?: Maybe<Array<PsychrometricRange>>;
  emc_reconditioning_ranges?: Maybe<Array<PsychrometricRange>>;
};

export type ForecastDay = {
  __typename: 'ForecastDay';
  epoch_time: Scalars['Date'];
  daytime_range?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nighttime_range?: Maybe<Array<Maybe<Scalars['Date']>>>;
  day_icon: WeatherIcon;
  night_icon: WeatherIcon;
  temp: DayNightRange;
  rh: DayNightRange;
  emc: DayNightRange;
};

export type ForecastDayV2 = {
  __typename: 'ForecastDayV2';
  epoch_time: Scalars['Date'];
  daytime_range?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nighttime_range?: Maybe<Array<Maybe<Scalars['Date']>>>;
  icon: WeatherIcon;
  temp_high_f: Scalars['Float'];
  temp_low_f: Scalars['Float'];
  temp: DayNightRange;
  rh: DayNightRange;
  emc: DayNightRange;
  precip_intensity_inph: Scalars['Float'];
  solarradiation_watt_per_square_metre?: Maybe<Scalars['Float']>;
  sunelevation?: Maybe<Scalars['Float']>;
  ghiradiation?: Maybe<Scalars['Float']>;
};

export type GetGrainFanPlotsOutput = {
  __typename: 'GetGrainFanPlotsOutput';
  fan_model: GrainBinFanModelOutput;
  number_of_plots: Scalars['Int'];
};

export type GrainBin = {
  __typename: 'GrainBin';
  id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
  account_id: Scalars['Int'];
  alias: Scalars['String'];
  bin_name: Scalars['String'];
  grain_type: GrainType;
  grain_type_next: GrainType;
  height_ft?: Maybe<Scalars['Float']>;
  diameter_ft?: Maybe<Scalars['Float']>;
  zip_code?: Maybe<Scalars['String']>;
  tz?: Maybe<Scalars['String']>;
  fan_type?: Maybe<FanType>;
  fan_type_next?: Maybe<FanType>;
  fan_power_hp?: Maybe<Scalars['Float']>;
  has_grain_spreader?: Maybe<Scalars['Boolean']>;
  floor_type: FloorType;
  current_grain_emc?: Maybe<Scalars['Float']>;
  price_per_kwh?: Maybe<Scalars['Float']>;
  target_grain_emc?: Maybe<Scalars['Float']>;
  recommendation_type?: Maybe<Scalars['String']>;
  current_grain_temp?: Maybe<Scalars['Float']>;
  location: Coordinates2;
  bin_events: Array<BinEvent>;
  fan_settings: GrainBinFanSettings;
  pellet_order: Array<Scalars['String']>;
  opt_in_fan_guidance?: Maybe<Scalars['Boolean']>;
  enable_fan_guidance?: Maybe<Scalars['Boolean']>;
  fan_guidance_start_date?: Maybe<Scalars['Date']>;
  fan_guidance_end_date?: Maybe<Scalars['Date']>;
  can_send_fan_guidance_notification?: Maybe<Scalars['Boolean']>;
  fan_guidance_extension_date?: Maybe<Scalars['Date']>;
  sent_fan_guidance_ext_notif: Scalars['Boolean'];
  archived: Scalars['Boolean'];
  updated_at?: Maybe<Scalars['Date']>;
  active_hub_links: Array<HubContainerLink>;
  container: GrainContainer;
  hub_links: Array<HubContainerLink>;
  fan_controllers: Array<GrainBinFanController>;
  fan_controllers_inactive: Array<GrainBinFanController>;
  grain_bin_storage_periods: Array<GrainBinStoragePeriodWithOngoingStorageFlag>;
  account_link: GrainBinAccountLink;
};

export type GrainBinAccountLink = ContainerAccountLink & {
  __typename: 'GrainBinAccountLink';
  account_id: Scalars['Int'];
  account: Account;
  container_id: Scalars['Int'];
  container_type: ContainerType;
  container: GrainContainer;
  grain_bin_id: Scalars['Int'];
  grain_bin: GrainBin;
};

export type GrainBinAggregation = {
  __typename: 'GrainBinAggregation';
  grain_bin_id: Scalars['Int'];
  fan_controllers: Array<Maybe<FanControllerDto>>;
  grain_bin_attributes: GrainBinDto;
};

export type GrainBinCfmSolutionOutput = {
  __typename: 'GrainBinCfmSolutionOutput';
  grain_bin_id: Scalars['Int'];
  created_at: Scalars['Date'];
  updated_at: Scalars['Date'];
  grain_type: Scalars['String'];
  fan_model: GrainBinFanModelOutput;
  grain_bin_height_solution?: Maybe<StaticPressureCfmSolution>;
  grain_bin_solution?: Maybe<StaticPressureCfmSolution>;
};

export type GrainBinDto = {
  __typename: 'GrainBinDTO';
  grain_bin_id: Scalars['Int'];
  bin_name: Scalars['String'];
  grain_type: GrainType;
  height_ft?: Maybe<Scalars['Float']>;
  diameter_ft?: Maybe<Scalars['Float']>;
  zip_code?: Maybe<Scalars['String']>;
  tz?: Maybe<Scalars['String']>;
  location: Coordinates2;
  elevation_ft?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['Date']>;
  has_grain_spreader?: Maybe<Scalars['Boolean']>;
  floor_type: FloorType;
  current_grain_emc?: Maybe<Scalars['Float']>;
  current_grain_temp?: Maybe<Scalars['Float']>;
  target_grain_emc?: Maybe<Scalars['Float']>;
  price_per_kwh?: Maybe<Scalars['Float']>;
  recommendation_type?: Maybe<Scalars['String']>;
  opt_in_fan_guidance?: Maybe<Scalars['Boolean']>;
  enable_fan_guidance?: Maybe<Scalars['Boolean']>;
  fan_guidance_start_date?: Maybe<Scalars['Date']>;
  fan_guidance_end_date?: Maybe<Scalars['Date']>;
  can_send_fan_guidance_notification?: Maybe<Scalars['Boolean']>;
  archived: Scalars['Boolean'];
  const_sheds_multiplier?: Maybe<Scalars['Int']>;
  fan_guidance_extension_date?: Maybe<Scalars['Date']>;
  sent_fan_guidance_ext_notif: Scalars['Boolean'];
  site?: Maybe<GrainSiteDto>;
};

export type GrainBinEstimate = {
  __typename: 'GrainBinEstimate';
  grain_bin_id: Scalars['Int'];
  is_bin_forecast_unavailable: Scalars['Boolean'];
  bin_grain_type: GrainType;
  bin_diameter_ft: Scalars['Float'];
  bin_height_ft: Scalars['Float'];
  temperature_chart_data_url?: Maybe<Scalars['String']>;
  moisture_chart_data_url?: Maybe<Scalars['String']>;
  temperature_f_colorbar_small_svg_url?: Maybe<Scalars['String']>;
  temperature_c_colorbar_small_svg_url?: Maybe<Scalars['String']>;
  temperature_f_colorbar_svg_url?: Maybe<Scalars['String']>;
  temperature_c_colorbar_svg_url?: Maybe<Scalars['String']>;
  moisture_colorbar_svg_url?: Maybe<Scalars['String']>;
  moisture_colorbar_small_svg_url?: Maybe<Scalars['String']>;
  internal_moisture_json?: Maybe<Scalars['String']>;
  internal_temperature_c_json?: Maybe<Scalars['String']>;
  internal_temperature_f_json?: Maybe<Scalars['String']>;
  internal_slider_c_json?: Maybe<Scalars['String']>;
  internal_slider_f_json?: Maybe<Scalars['String']>;
  target_temperature_f?: Maybe<Scalars['Float']>;
  target_moisture_percent?: Maybe<Scalars['Float']>;
  cfm_per_bushel?: Maybe<Scalars['Float']>;
  grain_bin_level_percent?: Maybe<Scalars['Float']>;
  grain_bin_level_bu?: Maybe<Scalars['Float']>;
  storage_period?: Maybe<GrainBinStoragePeriodWithOngoingStorageFlag>;
  fan_model?: Maybe<FanModelOutput>;
  last_grain_ticket_date?: Maybe<Scalars['Date']>;
  grain_surface_shape?: Maybe<GrainSurfaceShapeType>;
  total_hrs_runtime: Scalars['Int'];
  total_aeration_runs: Scalars['Int'];
  current_weather?: Maybe<CurrentWeather>;
  number_of_fans_connected_to_bin: Scalars['Int'];
  result_updated_at?: Maybe<Scalars['Date']>;
};

export type GrainBinEstimateParameters = {
  __typename: 'GrainBinEstimateParameters';
  grain_bin_id: Scalars['Int'];
  target_temperature_f?: Maybe<Scalars['Float']>;
  target_mcwb_percent?: Maybe<Scalars['Float']>;
  last_breaking_change_date?: Maybe<Scalars['Date']>;
  latest_result_requested_at_date?: Maybe<Scalars['Date']>;
};

export type GrainBinFanController = {
  __typename: 'GrainBinFanController';
  grain_bin_fan_controller_id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
  fan_controller_id: Scalars['String'];
  fan_controller_id_next: Scalars['Int'];
  start_date: Scalars['Date'];
  end_date: Scalars['Date'];
  updated_at: Scalars['Date'];
  grain_bin: GrainBin;
  fan_type?: Maybe<FanType>;
  has_plenum_sensor?: Maybe<Scalars['Boolean']>;
  fan_brand?: Maybe<FanBrand>;
  fan_runs: Array<FanRun>;
  cfm?: Maybe<Scalars['Float']>;
  horsepower?: Maybe<Scalars['Float']>;
  static_pressure_in?: Maybe<Scalars['Float']>;
  alias?: Maybe<Scalars['String']>;
  fan_controller: FanController;
  phase?: Maybe<Scalars['Int']>;
  voltage?: Maybe<Scalars['Int']>;
  power_factor?: Maybe<Scalars['Float']>;
  fan_model_id?: Maybe<Scalars['Int']>;
  no_of_fans_connected: Scalars['Int'];
};

export type GrainBinFanModelInput = {
  fan_brand_name: Scalars['String'];
  fan_model_type: FanType;
  fan_horsepower: Scalars['String'];
  make: Scalars['String'];
  diameter: Scalars['Float'];
  is_generic?: Maybe<Scalars['Boolean']>;
};

export type GrainBinFanModelOutput = {
  __typename: 'GrainBinFanModelOutput';
  fan_model_id: Scalars['Int'];
  fan_model_name: Scalars['String'];
  fan_brand_name: Scalars['String'];
  fan_model_type: FanType;
  fan_horsepower: Scalars['String'];
  make: Scalars['String'];
  diameter: Scalars['Float'];
  created_at: Scalars['Date'];
  updated_at: Scalars['Date'];
  is_generic: Scalars['Boolean'];
  fan_model_created_type: FanModelCreatedType;
};

export type GrainBinFanRunNowPeriodPref = {
  __typename: 'GrainBinFanRunNowPeriodPref';
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
  fan_run_now_period: Scalars['String'];
};

export type GrainBinFanSettings = {
  __typename: 'GrainBinFanSettings';
  grain_bin_id: Scalars['Int'];
  safety_weather: Scalars['Boolean'];
  run_window?: Maybe<FanControllerRunWindow>;
  updated_at: Scalars['Date'];
  grain_bin: GrainBin;
};

export type GrainBinLevelHistory = {
  __typename: 'GrainBinLevelHistory';
  period: Scalars['String'];
  max_bushels: Scalars['Int'];
  active_hubs_level_samples: Array<Maybe<ActiveHubsLevelSamples>>;
};

export type GrainBinLevelSample = {
  __typename: 'GrainBinLevelSample';
  bushel: Scalars['Int'];
  epoch_time: Scalars['Date'];
};

export type GrainBinNotification = {
  __typename: 'GrainBinNotification';
  grain_bin_id: Scalars['Int'];
  notification_id: Scalars['Int'];
  source_type: Scalars['Int'];
  epoch_time: Scalars['Date'];
  alert_content: Scalars['String'];
  has_delivered_to_user: Scalars['Boolean'];
};

export type GrainBinNotificationOptIn = {
  __typename: 'GrainBinNotificationOptIn';
  user_id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
  account_id: Scalars['Int'];
  opt_in: Scalars['Boolean'];
};

export type GrainBinSite = {
  __typename: 'GrainBinSite';
  site_id: Scalars['Int'];
  site_name: Scalars['String'];
  account_id: Scalars['Int'];
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
};

export type GrainBinSiteLink = {
  __typename: 'GrainBinSiteLink';
  grain_bin_id: Scalars['Int'];
  site_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['Date']>;
};

export type GrainBinSites = {
  __typename: 'GrainBinSites';
  site_id: Scalars['Int'];
  site_name: Scalars['String'];
  bins: Array<Maybe<GrainBinAggregation>>;
};

export type GrainBinStoragePeriod = {
  __typename: 'GrainBinStoragePeriod';
  grain_bin_storage_cycle_id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
  storage_cycle_name: Scalars['String'];
  grain_type: GrainType;
  start_epoch: Scalars['Date'];
  end_epoch?: Maybe<Scalars['Date']>;
  created_at: Scalars['Date'];
};

export type GrainBinStoragePeriodValidationResult = {
  __typename: 'GrainBinStoragePeriodValidationResult';
  error_message: Scalars['String'];
  failed_validation: Scalars['Boolean'];
};

export type GrainBinStoragePeriodWithOngoingStorageFlag = {
  __typename: 'GrainBinStoragePeriodWithOngoingStorageFlag';
  grain_bin_storage_cycle_id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
  storage_cycle_name: Scalars['String'];
  is_ongoing_storage_period: Scalars['Boolean'];
  grain_type: GrainType;
  start_epoch: Scalars['Date'];
  end_epoch?: Maybe<Scalars['Date']>;
  created_at: Scalars['Date'];
};

/** TODO: Deprecate */
export type GrainBinTelemetry = {
  __typename: 'GrainBinTelemetry';
  active_hub_links: Array<HubContainerLink>;
  grain_bin_id: Scalars['Int'];
  as_of: Scalars['Date'];
  interior?: Maybe<GrainStatus>;
  headspace?: Maybe<GrainStatus>;
  weather?: Maybe<GrainStatus>;
  hourly_forecast?: Maybe<HourlyForecastStatus>;
};

export type GrainBinTicket = {
  __typename: 'GrainBinTicket';
  grain_bin_ticket_id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
  epoch_time: Scalars['Date'];
  volume: Scalars['Float'];
  weight_in_lbs?: Maybe<Scalars['Float']>;
  test_weight_in_lbs?: Maybe<Scalars['Float']>;
  action_performed: GrainTicketActionType;
  grain_moisture_pct: Scalars['Float'];
  new_grain_bin_bushels: Scalars['Float'];
  grain_surface_shape: GrainSurfaceShapeType;
  notes?: Maybe<Scalars['String']>;
  created_at: Scalars['Date'];
  updated_at: Scalars['Date'];
};

export type GrainBinTicketLevelSample = {
  __typename: 'GrainBinTicketLevelSample';
  bushel: Scalars['Float'];
  epoch_time: Scalars['Date'];
};

export type GrainBinTicketRow = {
  __typename: 'GrainBinTicketRow';
  grain_bin_ticket_id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
  epoch_time: Scalars['Date'];
  volume: Scalars['Float'];
  weight_in_lbs?: Maybe<Scalars['Float']>;
  test_weight_in_lbs?: Maybe<Scalars['Float']>;
  action_performed: GrainTicketActionType;
  grain_moisture_pct: Scalars['Float'];
  notes?: Maybe<Scalars['String']>;
  grain_surface_shape: GrainSurfaceShapeType;
  created_at: Scalars['Date'];
  updated_at: Scalars['Date'];
};

export type GrainBinTickets = {
  __typename: 'GrainBinTickets';
  grain_bin_tickets_total_weight_in_lbs: Scalars['Float'];
  grain_bin_tickets_total_bushels: Scalars['Float'];
  grain_bin_all_tickets: Array<GrainBinTicket>;
};

export type GrainBinTicketsLevelHistory = {
  __typename: 'GrainBinTicketsLevelHistory';
  period: Scalars['String'];
  no_grain_tickets_created: Scalars['Boolean'];
  grain_tickets_bushel_level_on_period_start?: Maybe<GrainBinTicketLevelSample>;
  grain_tickets_bushel_level_on_period_end?: Maybe<GrainBinTicketLevelSample>;
  grain_tickets_level_samples: Array<Maybe<GrainBinTicketLevelSample>>;
};

export type GrainBinWeeklyEmailPref = {
  __typename: 'GrainBinWeeklyEmailPref';
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
  opt_in: Scalars['Boolean'];
};

export type GrainContainer = {
  __typename: 'GrainContainer';
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  alias: Scalars['String'];
  default_grain_type: GrainType;
  default_grain_type_next: GrainType;
  active_hub_links: Array<HubContainerLink>;
  active_hub_links_including_fc_plenum_hubs: Array<HubContainerLink>;
  hub_links: Array<HubContainerLink>;
  telemetry: GrainTelemetry;
  telemetry_history: GrainTelemetryHistory;
  telemetry_history_v2: GrainTelemetryHistoryV2;
  aeration_runs?: Maybe<Array<GrainContainerAerationRun>>;
  aeration_runs_within_period: AerationRunWithinPeriod;
  aeration_run_history: GrainContainerAerationRunWithLimit;
  aeration_schedule: Array<FanControllerRunWindow>;
  aeration_schedule_type?: Maybe<FanRunWindowRecommendedOption>;
  grain_bin_level_history: GrainBinLevelHistory;
  grain_bin_tickets_level_history: GrainBinTicketsLevelHistory;
};

export type GrainContainerTelemetryArgs = {
  as_of?: Maybe<Scalars['Date']>;
};

export type GrainContainerTelemetry_HistoryArgs = {
  as_of?: Maybe<Scalars['Date']>;
  period: Scalars['String'];
};

export type GrainContainerTelemetry_History_V2Args = {
  as_of?: Maybe<Scalars['Date']>;
  period: Scalars['String'];
};

export type GrainContainerAeration_Runs_Within_PeriodArgs = {
  period: Scalars['String'];
  as_of?: Maybe<Scalars['Date']>;
};

export type GrainContainerAeration_Run_HistoryArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  current_grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
};

export type GrainContainerGrain_Bin_Level_HistoryArgs = {
  as_of?: Maybe<Scalars['Date']>;
  period: Scalars['String'];
};

export type GrainContainerGrain_Bin_Tickets_Level_HistoryArgs = {
  grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
  as_of?: Maybe<Scalars['Date']>;
  period: Scalars['String'];
};

export type GrainContainerAerationRun = {
  __typename: 'GrainContainerAerationRun';
  container_id: Scalars['Int'];
  container_type?: Maybe<ContainerType>;
  start_epoch: Scalars['Date'];
  start_humidity_rh?: Maybe<Scalars['Float']>;
  start_temp_f?: Maybe<Scalars['Float']>;
  end_epoch?: Maybe<Scalars['Date']>;
  end_humidity_rh?: Maybe<Scalars['Float']>;
  end_temp_f?: Maybe<Scalars['Float']>;
  avg_humidity_rh?: Maybe<Scalars['Float']>;
  avg_temp_f?: Maybe<Scalars['Float']>;
  avg_emc?: Maybe<Scalars['Float']>;
  grain_type?: Maybe<GrainType>;
  current_grain_temp?: Maybe<Scalars['Float']>;
  current_grain_emc?: Maybe<Scalars['Float']>;
  target_grain_emc?: Maybe<Scalars['Float']>;
  enabled_fan_guidance?: Maybe<Scalars['Boolean']>;
  fan_guidance_start_date?: Maybe<Scalars['Date']>;
  fan_guidance_end_date?: Maybe<Scalars['Date']>;
  fan_runs?: Maybe<Array<GrainContainerFanRun>>;
};

export type GrainContainerAerationRunWithLimit = {
  __typename: 'GrainContainerAerationRunWithLimit';
  aeration_runs?: Maybe<Array<GrainContainerAerationRun>>;
  has_more?: Maybe<Scalars['Boolean']>;
  current_offset: Scalars['Int'];
  total_runs?: Maybe<Scalars['Int']>;
  total_hrs_runtime?: Maybe<Scalars['Int']>;
  fan_power_usage?: Maybe<GrainFanPowerUsage>;
};

export type GrainContainerFanPoint = {
  __typename: 'GrainContainerFanPoint';
  epoch_time: Scalars['Date'];
  fan_count: Scalars['Int'];
};

export type GrainContainerFanRun = {
  __typename: 'GrainContainerFanRun';
  grain_bin_fan_controller_id: Scalars['Int'];
  grain_bin_fan_controller?: Maybe<GrainBinFanController>;
  start_epoch: Scalars['Date'];
  start_reason: FanRunReason;
  end_epoch?: Maybe<Scalars['Date']>;
  end_reason?: Maybe<FanRunReason>;
  current_avg_a?: Maybe<Scalars['Float']>;
  start_recommendation_type?: Maybe<FanRunWindowRecommendedOption>;
  end_recommendation_type?: Maybe<FanRunWindowRecommendedOption>;
  fan_start_reason?: Maybe<FanRunStartReason>;
  fan_end_reason?: Maybe<FanRunEndReason>;
  enabled_fan_guidance?: Maybe<Scalars['Boolean']>;
};

export type GrainEnvPoint = {
  __typename: 'GrainEnvPoint';
  epoch_time: Scalars['Date'];
  temp_f: Scalars['Float'];
  humidity_rh: Scalars['Float'];
  emc: Scalars['Float'];
  co2_ppm: Scalars['Int'];
};

export type GrainEnvPointV2 = {
  __typename: 'GrainEnvPointV2';
  epoch_time: Scalars['Date'];
  temp_f?: Maybe<Scalars['Float']>;
  humidity_rh?: Maybe<Scalars['Float']>;
  emc?: Maybe<Scalars['Float']>;
  co2_ppm?: Maybe<Scalars['Int']>;
};

export type GrainFanPlotInput = {
  x1: Scalars['Float'];
  y1: Scalars['Float'];
  x2: Scalars['Float'];
  y2: Scalars['Float'];
  acceptable_range?: Maybe<Scalars['Float']>;
  iteration?: Maybe<Scalars['Int']>;
};

export type GrainFanPlotOutput = {
  __typename: 'GrainFanPlotOutput';
  fan_plot_id: Scalars['Int'];
  x1: Scalars['Float'];
  y1: Scalars['Float'];
  x2: Scalars['Float'];
  y2: Scalars['Float'];
  acceptable_range?: Maybe<Scalars['Float']>;
  iteration?: Maybe<Scalars['Int']>;
  created_at: Scalars['Date'];
  updated_at: Scalars['Date'];
};

export type GrainFanPlotsInput = {
  fan_model: GrainBinFanModelInput;
  fan_plots: Array<Maybe<GrainFanPlotInput>>;
};

export type GrainFanPlotsOutput = {
  __typename: 'GrainFanPlotsOutput';
  fan_model: GrainBinFanModelOutput;
  fan_plots: Array<Maybe<GrainFanPlotOutput>>;
};

export type GrainFanPowerUsage = {
  __typename: 'GrainFanPowerUsage';
  fan_controllers_with_missing_info: Array<Maybe<GrainBinFanController>>;
  fan_power_stats: Array<Maybe<FanPowerStats>>;
  total_kwh: Scalars['Float'];
};

export type GrainLevel = {
  __typename: 'GrainLevel';
  grain_level_bushels: Scalars['Float'];
  grain_level_percent: Scalars['Float'];
};

export type GrainSiteDto = {
  __typename: 'GrainSiteDTO';
  site_id: Scalars['Int'];
  site_name: Scalars['String'];
  account_id: Scalars['Int'];
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
};

export type GrainSpecificAlertPrefs = {
  __typename: 'GrainSpecificAlertPrefs';
  user_id: Scalars['Int'];
  fan_start_stop_alerts: Scalars['Boolean'];
  fan_error_alerts: Scalars['Boolean'];
  disable_fan_guidance_alerts: Scalars['Boolean'];
  grain_bin_co2_alerts: Scalars['Boolean'];
  grain_level_alerts: Scalars['Boolean'];
};

export type GrainStatus = {
  __typename: 'GrainStatus';
  aeration_state?: Maybe<Scalars['String']>;
  emc?: Maybe<Scalars['Float']>;
  emc_ranges: Array<StateRange>;
  emc_state?: Maybe<Scalars['String']>;
  epoch_time: Scalars['Date'];
  humidity_state?: Maybe<Scalars['String']>;
  humidity_rh?: Maybe<Scalars['Float']>;
  humidity_rh_ranges: Array<StateRange>;
  temp_diff_state?: Maybe<Scalars['String']>;
  temp_f?: Maybe<Scalars['Float']>;
  temp_f_ranges: Array<StateRange>;
  temp_state?: Maybe<Scalars['String']>;
  temp_min_f?: Maybe<Scalars['Float']>;
  temp_max_f?: Maybe<Scalars['Float']>;
  temp_max_diff_f?: Maybe<Scalars['Float']>;
};

export type GrainStatusV2 = {
  __typename: 'GrainStatusV2';
  aeration_state?: Maybe<Scalars['String']>;
  emc?: Maybe<Scalars['Float']>;
  emc_ranges: Array<StateRange>;
  emc_state?: Maybe<Scalars['String']>;
  epoch_time: Scalars['Date'];
  humidity_state?: Maybe<Scalars['String']>;
  humidity_rh?: Maybe<Scalars['Float']>;
  humidity_rh_ranges: Array<StateRange>;
  temp_diff_state?: Maybe<Scalars['String']>;
  temp_f?: Maybe<Scalars['Float']>;
  temp_f_ranges: Array<StateRange>;
  temp_state?: Maybe<Scalars['String']>;
  temp_min_f?: Maybe<Scalars['Float']>;
  temp_max_f?: Maybe<Scalars['Float']>;
  temp_max_diff_f?: Maybe<Scalars['Float']>;
  precip_intensity_inph?: Maybe<Scalars['Float']>;
  solarradiation_watt_per_square_metre?: Maybe<Scalars['Float']>;
  sunelevation?: Maybe<Scalars['Float']>;
  ghiradiation?: Maybe<Scalars['Float']>;
};

export enum GrainSurfaceShapeType {
  Peaked = 'peaked',
  HalfPeaked = 'half_peaked',
  Level = 'level',
  HalfInverted = 'half_inverted',
  Inverted = 'inverted',
  SideDraw = 'side_draw',
}

export type GrainTelemetry = {
  __typename: 'GrainTelemetry';
  active_hub_links: Array<HubContainerLink>;
  as_of: Scalars['Date'];
  headspace?: Maybe<GrainStatus>;
  forecast_data?: Maybe<ForecastData>;
  forecast_data_v2?: Maybe<ForecastDataV2>;
  weather_forecast?: Maybe<WeatherForecast>;
  weather_history?: Maybe<WeatherHistory>;
  recommendation_windows?: Maybe<RecommendationWindows>;
  /** recommendation_windows_for_experiments: [ExperimentPsychrometricRange!]! */
  temporary_recommendation_windows?: Maybe<TemporaryRecommendationWindows>;
  interior?: Maybe<GrainStatus>;
  location?: Maybe<Coordinates2>;
  location_next?: Maybe<Location>;
  location_with_timezone?: Maybe<Location>;
  pellets: Array<PelletTelemetry>;
  tiers?: Maybe<Array<GrainStatus>>;
  weather?: Maybe<GrainStatus>;
};

export type GrainTelemetryWeather_HistoryArgs = {
  prev_week_no: Scalars['Int'];
};

export type GrainTelemetryHistory = {
  __typename: 'GrainTelemetryHistory';
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  period: Scalars['String'];
  start_epoch: Scalars['Date'];
  end_epoch: Scalars['Date'];
  headspace: Array<GrainEnvPoint>;
  headspace_with_multi_hubs: ActiveHubGrainTelemetryHistory;
  weather_history: Array<Maybe<WeatherHistoryPoint>>;
  interior: Array<GrainEnvPoint>;
  tiers?: Maybe<Array<Array<GrainEnvPoint>>>;
  weather: Array<GrainEnvPoint>;
  pellet: Array<GrainEnvPoint>;
  pellets: Array<PelletTelemetryHistory>;
  fan: Array<GrainContainerFanPoint>;
};

export type GrainTelemetryHistoryPelletArgs = {
  pellet_id: Scalars['String'];
};

export type GrainTelemetryHistoryV2 = {
  __typename: 'GrainTelemetryHistoryV2';
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  period: Scalars['String'];
  start_epoch: Scalars['Date'];
  end_epoch: Scalars['Date'];
  headspace_history: HeadspaceTelemetryHistory;
  weather_history: Array<Maybe<WeatherHistoryPoint>>;
};

export type GrainTemporaryInputConditions = {
  __typename: 'GrainTemporaryInputConditions';
  grain_bin_id: Scalars['Int'];
  current_grain_emc: Scalars['Float'];
  current_grain_temp: Scalars['Float'];
  target_grain_emc?: Maybe<Scalars['Float']>;
  recommendation_type: Scalars['String'];
};

export enum GrainTicketActionType {
  Added = 'added',
  Removed = 'removed',
}

export enum GrainType {
  WheatSoftRedWinterWheat = 'WHEAT_SOFT_RED_WINTER_WHEAT',
  WheatWhite = 'WHEAT_WHITE',
  BeanRed = 'BEAN_RED',
  BeanPinto = 'BEAN_PINTO',
  BeanBlack = 'BEAN_BLACK',
  BeanWhite = 'BEAN_WHITE',
  BeanLentil = 'BEAN_LENTIL',
  Barley = 'BARLEY',
  Buckwheat = 'BUCKWHEAT',
  Canola = 'CANOLA',
  CanolaTobinPolish = 'CANOLA_TOBIN_POLISH',
  CanolaWestarArgentine = 'CANOLA_WESTAR_ARGENTINE',
  Corn = 'CORN',
  CornYellow = 'CORN_YELLOW',
  CornWhite = 'CORN_WHITE',
  CornWaxy = 'CORN_WAXY',
  CornPopcorn = 'CORN_POPCORN',
  CornPopcornWhite = 'CORN_POPCORN_WHITE',
  DdgSolubles_10 = 'DDG_SOLUBLES_10',
  DdgSolubles_15 = 'DDG_SOLUBLES_15',
  DdgSolubles_20 = 'DDG_SOLUBLES_20',
  DdgSolubles_25 = 'DDG_SOLUBLES_25',
  Oats = 'OATS',
  Peanuts = 'PEANUTS',
  PistachioNutHull = 'PISTACHIO_NUT_HULL',
  Rapeseed = 'RAPESEED',
  Rye = 'RYE',
  Sorghum = 'SORGHUM',
  Soybean = 'SOYBEAN',
  Sunflower = 'SUNFLOWER',
  SunflowerConfectionary = 'SUNFLOWER_CONFECTIONARY',
  WheatDurum = 'WHEAT_DURUM',
  WheatHardRedSpring = 'WHEAT_HARD_RED_SPRING',
  WheatHardRedWinter = 'WHEAT_HARD_RED_WINTER',
  Ddg = 'DDG',
  RiceLongGrain = 'RICE_LONG_GRAIN',
  RiceMediumGrain = 'RICE_MEDIUM_GRAIN',
  RiceShortGrain = 'RICE_SHORT_GRAIN',
}

export type HeadspaceHubTelemetryHistory = {
  __typename: 'HeadspaceHubTelemetryHistory';
  hub_id: Scalars['Int'];
  headspace_telemetry_history: Array<GrainEnvPointV2>;
};

export type HeadspaceTelemetryHistory = {
  __typename: 'HeadspaceTelemetryHistory';
  period: Scalars['String'];
  active_hubs_grain_telemetry_history: Array<Maybe<HeadspaceHubTelemetryHistory>>;
};

export type HeadspaceTelemetryHistoryPerHub = {
  __typename: 'HeadspaceTelemetryHistoryPerHub';
  hub_id: Scalars['Int'];
  headspace_telemetry_history: Array<GrainEnvPoint>;
};

export type HourlyForecastStatus = {
  __typename: 'HourlyForecastStatus';
  hours: Array<GrainStatus>;
  aeration_windows: Array<AerationWindow>;
};

export type HourlyForecastStatusV2 = {
  __typename: 'HourlyForecastStatusV2';
  hours: Array<GrainStatusV2>;
  aeration_windows: Array<AerationWindow>;
};

export type Hub = {
  __typename: 'Hub';
  hub_id: Scalars['Int'];
  core_id: Scalars['String'];
  alias: Scalars['String'];
  is_active: Scalars['Boolean'];
  environment?: Maybe<Scalars['String']>;
  modem?: Maybe<Scalars['String']>;
  hardware_rev?: Maybe<Scalars['String']>;
  firmware?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  ref_pellet_id?: Maybe<Scalars['String']>;
  hub_pressure_offset_pa?: Maybe<Scalars['Int']>;
  created_at: Scalars['Date'];
  updated_at: Scalars['Date'];
  deleted_at?: Maybe<Scalars['Date']>;
  events?: Maybe<HubEventsEdge>;
  root_id?: Maybe<Scalars['String']>;
  root?: Maybe<RootImage>;
  particle?: Maybe<ParticleDevice>;
  container_link?: Maybe<HubContainerLink>;
};

export type HubEventsArgs = {
  as_of?: Maybe<Scalars['Date']>;
};

export type HubContainerLink = {
  __typename: 'HubContainerLink';
  hub_container_link_id: Scalars['Int'];
  core_id: Scalars['String'];
  hub_id: Scalars['Int'];
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  container: GrainContainer;
  start_epoch: Scalars['Date'];
  end_epoch: Scalars['Date'];
  grain_bin?: Maybe<GrainBin>;
  barge?: Maybe<Barge>;
  pile?: Maybe<Pile>;
  hub?: Maybe<Hub>;
  position?: Maybe<RelativePosition>;
  alias?: Maybe<Scalars['String']>;
  nickname: Scalars['String'];
  hub_type: HubType;
  has_level_sensor: Scalars['Boolean'];
  hub_offset_ft: Scalars['Float'];
  x_ft: Scalars['Float'];
  y_ft: Scalars['Float'];
  z_ft: Scalars['Float'];
};

export type HubEventHistoryEdge = {
  __typename: 'HubEventHistoryEdge';
  hub_id: Scalars['Int'];
  core_id: Scalars['String'];
  start: Scalars['Date'];
  end: Scalars['Date'];
  hub?: Maybe<Hub>;
  status: Array<HubStatusEvent>;
  headspace: Array<HubHeadspaceEvent>;
  pellets: Array<HubPelletEvent>;
  gps: Array<HubGpsEvent>;
  signal: Array<HubSignalEvent>;
  network_debug: Array<HubNetworkDebugEvent>;
};

export type HubEventsEdge = {
  __typename: 'HubEventsEdge';
  hub_id: Scalars['Int'];
  core_id: Scalars['String'];
  as_of: Scalars['Date'];
  hub?: Maybe<Hub>;
  status?: Maybe<HubStatusEvent>;
  headspace?: Maybe<HubHeadspaceEvent>;
  pellets: Array<HubPelletEvent>;
  gps?: Maybe<HubGpsEvent>;
  signal?: Maybe<HubSignalEvent>;
  network_debug: Array<HubNetworkDebugEvent>;
};

export type HubGpsEvent = {
  __typename: 'HubGpsEvent';
  hub_id: Scalars['Int'];
  epoch_time: Scalars['Date'];
  read_time: Scalars['Date'];
  event_id: Scalars['Int'];
  gps_method: Scalars['Int'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  satellite_count: Scalars['Int'];
  hdop: Scalars['Float'];
  altitude_ft: Scalars['Float'];
  ground_speed_kn: Scalars['Float'];
  course_angle: Scalars['Float'];
  updated_at: Scalars['Date'];
};

export type HubHeadspaceEvent = {
  __typename: 'HubHeadspaceEvent';
  event_id: Scalars['Int'];
  hub_id: Scalars['Int'];
  epoch_time: Scalars['Date'];
  temp_0_f: Scalars['Float'];
  temp_1_f: Scalars['Float'];
  temp_2_f: Scalars['Float'];
  temp_min_f: Scalars['Float'];
  temp_max_f: Scalars['Float'];
  temp_f: Scalars['Float'];
  hum_0_rh: Scalars['Float'];
  hum_1_rh: Scalars['Float'];
  hum_2_rh: Scalars['Float'];
  hum_min_rh: Scalars['Float'];
  hum_max_rh: Scalars['Float'];
  humidity_rh: Scalars['Float'];
  pressure_0_pa: Scalars['Int'];
  pressure_1_pa: Scalars['Int'];
  pressure_2_pa: Scalars['Int'];
  pressure_2_abs_pa?: Maybe<Scalars['Int']>;
  pressure_min_pa: Scalars['Int'];
  pressure_max_pa: Scalars['Int'];
  packets_in_queue: Scalars['Int'];
  node_count?: Maybe<Scalars['Int']>;
  reset_reason?: Maybe<Scalars['Int']>;
  packet_count?: Maybe<Scalars['Int']>;
  t6713_co2_ppm?: Maybe<Scalars['Int']>;
  mos_co2_ppm?: Maybe<Scalars['Int']>;
  mos_tvoc_ppb?: Maybe<Scalars['Int']>;
  reset_count?: Maybe<Scalars['Int']>;
};

export type HubLocation = {
  __typename: 'HubLocation';
  hub_id: Scalars['Int'];
  epoch_time: Scalars['Date'];
  read_time: Scalars['Date'];
  event_id: Scalars['Int'];
  gps_method: Scalars['Int'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  satellite_count: Scalars['Int'];
  hdop: Scalars['Float'];
  altitude_ft: Scalars['Float'];
  ground_speed_kn: Scalars['Float'];
  course_angle: Scalars['Float'];
  updated_at: Scalars['Date'];
};

export type HubNetworkDebugEvent = {
  __typename: 'HubNetworkDebugEvent';
  hub_id: Scalars['Int'];
  epoch_time: Scalars['Date'];
  node_address: Scalars['String'];
  event_id: Scalars['Int'];
  packets_in_queue: Scalars['Int'];
  network_stats: Array<HubNetworkStat>;
  updated_at: Scalars['Date'];
  root_id?: Maybe<Scalars['String']>;
};

export type HubNetworkStat = {
  __typename: 'HubNetworkStat';
  neighbor_address: Scalars['String'];
  etx: Scalars['Int'];
  link_quality: Scalars['Int'];
  rssi: Scalars['Int'];
};

export type HubPelletEvent = {
  __typename: 'HubPelletEvent';
  event_id: Scalars['Int'];
  hub_id: Scalars['Int'];
  pellet_id: Scalars['String'];
  epoch_time: Scalars['Date'];
  sequence_number: Scalars['Int'];
  sender_id: Scalars['String'];
  time_offset_ms: Scalars['Int'];
  temp_0_f: Scalars['Float'];
  temp_1_f: Scalars['Float'];
  temp_2_f: Scalars['Float'];
  temp_f: Scalars['Float'];
  temp_min_f: Scalars['Float'];
  temp_max_f: Scalars['Float'];
  hum_0_rh: Scalars['Float'];
  hum_1_rh: Scalars['Float'];
  hum_2_rh: Scalars['Float'];
  hum_min_rh: Scalars['Float'];
  hum_max_rh: Scalars['Float'];
  humidity_rh: Scalars['Float'];
  pressure_2_pa: Scalars['Int'];
  batt_voltage_uv: Scalars['Int'];
  packets_in_queue: Scalars['Int'];
  reset_count: Scalars['Int'];
  rssi: Scalars['Int'];
  hop_count: Scalars['Int'];
  avg_current_ua: Scalars['Int'];
  co2_ppm: Scalars['Int'];
  drift_ppm: Scalars['Int'];
  epoch_time_raw: Scalars['Date'];
  neighbor_count: Scalars['Int'];
  network_etx: Scalars['Int'];
  network_packets_sent: Scalars['Int'];
  network_packets_ack: Scalars['Int'];
  packets_received: Scalars['Int'];
  parent_address: Scalars['String'];
  pref_parent_rssi: Scalars['Int'];
  source_address: Scalars['String'];
  voc_ppb: Scalars['Int'];
};

export type HubSignalEvent = {
  __typename: 'HubSignalEvent';
  hub_id: Scalars['Int'];
  epoch_time: Scalars['Date'];
  event_id: Scalars['Int'];
  signal_rssi?: Maybe<Scalars['Int']>;
  signal_quality?: Maybe<Scalars['Int']>;
};

export type HubStatusEvent = {
  __typename: 'HubStatusEvent';
  event_id: Scalars['Int'];
  hub_id: Scalars['Int'];
  epoch_time: Scalars['Date'];
  temp_0_f: Scalars['Float'];
  temp_1_f: Scalars['Float'];
  temp_2_f: Scalars['Float'];
  temp_f: Scalars['Float'];
  temp_min_f: Scalars['Float'];
  temp_max_f: Scalars['Float'];
  hum_0_rh: Scalars['Float'];
  hum_1_rh: Scalars['Float'];
  hum_2_rh: Scalars['Float'];
  hum_min_rh: Scalars['Float'];
  hum_max_rh: Scalars['Float'];
  humidity_rh: Scalars['Float'];
  pressure_0_pa: Scalars['Int'];
  pressure_1_pa: Scalars['Int'];
  pressure_2_pa: Scalars['Int'];
  pressure_2_abs_pa?: Maybe<Scalars['Int']>;
  pressure_min_pa: Scalars['Int'];
  pressure_max_pa: Scalars['Int'];
  batt_voltage_uv: Scalars['Float'];
  panel_voltage_uv: Scalars['Float'];
  packets_in_queue: Scalars['Int'];
  reset_count: Scalars['Int'];
  updated_at: Scalars['Date'];
  batt_status: Scalars['Int'];
};

export enum HubType {
  Headspace = 'headspace',
  Plenum = 'plenum',
  FanControllerPlenum = 'fan_controller_plenum',
}

export type HumidityMinMax = {
  __typename: 'HumidityMinMax';
  humidity_high_rh: Scalars['Float'];
  humidity_low_rh: Scalars['Float'];
};

export enum ImageType {
  Pellet = 'pellet',
  Daughter = 'daughter',
  Hub = 'hub',
  ParticleFanController = 'particle_fan_controller',
  ParticleHub = 'particle_hub',
  Util = 'util',
  ParticleElectronHub = 'particle_electron_hub',
  ParticleBoronHub = 'particle_boron_hub',
  ParticleFanControllerBoron = 'particle_fan_controller_boron',
}

export type Location = {
  __typename: 'Location';
  epoch_time: Scalars['Date'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  timezone?: Maybe<Scalars['String']>;
};

export type LocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export enum MassUnitType {
  Pound = 'pound',
  Kilogram = 'kilogram',
}

export type Mutation = {
  __typename: 'Mutation';
  dummy?: Maybe<Scalars['String']>;
  updateFanController?: Maybe<FanController>;
  setFanControllerState?: Maybe<FanControllerState>;
  pingFanController?: Maybe<FanControllerPingRequest>;
  resetFanController?: Maybe<FanControllerResetRequest>;
  updateHub: Hub;
  createPelletGroup: PelletGroup;
  createPelletCable: PelletCable;
  updatePelletCable: PelletCable;
  savePelletGroupPellets: Array<PelletGroup>;
  registerPelletRef: PelletRef;
  savePelletImage: PelletImage;
  saveRootImage: RootImage;
  registerHub: Hub;
  registerFanController?: Maybe<FanController>;
  inviteUser: Scalars['Boolean'];
  manuallyAddUser: Scalars['Boolean'];
  requestUserVerification: Scalars['Boolean'];
  requestPasswordReset: Scalars['Boolean'];
  createUser: Viewer;
  createAccount: Account;
  updateAccount: Account;
  updateUser: User;
  deleteUser: UserId;
  deleteUserBySuperUser: UserId;
  updateUserPassword: User;
  updateUserVerification: Viewer;
  setUserPassword: Viewer;
  createToken: UserToken;
  createToken2: Scalars['String'];
  createTokenByOAuth: Viewer;
  renewToken: Viewer;
  renewToken2: Scalars['String'];
  createCustomerCareToken: Token;
  updateBarge: Barge;
  linkBargeCoverPellet: Array<BargeCoverPelletLink>;
  unlinkBargeCoverPellet: Array<BargeCoverPelletLink>;
  linkPileCable: PileCableLink;
  linkPileCablePellet: Array<PileCablePelletLink>;
  unlinkPileCable: PileCableLink;
  unlinkPileCablePellet: Array<PileCablePelletLink>;
  updatePile: Pile;
  updatePileCableLink: PileCableLink;
  validateStoragePeriodOverlaps: GrainBinStoragePeriodValidationResult;
  createBinStoragePeriod?: Maybe<GrainBinStoragePeriod>;
  updateBinStoragePeriod?: Maybe<GrainBinStoragePeriod>;
  deleteBinStoragePeriod?: Maybe<GrainBinStoragePeriod>;
  createBinEvent: Array<BinEvent>;
  removeBinEvent: Array<BinEvent>;
  assignFanController: GrainBinFanSettings;
  assignHub: HubContainerLink;
  decommissionFanController: FanController;
  decommissionHub: Hub;
  setGrainBinFanSettings: GrainBinFanSettings;
  setGrainContainerAerationSchedule: GrainContainer;
  stopGrainContainerAeration: GrainContainer;
  unassignFanController: GrainBinFanSettings;
  unassignFanController2: GrainBinFanSettings;
  updateGrainBinFanController: GrainBinFanController;
  updateGrainBin: GrainBin;
  updateHubAssignment: HubContainerLink;
  initiateGuidedRecommendations: GrainContainer;
  saveTemporaryInputConditions: GrainTemporaryInputConditions;
  uploadGrainFanPlots: Array<Maybe<GrainFanPlotsOutput>>;
  createGrainBinSite?: Maybe<GrainBinSite>;
  renameGrainBinSite?: Maybe<GrainBinSite>;
  deleteGrainBinSite?: Maybe<GrainBinSite>;
  updateGrainBinSiteLink?: Maybe<GrainBinSiteLink>;
  saveBinEstimateTargetValues?: Maybe<GrainBinEstimateParameters>;
  archiveGrainContainer: ContainerAccountLink;
  createBarge: BargeAccountLink;
  createGrainBin: GrainBinAccountLink;
  createGrainBinSystem: GrainBinAccountLink;
  createPile: PileAccountLink;
  removeBarge: BargeAccountLink;
  removePile: PileAccountLink;
  updateGrainBinNotificationPref: GrainBinNotificationOptIn;
  configureAlertPref: UserAlertPref;
  updateSpecificAlertPref: GrainSpecificAlertPrefs;
  performGrainBinSelection: UserSelectedGrainBin;
  performGrainBinUnselection: UserSelectedGrainContainer;
  archiveUnArchiveGrainContainer: GrainContainer;
  createGrainBinTicket?: Maybe<GrainBinTicketRow>;
  deleteGrainBinTicket?: Maybe<GrainBinTicketRow>;
  updateGrainBinTicket?: Maybe<GrainBinTicketRow>;
  approveSensorGeneratedGrainTicket?: Maybe<SensorGeneratedGrainTicket>;
  declineSensorGeneratedGrainTicket?: Maybe<SensorGeneratedGrainTicket>;
  setFanRunNowSchedule: GrainContainer;
  /** Weekly grain bin email mutations */
  subscribeWeeklyGrainEmail: UserWeeklyGrainEmailSchedule;
  unsubscribeWeeklyGrainEmail: UserWeeklyGrainEmailSchedule;
  updateWeeklyGrainEmailSchedule: UserWeeklyGrainEmailSchedule;
  updateGrainBinWeeklyEmailPref: GrainBinWeeklyEmailPref;
  setNotificationsReadOn: UserNotificationRead;
  saveOperationsViewUserLevelPrefs?: Maybe<UserPref>;
  saveOperationsViewRowVisibilityPrefs?: Maybe<RowVisibilityPref>;
  setUserUnitsPref: UserUnitsPref;
};

export type MutationUpdateFanControllerArgs = {
  fan_controller_id: Scalars['Int'];
  core_id?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  modem?: Maybe<Scalars['String']>;
  hardware_rev?: Maybe<Scalars['String']>;
  firmware?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  msg_version?: Maybe<Scalars['Int']>;
  diag_test_is_enable?: Maybe<Scalars['Boolean']>;
};

export type MutationSetFanControllerStateArgs = {
  core_id: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  run_window?: Maybe<RunWindowInput>;
  vibration_threshold?: Maybe<Scalars['Float']>;
  safety_interval?: Maybe<Scalars['Int']>;
  config_reason?: Maybe<Scalars['Int']>;
  clear_run_window?: Maybe<Scalars['Boolean']>;
  error_code?: Maybe<FanHeartbeatErrorCode>;
  is_override?: Maybe<Scalars['Boolean']>;
};

export type MutationPingFanControllerArgs = {
  fan_controller_id: Scalars['Int'];
};

export type MutationResetFanControllerArgs = {
  fan_controller_id: Scalars['Int'];
};

export type MutationUpdateHubArgs = {
  hub_id: Scalars['Int'];
  is_active?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<Scalars['String']>;
  modem?: Maybe<Scalars['String']>;
  hardware_rev?: Maybe<Scalars['String']>;
  firmware?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  ref_pellet_id?: Maybe<Scalars['String']>;
  hub_pressure_offset_pa?: Maybe<Scalars['Int']>;
  root_id?: Maybe<Scalars['String']>;
};

export type MutationCreatePelletGroupArgs = {
  pellet_group_type: PelletGroupType;
};

export type MutationCreatePelletCableArgs = {
  length_ft: Scalars['Float'];
  spacing_ft: Scalars['Float'];
};

export type MutationUpdatePelletCableArgs = {
  pellet_group_id: Scalars['Int'];
  length_ft: Scalars['Float'];
  spacing_ft: Scalars['Float'];
};

export type MutationSavePelletGroupPelletsArgs = {
  pellet_group_id: Scalars['Int'];
  pellet_links: Array<PelletGroupPelletLinkInput>;
};

export type MutationRegisterPelletRefArgs = {
  pellet_id: Scalars['String'];
  ref_pellet_id: Scalars['String'];
  pressure_offset_pa: Scalars['Int'];
};

export type MutationSavePelletImageArgs = {
  pellet_id: Scalars['String'];
  programmer_id: Scalars['String'];
  dslite_erase?: Maybe<Scalars['String']>;
  dslite_test?: Maybe<Scalars['String']>;
  test_img_hash?: Maybe<Scalars['String']>;
  test_result?: Maybe<Scalars['String']>;
  test_output?: Maybe<Scalars['String']>;
  pressure_offset_pa?: Maybe<Scalars['Int']>;
  dslite_final?: Maybe<Scalars['String']>;
  final_img_hash?: Maybe<Scalars['String']>;
  created_at: Scalars['Date'];
  updated_at?: Maybe<Scalars['Date']>;
  ref_pellet_id?: Maybe<Scalars['String']>;
};

export type MutationSaveRootImageArgs = {
  root_id: Scalars['String'];
  test_img_hash?: Maybe<Scalars['String']>;
  test_result?: Maybe<Scalars['String']>;
  serial_output?: Maybe<Scalars['String']>;
  pressure_offset_pa?: Maybe<Scalars['Int']>;
  final_img_hash?: Maybe<Scalars['String']>;
  created_at: Scalars['Date'];
  updated_at?: Maybe<Scalars['Date']>;
  ref_device_id?: Maybe<Scalars['String']>;
};

export type MutationRegisterHubArgs = {
  core_id: Scalars['String'];
  is_active: Scalars['Boolean'];
  environment: Scalars['String'];
  hardware_rev?: Maybe<Scalars['String']>;
  firmware?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  ref_pellet_id?: Maybe<Scalars['String']>;
  hub_pressure_offset_pa?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['Date']>;
  root_id?: Maybe<Scalars['String']>;
  is_plenum_sensored_fc?: Maybe<Scalars['Boolean']>;
};

export type MutationRegisterFanControllerArgs = {
  core_id: Scalars['String'];
  environment: Scalars['String'];
  hardware_rev: Scalars['String'];
  firmware: Scalars['String'];
  notes: Scalars['String'];
  is_plenum_sensored_fc?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['Date']>;
};

export type MutationInviteUserArgs = {
  account_id: Scalars['Int'];
  email_address: Scalars['String'];
  role: UserRole;
};

export type MutationManuallyAddUserArgs = {
  account_id: Scalars['Int'];
  email_address: Scalars['String'];
  role: UserRole;
};

export type MutationRequestUserVerificationArgs = {
  user_id: Scalars['Int'];
};

export type MutationRequestPasswordResetArgs = {
  email_address: Scalars['String'];
};

export type MutationCreateUserArgs = {
  token: Scalars['String'];
  email_address: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  telephone: Scalars['String'];
  password: Scalars['String'];
};

export type MutationCreateAccountArgs = {
  organization: Scalars['String'];
  barge_support: Scalars['Boolean'];
  grain_bin_support: Scalars['Boolean'];
  pile_support: Scalars['Boolean'];
  system_account: Scalars['Boolean'];
};

export type MutationUpdateAccountArgs = {
  account_id: Scalars['Int'];
  organization?: Maybe<Scalars['String']>;
  barge_support?: Maybe<Scalars['Boolean']>;
  grain_bin_support?: Maybe<Scalars['Boolean']>;
  pile_support?: Maybe<Scalars['Boolean']>;
  system_account?: Maybe<Scalars['Boolean']>;
};

export type MutationUpdateUserArgs = {
  user_id: Scalars['Int'];
  email_address?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  settings?: Maybe<UserSettingsInput>;
};

export type MutationDeleteUserArgs = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
};

export type MutationDeleteUserBySuperUserArgs = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
};

export type MutationUpdateUserPasswordArgs = {
  user_id: Scalars['Int'];
  old_password: Scalars['String'];
  new_password: Scalars['String'];
};

export type MutationUpdateUserVerificationArgs = {
  token: Scalars['String'];
};

export type MutationSetUserPasswordArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
};

export type MutationCreateTokenArgs = {
  email_address: Scalars['String'];
  password: Scalars['String'];
};

export type MutationCreateToken2Args = {
  email_address: Scalars['String'];
  password: Scalars['String'];
};

export type MutationCreateTokenByOAuthArgs = {
  oauth_token: Scalars['String'];
};

export type MutationRenewTokenArgs = {
  token?: Maybe<Scalars['String']>;
};

export type MutationCreateCustomerCareTokenArgs = {
  username: Scalars['String'];
  auth_token: Scalars['String'];
};

export type MutationUpdateBargeArgs = {
  barge_id: Scalars['Int'];
  alias?: Maybe<Scalars['String']>;
  default_grain_type?: Maybe<GrainType>;
  length_ft?: Maybe<Scalars['Float']>;
  width_ft?: Maybe<Scalars['Float']>;
  height_ft?: Maybe<Scalars['Float']>;
};

export type MutationLinkBargeCoverPelletArgs = {
  barge_id: Scalars['Int'];
  cover_no: Scalars['Int'];
  pellet_id: Scalars['String'];
  start_epoch?: Maybe<Scalars['Date']>;
};

export type MutationUnlinkBargeCoverPelletArgs = {
  barge_cover_pellet_link_id: Scalars['Int'];
  as_of?: Maybe<Scalars['Date']>;
};

export type MutationLinkPileCableArgs = {
  alias: Scalars['String'];
  pile_id: Scalars['Int'];
  cable_id: Scalars['Int'];
  x_ft: Scalars['Float'];
  y_ft: Scalars['Float'];
  start_epoch?: Maybe<Scalars['Date']>;
};

export type MutationLinkPileCablePelletArgs = {
  pile_id: Scalars['Int'];
  cable_no: Scalars['Int'];
  pellet_id: Scalars['String'];
  start_epoch?: Maybe<Scalars['Date']>;
};

export type MutationUnlinkPileCableArgs = {
  pile_cable_link_id: Scalars['Int'];
  as_of?: Maybe<Scalars['Date']>;
};

export type MutationUnlinkPileCablePelletArgs = {
  pile_cable_pellet_link_id: Scalars['Int'];
  as_of?: Maybe<Scalars['Date']>;
};

export type MutationUpdatePileArgs = {
  pile_id: Scalars['Int'];
  alias?: Maybe<Scalars['String']>;
  default_grain_type?: Maybe<GrainType>;
  orientation: Orientation;
  shape?: Maybe<ShapeInput>;
};

export type MutationUpdatePileCableLinkArgs = {
  pile_cable_link_id: Scalars['Int'];
  alias: Scalars['String'];
  x_ft: Scalars['Float'];
  y_ft: Scalars['Float'];
};

export type MutationValidateStoragePeriodOverlapsArgs = {
  grain_bin_id: Scalars['Int'];
  storage_cycle_name: Scalars['String'];
  grain_type: GrainType;
  start_epoch: Scalars['Date'];
  end_epoch?: Maybe<Scalars['Date']>;
  grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
};

export type MutationCreateBinStoragePeriodArgs = {
  grain_bin_id: Scalars['Int'];
  storage_cycle_name: Scalars['String'];
  grain_type: GrainType;
  start_epoch: Scalars['Date'];
  end_epoch?: Maybe<Scalars['Date']>;
};

export type MutationUpdateBinStoragePeriodArgs = {
  grain_bin_storage_cycle_id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
  storage_cycle_name?: Maybe<Scalars['String']>;
  grain_type?: Maybe<GrainType>;
  start_epoch?: Maybe<Scalars['Date']>;
  end_epoch?: Maybe<Scalars['Date']>;
};

export type MutationDeleteBinStoragePeriodArgs = {
  grain_bin_storage_cycle_id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
};

export type MutationCreateBinEventArgs = {
  grain_bin_id: Scalars['Int'];
  epoch_time: Scalars['Date'];
  event_type: BinEventType;
  event_value?: Maybe<Scalars['String']>;
};

export type MutationRemoveBinEventArgs = {
  grain_bin_id: Scalars['Int'];
  epoch_time: Scalars['Date'];
  event_type: BinEventType;
};

export type MutationAssignFanControllerArgs = {
  grain_bin_id: Scalars['Int'];
  core_id: Scalars['String'];
  start_date?: Maybe<Scalars['Date']>;
  end_date?: Maybe<Scalars['Date']>;
  has_plenum_sensor?: Maybe<Scalars['Boolean']>;
  cfm?: Maybe<Scalars['Float']>;
  static_pressure_in?: Maybe<Scalars['Float']>;
  alias?: Maybe<Scalars['String']>;
  phase?: Maybe<Scalars['Int']>;
  voltage?: Maybe<Scalars['Int']>;
  power_factor?: Maybe<Scalars['Float']>;
  fan_model_id?: Maybe<Scalars['Int']>;
  make: Scalars['String'];
  fan_brand_name: Scalars['String'];
  fan_model_type: FanType;
  fan_horsepower: Scalars['String'];
  diameter: Scalars['Float'];
  fan_model_created_type: FanModelCreatedType;
  linked_generic_fan_model_id?: Maybe<Scalars['Int']>;
  no_of_fans_connected: Scalars['Int'];
};

export type MutationAssignHubArgs = {
  core_id: Scalars['String'];
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  start_epoch?: Maybe<Scalars['Date']>;
  end_epoch?: Maybe<Scalars['Date']>;
  alias?: Maybe<Scalars['String']>;
  nickname: Scalars['String'];
  hub_type: HubType;
  has_level_sensor: Scalars['Boolean'];
  hub_offset_ft: Scalars['Float'];
  x_ft?: Maybe<Scalars['Float']>;
  y_ft?: Maybe<Scalars['Float']>;
  z_ft?: Maybe<Scalars['Float']>;
};

export type MutationDecommissionFanControllerArgs = {
  fan_controller_id: Scalars['Int'];
  as_of?: Maybe<Scalars['Date']>;
};

export type MutationDecommissionHubArgs = {
  hub_id: Scalars['Int'];
  as_of?: Maybe<Scalars['Date']>;
};

export type MutationSetGrainBinFanSettingsArgs = {
  grain_bin_id: Scalars['Int'];
  safety_weather?: Maybe<Scalars['Boolean']>;
  run_window?: Maybe<RunWindowInput>;
  clear_run_window?: Maybe<Scalars['Boolean']>;
};

export type MutationSetGrainContainerAerationScheduleArgs = {
  container_id: Scalars['Int'];
  container_type: ContainerType;
  run_windows: Array<RunWindowInput>;
  start_recommendation_type: FanRunWindowRecommendedOption;
  end_recommendation_type: FanRunWindowRecommendedOption;
};

export type MutationStopGrainContainerAerationArgs = {
  container_id: Scalars['Int'];
  container_type: ContainerType;
};

export type MutationUnassignFanControllerArgs = {
  grain_bin_fan_controller_id: Scalars['Int'];
  as_of?: Maybe<Scalars['Date']>;
};

export type MutationUnassignFanController2Args = {
  grain_bin_fan_controller_id: Scalars['Int'];
  as_of?: Maybe<Scalars['Date']>;
};

export type MutationUpdateGrainBinFanControllerArgs = {
  grain_bin_fan_controller_id: Scalars['Int'];
  has_plenum_sensor?: Maybe<Scalars['Boolean']>;
  cfm?: Maybe<Scalars['Float']>;
  static_pressure_in?: Maybe<Scalars['Float']>;
  alias?: Maybe<Scalars['String']>;
  phase?: Maybe<Scalars['Int']>;
  power_factor?: Maybe<Scalars['Float']>;
  voltage?: Maybe<Scalars['Int']>;
  fan_model_id?: Maybe<Scalars['Int']>;
  make: Scalars['String'];
  fan_brand_name: Scalars['String'];
  fan_model_type: FanType;
  fan_horsepower: Scalars['String'];
  diameter: Scalars['Float'];
  fan_model_created_type: FanModelCreatedType;
  linked_generic_fan_model_id?: Maybe<Scalars['Int']>;
  no_of_fans_connected?: Maybe<Scalars['Int']>;
};

export type MutationUpdateGrainBinArgs = {
  grain_bin_id: Scalars['Int'];
  bin_name?: Maybe<Scalars['String']>;
  grain_type?: Maybe<GrainType>;
  height_ft?: Maybe<Scalars['Float']>;
  diameter_ft?: Maybe<Scalars['Float']>;
  zip_code?: Maybe<Scalars['String']>;
  fan_type?: Maybe<FanType>;
  fan_power_hp?: Maybe<Scalars['Float']>;
  location?: Maybe<LocationInput>;
  current_grain_emc?: Maybe<Scalars['Float']>;
  current_grain_temp?: Maybe<Scalars['Float']>;
  has_grain_spreader?: Maybe<Scalars['Boolean']>;
  target_grain_emc?: Maybe<Scalars['Float']>;
  price_per_kwh?: Maybe<Scalars['Float']>;
  recommendation_type?: Maybe<Scalars['String']>;
  opt_in_fan_guidance?: Maybe<Scalars['Boolean']>;
  enable_fan_guidance?: Maybe<Scalars['Boolean']>;
  fan_guidance_start_date?: Maybe<Scalars['Date']>;
  fan_guidance_end_date?: Maybe<Scalars['Date']>;
  floor_type?: Maybe<FloorType>;
};

export type MutationUpdateHubAssignmentArgs = {
  hub_container_link_id: Scalars['Int'];
  start_epoch?: Maybe<Scalars['Date']>;
  end_epoch?: Maybe<Scalars['Date']>;
  nickname?: Maybe<Scalars['String']>;
  hub_type?: Maybe<HubType>;
  has_level_sensor?: Maybe<Scalars['Boolean']>;
  hub_offset_ft?: Maybe<Scalars['Float']>;
  alias?: Maybe<Scalars['String']>;
  x_ft?: Maybe<Scalars['Float']>;
  y_ft?: Maybe<Scalars['Float']>;
  z_ft?: Maybe<Scalars['Float']>;
};

export type MutationInitiateGuidedRecommendationsArgs = {
  container_id: Scalars['Int'];
  container_type: ContainerType;
  automated_period: Scalars['Int'];
};

export type MutationSaveTemporaryInputConditionsArgs = {
  grain_bin_id: Scalars['Int'];
  current_grain_temp: Scalars['Float'];
  current_grain_emc: Scalars['Float'];
  target_grain_emc?: Maybe<Scalars['Float']>;
  recommendation_type: Scalars['String'];
};

export type MutationUploadGrainFanPlotsArgs = {
  grain_bin_fan_plots: Array<Maybe<GrainFanPlotsInput>>;
};

export type MutationCreateGrainBinSiteArgs = {
  account_id: Scalars['Int'];
  site_name: Scalars['String'];
};

export type MutationRenameGrainBinSiteArgs = {
  site_id: Scalars['Int'];
  site_name: Scalars['String'];
};

export type MutationDeleteGrainBinSiteArgs = {
  site_id: Scalars['Int'];
};

export type MutationUpdateGrainBinSiteLinkArgs = {
  grain_bin_id: Scalars['Int'];
  site_id: Scalars['Int'];
};

export type MutationSaveBinEstimateTargetValuesArgs = {
  grain_bin_id: Scalars['Int'];
  target_temperature_f: Scalars['Float'];
  target_mcwb_percent: Scalars['Float'];
};

export type MutationArchiveGrainContainerArgs = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  archived_at?: Maybe<Scalars['Date']>;
};

export type MutationCreateBargeArgs = {
  account_id: Scalars['Int'];
  alias: Scalars['String'];
  default_grain_type: GrainType;
  length_ft: Scalars['Float'];
  width_ft: Scalars['Float'];
  height_ft: Scalars['Float'];
};

export type MutationCreateGrainBinArgs = {
  account_id: Scalars['Int'];
  alias: Scalars['String'];
  grain_type: GrainType;
  height_ft: Scalars['Float'];
  diameter_ft: Scalars['Float'];
  zip_code?: Maybe<Scalars['String']>;
  location: LocationInput;
  fan_type?: Maybe<FanType>;
  fan_power_hp?: Maybe<Scalars['Float']>;
  has_grain_spreader?: Maybe<Scalars['Boolean']>;
  current_grain_emc?: Maybe<Scalars['Float']>;
  current_grain_temp?: Maybe<Scalars['Float']>;
  target_grain_emc?: Maybe<Scalars['Float']>;
  price_per_kwh?: Maybe<Scalars['Float']>;
  recommendation_type?: Maybe<Scalars['String']>;
  opt_in_fan_guidance?: Maybe<Scalars['Boolean']>;
  enable_fan_guidance?: Maybe<Scalars['Boolean']>;
  can_send_fan_guidance_notification?: Maybe<Scalars['Boolean']>;
  fan_guidance_start_date?: Maybe<Scalars['Date']>;
  fan_guidance_end_date?: Maybe<Scalars['Date']>;
  floor_type: FloorType;
};

export type MutationCreateGrainBinSystemArgs = {
  account_id: Scalars['Int'];
  alias: Scalars['String'];
  grain_type: GrainType;
  height_ft: Scalars['Float'];
  diameter_ft: Scalars['Float'];
  zip_code?: Maybe<Scalars['String']>;
  location: LocationInput;
  has_grain_spreader: Scalars['Boolean'];
  current_grain_emc?: Maybe<Scalars['Float']>;
  current_grain_temp?: Maybe<Scalars['Float']>;
  target_grain_emc?: Maybe<Scalars['Float']>;
  price_per_kwh?: Maybe<Scalars['Float']>;
  recommendation_type?: Maybe<Scalars['String']>;
  fan_controllers: Array<Maybe<AssignFanControllerInput>>;
  hubs: Array<Maybe<AssignHubInput>>;
  opt_in_fan_guidance?: Maybe<Scalars['Boolean']>;
  enable_fan_guidance?: Maybe<Scalars['Boolean']>;
  can_send_fan_guidance_notification?: Maybe<Scalars['Boolean']>;
  fan_guidance_start_date?: Maybe<Scalars['Date']>;
  fan_guidance_end_date?: Maybe<Scalars['Date']>;
  floor_type: FloorType;
};

export type MutationCreatePileArgs = {
  account_id: Scalars['Int'];
  alias: Scalars['String'];
  default_grain_type: GrainType;
  orientation: Orientation;
  shape?: Maybe<ShapeInput>;
};

export type MutationRemoveBargeArgs = {
  barge_id: Scalars['Int'];
  as_of?: Maybe<Scalars['Date']>;
};

export type MutationRemovePileArgs = {
  pile_id: Scalars['Int'];
  as_of?: Maybe<Scalars['Date']>;
};

export type MutationUpdateGrainBinNotificationPrefArgs = {
  user_id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
  account_id: Scalars['Int'];
  opt_in: Scalars['Boolean'];
};

export type MutationConfigureAlertPrefArgs = {
  user_id: Scalars['Int'];
  alert_type: Scalars['String'];
};

export type MutationUpdateSpecificAlertPrefArgs = {
  user_id: Scalars['Int'];
  fan_start_stop_alerts: Scalars['Boolean'];
  fan_error_alerts: Scalars['Boolean'];
  disable_fan_guidance_alerts: Scalars['Boolean'];
  grain_bin_co2_alerts: Scalars['Boolean'];
  grain_level_alerts: Scalars['Boolean'];
};

export type MutationPerformGrainBinSelectionArgs = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  container_id: Scalars['Int'];
};

export type MutationPerformGrainBinUnselectionArgs = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
};

export type MutationArchiveUnArchiveGrainContainerArgs = {
  container_id: Scalars['Int'];
  container_type: ContainerType;
  archive_unarchive_flag: ArchiveUnArchiveType;
};

export type MutationCreateGrainBinTicketArgs = {
  grain_bin_id: Scalars['Int'];
  epoch_time: Scalars['Date'];
  volume: Scalars['Float'];
  action_performed: GrainTicketActionType;
  grain_moisture_pct: Scalars['Float'];
  notes: Scalars['String'];
  grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
  weight_in_lbs: Scalars['Float'];
  test_weight_in_lbs: Scalars['Float'];
  grain_surface_shape: GrainSurfaceShapeType;
};

export type MutationDeleteGrainBinTicketArgs = {
  grain_bin_ticket_id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
};

export type MutationUpdateGrainBinTicketArgs = {
  grain_bin_ticket_id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
  epoch_time: Scalars['Date'];
  volume: Scalars['Float'];
  action_performed: GrainTicketActionType;
  grain_moisture_pct: Scalars['Float'];
  notes: Scalars['String'];
  grain_surface_shape: GrainSurfaceShapeType;
  grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
  weight_in_lbs: Scalars['Float'];
  test_weight_in_lbs: Scalars['Float'];
};

export type MutationApproveSensorGeneratedGrainTicketArgs = {
  grain_bin_ticket_id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
  epoch_time: Scalars['Date'];
  volume: Scalars['Float'];
  grain_moisture_pct: Scalars['Float'];
  weight_in_lbs: Scalars['Float'];
  test_weight_in_lbs: Scalars['Float'];
  action_performed: GrainTicketActionType;
  notes: Scalars['String'];
  grain_surface_shape: GrainSurfaceShapeType;
  grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
};

export type MutationDeclineSensorGeneratedGrainTicketArgs = {
  grain_bin_ticket_id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
};

export type MutationSetFanRunNowScheduleArgs = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  container_id: Scalars['Int'];
  container_type: ContainerType;
  period: Scalars['String'];
};

export type MutationSubscribeWeeklyGrainEmailArgs = {
  account_id: Scalars['Int'];
  user_id: Scalars['Int'];
  schedule_day: Scalars['String'];
  schedule_hour: Scalars['Int'];
  schedule_timezone: Scalars['String'];
};

export type MutationUnsubscribeWeeklyGrainEmailArgs = {
  user_id: Scalars['Int'];
};

export type MutationUpdateWeeklyGrainEmailScheduleArgs = {
  user_id: Scalars['Int'];
  schedule_day: Scalars['String'];
  schedule_hour: Scalars['Int'];
  schedule_timezone: Scalars['String'];
};

export type MutationUpdateGrainBinWeeklyEmailPrefArgs = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
  opt_in: Scalars['Boolean'];
};

export type MutationSetNotificationsReadOnArgs = {
  last_notifications_read_on: Scalars['Date'];
};

export type MutationSaveOperationsViewUserLevelPrefsArgs = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  pref_type_key: Scalars['Int'];
  pref_json: Scalars['String'];
};

export type MutationSaveOperationsViewRowVisibilityPrefsArgs = {
  account_id: Scalars['Int'];
  pref_json: Scalars['String'];
};

export type MutationSetUserUnitsPrefArgs = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  temp_unit: TempUnitType;
  distance_unit: DistanceUnitType;
  mass_unit: MassUnitType;
  test_weight_unit: TestWeightUnitType;
};

export enum Orientation {
  NorthSouth = 'north_south',
  EastWest = 'east_west',
}

export type ParticleDevice = {
  __typename: 'ParticleDevice';
  id: Scalars['String'];
  name: Scalars['String'];
  serial_number?: Maybe<Scalars['String']>;
  last_ip_address?: Maybe<Scalars['String']>;
  last_heard?: Maybe<Scalars['Date']>;
  product_id: Scalars['Int'];
  connected: Scalars['Boolean'];
  platform_id: Scalars['Int'];
  cellular: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  iccid?: Maybe<Scalars['String']>;
  imei?: Maybe<Scalars['String']>;
  product?: Maybe<ParticleProduct>;
};

export type ParticleEvent = {
  __typename: 'ParticleEvent';
  event_id: Scalars['Int'];
  core_id: Scalars['String'];
  published_at: Scalars['Date'];
  event_name: Scalars['String'];
  event_data: Scalars['String'];
  device?: Maybe<ParticleDevice>;
};

export type ParticleProduct = {
  __typename: 'ParticleProduct';
  id: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
  type: Scalars['String'];
  devices: Array<ParticleDevice>;
};

export type Pellet = {
  __typename: 'Pellet';
  pellet_id: Scalars['String'];
  created_at: Scalars['Date'];
  pellet_group_link?: Maybe<PelletGroupPelletLink>;
  image?: Maybe<PelletImage>;
};

export type PelletCable = {
  __typename: 'PelletCable';
  pellet_group_id: Scalars['Int'];
  length_ft: Scalars['Float'];
  spacing_ft: Scalars['Float'];
  pellet_group: PelletGroup;
};

export type PelletGroup = {
  __typename: 'PelletGroup';
  pellet_group_id: Scalars['Int'];
  hostname: Scalars['String'];
  package_alias: Scalars['String'];
  pellet_links: Array<PelletGroupPelletLink>;
  created_at: Scalars['Date'];
  pellet_group_type?: Maybe<PelletGroupType>;
};

export type PelletGroupPelletLink = {
  __typename: 'PelletGroupPelletLink';
  pellet_group_pellet_link_id: Scalars['Int'];
  pellet_group_id: Scalars['Int'];
  pellet_id: Scalars['String'];
  position_no: Scalars['Int'];
  created_at: Scalars['Date'];
  reassigned_at?: Maybe<Scalars['Date']>;
  pellet: Pellet;
  pellet_group: PelletGroup;
};

export type PelletGroupPelletLinkInput = {
  pellet_id: Scalars['String'];
  position_no: Scalars['Int'];
};

export enum PelletGroupType {
  Unknown = 'unknown',
  Tray = 'tray',
  Cartridge = 'cartridge',
  Cable = 'cable',
}

export type PelletImage = {
  __typename: 'PelletImage';
  pellet_id: Scalars['String'];
  programmer_id: Scalars['String'];
  dslite_erase: Scalars['String'];
  dslite_test: Scalars['String'];
  test_img_hash?: Maybe<Scalars['String']>;
  test_result?: Maybe<Scalars['String']>;
  test_output?: Maybe<Scalars['String']>;
  pressure_offset_pa?: Maybe<Scalars['Int']>;
  dslite_final?: Maybe<Scalars['String']>;
  final_img_hash?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  ref_pellet_id?: Maybe<Scalars['String']>;
  pellet: Pellet;
  pellet_ref?: Maybe<PelletRef>;
};

export type PelletRef = {
  __typename: 'PelletRef';
  pellet_id: Scalars['String'];
  ref_pellet_id: Scalars['String'];
  pressure_offset_pa: Scalars['Int'];
  created_at: Scalars['Date'];
  pellet: Pellet;
  ref_pellet: PelletRef;
};

export type PelletTelemetry = {
  __typename: 'PelletTelemetry';
  pellet_id: Scalars['String'];
  is_emc_stable?: Maybe<Scalars['Boolean']>;
  dod_temp_diff_f: Scalars['Float'];
  latest: GrainStatus;
};

export type PelletTelemetryHistory = {
  __typename: 'PelletTelemetryHistory';
  pellet_id: Scalars['String'];
  data: Array<GrainEnvPoint>;
};

export type Pile = {
  __typename: 'Pile';
  pile_id: Scalars['Int'];
  alias: Scalars['String'];
  default_grain_type: GrainType;
  default_grain_type_next: GrainType;
  grain_store_type: Scalars['Int'];
  shape?: Maybe<Shape>;
  orientation: Orientation;
  cable_pellet_links: Array<PileCablePelletLink>;
  container: GrainContainer;
  hub_links: Array<HubContainerLink>;
  active_hub_links: Array<HubContainerLink>;
  account_link: PileAccountLink;
};

export type PileAccountLink = ContainerAccountLink & {
  __typename: 'PileAccountLink';
  account_id: Scalars['Int'];
  account: Account;
  container_id: Scalars['Int'];
  container_type: ContainerType;
  container: GrainContainer;
  pile_id: Scalars['Int'];
  pile: Pile;
};

export type PileCableLink = {
  __typename: 'PileCableLink';
  pile_cable_link_id: Scalars['Int'];
  alias: Scalars['String'];
  pile_id: Scalars['Int'];
  cable_id: Scalars['Int'];
  x_ft: Scalars['Float'];
  y_ft: Scalars['Float'];
  z_ft: Scalars['Float'];
  start_epoch: Scalars['Date'];
  end_epoch: Scalars['Date'];
  pile: Pile;
  cable: PelletCable;
};

export type PileCablePelletLink = {
  __typename: 'PileCablePelletLink';
  pile_cable_pellet_link_id: Scalars['Int'];
  pile_id: Scalars['Int'];
  cable_no: Scalars['Int'];
  pellet_id: Scalars['String'];
  start_epoch: Scalars['Date'];
  end_epoch: Scalars['Date'];
};

export type PsychrometricRange = {
  __typename: 'PsychrometricRange';
  start: Scalars['Date'];
  end: Scalars['Date'];
  temp_diff: Scalars['Float'];
  emc_diff: Scalars['Float'];
};

export type Query = {
  __typename: 'Query';
  dummy?: Maybe<Scalars['String']>;
  particle_device: ParticleDevice;
  particle_product: ParticleProduct;
  particle_products: Array<ParticleProduct>;
  particle_events: Array<ParticleEvent>;
  fan_controller?: Maybe<FanController>;
  fan_controller_request_history: Array<FanControllerRequest>;
  fan_controller_response_history: Array<FanControllerResponse>;
  fan_run_history: Array<FanRun>;
  fan_controllers: Array<FanController>;
  fan_controllers_query: Array<FanController>;
  fan_controllers_page_query: Array<FanController>;
  fan_controllers_count: Count;
  fan_controller_state: FanControllerState;
  hub: Hub;
  hubs: Array<Hub>;
  hubs_query: Array<Hub>;
  hub_events: HubEventsEdge;
  hub_event_history: HubEventHistoryEdge;
  pellet: Pellet;
  pellets: Array<Pellet>;
  pellet_group: PelletGroup;
  pellet_groups: Array<PelletGroup>;
  pellet_cable: PelletCable;
  pellet_cables: Array<PelletCable>;
  pellet_image_histories: Array<PelletImage>;
  query_device_images: Array<DeviceImageInfo>;
  account: Account;
  accounts: Array<Account>;
  user: User;
  viewer?: Maybe<Viewer>;
  isOtpValid: Scalars['Boolean'];
  barge: Barge;
  barges: Array<Barge>;
  barge_cover_pellet_links: Array<BargeCoverPelletLink>;
  pile: Pile;
  piles: Array<Pile>;
  pile_cable_pellet_links: Array<PileCablePelletLink>;
  active_pile_cable_links: Array<PileCableLink>;
  grain_bin: GrainBin;
  grain_bin_fan_settings: GrainBinFanSettings;
  grain_bin_storage_periods: Array<GrainBinStoragePeriodWithOngoingStorageFlag>;
  latest_grain_bin_events: Array<BinEvent>;
  grain_bin_fan_controllers: Array<GrainBinFanController>;
  grain_container: GrainContainer;
  /** TODO: Deprecate */
  grain_bin_telemetry?: Maybe<GrainBinTelemetry>;
  hub_container_link: HubContainerLink;
  hub_container_link_by_hub_id?: Maybe<HubContainerLink>;
  hub_container_link_by_core_id?: Maybe<HubContainerLink>;
  hub_container_links_by_container: Array<HubContainerLink>;
  hub_container_links_by_container_active: Array<HubContainerLink>;
  is_fan_controller_assigned?: Maybe<AssignmentStatus>;
  is_hub_assigned?: Maybe<AssignmentStatus>;
  are_fan_controllers_assigned: Array<AssignmentStatus>;
  are_hubs_assigned: Array<AssignmentStatus>;
  grain_fan_plots?: Maybe<Array<Maybe<GetGrainFanPlotsOutput>>>;
  grain_bin_cfm_solution?: Maybe<GrainBinCfmSolutionOutput>;
  prompt_fan_guidance_extension: Scalars['Boolean'];
  recommended_run_windows_experiments: Array<RecommRunWindowsExperiments>;
  grain_bin_experiment_cfm_values: ExperimentCfmValues;
  grain_bin_default_experiment_bound_values?: Maybe<ExperimentBoundValues>;
  containers_last_week_airspace_history: Array<Maybe<ContainerLastWeekAirspaceHistory>>;
  containers_grain_level: Array<Maybe<ContainerGrainLevel>>;
  containers_last_aeration_run: Array<Maybe<ContainerLastAerationRun>>;
  containers_aeration_schedule: Array<Maybe<ContainerAerationSchedule>>;
  containers_weather: Array<Maybe<ContainerWeather>>;
  grain_fan_models: Array<Maybe<FanModelResult>>;
  container_grain_level_percent?: Maybe<ContainerGrainLevelPercent>;
  show_grain_ticket_level_deviation_warning: Scalars['Boolean'];
  show_co2_level_warning?: Maybe<Co2AlertInfo>;
  bin_estimate_processing_state: BinEstimateProcessingStateInfo;
  bin_grain_model_prerequisites: BinGrainModelPrerequisitesInfo;
  barge_account_links_by_account_id: Array<BargeAccountLink>;
  grain_bin_account_links_by_account_id: Array<GrainBinAccountLink>;
  pile_account_links_by_account_id: Array<PileAccountLink>;
  grain_bin_tickets: GrainBinTickets;
  sensor_generated_grain_tickets: Array<SensorGeneratedGrainTicket>;
  user_device_notifications_history: Array<Maybe<GrainBinNotification>>;
  grain_bin_sites: Array<GrainBinSites>;
  operations_view_cols_visibility_prefs?: Maybe<UserPref>;
  operations_view_rows_visibility_prefs?: Maybe<RowVisibilityPref>;
  grain_bin_estimate?: Maybe<GrainBinEstimate>;
};

export type QueryParticle_DeviceArgs = {
  id: Scalars['String'];
};

export type QueryParticle_ProductArgs = {
  id: Scalars['Int'];
};

export type QueryParticle_EventsArgs = {
  device_id: Scalars['String'];
  as_of?: Maybe<Scalars['Date']>;
};

export type QueryFan_ControllerArgs = {
  fan_controller_id: Scalars['Int'];
};

export type QueryFan_Controller_Request_HistoryArgs = {
  fan_controller_id: Scalars['Int'];
  as_of?: Maybe<Scalars['Date']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryFan_Controller_Response_HistoryArgs = {
  fan_controller_id: Scalars['Int'];
  as_of?: Maybe<Scalars['Date']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryFan_Run_HistoryArgs = {
  fan_controller_id: Scalars['Int'];
  as_of?: Maybe<Scalars['Date']>;
};

export type QueryFan_ControllersArgs = {
  fan_controller_id: Array<Scalars['Int']>;
};

export type QueryFan_Controllers_Page_QueryArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  sortBy: Scalars['String'];
  sortAsc: Scalars['Boolean'];
};

export type QueryFan_Controller_StateArgs = {
  fan_controller_id: Scalars['Int'];
};

export type QueryHubArgs = {
  hub_id: Scalars['Int'];
};

export type QueryHubsArgs = {
  hub_id: Array<Scalars['Int']>;
};

export type QueryHubs_QueryArgs = {
  env: Scalars['String'];
};

export type QueryHub_EventsArgs = {
  hub_id: Scalars['Int'];
  as_of?: Maybe<Scalars['Date']>;
};

export type QueryHub_Event_HistoryArgs = {
  hub_id: Scalars['Int'];
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type QueryPelletArgs = {
  pellet_id: Scalars['String'];
};

export type QueryPelletsArgs = {
  pellet_id: Array<Scalars['String']>;
};

export type QueryPellet_GroupArgs = {
  pellet_group_id: Scalars['Int'];
};

export type QueryPellet_GroupsArgs = {
  pellet_group_id: Array<Scalars['Int']>;
};

export type QueryPellet_CableArgs = {
  pellet_group_id: Scalars['Int'];
};

export type QueryPellet_CablesArgs = {
  pellet_group_id: Array<Scalars['Int']>;
};

export type QueryPellet_Image_HistoriesArgs = {
  pellet_id: Array<Scalars['String']>;
};

export type QueryQuery_Device_ImagesArgs = {
  image_type: ImageType;
  version?: Maybe<Scalars['String']>;
};

export type QueryAccountArgs = {
  account_id: Scalars['Int'];
};

export type QueryAccountsArgs = {
  account_ids?: Maybe<Array<Scalars['Int']>>;
};

export type QueryUserArgs = {
  user_id: Scalars['Int'];
};

export type QueryIsOtpValidArgs = {
  token: Scalars['String'];
};

export type QueryBargeArgs = {
  barge_id: Scalars['Int'];
};

export type QueryBargesArgs = {
  barge_ids: Array<Scalars['Int']>;
};

export type QueryBarge_Cover_Pellet_LinksArgs = {
  barge_id: Scalars['Int'];
};

export type QueryPileArgs = {
  pile_id: Scalars['Int'];
};

export type QueryPilesArgs = {
  pile_ids: Array<Scalars['Int']>;
};

export type QueryPile_Cable_Pellet_LinksArgs = {
  pile_id: Scalars['Int'];
};

export type QueryActive_Pile_Cable_LinksArgs = {
  pile_id: Scalars['Int'];
};

export type QueryGrain_BinArgs = {
  grain_bin_id: Scalars['Int'];
};

export type QueryGrain_Bin_Fan_SettingsArgs = {
  grain_bin_id: Scalars['Int'];
};

export type QueryGrain_Bin_Storage_PeriodsArgs = {
  grain_bin_id: Scalars['Int'];
};

export type QueryLatest_Grain_Bin_EventsArgs = {
  grain_bin_id: Scalars['Int'];
};

export type QueryGrain_Bin_Fan_ControllersArgs = {
  grain_bin_id: Scalars['Int'];
};

export type QueryGrain_ContainerArgs = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
};

export type QueryGrain_Bin_TelemetryArgs = {
  grain_bin_id: Scalars['Int'];
  as_of?: Maybe<Scalars['Date']>;
};

export type QueryHub_Container_LinkArgs = {
  hub_container_link_id: Scalars['Int'];
};

export type QueryHub_Container_Link_By_Hub_IdArgs = {
  hub_id: Scalars['Int'];
  as_of?: Maybe<Scalars['Date']>;
};

export type QueryHub_Container_Link_By_Core_IdArgs = {
  core_id: Scalars['String'];
  as_of?: Maybe<Scalars['Date']>;
};

export type QueryHub_Container_Links_By_ContainerArgs = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
};

export type QueryHub_Container_Links_By_Container_ActiveArgs = {
  as_of?: Maybe<Scalars['Date']>;
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
};

export type QueryIs_Fan_Controller_AssignedArgs = {
  device_id: Scalars['String'];
};

export type QueryIs_Hub_AssignedArgs = {
  device_id: Scalars['String'];
};

export type QueryAre_Fan_Controllers_AssignedArgs = {
  device_ids: Array<Scalars['String']>;
};

export type QueryAre_Hubs_AssignedArgs = {
  device_ids: Array<Scalars['String']>;
};

export type QueryGrain_Fan_PlotsArgs = {
  fan_brand_name?: Maybe<Scalars['String']>;
  fan_model_type?: Maybe<FanType>;
  fan_horsepower?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['Float']>;
};

export type QueryGrain_Bin_Cfm_SolutionArgs = {
  grain_bin_id: Scalars['Int'];
};

export type QueryPrompt_Fan_Guidance_ExtensionArgs = {
  grain_bin_id: Scalars['Int'];
};

export type QueryRecommended_Run_Windows_ExperimentsArgs = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  recommendation_type?: Maybe<Scalars['String']>;
  cfm_scaling?: Maybe<Scalars['Float']>;
  cfm_offset?: Maybe<Scalars['Float']>;
  cfm_min?: Maybe<Scalars['Float']>;
  cfm_max?: Maybe<Scalars['Float']>;
  cfm?: Maybe<Scalars['Float']>;
  upper_bound?: Maybe<Scalars['Float']>;
  lower_bound?: Maybe<Scalars['Float']>;
  prev_week_no?: Maybe<Scalars['Int']>;
  from_weather_history?: Maybe<Scalars['Boolean']>;
};

export type QueryGrain_Bin_Experiment_Cfm_ValuesArgs = {
  grain_bin_id: Scalars['Int'];
};

export type QueryGrain_Bin_Default_Experiment_Bound_ValuesArgs = {
  grain_bin_id: Scalars['Int'];
};

export type QueryContainers_Last_Week_Airspace_HistoryArgs = {
  grain_bin_ids: Array<Scalars['Int']>;
};

export type QueryContainers_Grain_LevelArgs = {
  grain_bin_ids: Array<Scalars['Int']>;
};

export type QueryContainers_Last_Aeration_RunArgs = {
  grain_bin_ids: Array<Scalars['Int']>;
};

export type QueryContainers_Aeration_ScheduleArgs = {
  grain_bin_ids: Array<Scalars['Int']>;
};

export type QueryContainers_WeatherArgs = {
  grain_bin_ids: Array<Scalars['Int']>;
};

export type QueryGrain_Fan_ModelsArgs = {
  fan_model_filter: Array<FanModelFilter>;
};

export type QueryContainer_Grain_Level_PercentArgs = {
  grain_bin_id: Scalars['Int'];
};

export type QueryShow_Grain_Ticket_Level_Deviation_WarningArgs = {
  grain_bin_id: Scalars['Int'];
  grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
};

export type QueryShow_Co2_Level_WarningArgs = {
  container_id: Scalars['Int'];
};

export type QueryBin_Estimate_Processing_StateArgs = {
  grain_bin_id: Scalars['Int'];
};

export type QueryBin_Grain_Model_PrerequisitesArgs = {
  grain_bin_id: Scalars['Int'];
};

export type QueryBarge_Account_Links_By_Account_IdArgs = {
  account_id: Scalars['Int'];
};

export type QueryGrain_Bin_Account_Links_By_Account_IdArgs = {
  account_id: Scalars['Int'];
};

export type QueryPile_Account_Links_By_Account_IdArgs = {
  account_id: Scalars['Int'];
};

export type QueryGrain_Bin_TicketsArgs = {
  grain_bin_id: Scalars['Int'];
  grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
};

export type QuerySensor_Generated_Grain_TicketsArgs = {
  grain_bin_id: Scalars['Int'];
  grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
};

export type QueryUser_Device_Notifications_HistoryArgs = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  period: Scalars['String'];
};

export type QueryGrain_Bin_SitesArgs = {
  account_id: Scalars['Int'];
  user_id: Scalars['Int'];
};

export type QueryOperations_View_Cols_Visibility_PrefsArgs = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
};

export type QueryOperations_View_Rows_Visibility_PrefsArgs = {
  account_id: Scalars['Int'];
};

export type QueryGrain_Bin_EstimateArgs = {
  grain_bin_id: Scalars['Int'];
};

export type RecommendationWindows = {
  __typename: 'RecommendationWindows';
  emc_ranges?: Maybe<Array<PsychrometricRange>>;
  temp_ranges?: Maybe<Array<PsychrometricRange>>;
  emc_reconditioning_ranges?: Maybe<Array<PsychrometricRange>>;
};

export type RecommRunWindowsExperiments = {
  __typename: 'RecommRunWindowsExperiments';
  name: Scalars['String'];
  experiment: Scalars['String'];
  type: Scalars['String'];
  emc_ranges?: Maybe<Array<PsychrometricRange>>;
  temp_ranges?: Maybe<Array<PsychrometricRange>>;
  emc_reconditioning_ranges?: Maybe<Array<PsychrometricRange>>;
  hasCfmPresent: Scalars['Boolean'];
};

export type RelativePosition = {
  __typename: 'RelativePosition';
  x_ft: Scalars['Float'];
  y_ft: Scalars['Float'];
  z_ft: Scalars['Float'];
};

export type RootImage = {
  __typename: 'RootImage';
  root_id: Scalars['String'];
  test_img_hash?: Maybe<Scalars['String']>;
  test_result?: Maybe<Scalars['String']>;
  serial_output?: Maybe<Scalars['String']>;
  pressure_offset_pa?: Maybe<Scalars['Int']>;
  final_img_hash?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  ref_device_id?: Maybe<Scalars['String']>;
  ref_device?: Maybe<PelletRef>;
};

export type RowVisibilityPref = {
  __typename: 'RowVisibilityPref';
  account_id: Scalars['Int'];
  pref_json: Scalars['String'];
};

export type RunWindowInput = {
  start_epoch: Scalars['Date'];
  end_epoch: Scalars['Date'];
};

export type SensorGeneratedGrainTicket = {
  __typename: 'SensorGeneratedGrainTicket';
  grain_bin_ticket_id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
  epoch_time: Scalars['Date'];
  bin_level_mm: Scalars['Float'];
  volume: Scalars['Float'];
  action_performed: GrainTicketActionType;
  notes?: Maybe<Scalars['String']>;
  approve_state: Scalars['String'];
  created_at: Scalars['Date'];
  updated_at: Scalars['Date'];
};

export type Shape = {
  __typename: 'Shape';
  height_ft?: Maybe<Scalars['Float']>;
  length_ft?: Maybe<Scalars['Float']>;
  width_ft?: Maybe<Scalars['Float']>;
  radius_ft?: Maybe<Scalars['Float']>;
  shape_type: ShapeType;
};

export type ShapeInput = {
  height_ft?: Maybe<Scalars['Float']>;
  length_ft?: Maybe<Scalars['Float']>;
  width_ft?: Maybe<Scalars['Float']>;
  radius_ft?: Maybe<Scalars['Float']>;
  shape_type: ShapeType;
};

export enum ShapeType {
  HalfCylinder = 'half_cylinder',
  Cylinder = 'cylinder',
  Cone = 'cone',
  Cuboid = 'cuboid',
}

export type SmsSettings = {
  __typename: 'SmsSettings';
  enabled: Scalars['Boolean'];
  daily_forecast_report: Scalars['Boolean'];
  fan_controller: Scalars['Boolean'];
};

export type SmsSettingsInput = {
  enabled: Scalars['Boolean'];
  daily_forecast_report: Scalars['Boolean'];
  fan_controller?: Maybe<Scalars['Boolean']>;
};

export type StateRange = {
  __typename: 'StateRange';
  state: Scalars['String'];
  low: Scalars['Float'];
  high: Scalars['Float'];
};

export type StaticPressureCfmSolution = {
  __typename: 'StaticPressureCfmSolution';
  solution_id: Scalars['Int'];
  curve_type: Scalars['String'];
  grain_type: Scalars['String'];
  floor_type: FloorType;
  fan_model: GrainBinFanModelOutput;
  number_of_fans: Scalars['Int'];
  fan_configuration: FanConfiguration;
  bin_diameter_ft: Scalars['Float'];
  grain_depth_ft: Scalars['Float'];
  cfm: Scalars['Float'];
  cfm_per_bushel: Scalars['Float'];
  total_bushels: Scalars['Float'];
  static_pressure: Scalars['Float'];
  delta_static_pressure: Scalars['Float'];
  found_solution: Scalars['Boolean'];
  created_at: Scalars['Date'];
  updated_at: Scalars['Date'];
};

export type Stats = {
  __typename: 'Stats';
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  median?: Maybe<Scalars['Float']>;
};

export type Subscription = {
  __typename: 'Subscription';
  dummy?: Maybe<Scalars['String']>;
};

export type TempMinMax = {
  __typename: 'TempMinMax';
  temp_high_f: Scalars['Float'];
  temp_low_f: Scalars['Float'];
};

export type TemporaryRecommendationWindows = {
  __typename: 'TemporaryRecommendationWindows';
  recommendation_windows?: Maybe<RecommendationWindows>;
  recommendation_type?: Maybe<Scalars['String']>;
};

export enum TempUnitType {
  Fahrenheit = 'fahrenheit',
  Celsius = 'celsius',
}

export enum TestWeightUnitType {
  PoundPerUsBushel = 'pound_per_us_bushel',
  KilogramPerHectoliter = 'kilogram_per_hectoliter',
}

export type Token = {
  __typename: 'Token';
  token: Scalars['String'];
};

export type User = {
  __typename: 'User';
  id: Scalars['Int'];
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  account: Account;
  email_address: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  telephone: Scalars['String'];
  verified_email: Scalars['Boolean'];
  verified_sms: Scalars['Boolean'];
  entitlements: Entitlements;
  settings: UserSettings;
  needs_pw_change?: Maybe<Scalars['Boolean']>;
  role: UserRole;
  selected_grain_bin?: Maybe<UserSelectedGrainBin>;
  grain_notification_opt_ins: Array<GrainBinNotificationOptIn>;
  grain_specific_alert_prefs: GrainSpecificAlertPrefs;
  alert_pref: UserAlertPref;
  weekly_grain_email_schedule?: Maybe<UserWeeklyGrainEmailSchedule>;
  grain_weekly_email_opt_pref: Array<GrainBinWeeklyEmailPref>;
  grain_bin_fan_run_now_period_pref: GrainBinFanRunNowPeriodPref;
  user_last_notifications_read_on: Scalars['Date'];
  user_new_notifications_count: Scalars['Int'];
  user_units_pref: UserUnitsPref;
};

export type UserUser_New_Notifications_CountArgs = {
  period: Scalars['String'];
};

export type UserAlertPref = {
  __typename: 'UserAlertPref';
  user_id: Scalars['Int'];
  alert_type: Scalars['String'];
};

export type UserId = {
  __typename: 'UserId';
  user_id: Scalars['Int'];
};

export type UserModel = {
  __typename: 'UserModel';
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  email_address: Scalars['String'];
  password_hash: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  telephone: Scalars['String'];
  verified_email: Scalars['Boolean'];
  verified_sms: Scalars['Boolean'];
  updated_at: Scalars['Date'];
  entitlements: Entitlements;
  settings: UserSettings;
  needs_pw_change?: Maybe<Scalars['Boolean']>;
  role: UserRole;
};

export type UserNotificationRead = {
  __typename: 'UserNotificationRead';
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  last_notifications_read_on: Scalars['Date'];
};

export type UserPref = {
  __typename: 'UserPref';
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  pref_type_key: Scalars['Int'];
  pref_json: Scalars['String'];
};

export enum UserRole {
  Admin = 'admin',
  FanAccess = 'fan_access',
  FullAccess = 'full_access',
  ReadOnly = 'read_only',
}

export type UserSelectedGrainBin = {
  __typename: 'UserSelectedGrainBin';
  account_id: Scalars['Int'];
  user_id: Scalars['Int'];
  container_id?: Maybe<Scalars['Int']>;
  container_type?: Maybe<Scalars['Int']>;
  container_name: Scalars['String'];
};

export type UserSelectedGrainContainer = {
  __typename: 'UserSelectedGrainContainer';
  account_id: Scalars['Int'];
  user_id: Scalars['Int'];
  container_id?: Maybe<Scalars['Int']>;
  container_type?: Maybe<Scalars['Int']>;
};

export type UserSettings = {
  __typename: 'UserSettings';
  version: Scalars['String'];
  sms_alerts: SmsSettings;
  enabled: Scalars['Boolean'];
  daily_forecast_report: Scalars['Boolean'];
  fan_controller: Scalars['Boolean'];
};

export type UserSettingsInput = {
  version: Scalars['String'];
  enabled: Scalars['Boolean'];
  daily_forecast_report: Scalars['Boolean'];
  fan_controller: Scalars['Boolean'];
};

export type UserToken = {
  __typename: 'UserToken';
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  token: Scalars['String'];
};

export type UserUnitsPref = {
  __typename: 'UserUnitsPref';
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  temp_unit: TempUnitType;
  distance_unit: DistanceUnitType;
  mass_unit: MassUnitType;
  test_weight_unit: TestWeightUnitType;
};

export type UserWeeklyGrainEmailSchedule = {
  __typename: 'UserWeeklyGrainEmailSchedule';
  user_id: Scalars['Int'];
  schedule_day: Scalars['String'];
  schedule_hour: Scalars['Int'];
  schedule_timezone: Scalars['String'];
  subscribed: Scalars['Boolean'];
  last_email_sent_at?: Maybe<Scalars['Date']>;
  next_email_schedule_date: Scalars['Date'];
  updated_at: Scalars['Date'];
};

export type Viewer = {
  __typename: 'Viewer';
  id: Scalars['Int'];
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  user: User;
  account: Account;
  token: Scalars['String'];
  user_role: UserRole;
  user_verified: Scalars['Boolean'];
};

export type WeatherForecast = {
  __typename: 'WeatherForecast';
  daily_forecast?: Maybe<Array<Maybe<ForecastDayV2>>>;
  aeration_windows: Array<AerationWindow>;
  hourly_forecast: Array<GrainStatusV2>;
};

export type WeatherHistory = {
  __typename: 'WeatherHistory';
  daily_weather_history?: Maybe<Array<Maybe<ForecastDayV2>>>;
  aeration_run_history: Array<GrainContainerAerationRun>;
  hourly_weather_history?: Maybe<Array<Maybe<GrainStatusV2>>>;
};

export type WeatherHistoryPoint = {
  __typename: 'WeatherHistoryPoint';
  epoch_time: Scalars['Date'];
  temp_f: Scalars['Float'];
  humidity_rh: Scalars['Float'];
  emc: Scalars['Float'];
};

export enum WeatherIcon {
  Clear = 'clear',
  Partly = 'partly',
  Windy = 'windy',
  Cloudy = 'cloudy',
  Rain = 'rain',
  Sleet = 'sleet',
  Snow = 'snow',
}

export type WeatherInfo = {
  __typename: 'WeatherInfo';
  temp: TempMinMax;
  humidity: HumidityMinMax;
  emc: EmcMinMax;
  icon: Scalars['String'];
};
export type AccountFragmentFragment = { __typename: 'Account' } & Pick<
  Account,
  | 'account_id'
  | 'barge_support'
  | 'grain_bin_support'
  | 'pile_support'
  | 'organization'
  | 'system_account'
> & {
    barge_links: Array<
      { __typename: 'BargeAccountLink' } & Pick<BargeAccountLink, 'barge_id'> & {
          barge: { __typename: 'Barge' } & Pick<
            Barge,
            'default_grain_type' | 'barge_id' | 'alias' | 'archived'
          >;
        }
    >;
    pile_links: Array<
      { __typename: 'PileAccountLink' } & Pick<PileAccountLink, 'pile_id'> & {
          pile: { __typename: 'Pile' } & Pick<Pile, 'default_grain_type' | 'pile_id' | 'alias'>;
        }
    >;
    grain_bin_links: Array<
      { __typename: 'GrainBinAccountLink' } & Pick<GrainBinAccountLink, 'grain_bin_id'> & {
          grain_bin: { __typename: 'GrainBin' } & Pick<
            GrainBin,
            'grain_bin_id' | 'alias' | 'grain_type' | 'archived'
          > & {
              location: { __typename: 'Coordinates2' } & Pick<
                Coordinates2,
                'latitude' | 'longitude'
              >;
              fan_controllers: Array<
                { __typename: 'GrainBinFanController' } & Pick<
                  GrainBinFanController,
                  'grain_bin_fan_controller_id' | 'alias'
                > & {
                    fan_controller: { __typename: 'FanController' } & Pick<
                      FanController,
                      'fan_controller_id_next'
                    > & {
                        state_next: Maybe<
                          { __typename: 'FanControllerState' } & Pick<
                            FanControllerState,
                            'fan_controller_id' | 'value_next' | 'is_on' | 'error_code'
                          >
                        >;
                      };
                  }
              >;
            };
        }
    >;
  };

export type AerationWindowFragmentFragment = { __typename: 'AerationWindow' } & Pick<
  AerationWindow,
  'start' | 'end'
>;

export type BargeAccountLinkIdFragmentFragment = { __typename: 'BargeAccountLink' } & Pick<
  BargeAccountLink,
  'barge_id'
>;

export type BargeAccountLinkInternalFragmentFragment = { __typename: 'BargeAccountLink' } & {
  barge: { __typename: 'Barge' } & BargeFragmentFragment;
  container: { __typename: 'GrainContainer' } & GrainContainerHubLinksFragmentFragment;
} & BargeAccountLinkIdFragmentFragment;

export type BargeCoverPelletLinkFragmentFragment = { __typename: 'BargeCoverPelletLink' } & Pick<
  BargeCoverPelletLink,
  'barge_cover_pellet_link_id' | 'barge_id' | 'cover_no' | 'pellet_id' | 'start_epoch'
>;

export type BargeFragmentFragment = { __typename: 'Barge' } & Pick<
  Barge,
  | 'barge_id'
  | 'alias'
  | 'default_grain_type'
  | 'length_ft'
  | 'width_ft'
  | 'height_ft'
  | 'archived'
  | 'updated_at'
>;

export type BaseAccountFragmentFragment = { __typename: 'Account' } & Pick<
  Account,
  'account_id' | 'organization' | 'grain_bin_support' | 'barge_support' | 'pile_support'
>;

export type BinEventFragmentFragment = { __typename: 'BinEvent' } & Pick<
  BinEvent,
  'epoch_time' | 'event_type' | 'event_value'
>;

export type BinGrainModelPrerequisitesInfoFragmentFragment = {
  __typename: 'BinGrainModelPrerequisitesInfo';
} & Pick<
  BinGrainModelPrerequisitesInfo,
  | 'grain_bin_id'
  | 'has_active_fan_controller'
  | 'has_active_hub'
  | 'has_active_ongoing_storage_period'
  | 'has_active_storage_period_in_year'
  | 'has_active_grain_tickets'
  | 'has_acceptable_grain_type'
  | 'has_valid_fan_airflow'
  | 'is_meeting_all_prerequisites'
>;

export type ComputedForecastHourFragmentFragment = { __typename: 'GrainStatus' } & Pick<
  GrainStatus,
  'epoch_time' | 'humidity_rh' | 'temp_f' | 'emc' | 'humidity_state' | 'aeration_state'
>;

export type ComputedForecastHourV2FragmentFragment = { __typename: 'GrainStatusV2' } & Pick<
  GrainStatusV2,
  | 'epoch_time'
  | 'humidity_rh'
  | 'temp_f'
  | 'emc'
  | 'precip_intensity_inph'
  | 'humidity_state'
  | 'aeration_state'
  | 'solarradiation_watt_per_square_metre'
  | 'sunelevation'
  | 'ghiradiation'
>;

export type ExperimentBoundValuesFragmentFragment = { __typename: 'ExperimentBoundValues' } & Pick<
  ExperimentBoundValues,
  'lower_bound' | 'upper_bound'
>;

export type ExperimentCfmValuesFragmentFragment = { __typename: 'ExperimentCfmValues' } & Pick<
  ExperimentCfmValues,
  'cfm_scaling' | 'cfm_offset' | 'cfm_min' | 'cfm_max' | 'cfm'
>;

export type ExperimentPsychrometricRangeFragmentFragment = {
  __typename: 'ExperimentPsychrometricRange';
} & Pick<ExperimentPsychrometricRange, 'name' | 'experiment' | 'type'> & {
    emc_ranges: Maybe<
      Array<{ __typename: 'PsychrometricRange' } & PsychrometricRangeFragmentFragment>
    >;
    temp_ranges: Maybe<
      Array<{ __typename: 'PsychrometricRange' } & PsychrometricRangeFragmentFragment>
    >;
    emc_reconditioning_ranges: Maybe<
      Array<{ __typename: 'PsychrometricRange' } & PsychrometricRangeFragmentFragment>
    >;
  };

export type FanControllerDiagnosticsFragmentFragment = { __typename: 'FanController' } & Pick<
  FanController,
  'fan_controller_id_next' | 'core_id' | 'hardware_rev' | 'diag_test_is_enable'
> & {
    state_next: Maybe<{ __typename: 'FanControllerState' } & FanControllerStateFragmentFragment>;
    particle: Maybe<{ __typename: 'ParticleDevice' } & ParticleDeviceFragmentFragment>;
    grain_bin_link: Maybe<
      { __typename: 'GrainBinFanController' } & Pick<
        GrainBinFanController,
        'grain_bin_fan_controller_id'
      > & {
          grain_bin: { __typename: 'GrainBin' } & Pick<GrainBin, 'grain_bin_id' | 'alias'> & {
              account_link: { __typename: 'GrainBinAccountLink' } & Pick<
                GrainBinAccountLink,
                'grain_bin_id' | 'account_id'
              > & { account: { __typename: 'Account' } & BaseAccountFragmentFragment };
            };
        }
    >;
  };

export type FanControllerFragmentFragment = { __typename: 'FanController' } & Pick<
  FanController,
  'fan_controller_id_next' | 'core_id' | 'environment' | 'alias' | 'diag_test_is_enable'
> & {
    state_next: Maybe<{ __typename: 'FanControllerState' } & FanControllerStateFragmentFragment>;
  };

export type FanControllerPageFragmentFragment = { __typename: 'FanController' } & Pick<
  FanController,
  'fan_controller_id_next' | 'core_id' | 'hardware_rev' | 'alias'
> & {
    grain_bin_link: Maybe<
      { __typename: 'GrainBinFanController' } & Pick<
        GrainBinFanController,
        'grain_bin_fan_controller_id'
      > & {
          grain_bin: { __typename: 'GrainBin' } & Pick<GrainBin, 'grain_bin_id' | 'alias'> & {
              account_link: { __typename: 'GrainBinAccountLink' } & Pick<
                GrainBinAccountLink,
                'grain_bin_id' | 'account_id'
              > & { account: { __typename: 'Account' } & BaseAccountFragmentFragment };
            };
        }
    >;
  };

export type FanControllerRequestFragmentFragment =
  | ({ __typename: 'FanControllerPingRequest' } & Pick<
      FanControllerPingRequest,
      'fan_controller_id' | 'request_epoch' | 'updated_at' | 'sns_message_id' | 'delivered'
    >)
  | ({ __typename: 'FanControllerResetRequest' } & Pick<
      FanControllerResetRequest,
      'fan_controller_id' | 'request_epoch' | 'updated_at' | 'sns_message_id' | 'delivered'
    >)
  | ({ __typename: 'FanControllerSetConfigRequest' } & Pick<
      FanControllerSetConfigRequest,
      | 'fan_controller_id'
      | 'request_epoch'
      | 'updated_at'
      | 'sns_message_id'
      | 'delivered'
      | 'enabled'
      | 'safety_interval'
      | 'first_request_epoch'
      | 'vibration_threshold'
      | 'error_code'
    > & {
        run_window: Maybe<
          { __typename: 'FanControllerRunWindow' } & Pick<
            FanControllerRunWindow,
            'start_epoch' | 'end_epoch'
          >
        >;
      });

export type FanControllerResponseFragmentFragment =
  | ({ __typename: 'FanControllerResetResponse' } & Pick<
      FanControllerResetResponse,
      'fan_controller_id' | 'response_epoch' | 'event_id' | 'sequence_number'
    > & { reset_reason: FanControllerResetResponse['reason'] })
  | ({ __typename: 'FanControllerGetConfigResponse' } & Pick<
      FanControllerGetConfigResponse,
      'fan_controller_id' | 'response_epoch' | 'event_id' | 'sequence_number'
    > & { get_config_reason: FanControllerGetConfigResponse['reason'] })
  | ({ __typename: 'FanControllerHeartbeatResponse' } & Pick<
      FanControllerHeartbeatResponse,
      | 'fan_controller_id'
      | 'response_epoch'
      | 'event_id'
      | 'sequence_number'
      | 'enabled'
      | 'is_on'
      | 'is_override'
      | 'current_a'
      | 'safety_interval'
      | 'vibration_magnitude'
      | 'rssi'
      | 'request_epoch'
      | 'vibration_threshold'
      | 'error_code'
      | 'signal_quality'
    > & { heartbeat_reason: FanControllerHeartbeatResponse['reason'] } & {
        run_window: Maybe<
          { __typename: 'FanControllerRunWindow' } & Pick<
            FanControllerRunWindow,
            'start_epoch' | 'end_epoch'
          >
        >;
      })
  | ({ __typename: 'FanControllerDebugResponse' } & Pick<
      FanControllerDebugResponse,
      'fan_controller_id' | 'response_epoch' | 'event_id' | 'sequence_number' | 'payload'
    >);

export type FanControllerStateFragmentFragment = { __typename: 'FanControllerState' } & Pick<
  FanControllerState,
  | 'value_next'
  | 'is_override'
  | 'updated_at'
  | 'fan_controller_id'
  | 'ping_attempts'
  | 'config_attempts'
  | 'is_on'
  | 'error_code'
> & {
    config: { __typename: 'FanControllerConfig' } & Pick<
      FanControllerConfig,
      'enabled' | 'safety_interval' | 'vibration_threshold' | 'config_epoch'
    > & {
        run_window: Maybe<
          { __typename: 'FanControllerRunWindow' } & Pick<
            FanControllerRunWindow,
            'start_epoch' | 'end_epoch'
          >
        >;
      };
  };

export type FanModelResultValFragmentFragment = { __typename: 'FanModelResultVal' } & Pick<
  FanModelResultVal,
  | 'fan_model_id'
  | 'fan_model_name'
  | 'fan_brand_name'
  | 'fan_horsepower'
  | 'fan_model_type'
  | 'make'
  | 'diameter'
  | 'fan_model_created_type'
  | 'is_generic'
  | 'linked_generic_fan_model_id'
>;

export type FanModelResultValuesFragmentFragment = { __typename: 'FanModelResultValues' } & Pick<
  FanModelResultValues,
  'key'
> & { label: { __typename: 'FanModelResultVal' } & FanModelResultValFragmentFragment };

export type FanRunFragmentFragment = { __typename: 'FanRun' } & Pick<
  FanRun,
  | 'fan_controller_id'
  | 'start_epoch'
  | 'end_epoch'
  | 'current_avg_a'
  | 'reason_start'
  | 'reason_end'
>;

export type FanSettingsFragmentFragment = { __typename: 'GrainBinFanSettings' } & Pick<
  GrainBinFanSettings,
  'grain_bin_id' | 'safety_weather' | 'updated_at'
> & {
    run_window: Maybe<
      { __typename: 'FanControllerRunWindow' } & Pick<
        FanControllerRunWindow,
        'start_epoch' | 'end_epoch'
      >
    >;
    grain_bin: { __typename: 'GrainBin' } & Pick<GrainBin, 'grain_bin_id'> & {
        fan_controllers: Array<
          { __typename: 'GrainBinFanController' } & GrainBinFanControllerFragmentFragment
        >;
      };
  };

export type ForecastDataFragmentFragment = { __typename: 'ForecastData' } & {
  computed_forecast: Maybe<
    { __typename: 'HourlyForecastStatus' } & HourlyForecastStatusFragmentFragment
  >;
  daily_forecast: Maybe<Array<Maybe<{ __typename: 'ForecastDay' } & ForecastDayFragmentFragment>>>;
  emc_reconditioning_ranges: Maybe<
    Array<{ __typename: 'PsychrometricRange' } & PsychrometricRangeFragmentFragment>
  >;
  emc_ranges: Maybe<
    Array<{ __typename: 'PsychrometricRange' } & PsychrometricRangeFragmentFragment>
  >;
  temp_ranges: Maybe<
    Array<{ __typename: 'PsychrometricRange' } & PsychrometricRangeFragmentFragment>
  >;
};

export type ForecastDataV2FragmentFragment = { __typename: 'ForecastDataV2' } & {
  computed_forecast: Maybe<
    { __typename: 'HourlyForecastStatusV2' } & HourlyForecastStatusV2FragmentFragment
  >;
  daily_forecast: Maybe<
    Array<Maybe<{ __typename: 'ForecastDayV2' } & ForecastDayV2FragmentFragment>>
  >;
  emc_reconditioning_ranges: Maybe<
    Array<{ __typename: 'PsychrometricRange' } & PsychrometricRangeFragmentFragment>
  >;
  emc_ranges: Maybe<
    Array<{ __typename: 'PsychrometricRange' } & PsychrometricRangeFragmentFragment>
  >;
  temp_ranges: Maybe<
    Array<{ __typename: 'PsychrometricRange' } & PsychrometricRangeFragmentFragment>
  >;
};

export type DayNightStatsFragmentFragment = { __typename: 'DayNightRange' } & {
  day: { __typename: 'Stats' } & Pick<Stats, 'max' | 'min' | 'median'>;
  night: { __typename: 'Stats' } & Pick<Stats, 'max' | 'min' | 'median'>;
};

export type ForecastDayFragmentFragment = { __typename: 'ForecastDay' } & Pick<
  ForecastDay,
  'epoch_time' | 'daytime_range' | 'nighttime_range' | 'day_icon' | 'night_icon'
> & {
    temp: { __typename: 'DayNightRange' } & DayNightStatsFragmentFragment;
    rh: { __typename: 'DayNightRange' } & DayNightStatsFragmentFragment;
    emc: { __typename: 'DayNightRange' } & DayNightStatsFragmentFragment;
  };

export type ForecastDayV2FragmentFragment = { __typename: 'ForecastDayV2' } & Pick<
  ForecastDayV2,
  | 'epoch_time'
  | 'daytime_range'
  | 'nighttime_range'
  | 'icon'
  | 'precip_intensity_inph'
  | 'solarradiation_watt_per_square_metre'
  | 'sunelevation'
  | 'ghiradiation'
  | 'temp_low_f'
  | 'temp_high_f'
> & {
    temp: { __typename: 'DayNightRange' } & DayNightStatsFragmentFragment;
    rh: { __typename: 'DayNightRange' } & DayNightStatsFragmentFragment;
    emc: { __typename: 'DayNightRange' } & DayNightStatsFragmentFragment;
  };

export type GetFanModelResultFragmentFragment = { __typename: 'FanModelResult' } & Pick<
  FanModelResult,
  'label' | 'key'
> & {
    values: Array<
      Maybe<{ __typename: 'FanModelResultValues' } & FanModelResultValuesFragmentFragment>
    >;
  };

export type GetGrainFanPlotsOutputFragmentFragment = {
  __typename: 'GetGrainFanPlotsOutput';
} & Pick<GetGrainFanPlotsOutput, 'number_of_plots'> & {
    fan_model: { __typename: 'GrainBinFanModelOutput' } & Pick<
      GrainBinFanModelOutput,
      | 'fan_model_id'
      | 'fan_model_name'
      | 'fan_model_type'
      | 'fan_brand_name'
      | 'fan_horsepower'
      | 'diameter'
      | 'is_generic'
      | 'created_at'
      | 'updated_at'
    >;
  };

export type GrainBinAccountLinkIdFragmentFragment = { __typename: 'GrainBinAccountLink' } & Pick<
  GrainBinAccountLink,
  'grain_bin_id'
>;

export type GrainBinAccountLinkInternalFragmentFragment = { __typename: 'GrainBinAccountLink' } & {
  grain_bin: { __typename: 'GrainBin' } & {
    fan_controllers: Array<
      { __typename: 'GrainBinFanController' } & GrainBinFanControllerIdFragmentFragment
    >;
  } & GrainBinFragmentFragment;
  container: { __typename: 'GrainContainer' } & GrainContainerHubLinksFragmentFragment;
} & GrainBinAccountLinkIdFragmentFragment;

export type GrainBinCfmSolutionOutputFragmentFragment = {
  __typename: 'GrainBinCfmSolutionOutput';
} & Pick<GrainBinCfmSolutionOutput, 'grain_bin_id' | 'created_at' | 'updated_at' | 'grain_type'> & {
    fan_model: { __typename: 'GrainBinFanModelOutput' } & Pick<
      GrainBinFanModelOutput,
      'fan_model_name'
    >;
    grain_bin_solution: Maybe<
      { __typename: 'StaticPressureCfmSolution' } & StaticPressureCfmSolutionFragmentFragment
    >;
    grain_bin_height_solution: Maybe<
      { __typename: 'StaticPressureCfmSolution' } & StaticPressureCfmSolutionFragmentFragment
    >;
  };

export type GrainBinDtoFragmentFragment = { __typename: 'GrainBinDTO' } & Pick<
  GrainBinDto,
  | 'grain_bin_id'
  | 'bin_name'
  | 'grain_type'
  | 'has_grain_spreader'
  | 'height_ft'
  | 'diameter_ft'
  | 'zip_code'
  | 'current_grain_emc'
  | 'current_grain_temp'
  | 'target_grain_emc'
  | 'price_per_kwh'
  | 'recommendation_type'
  | 'tz'
  | 'opt_in_fan_guidance'
  | 'enable_fan_guidance'
  | 'fan_guidance_start_date'
  | 'fan_guidance_end_date'
  | 'floor_type'
  | 'archived'
  | 'fan_guidance_extension_date'
  | 'sent_fan_guidance_ext_notif'
  | 'updated_at'
> & {
    location: { __typename: 'Coordinates2' } & Pick<Coordinates2, 'latitude' | 'longitude'>;
    site: Maybe<
      { __typename: 'GrainSiteDTO' } & Pick<
        GrainSiteDto,
        'site_id' | 'site_name' | 'account_id' | 'created_at' | 'updated_at'
      >
    >;
  };

export type GrainBinEstimateFragmentFragment = { __typename: 'GrainBinEstimate' } & Pick<
  GrainBinEstimate,
  | 'grain_bin_id'
  | 'moisture_colorbar_svg_url'
  | 'temperature_f_colorbar_svg_url'
  | 'temperature_f_colorbar_small_svg_url'
  | 'temperature_c_colorbar_svg_url'
  | 'temperature_c_colorbar_small_svg_url'
  | 'moisture_colorbar_small_svg_url'
  | 'internal_moisture_json'
  | 'internal_temperature_c_json'
  | 'internal_temperature_f_json'
  | 'internal_slider_c_json'
  | 'internal_slider_f_json'
  | 'is_bin_forecast_unavailable'
  | 'temperature_chart_data_url'
  | 'moisture_chart_data_url'
  | 'target_temperature_f'
  | 'target_moisture_percent'
  | 'bin_grain_type'
  | 'bin_diameter_ft'
  | 'bin_height_ft'
  | 'grain_bin_level_percent'
  | 'grain_bin_level_bu'
  | 'cfm_per_bushel'
  | 'total_aeration_runs'
  | 'total_hrs_runtime'
  | 'last_grain_ticket_date'
  | 'grain_surface_shape'
  | 'number_of_fans_connected_to_bin'
  | 'result_updated_at'
> & {
    current_weather: Maybe<
      { __typename: 'CurrentWeather' } & Pick<
        CurrentWeather,
        'temp_f' | 'humidity_rh' | 'emc' | 'icon'
      >
    >;
    fan_model: Maybe<
      { __typename: 'FanModelOutput' } & Pick<
        FanModelOutput,
        | 'fan_model_id'
        | 'fan_model_name'
        | 'make'
        | 'fan_brand_name'
        | 'fan_model_type'
        | 'fan_horsepower'
        | 'diameter'
        | 'fan_model_created_type'
      >
    >;
    storage_period: Maybe<
      { __typename: 'GrainBinStoragePeriodWithOngoingStorageFlag' } & Pick<
        GrainBinStoragePeriodWithOngoingStorageFlag,
        | 'grain_bin_storage_cycle_id'
        | 'grain_bin_id'
        | 'storage_cycle_name'
        | 'is_ongoing_storage_period'
        | 'grain_type'
        | 'start_epoch'
        | 'end_epoch'
        | 'created_at'
      >
    >;
  };

export type GrainBinFanControllerIdFragmentFragment = {
  __typename: 'GrainBinFanController';
} & Pick<
  GrainBinFanController,
  | 'grain_bin_fan_controller_id'
  | 'fan_controller_id_next'
  | 'grain_bin_id'
  | 'alias'
  | 'horsepower'
  | 'fan_type'
  | 'has_plenum_sensor'
  | 'phase'
  | 'power_factor'
  | 'voltage'
  | 'fan_model_id'
  | 'no_of_fans_connected'
>;

export type GrainBinFanControllerFragmentFragment = { __typename: 'GrainBinFanController' } & Pick<
  GrainBinFanController,
  'start_date' | 'end_date'
> & {
    fan_controller: { __typename: 'FanController' } & FanControllerFragmentFragment;
  } & GrainBinFanControllerIdFragmentFragment;

export type GrainBinFanModelOutputFragmentFragment = {
  __typename: 'GrainBinFanModelOutput';
} & Pick<
  GrainBinFanModelOutput,
  | 'fan_model_id'
  | 'fan_model_name'
  | 'make'
  | 'fan_brand_name'
  | 'fan_model_type'
  | 'fan_horsepower'
  | 'diameter'
  | 'fan_model_created_type'
  | 'created_at'
  | 'updated_at'
>;

export type GrainBinFragmentFragment = { __typename: 'GrainBin' } & Pick<
  GrainBin,
  | 'grain_bin_id'
  | 'account_id'
  | 'bin_name'
  | 'grain_type'
  | 'has_grain_spreader'
  | 'height_ft'
  | 'diameter_ft'
  | 'zip_code'
  | 'pellet_order'
  | 'current_grain_emc'
  | 'current_grain_temp'
  | 'target_grain_emc'
  | 'price_per_kwh'
  | 'recommendation_type'
  | 'tz'
  | 'opt_in_fan_guidance'
  | 'enable_fan_guidance'
  | 'fan_guidance_start_date'
  | 'fan_guidance_end_date'
  | 'floor_type'
  | 'archived'
  | 'fan_guidance_extension_date'
  | 'sent_fan_guidance_ext_notif'
  | 'updated_at'
> & {
    location: { __typename: 'Coordinates2' } & Pick<Coordinates2, 'latitude' | 'longitude'>;
    fan_controllers: Array<
      { __typename: 'GrainBinFanController' } & Pick<
        GrainBinFanController,
        'grain_bin_fan_controller_id'
      > & {
          fan_controller: { __typename: 'FanController' } & Pick<
            FanController,
            'fan_controller_id_next' | 'alias'
          > & {
              state_next: Maybe<
                { __typename: 'FanControllerState' } & Pick<
                  FanControllerState,
                  'fan_controller_id' | 'value_next' | 'is_on' | 'error_code'
                >
              >;
            };
        }
    >;
    container: { __typename: 'GrainContainer' } & Pick<GrainContainer, 'aeration_schedule_type'> & {
        aeration_schedule: Array<
          { __typename: 'FanControllerRunWindow' } & Pick<
            FanControllerRunWindow,
            'start_epoch' | 'end_epoch'
          >
        >;
      };
    grain_bin_storage_periods: Array<
      { __typename: 'GrainBinStoragePeriodWithOngoingStorageFlag' } & Pick<
        GrainBinStoragePeriodWithOngoingStorageFlag,
        | 'grain_bin_storage_cycle_id'
        | 'grain_bin_id'
        | 'storage_cycle_name'
        | 'is_ongoing_storage_period'
        | 'grain_type'
        | 'start_epoch'
        | 'end_epoch'
        | 'created_at'
      >
    >;
  };

export type GrainBinNotificationFragmentFragment = { __typename: 'GrainBinNotification' } & Pick<
  GrainBinNotification,
  | 'grain_bin_id'
  | 'notification_id'
  | 'source_type'
  | 'epoch_time'
  | 'alert_content'
  | 'has_delivered_to_user'
>;

export type GrainBinSiteFragmentFragment = { __typename: 'GrainBinSite' } & Pick<
  GrainBinSite,
  'site_id' | 'site_name' | 'account_id' | 'created_at' | 'updated_at'
>;

export type GrainBinStoragePeriodValidationResultFragmentFragment = {
  __typename: 'GrainBinStoragePeriodValidationResult';
} & Pick<GrainBinStoragePeriodValidationResult, 'error_message' | 'failed_validation'>;

export type GrainBinTicketFragmentFragment = { __typename: 'GrainBinTicket' } & Pick<
  GrainBinTicket,
  | 'grain_bin_ticket_id'
  | 'grain_bin_id'
  | 'epoch_time'
  | 'volume'
  | 'weight_in_lbs'
  | 'test_weight_in_lbs'
  | 'action_performed'
  | 'grain_moisture_pct'
  | 'notes'
  | 'grain_surface_shape'
  | 'new_grain_bin_bushels'
  | 'created_at'
  | 'updated_at'
>;

export type GrainBinTicketRowFragmentFragment = { __typename: 'GrainBinTicketRow' } & Pick<
  GrainBinTicketRow,
  | 'grain_bin_ticket_id'
  | 'grain_bin_id'
  | 'epoch_time'
  | 'volume'
  | 'weight_in_lbs'
  | 'test_weight_in_lbs'
  | 'action_performed'
  | 'grain_moisture_pct'
  | 'grain_surface_shape'
  | 'notes'
  | 'created_at'
  | 'updated_at'
>;

export type GrainBinWeeklyEmailPrefFragmentFragment = {
  __typename: 'GrainBinWeeklyEmailPref';
} & Pick<GrainBinWeeklyEmailPref, 'user_id' | 'account_id' | 'grain_bin_id' | 'opt_in'>;

export type GrainContainerFanRunFragmentFragment = { __typename: 'GrainContainerFanRun' } & Pick<
  GrainContainerFanRun,
  | 'grain_bin_fan_controller_id'
  | 'start_epoch'
  | 'end_epoch'
  | 'current_avg_a'
  | 'start_recommendation_type'
  | 'end_recommendation_type'
  | 'fan_start_reason'
  | 'fan_end_reason'
  | 'enabled_fan_guidance'
> & {
    grain_bin_fan_controller: Maybe<
      { __typename: 'GrainBinFanController' } & Pick<
        GrainBinFanController,
        'grain_bin_fan_controller_id' | 'alias'
      >
    >;
  };

export type GrainContainerAerationRunFragmentFragment = {
  __typename: 'GrainContainerAerationRun';
} & Pick<
  GrainContainerAerationRun,
  | 'container_id'
  | 'container_type'
  | 'start_epoch'
  | 'start_humidity_rh'
  | 'start_temp_f'
  | 'end_epoch'
  | 'end_humidity_rh'
  | 'end_temp_f'
  | 'avg_humidity_rh'
  | 'avg_temp_f'
  | 'avg_emc'
  | 'grain_type'
  | 'current_grain_temp'
  | 'current_grain_emc'
  | 'target_grain_emc'
  | 'enabled_fan_guidance'
  | 'fan_guidance_start_date'
  | 'fan_guidance_end_date'
> & {
    fan_runs: Maybe<
      Array<{ __typename: 'GrainContainerFanRun' } & GrainContainerFanRunFragmentFragment>
    >;
  };

export type GrainContainerFanPointFragmentFragment = {
  __typename: 'GrainContainerFanPoint';
} & Pick<GrainContainerFanPoint, 'epoch_time' | 'fan_count'>;

export type GrainContainerIdFragmentFragment = { __typename: 'GrainContainer' } & Pick<
  GrainContainer,
  'container_id' | 'container_type' | 'alias' | 'default_grain_type'
>;

export type GrainContainerHubLinksFragmentFragment = { __typename: 'GrainContainer' } & {
  active_hub_links: Array<
    { __typename: 'HubContainerLink' } & HubContainerLinkBaseFragmentFragment
  >;
} & GrainContainerIdFragmentFragment;

export type GrainContainerHubLinksWithFcPlenumHubsFragmentFragment = {
  __typename: 'GrainContainer';
} & {
  active_hub_links_including_fc_plenum_hubs: Array<
    { __typename: 'HubContainerLink' } & HubContainerLinkBaseFragmentFragment
  >;
} & GrainContainerIdFragmentFragment;

export type GrainBinGrainContainerFragmentFragment = { __typename: 'GrainContainer' } & Pick<
  GrainContainer,
  'aeration_schedule_type'
> & {
    aeration_schedule: Array<
      { __typename: 'FanControllerRunWindow' } & Pick<
        FanControllerRunWindow,
        'start_epoch' | 'end_epoch'
      >
    >;
  } & GrainContainerIdFragmentFragment;

export type HistoryPointFragment = { __typename: 'GrainEnvPoint' } & Pick<
  GrainEnvPoint,
  'epoch_time' | 'emc' | 'humidity_rh' | 'temp_f' | 'co2_ppm'
>;

export type HistoryPointV2Fragment = { __typename: 'GrainEnvPointV2' } & Pick<
  GrainEnvPointV2,
  'epoch_time' | 'emc' | 'humidity_rh' | 'temp_f' | 'co2_ppm'
>;

export type WeatherHistoryPointFragmentFragment = { __typename: 'WeatherHistoryPoint' } & Pick<
  WeatherHistoryPoint,
  'epoch_time' | 'emc' | 'humidity_rh' | 'temp_f'
>;

export type GrainContainerHistoryFragmentFragment = { __typename: 'GrainContainer' } & {
  telemetry_history: { __typename: 'GrainTelemetryHistory' } & Pick<
    GrainTelemetryHistory,
    'container_id' | 'container_type' | 'period'
  > & {
      weather: Array<{ __typename: 'GrainEnvPoint' } & HistoryPointFragment>;
      interior: Array<{ __typename: 'GrainEnvPoint' } & HistoryPointFragment>;
      headspace: Array<{ __typename: 'GrainEnvPoint' } & HistoryPointFragment>;
      tiers: Maybe<Array<Array<{ __typename: 'GrainEnvPoint' } & HistoryPointFragment>>>;
      pellets: Array<
        { __typename: 'PelletTelemetryHistory' } & Pick<PelletTelemetryHistory, 'pellet_id'> & {
            data: Array<{ __typename: 'GrainEnvPoint' } & HistoryPointFragment>;
          }
      >;
      fan: Array<{ __typename: 'GrainContainerFanPoint' } & GrainContainerFanPointFragmentFragment>;
    };
} & GrainContainerIdFragmentFragment;

export type GrainContainerHistoryFanFragmentFragment = { __typename: 'GrainContainer' } & {
  telemetry_history: { __typename: 'GrainTelemetryHistory' } & Pick<
    GrainTelemetryHistory,
    'container_id' | 'container_type' | 'period'
  > & {
      fan: Array<{ __typename: 'GrainContainerFanPoint' } & GrainContainerFanPointFragmentFragment>;
    };
} & GrainContainerIdFragmentFragment;

export type GrainContainerHistoryAerationRunsFragmentFragment = { __typename: 'GrainContainer' } & {
  aeration_runs: Maybe<
    Array<{ __typename: 'GrainContainerAerationRun' } & GrainContainerAerationRunFragmentFragment>
  >;
} & GrainContainerIdFragmentFragment;

export type GrainContainerHistoryAerationRunsWithinPeriodFragmentFragment = {
  __typename: 'GrainContainer';
} & {
  aeration_runs_within_period: { __typename: 'AerationRunWithinPeriod' } & Pick<
    AerationRunWithinPeriod,
    'period'
  > & {
      aeration_runs: Maybe<
        Array<
          Maybe<
            { __typename: 'GrainContainerAerationRun' } & GrainContainerAerationRunFragmentFragment
          >
        >
      >;
    };
} & GrainContainerIdFragmentFragment;

export type GrainContainerHistoryAerationRunsWithLimitFragmentFragment = {
  __typename: 'GrainContainer';
} & {
  aeration_run_history: { __typename: 'GrainContainerAerationRunWithLimit' } & Pick<
    GrainContainerAerationRunWithLimit,
    'has_more' | 'current_offset' | 'total_runs' | 'total_hrs_runtime'
  > & {
      aeration_runs: Maybe<
        Array<
          { __typename: 'GrainContainerAerationRun' } & GrainContainerAerationRunFragmentFragment
        >
      >;
      fan_power_usage: Maybe<
        { __typename: 'GrainFanPowerUsage' } & GrainFanPowerUsageFragmentFragment
      >;
    };
} & GrainContainerIdFragmentFragment;

export type GrainContainerHistoryNextAerationRunsWithLimitFragmentFragment = {
  __typename: 'GrainContainer';
} & {
  aeration_run_history: { __typename: 'GrainContainerAerationRunWithLimit' } & Pick<
    GrainContainerAerationRunWithLimit,
    'has_more' | 'current_offset'
  > & {
      aeration_runs: Maybe<
        Array<
          { __typename: 'GrainContainerAerationRun' } & GrainContainerAerationRunFragmentFragment
        >
      >;
    };
} & GrainContainerIdFragmentFragment;

export type GrainContainerHistoryHeadspaceFragmentFragment = { __typename: 'GrainContainer' } & {
  telemetry_history_v2: { __typename: 'GrainTelemetryHistoryV2' } & Pick<
    GrainTelemetryHistoryV2,
    'container_id' | 'container_type' | 'period'
  > & {
      headspace_history: { __typename: 'HeadspaceTelemetryHistory' } & {
        active_hubs_grain_telemetry_history: Array<
          Maybe<
            { __typename: 'HeadspaceHubTelemetryHistory' } & Pick<
              HeadspaceHubTelemetryHistory,
              'hub_id'
            > & {
                headspace_telemetry_history: Array<
                  { __typename: 'GrainEnvPointV2' } & HistoryPointV2Fragment
                >;
              }
          >
        >;
      };
    };
} & GrainContainerIdFragmentFragment;

export type GrainContainerHistoryHeadspaceWeatherFragmentFragment = {
  __typename: 'GrainContainer';
} & {
  telemetry_history_v2: { __typename: 'GrainTelemetryHistoryV2' } & Pick<
    GrainTelemetryHistoryV2,
    'container_id' | 'container_type' | 'period'
  > & {
      weather_history: Array<
        Maybe<{ __typename: 'WeatherHistoryPoint' } & WeatherHistoryPointFragmentFragment>
      >;
    };
} & GrainContainerIdFragmentFragment;

export type GrainContainerHistoryGrainLevelFragmentFragment = { __typename: 'GrainContainer' } & {
  grain_bin_level_history: { __typename: 'GrainBinLevelHistory' } & Pick<
    GrainBinLevelHistory,
    'period' | 'max_bushels'
  > & {
      active_hubs_level_samples: Array<
        Maybe<
          { __typename: 'ActiveHubsLevelSamples' } & Pick<ActiveHubsLevelSamples, 'hub_id'> & {
              level_samples: Array<
                Maybe<
                  { __typename: 'GrainBinLevelSample' } & Pick<
                    GrainBinLevelSample,
                    'bushel' | 'epoch_time'
                  >
                >
              >;
            }
        >
      >;
    };
} & GrainContainerIdFragmentFragment;

export type GrainContainerHistoryGrainTicketsLevelFragmentFragment = {
  __typename: 'GrainContainer';
} & {
  grain_bin_tickets_level_history: { __typename: 'GrainBinTicketsLevelHistory' } & Pick<
    GrainBinTicketsLevelHistory,
    'period' | 'no_grain_tickets_created'
  > & {
      grain_tickets_bushel_level_on_period_start: Maybe<
        { __typename: 'GrainBinTicketLevelSample' } & Pick<
          GrainBinTicketLevelSample,
          'bushel' | 'epoch_time'
        >
      >;
      grain_tickets_bushel_level_on_period_end: Maybe<
        { __typename: 'GrainBinTicketLevelSample' } & Pick<
          GrainBinTicketLevelSample,
          'bushel' | 'epoch_time'
        >
      >;
      grain_tickets_level_samples: Array<
        Maybe<
          { __typename: 'GrainBinTicketLevelSample' } & Pick<
            GrainBinTicketLevelSample,
            'bushel' | 'epoch_time'
          >
        >
      >;
    };
};

export type GrainContainerHistoryInteriorFragmentFragment = { __typename: 'GrainContainer' } & {
  telemetry_history: { __typename: 'GrainTelemetryHistory' } & Pick<
    GrainTelemetryHistory,
    'container_id' | 'container_type' | 'period'
  > & { interior: Array<{ __typename: 'GrainEnvPoint' } & HistoryPointFragment> };
} & GrainContainerIdFragmentFragment;

export type GrainContainerHistoryPelletFragmentFragment = { __typename: 'GrainContainer' } & {
  telemetry_history: { __typename: 'GrainTelemetryHistory' } & Pick<
    GrainTelemetryHistory,
    'container_id' | 'container_type' | 'period'
  > & { pellet: Array<{ __typename: 'GrainEnvPoint' } & HistoryPointFragment> };
} & GrainContainerIdFragmentFragment;

export type GrainContainerHistoryWeatherFragmentFragment = { __typename: 'GrainContainer' } & {
  telemetry_history: { __typename: 'GrainTelemetryHistory' } & Pick<
    GrainTelemetryHistory,
    'container_id' | 'container_type' | 'period'
  > & { weather: Array<{ __typename: 'GrainEnvPoint' } & HistoryPointFragment> };
} & GrainContainerIdFragmentFragment;

export type GrainContainerHistoryBinSummaryFragmentFragment = { __typename: 'GrainContainer' } & {
  telemetry_history: { __typename: 'GrainTelemetryHistory' } & Pick<
    GrainTelemetryHistory,
    'container_id' | 'container_type' | 'period'
  > & {
      interior: Array<{ __typename: 'GrainEnvPoint' } & HistoryPointFragment>;
      headspace: Array<{ __typename: 'GrainEnvPoint' } & HistoryPointFragment>;
      tiers: Maybe<Array<Array<{ __typename: 'GrainEnvPoint' } & HistoryPointFragment>>>;
    };
} & GrainContainerIdFragmentFragment;

export type GrainContainerHistoryPelletsFragmentFragment = { __typename: 'GrainContainer' } & {
  telemetry_history: { __typename: 'GrainTelemetryHistory' } & Pick<
    GrainTelemetryHistory,
    'container_id' | 'container_type' | 'period'
  > & {
      interior: Array<{ __typename: 'GrainEnvPoint' } & HistoryPointFragment>;
      pellets: Array<
        { __typename: 'PelletTelemetryHistory' } & Pick<PelletTelemetryHistory, 'pellet_id'> & {
            data: Array<{ __typename: 'GrainEnvPoint' } & HistoryPointFragment>;
          }
      >;
    };
} & GrainContainerIdFragmentFragment;

export type GrainFanPlotsOutputFragmentFragment = { __typename: 'GrainFanPlotsOutput' } & {
  fan_model: { __typename: 'GrainBinFanModelOutput' } & Pick<
    GrainBinFanModelOutput,
    | 'fan_model_id'
    | 'fan_model_name'
    | 'fan_model_type'
    | 'fan_brand_name'
    | 'fan_horsepower'
    | 'created_at'
    | 'updated_at'
  >;
  fan_plots: Array<
    Maybe<
      { __typename: 'GrainFanPlotOutput' } & Pick<
        GrainFanPlotOutput,
        | 'fan_plot_id'
        | 'x1'
        | 'x2'
        | 'y1'
        | 'y2'
        | 'acceptable_range'
        | 'iteration'
        | 'created_at'
        | 'updated_at'
      >
    >
  >;
};

export type FanPowerStatsFragmentFragment = { __typename: 'FanPowerStats' } & Pick<
  FanPowerStats,
  'fan_controller_id_next' | 'alias' | 'total_run_hours' | 'total_kwh' | 'start_epoch'
>;

export type GrainFanPowerUsageFragmentFragment = { __typename: 'GrainFanPowerUsage' } & Pick<
  GrainFanPowerUsage,
  'total_kwh'
> & {
    fan_controllers_with_missing_info: Array<
      Maybe<{ __typename: 'GrainBinFanController' } & GrainBinFanControllerFragmentFragment>
    >;
    fan_power_stats: Array<Maybe<{ __typename: 'FanPowerStats' } & FanPowerStatsFragmentFragment>>;
  };

export type GrainStatusFragmentFragment = { __typename: 'GrainStatus' } & Pick<
  GrainStatus,
  | 'emc'
  | 'emc_state'
  | 'epoch_time'
  | 'humidity_state'
  | 'humidity_rh'
  | 'temp_diff_state'
  | 'temp_f'
  | 'temp_state'
  | 'temp_min_f'
  | 'temp_max_f'
  | 'temp_max_diff_f'
> & {
    emc_ranges: Array<{ __typename: 'StateRange' } & Pick<StateRange, 'state' | 'low' | 'high'>>;
    humidity_rh_ranges: Array<
      { __typename: 'StateRange' } & Pick<StateRange, 'state' | 'low' | 'high'>
    >;
    temp_f_ranges: Array<{ __typename: 'StateRange' } & Pick<StateRange, 'state' | 'low' | 'high'>>;
  };

export type BargeTelemetryFragmentFragment = { __typename: 'GrainTelemetry' } & {
  headspace: Maybe<{ __typename: 'GrainStatus' } & GrainStatusFragmentFragment>;
  pellets: Array<
    { __typename: 'PelletTelemetry' } & Pick<
      PelletTelemetry,
      'pellet_id' | 'is_emc_stable' | 'dod_temp_diff_f'
    > & { latest: { __typename: 'GrainStatus' } & GrainStatusFragmentFragment }
  >;
  location_next: Maybe<
    { __typename: 'Location' } & Pick<Location, 'epoch_time' | 'latitude' | 'longitude'>
  >;
  location_with_timezone: Maybe<
    { __typename: 'Location' } & Pick<
      Location,
      'epoch_time' | 'latitude' | 'longitude' | 'timezone'
    >
  >;
  weather: Maybe<{ __typename: 'GrainStatus' } & GrainStatusFragmentFragment>;
  weather_forecast: Maybe<{ __typename: 'WeatherForecast' } & WeatherForecastFragmentFragment>;
};

export type GrainBinTelemetryFragmentFragment = { __typename: 'GrainTelemetry' } & {
  interior: Maybe<{ __typename: 'GrainStatus' } & GrainStatusFragmentFragment>;
  headspace: Maybe<{ __typename: 'GrainStatus' } & GrainStatusFragmentFragment>;
  weather_forecast: Maybe<{ __typename: 'WeatherForecast' } & WeatherForecastFragmentFragment>;
  recommendation_windows: Maybe<
    { __typename: 'RecommendationWindows' } & RecommendationWindowsFragmentFragment
  >;
  temporary_recommendation_windows: Maybe<
    { __typename: 'TemporaryRecommendationWindows' } & Pick<
      TemporaryRecommendationWindows,
      'recommendation_type'
    > & {
        recommendation_windows: Maybe<
          { __typename: 'RecommendationWindows' } & RecommendationWindowsFragmentFragment
        >;
      }
  >;
  location_next: Maybe<
    { __typename: 'Location' } & Pick<Location, 'epoch_time' | 'latitude' | 'longitude'>
  >;
  tiers: Maybe<Array<{ __typename: 'GrainStatus' } & GrainStatusFragmentFragment>>;
  weather: Maybe<{ __typename: 'GrainStatus' } & GrainStatusFragmentFragment>;
};

export type GrainTemporaryInputConditionsFragmentFragment = {
  __typename: 'GrainTemporaryInputConditions';
} & Pick<
  GrainTemporaryInputConditions,
  | 'grain_bin_id'
  | 'current_grain_temp'
  | 'current_grain_emc'
  | 'target_grain_emc'
  | 'recommendation_type'
>;

export type GrainWeatherInfoFragmentFragment = { __typename: 'WeatherInfo' } & Pick<
  WeatherInfo,
  'icon'
> & {
    temp: { __typename: 'TempMinMax' } & Pick<TempMinMax, 'temp_high_f' | 'temp_low_f'>;
    humidity: { __typename: 'HumidityMinMax' } & Pick<
      HumidityMinMax,
      'humidity_high_rh' | 'humidity_low_rh'
    >;
    emc: { __typename: 'EmcMinMax' } & Pick<EmcMinMax, 'emc_high' | 'emc_low'>;
  };

export type HourlyForecastStatusFragmentFragment = { __typename: 'HourlyForecastStatus' } & {
  hours: Array<{ __typename: 'GrainStatus' } & ComputedForecastHourFragmentFragment>;
  aeration_windows: Array<{ __typename: 'AerationWindow' } & AerationWindowFragmentFragment>;
};

export type HourlyForecastStatusV2FragmentFragment = { __typename: 'HourlyForecastStatusV2' } & {
  hours: Array<{ __typename: 'GrainStatusV2' } & ComputedForecastHourV2FragmentFragment>;
  aeration_windows: Array<{ __typename: 'AerationWindow' } & AerationWindowFragmentFragment>;
};

export type HubContainerLinkBaseFragmentFragment = { __typename: 'HubContainerLink' } & Pick<
  HubContainerLink,
  | 'hub_container_link_id'
  | 'hub_id'
  | 'alias'
  | 'core_id'
  | 'container_id'
  | 'container_type'
  | 'start_epoch'
  | 'end_epoch'
  | 'nickname'
  | 'hub_type'
  | 'has_level_sensor'
  | 'hub_offset_ft'
> & { hub: Maybe<{ __typename: 'Hub' } & Pick<Hub, 'hub_id' | 'alias'>> };

export type HubContainerLinkFragmentFragment = { __typename: 'HubContainerLink' } & {
  container: { __typename: 'GrainContainer' } & Pick<
    GrainContainer,
    'container_id' | 'container_type' | 'alias'
  >;
} & HubContainerLinkBaseFragmentFragment;

export type HubDiagnosticsFragmentFragment = { __typename: 'Hub' } & Pick<
  Hub,
  'hub_id' | 'core_id' | 'alias' | 'is_active'
>;

export type HubNetworkDebugEventFragmentFragment = { __typename: 'HubNetworkDebugEvent' } & Pick<
  HubNetworkDebugEvent,
  | 'hub_id'
  | 'epoch_time'
  | 'node_address'
  | 'event_id'
  | 'packets_in_queue'
  | 'updated_at'
  | 'root_id'
> & { network_stats: Array<{ __typename: 'HubNetworkStat' } & HubNetworkStatFragmentFragment> };

export type HubNetworkStatFragmentFragment = { __typename: 'HubNetworkStat' } & Pick<
  HubNetworkStat,
  'neighbor_address' | 'etx' | 'link_quality' | 'rssi'
>;

export type HubStatusEventFragmentFragment = { __typename: 'HubStatusEvent' } & Pick<
  HubStatusEvent,
  | 'event_id'
  | 'hub_id'
  | 'epoch_time'
  | 'temp_0_f'
  | 'temp_1_f'
  | 'temp_2_f'
  | 'temp_f'
  | 'temp_min_f'
  | 'temp_max_f'
  | 'hum_0_rh'
  | 'hum_1_rh'
  | 'hum_2_rh'
  | 'hum_min_rh'
  | 'hum_max_rh'
  | 'humidity_rh'
  | 'pressure_0_pa'
  | 'pressure_1_pa'
  | 'pressure_2_pa'
  | 'pressure_2_abs_pa'
  | 'pressure_min_pa'
  | 'pressure_max_pa'
  | 'batt_voltage_uv'
  | 'panel_voltage_uv'
  | 'packets_in_queue'
  | 'reset_count'
  | 'updated_at'
  | 'batt_status'
>;

export type HubPelletEventFragmentFragment = { __typename: 'HubPelletEvent' } & Pick<
  HubPelletEvent,
  | 'event_id'
  | 'hub_id'
  | 'pellet_id'
  | 'epoch_time'
  | 'sequence_number'
  | 'sender_id'
  | 'time_offset_ms'
  | 'temp_0_f'
  | 'temp_1_f'
  | 'temp_2_f'
  | 'temp_f'
  | 'temp_min_f'
  | 'temp_max_f'
  | 'hum_0_rh'
  | 'hum_1_rh'
  | 'hum_2_rh'
  | 'hum_min_rh'
  | 'hum_max_rh'
  | 'humidity_rh'
  | 'pressure_2_pa'
  | 'batt_voltage_uv'
  | 'packets_in_queue'
  | 'reset_count'
  | 'rssi'
  | 'hop_count'
  | 'avg_current_ua'
  | 'co2_ppm'
  | 'drift_ppm'
  | 'epoch_time_raw'
  | 'neighbor_count'
  | 'network_etx'
  | 'network_packets_sent'
  | 'network_packets_ack'
  | 'packets_received'
  | 'parent_address'
  | 'pref_parent_rssi'
  | 'source_address'
  | 'voc_ppb'
>;

export type HubGpsEventFragmentFragment = { __typename: 'HubGpsEvent' } & Pick<
  HubGpsEvent,
  | 'hub_id'
  | 'epoch_time'
  | 'read_time'
  | 'event_id'
  | 'gps_method'
  | 'latitude'
  | 'longitude'
  | 'satellite_count'
  | 'hdop'
  | 'altitude_ft'
  | 'ground_speed_kn'
  | 'course_angle'
  | 'updated_at'
>;

export type HubSignalEventFragmentFragment = { __typename: 'HubSignalEvent' } & Pick<
  HubSignalEvent,
  'hub_id' | 'epoch_time' | 'event_id' | 'signal_rssi' | 'signal_quality'
>;

export type HubHeadspaceEventFragmentFragment = { __typename: 'HubHeadspaceEvent' } & Pick<
  HubHeadspaceEvent,
  | 'event_id'
  | 'hub_id'
  | 'epoch_time'
  | 'temp_0_f'
  | 'temp_1_f'
  | 'temp_2_f'
  | 'temp_min_f'
  | 'temp_max_f'
  | 'temp_f'
  | 'hum_0_rh'
  | 'hum_1_rh'
  | 'hum_2_rh'
  | 'hum_min_rh'
  | 'hum_max_rh'
  | 'humidity_rh'
  | 'pressure_0_pa'
  | 'pressure_1_pa'
  | 'pressure_2_pa'
  | 'pressure_2_abs_pa'
  | 'pressure_min_pa'
  | 'pressure_max_pa'
  | 'packets_in_queue'
  | 'node_count'
  | 'reset_reason'
  | 'packet_count'
  | 't6713_co2_ppm'
  | 'mos_co2_ppm'
  | 'mos_tvoc_ppb'
  | 'reset_count'
>;

export type HubEventHistoryFragmentFragment = { __typename: 'HubEventHistoryEdge' } & Pick<
  HubEventHistoryEdge,
  'hub_id' | 'core_id' | 'start' | 'end'
> & {
    hub: Maybe<{ __typename: 'Hub' } & HubFragmentFragment>;
    status: Array<{ __typename: 'HubStatusEvent' } & HubStatusEventFragmentFragment>;
    pellets: Array<{ __typename: 'HubPelletEvent' } & HubPelletEventFragmentFragment>;
    gps: Array<{ __typename: 'HubGpsEvent' } & HubGpsEventFragmentFragment>;
    signal: Array<{ __typename: 'HubSignalEvent' } & HubSignalEventFragmentFragment>;
    headspace: Array<{ __typename: 'HubHeadspaceEvent' } & HubHeadspaceEventFragmentFragment>;
    network_debug: Array<
      { __typename: 'HubNetworkDebugEvent' } & HubNetworkDebugEventFragmentFragment
    >;
  };

export type HubFragmentFragment = { __typename: 'Hub' } & Pick<
  Hub,
  | 'hub_id'
  | 'core_id'
  | 'is_active'
  | 'environment'
  | 'hardware_rev'
  | 'firmware'
  | 'notes'
  | 'ref_pellet_id'
  | 'hub_pressure_offset_pa'
  | 'created_at'
  | 'updated_at'
  | 'deleted_at'
> & { particle: Maybe<{ __typename: 'ParticleDevice' } & ParticleDeviceFragmentFragment> };

export type HubInfoFragmentFragment = { __typename: 'Hub' } & Pick<
  Hub,
  'hub_id' | 'core_id' | 'alias'
> & {
    events: Maybe<
      { __typename: 'HubEventsEdge' } & {
        status: Maybe<
          { __typename: 'HubStatusEvent' } & Pick<
            HubStatusEvent,
            'epoch_time' | 'batt_voltage_uv' | 'packets_in_queue' | 'reset_count'
          >
        >;
        headspace: Maybe<
          { __typename: 'HubHeadspaceEvent' } & Pick<
            HubHeadspaceEvent,
            'epoch_time' | 'packets_in_queue' | 'reset_count'
          >
        >;
        pellets: Array<
          { __typename: 'HubPelletEvent' } & Pick<
            HubPelletEvent,
            | 'epoch_time'
            | 'sequence_number'
            | 'batt_voltage_uv'
            | 'packets_in_queue'
            | 'reset_count'
            | 'rssi'
          >
        >;
        gps: Maybe<{ __typename: 'HubGpsEvent' } & Pick<HubGpsEvent, 'epoch_time'>>;
      }
    >;
    container_link: Maybe<
      { __typename: 'HubContainerLink' } & {
        grain_bin: Maybe<
          { __typename: 'GrainBin' } & Pick<GrainBin, 'grain_bin_id' | 'alias'> & {
              account_link: { __typename: 'GrainBinAccountLink' } & Pick<
                GrainBinAccountLink,
                'grain_bin_id'
              > & { account: { __typename: 'Account' } & BaseAccountFragmentFragment };
            }
        >;
        barge: Maybe<
          { __typename: 'Barge' } & Pick<Barge, 'barge_id' | 'alias'> & {
              account_link: { __typename: 'BargeAccountLink' } & Pick<
                BargeAccountLink,
                'barge_id'
              > & { account: { __typename: 'Account' } & BaseAccountFragmentFragment };
            }
        >;
        pile: Maybe<
          { __typename: 'Pile' } & Pick<Pile, 'pile_id' | 'alias'> & {
              account_link: { __typename: 'PileAccountLink' } & Pick<PileAccountLink, 'pile_id'> & {
                  account: { __typename: 'Account' } & BaseAccountFragmentFragment;
                };
            }
        >;
      } & HubContainerLinkBaseFragmentFragment
    >;
  };

export type AssignmentStatusFragmentFragment = { __typename: 'AssignmentStatus' } & Pick<
  AssignmentStatus,
  'is_assigned'
>;

export type OpsViewRowVisibilityPrefsFragmentFragment = { __typename: 'RowVisibilityPref' } & Pick<
  RowVisibilityPref,
  'account_id' | 'pref_json'
>;

export type OpsViewVisibilityPrefsFragmentFragment = { __typename: 'UserPref' } & Pick<
  UserPref,
  'user_id' | 'account_id' | 'pref_type_key' | 'pref_json'
>;

export type ParticleEventFragmentFragment = { __typename: 'ParticleEvent' } & Pick<
  ParticleEvent,
  'core_id' | 'event_id' | 'event_name' | 'event_data' | 'published_at'
> & { device: Maybe<{ __typename: 'ParticleDevice' } & ParticleDeviceFragmentFragment> };

export type ParticleDeviceFragmentFragment = { __typename: 'ParticleDevice' } & Pick<
  ParticleDevice,
  | 'id'
  | 'name'
  | 'serial_number'
  | 'last_ip_address'
  | 'last_heard'
  | 'product_id'
  | 'connected'
  | 'platform_id'
  | 'cellular'
  | 'notes'
  | 'iccid'
  | 'imei'
>;

export type PelletGroupFragmentFragment = { __typename: 'PelletGroup' } & Pick<
  PelletGroup,
  'pellet_group_id' | 'hostname' | 'package_alias' | 'created_at' | 'pellet_group_type'
> & {
    pellet_links: Array<
      { __typename: 'PelletGroupPelletLink' } & PelletGroupPelletFragmentFragment
    >;
  };

export type PelletGroupPelletFragmentFragment = { __typename: 'PelletGroupPelletLink' } & Pick<
  PelletGroupPelletLink,
  | 'pellet_group_pellet_link_id'
  | 'pellet_group_id'
  | 'pellet_id'
  | 'position_no'
  | 'created_at'
  | 'reassigned_at'
>;

export type PileAccountLinkIdFragmentFragment = { __typename: 'PileAccountLink' } & Pick<
  PileAccountLink,
  'pile_id'
>;

export type PileAccountLinkInternalFragmentFragment = { __typename: 'PileAccountLink' } & {
  pile: { __typename: 'Pile' } & PileFragmentFragment;
  container: { __typename: 'GrainContainer' } & GrainContainerHubLinksFragmentFragment;
} & PileAccountLinkIdFragmentFragment;

export type PileCablePelletLinkFragmentFragment = { __typename: 'PileCablePelletLink' } & Pick<
  PileCablePelletLink,
  'pile_cable_pellet_link_id' | 'pile_id' | 'cable_no' | 'pellet_id' | 'start_epoch'
>;

export type PileFragmentFragment = { __typename: 'Pile' } & Pick<
  Pile,
  'pile_id' | 'alias' | 'default_grain_type' | 'orientation'
> & {
    shape: Maybe<
      { __typename: 'Shape' } & Pick<
        Shape,
        'height_ft' | 'width_ft' | 'length_ft' | 'radius_ft' | 'shape_type'
      >
    >;
  };

export type PileCableLinkFragmentFragment = { __typename: 'PileCableLink' } & Pick<
  PileCableLink,
  | 'pile_cable_link_id'
  | 'alias'
  | 'cable_id'
  | 'pile_id'
  | 'x_ft'
  | 'y_ft'
  | 'z_ft'
  | 'start_epoch'
  | 'end_epoch'
> & {
    cable: { __typename: 'PelletCable' } & Pick<
      PelletCable,
      'pellet_group_id' | 'length_ft' | 'spacing_ft'
    > & {
        pellet_group: { __typename: 'PelletGroup' } & Pick<
          PelletGroup,
          'pellet_group_id' | 'package_alias'
        > & {
            pellet_links: Array<
              { __typename: 'PelletGroupPelletLink' } & Pick<
                PelletGroupPelletLink,
                'pellet_group_pellet_link_id' | 'pellet_id' | 'position_no' | 'reassigned_at'
              >
            >;
          };
      };
  };

export type PsychrometricRangeFragmentFragment = { __typename: 'PsychrometricRange' } & Pick<
  PsychrometricRange,
  'start' | 'end' | 'temp_diff' | 'emc_diff'
>;

export type RecommendationWindowsFragmentFragment = { __typename: 'RecommendationWindows' } & {
  emc_ranges: Maybe<
    Array<{ __typename: 'PsychrometricRange' } & PsychrometricRangeFragmentFragment>
  >;
  temp_ranges: Maybe<
    Array<{ __typename: 'PsychrometricRange' } & PsychrometricRangeFragmentFragment>
  >;
  emc_reconditioning_ranges: Maybe<
    Array<{ __typename: 'PsychrometricRange' } & PsychrometricRangeFragmentFragment>
  >;
};

export type RecommRunWindowsExperimentsFragmentFragment = {
  __typename: 'RecommRunWindowsExperiments';
} & Pick<RecommRunWindowsExperiments, 'name' | 'experiment' | 'type' | 'hasCfmPresent'> & {
    emc_ranges: Maybe<
      Array<{ __typename: 'PsychrometricRange' } & PsychrometricRangeFragmentFragment>
    >;
    temp_ranges: Maybe<
      Array<{ __typename: 'PsychrometricRange' } & PsychrometricRangeFragmentFragment>
    >;
    emc_reconditioning_ranges: Maybe<
      Array<{ __typename: 'PsychrometricRange' } & PsychrometricRangeFragmentFragment>
    >;
  };

export type SensorGeneratedGrainTicketFragmentFragment = {
  __typename: 'SensorGeneratedGrainTicket';
} & Pick<
  SensorGeneratedGrainTicket,
  | 'grain_bin_ticket_id'
  | 'grain_bin_id'
  | 'epoch_time'
  | 'bin_level_mm'
  | 'volume'
  | 'action_performed'
  | 'notes'
  | 'approve_state'
  | 'created_at'
  | 'updated_at'
>;

export type StaticPressureCfmSolutionFragmentFragment = {
  __typename: 'StaticPressureCfmSolution';
} & Pick<
  StaticPressureCfmSolution,
  | 'cfm'
  | 'static_pressure'
  | 'found_solution'
  | 'cfm_per_bushel'
  | 'total_bushels'
  | 'grain_depth_ft'
>;

export type UserFragmentFragment = { __typename: 'User' } & Pick<
  User,
  | 'user_id'
  | 'account_id'
  | 'first_name'
  | 'last_name'
  | 'email_address'
  | 'telephone'
  | 'role'
  | 'verified_email'
  | 'needs_pw_change'
  | 'user_last_notifications_read_on'
  | 'user_new_notifications_count'
> & {
    settings: { __typename: 'UserSettings' } & UserSettingsFragmentFragment;
    selected_grain_bin: Maybe<
      { __typename: 'UserSelectedGrainBin' } & Pick<
        UserSelectedGrainBin,
        'account_id' | 'user_id' | 'container_id' | 'container_type' | 'container_name'
      >
    >;
    alert_pref: { __typename: 'UserAlertPref' } & Pick<UserAlertPref, 'user_id' | 'alert_type'>;
    grain_specific_alert_prefs: { __typename: 'GrainSpecificAlertPrefs' } & Pick<
      GrainSpecificAlertPrefs,
      | 'user_id'
      | 'fan_start_stop_alerts'
      | 'fan_error_alerts'
      | 'disable_fan_guidance_alerts'
      | 'grain_bin_co2_alerts'
      | 'grain_level_alerts'
    >;
    grain_notification_opt_ins: Array<
      { __typename: 'GrainBinNotificationOptIn' } & Pick<
        GrainBinNotificationOptIn,
        'user_id' | 'grain_bin_id' | 'account_id' | 'opt_in'
      >
    >;
    weekly_grain_email_schedule: Maybe<
      { __typename: 'UserWeeklyGrainEmailSchedule' } & Pick<
        UserWeeklyGrainEmailSchedule,
        | 'user_id'
        | 'schedule_day'
        | 'schedule_hour'
        | 'schedule_timezone'
        | 'subscribed'
        | 'last_email_sent_at'
        | 'next_email_schedule_date'
        | 'updated_at'
      >
    >;
    grain_weekly_email_opt_pref: Array<
      { __typename: 'GrainBinWeeklyEmailPref' } & Pick<
        GrainBinWeeklyEmailPref,
        'user_id' | 'account_id' | 'grain_bin_id' | 'opt_in'
      >
    >;
    grain_bin_fan_run_now_period_pref: { __typename: 'GrainBinFanRunNowPeriodPref' } & Pick<
      GrainBinFanRunNowPeriodPref,
      'user_id' | 'account_id' | 'grain_bin_id' | 'fan_run_now_period'
    >;
    user_units_pref: { __typename: 'UserUnitsPref' } & UserUnitsPrefFragmentFragment;
  };

export type UserModelFragmentFragment = { __typename: 'UserModel' } & Pick<
  UserModel,
  | 'user_id'
  | 'account_id'
  | 'first_name'
  | 'last_name'
  | 'email_address'
  | 'telephone'
  | 'role'
  | 'verified_email'
>;

export type UserSelectedGrainBinFragmentFragment = { __typename: 'UserSelectedGrainBin' } & Pick<
  UserSelectedGrainBin,
  'account_id' | 'user_id' | 'container_id' | 'container_type' | 'container_name'
>;

export type UserSettingsFragmentFragment = { __typename: 'UserSettings' } & Pick<
  UserSettings,
  'version'
> & {
    sms_alerts: { __typename: 'SmsSettings' } & Pick<
      SmsSettings,
      'enabled' | 'daily_forecast_report' | 'fan_controller'
    >;
  };

export type UserUnitsPrefFragmentFragment = { __typename: 'UserUnitsPref' } & Pick<
  UserUnitsPref,
  'user_id' | 'account_id' | 'temp_unit' | 'distance_unit' | 'mass_unit' | 'test_weight_unit'
>;

export type UserWeeklyGrainEmailScheduleFragmentFragment = {
  __typename: 'UserWeeklyGrainEmailSchedule';
} & Pick<
  UserWeeklyGrainEmailSchedule,
  | 'user_id'
  | 'schedule_day'
  | 'schedule_hour'
  | 'schedule_timezone'
  | 'subscribed'
  | 'last_email_sent_at'
  | 'next_email_schedule_date'
  | 'updated_at'
>;

export type ViewerFragmentFragment = { __typename: 'Viewer' } & Pick<
  Viewer,
  'user_id' | 'account_id' | 'token'
> & {
    user: { __typename: 'User' } & UserFragmentFragment;
    account: { __typename: 'Account' } & AccountFragmentFragment;
  };

export type WeatherForecastFragmentFragment = { __typename: 'WeatherForecast' } & {
  daily_forecast: Maybe<
    Array<Maybe<{ __typename: 'ForecastDayV2' } & ForecastDayV2FragmentFragment>>
  >;
  hourly_forecast: Array<{ __typename: 'GrainStatusV2' } & ComputedForecastHourV2FragmentFragment>;
  aeration_windows: Array<{ __typename: 'AerationWindow' } & AerationWindowFragmentFragment>;
};

export type WeatherHistoryFragmentFragment = { __typename: 'WeatherHistory' } & {
  daily_weather_history: Maybe<
    Array<Maybe<{ __typename: 'ForecastDayV2' } & ForecastDayV2FragmentFragment>>
  >;
  hourly_weather_history: Maybe<
    Array<Maybe<{ __typename: 'GrainStatusV2' } & ComputedForecastHourV2FragmentFragment>>
  >;
  aeration_run_history: Array<
    { __typename: 'GrainContainerAerationRun' } & GrainContainerAerationRunFragmentFragment
  >;
};

export type ApproveSensorGeneratedGrainTicketMutationVariables = {
  grain_bin_id: Scalars['Int'];
  grain_bin_ticket_id: Scalars['Int'];
  epoch_time: Scalars['Date'];
  volume: Scalars['Float'];
  weight_in_lbs: Scalars['Float'];
  test_weight_in_lbs: Scalars['Float'];
  grain_moisture_pct: Scalars['Float'];
  action_performed: GrainTicketActionType;
  notes: Scalars['String'];
  grain_surface_shape: GrainSurfaceShapeType;
  grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
};

export type ApproveSensorGeneratedGrainTicketMutation = { __typename: 'Mutation' } & {
  approveSensorGeneratedGrainTicket: Maybe<
    { __typename: 'SensorGeneratedGrainTicket' } & SensorGeneratedGrainTicketFragmentFragment
  >;
};

export type ArchiveGrainContainerMutationVariables = {
  container_id: Scalars['Int'];
  container_type: ContainerType;
  archive_unarchive_flag: ArchiveUnArchiveType;
};

export type ArchiveGrainContainerMutation = { __typename: 'Mutation' } & {
  archiveUnArchiveGrainContainer: {
    __typename: 'GrainContainer';
  } & GrainBinGrainContainerFragmentFragment;
};

export type AssignFanControllerMutationVariables = {
  grain_bin_id: Scalars['Int'];
  core_id: Scalars['String'];
  alias: Scalars['String'];
  has_plenum_sensor?: Maybe<Scalars['Boolean']>;
  phase?: Maybe<Scalars['Int']>;
  power_factor?: Maybe<Scalars['Float']>;
  voltage?: Maybe<Scalars['Int']>;
  fan_model_id?: Maybe<Scalars['Int']>;
  make: Scalars['String'];
  fan_brand_name: Scalars['String'];
  fan_model_type: FanType;
  fan_horsepower: Scalars['String'];
  diameter: Scalars['Float'];
  fan_model_created_type: FanModelCreatedType;
  linked_generic_fan_model_id?: Maybe<Scalars['Int']>;
  no_of_fans_connected: Scalars['Int'];
};

export type AssignFanControllerMutation = { __typename: 'Mutation' } & {
  assignFanController: { __typename: 'GrainBinFanSettings' } & FanSettingsFragmentFragment;
};

export type AssignHubMutationVariables = {
  core_id: Scalars['String'];
  nickname: Scalars['String'];
  hub_type: HubType;
  has_level_sensor: Scalars['Boolean'];
  hub_offset_ft: Scalars['Float'];
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  start_epoch?: Maybe<Scalars['Date']>;
  end_epoch?: Maybe<Scalars['Date']>;
};

export type AssignHubMutation = { __typename: 'Mutation' } & {
  assignHub: { __typename: 'HubContainerLink' } & HubContainerLinkBaseFragmentFragment;
};

export type ConfigureAlertPrefMutationVariables = {
  user_id: Scalars['Int'];
  alert_type: Scalars['String'];
};

export type ConfigureAlertPrefMutation = { __typename: 'Mutation' } & {
  configureAlertPref: { __typename: 'UserAlertPref' } & Pick<
    UserAlertPref,
    'user_id' | 'alert_type'
  >;
};

export type CreateAccountMutationVariables = {
  organization: Scalars['String'];
  barge_support: Scalars['Boolean'];
  grain_bin_support: Scalars['Boolean'];
  pile_support: Scalars['Boolean'];
};

export type CreateAccountMutation = { __typename: 'Mutation' } & {
  createAccount: { __typename: 'Account' } & AccountFragmentFragment;
};

export type CreateBargeMutationVariables = {
  account_id: Scalars['Int'];
  alias: Scalars['String'];
  default_grain_type: GrainType;
  height_ft: Scalars['Float'];
  length_ft: Scalars['Float'];
  width_ft: Scalars['Float'];
};

export type CreateBargeMutation = { __typename: 'Mutation' } & {
  createBarge: { __typename: 'BargeAccountLink' } & Pick<
    BargeAccountLink,
    'barge_id' | 'account_id'
  > & { barge: { __typename: 'Barge' } & BargeFragmentFragment };
};

export type CreateBinEventMutationVariables = {
  grain_bin_id: Scalars['Int'];
  epoch_time: Scalars['Date'];
  event_type: BinEventType;
  event_value?: Maybe<Scalars['String']>;
};

export type CreateBinEventMutation = { __typename: 'Mutation' } & {
  createBinEvent: Array<{ __typename: 'BinEvent' } & BinEventFragmentFragment>;
};

export type CreateBinStoragePeriodMutationVariables = {
  grain_bin_id: Scalars['Int'];
  storage_cycle_name: Scalars['String'];
  grain_type: GrainType;
  start_epoch: Scalars['Date'];
  end_epoch?: Maybe<Scalars['Date']>;
};

export type CreateBinStoragePeriodMutation = { __typename: 'Mutation' } & {
  createBinStoragePeriod: Maybe<
    { __typename: 'GrainBinStoragePeriod' } & Pick<
      GrainBinStoragePeriod,
      | 'grain_bin_storage_cycle_id'
      | 'grain_bin_id'
      | 'storage_cycle_name'
      | 'grain_type'
      | 'start_epoch'
      | 'end_epoch'
      | 'created_at'
    >
  >;
};

export type CreateCustomerCareTokenMutationVariables = {
  username: Scalars['String'];
  auth_token: Scalars['String'];
};

export type CreateCustomerCareTokenMutation = { __typename: 'Mutation' } & {
  createCustomerCareToken: { __typename: 'Token' } & Pick<Token, 'token'>;
};

export type CreateGrainBinMutationVariables = {
  account_id: Scalars['Int'];
  bin_name: Scalars['String'];
  grain_type: GrainType;
  height_ft: Scalars['Float'];
  diameter_ft: Scalars['Float'];
  zip_code?: Maybe<Scalars['String']>;
  location: LocationInput;
  current_grain_emc?: Maybe<Scalars['Float']>;
  current_grain_temp?: Maybe<Scalars['Float']>;
  has_grain_spreader?: Maybe<Scalars['Boolean']>;
  floor_type: FloorType;
};

export type CreateGrainBinMutation = { __typename: 'Mutation' } & {
  createGrainBin: { __typename: 'GrainBinAccountLink' } & Pick<
    GrainBinAccountLink,
    'grain_bin_id' | 'account_id'
  > & { grain_bin: { __typename: 'GrainBin' } & GrainBinFragmentFragment };
};

export type CreateGrainBinSiteMutationVariables = {
  account_id: Scalars['Int'];
  site_name: Scalars['String'];
};

export type CreateGrainBinSiteMutation = { __typename: 'Mutation' } & {
  createGrainBinSite: Maybe<{ __typename: 'GrainBinSite' } & GrainBinSiteFragmentFragment>;
};

export type CreateGrainBinSystemMutationVariables = {
  account_id: Scalars['Int'];
  alias: Scalars['String'];
  grain_type: GrainType;
  height_ft: Scalars['Float'];
  diameter_ft: Scalars['Float'];
  zip_code?: Maybe<Scalars['String']>;
  location: LocationInput;
  has_grain_spreader: Scalars['Boolean'];
  current_grain_emc?: Maybe<Scalars['Float']>;
  current_grain_temp?: Maybe<Scalars['Float']>;
  fan_controllers: Array<Maybe<AssignFanControllerInput>>;
  hubs: Array<Maybe<AssignHubInput>>;
  floor_type: FloorType;
};

export type CreateGrainBinSystemMutation = { __typename: 'Mutation' } & {
  createGrainBinSystem: { __typename: 'GrainBinAccountLink' } & Pick<
    GrainBinAccountLink,
    'grain_bin_id' | 'account_id'
  > & { grain_bin: { __typename: 'GrainBin' } & GrainBinFragmentFragment };
};

export type CreateGrainBinTicketMutationVariables = {
  grain_bin_id: Scalars['Int'];
  epoch_time: Scalars['Date'];
  volume: Scalars['Float'];
  weight_in_lbs: Scalars['Float'];
  test_weight_in_lbs: Scalars['Float'];
  action_performed: GrainTicketActionType;
  grain_moisture_pct: Scalars['Float'];
  notes: Scalars['String'];
  grain_surface_shape: GrainSurfaceShapeType;
  grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
};

export type CreateGrainBinTicketMutation = { __typename: 'Mutation' } & {
  createGrainBinTicket: Maybe<
    { __typename: 'GrainBinTicketRow' } & GrainBinTicketRowFragmentFragment
  >;
};

export type CreatePileMutationVariables = {
  account_id: Scalars['Int'];
  alias: Scalars['String'];
  default_grain_type: GrainType;
  orientation: Orientation;
  shape?: Maybe<ShapeInput>;
};

export type CreatePileMutation = { __typename: 'Mutation' } & {
  createPile: { __typename: 'PileAccountLink' } & Pick<
    PileAccountLink,
    'pile_id' | 'account_id'
  > & { pile: { __typename: 'Pile' } & PileFragmentFragment };
};

export type CreateTokenMutationVariables = {
  email_address: Scalars['String'];
  password: Scalars['String'];
};

export type CreateTokenMutation = { __typename: 'Mutation' } & {
  createToken: { __typename: 'UserToken' } & Pick<UserToken, 'user_id' | 'account_id' | 'token'>;
};

export type CreateTokenByOAuthMutationVariables = {
  oauth_token: Scalars['String'];
};

export type CreateTokenByOAuthMutation = { __typename: 'Mutation' } & {
  createTokenByOAuth: { __typename: 'Viewer' } & ViewerFragmentFragment;
};

export type CreateUserMutationVariables = {
  token: Scalars['String'];
  email_address: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  telephone: Scalars['String'];
  password: Scalars['String'];
};

export type CreateUserMutation = { __typename: 'Mutation' } & {
  createUser: { __typename: 'Viewer' } & ViewerFragmentFragment;
};

export type DeclineSensorGeneratedGrainTicketMutationVariables = {
  grain_bin_id: Scalars['Int'];
  grain_bin_ticket_id: Scalars['Int'];
};

export type DeclineSensorGeneratedGrainTicketMutation = { __typename: 'Mutation' } & {
  declineSensorGeneratedGrainTicket: Maybe<
    { __typename: 'SensorGeneratedGrainTicket' } & SensorGeneratedGrainTicketFragmentFragment
  >;
};

export type DecommissionFanControllerMutationVariables = {
  fan_controller_id: Scalars['Int'];
  as_of?: Maybe<Scalars['Date']>;
};

export type DecommissionFanControllerMutation = { __typename: 'Mutation' } & {
  decommissionFanController: { __typename: 'FanController' } & FanControllerFragmentFragment;
};

export type DecommissionHubMutationVariables = {
  hub_id: Scalars['Int'];
  as_of?: Maybe<Scalars['Date']>;
};

export type DecommissionHubMutation = { __typename: 'Mutation' } & {
  decommissionHub: { __typename: 'Hub' } & HubFragmentFragment;
};

export type DeleteBinStoragePeriodMutationVariables = {
  grain_bin_id: Scalars['Int'];
  grain_bin_storage_cycle_id: Scalars['Int'];
};

export type DeleteBinStoragePeriodMutation = { __typename: 'Mutation' } & {
  deleteBinStoragePeriod: Maybe<
    { __typename: 'GrainBinStoragePeriod' } & Pick<
      GrainBinStoragePeriod,
      | 'grain_bin_storage_cycle_id'
      | 'grain_bin_id'
      | 'storage_cycle_name'
      | 'grain_type'
      | 'start_epoch'
      | 'end_epoch'
      | 'created_at'
    >
  >;
};

export type DeleteGrainBinSiteMutationVariables = {
  site_id: Scalars['Int'];
};

export type DeleteGrainBinSiteMutation = { __typename: 'Mutation' } & {
  deleteGrainBinSite: Maybe<{ __typename: 'GrainBinSite' } & GrainBinSiteFragmentFragment>;
};

export type DeleteGrainBinTicketMutationVariables = {
  grain_bin_id: Scalars['Int'];
  grain_bin_ticket_id: Scalars['Int'];
};

export type DeleteGrainBinTicketMutation = { __typename: 'Mutation' } & {
  deleteGrainBinTicket: Maybe<
    { __typename: 'GrainBinTicketRow' } & GrainBinTicketRowFragmentFragment
  >;
};

export type DeleteUserMutationVariables = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
};

export type DeleteUserMutation = { __typename: 'Mutation' } & {
  deleteUser: { __typename: 'UserId' } & Pick<UserId, 'user_id'>;
};

export type DeleteUserBySuperUserMutationVariables = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
};

export type DeleteUserBySuperUserMutation = { __typename: 'Mutation' } & {
  deleteUserBySuperUser: { __typename: 'UserId' } & Pick<UserId, 'user_id'>;
};

export type InitiateGuidedRecommendationsMutationVariables = {
  container_id: Scalars['Int'];
  container_type: ContainerType;
  automated_period: Scalars['Int'];
};

export type InitiateGuidedRecommendationsMutation = { __typename: 'Mutation' } & {
  initiateGuidedRecommendations: {
    __typename: 'GrainContainer';
  } & GrainBinGrainContainerFragmentFragment;
};

export type InviteUserMutationVariables = {
  account_id: Scalars['Int'];
  email_address: Scalars['String'];
  role: UserRole;
};

export type InviteUserMutation = { __typename: 'Mutation' } & Pick<Mutation, 'inviteUser'>;

export type LinkBargeCoverPelletMutationVariables = {
  barge_id: Scalars['Int'];
  cover_no: Scalars['Int'];
  pellet_id: Scalars['String'];
};

export type LinkBargeCoverPelletMutation = { __typename: 'Mutation' } & {
  linkBargeCoverPellet: Array<
    { __typename: 'BargeCoverPelletLink' } & BargeCoverPelletLinkFragmentFragment
  >;
};

export type LinkPileCableMutationVariables = {
  alias: Scalars['String'];
  pile_id: Scalars['Int'];
  cable_id: Scalars['Int'];
  x_ft: Scalars['Float'];
  y_ft: Scalars['Float'];
};

export type LinkPileCableMutation = { __typename: 'Mutation' } & {
  linkPileCable: { __typename: 'PileCableLink' } & PileCableLinkFragmentFragment;
};

export type LinkPileCablePelletMutationVariables = {
  pile_id: Scalars['Int'];
  cable_no: Scalars['Int'];
  pellet_id: Scalars['String'];
};

export type LinkPileCablePelletMutation = { __typename: 'Mutation' } & {
  linkPileCablePellet: Array<
    { __typename: 'PileCablePelletLink' } & PileCablePelletLinkFragmentFragment
  >;
};

export type ManuallyAddUserMutationVariables = {
  account_id: Scalars['Int'];
  email_address: Scalars['String'];
  role: UserRole;
};

export type ManuallyAddUserMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'manuallyAddUser'
>;

export type PerformGrainBinSelectionMutationVariables = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  container_id: Scalars['Int'];
};

export type PerformGrainBinSelectionMutation = { __typename: 'Mutation' } & {
  performGrainBinSelection: {
    __typename: 'UserSelectedGrainBin';
  } & UserSelectedGrainBinFragmentFragment;
};

export type UserSelectedGrainContainerFragmentFragment = {
  __typename: 'UserSelectedGrainContainer';
} & Pick<UserSelectedGrainContainer, 'account_id' | 'user_id' | 'container_id' | 'container_type'>;

export type PerformGrainBinUnselectionMutationVariables = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
};

export type PerformGrainBinUnselectionMutation = { __typename: 'Mutation' } & {
  performGrainBinUnselection: {
    __typename: 'UserSelectedGrainContainer';
  } & UserSelectedGrainContainerFragmentFragment;
};

export type PingFanControllerMutationVariables = {
  fan_controller_id: Scalars['Int'];
};

export type PingFanControllerMutation = { __typename: 'Mutation' } & {
  pingFanController: Maybe<
    { __typename: 'FanControllerPingRequest' } & FanControllerRequestFragmentFragment
  >;
};

export type RegisterFanControllerMutationVariables = {
  core_id: Scalars['String'];
  environment: Scalars['String'];
  hardware_rev: Scalars['String'];
  firmware: Scalars['String'];
  notes: Scalars['String'];
  created_at?: Maybe<Scalars['Date']>;
  is_plenum_sensored_fc?: Maybe<Scalars['Boolean']>;
};

export type RegisterFanControllerMutation = { __typename: 'Mutation' } & {
  registerFanController: Maybe<{ __typename: 'FanController' } & FanControllerFragmentFragment>;
};

export type RegisterHubMutationVariables = {
  core_id: Scalars['String'];
  is_active: Scalars['Boolean'];
  environment: Scalars['String'];
  hardware_rev: Scalars['String'];
  firmware: Scalars['String'];
  notes: Scalars['String'];
  ref_pellet_id?: Maybe<Scalars['String']>;
  hub_pressure_offset_pa?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['Date']>;
  is_plenum_sensored_fc?: Maybe<Scalars['Boolean']>;
};

export type RegisterHubMutation = { __typename: 'Mutation' } & {
  registerHub: { __typename: 'Hub' } & HubFragmentFragment;
};

export type RemoveBinEventMutationVariables = {
  grain_bin_id: Scalars['Int'];
  epoch_time: Scalars['Date'];
  event_type: BinEventType;
};

export type RemoveBinEventMutation = { __typename: 'Mutation' } & {
  removeBinEvent: Array<{ __typename: 'BinEvent' } & BinEventFragmentFragment>;
};

export type RenameGrainBinSiteMutationVariables = {
  site_id: Scalars['Int'];
  site_name: Scalars['String'];
};

export type RenameGrainBinSiteMutation = { __typename: 'Mutation' } & {
  renameGrainBinSite: Maybe<{ __typename: 'GrainBinSite' } & GrainBinSiteFragmentFragment>;
};

export type RenewTokenMutationVariables = {};

export type RenewTokenMutation = { __typename: 'Mutation' } & {
  renewToken: { __typename: 'Viewer' } & ViewerFragmentFragment;
};

export type RequestUserVerificationMutationVariables = {
  user_id: Scalars['Int'];
};

export type RequestUserVerificationMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'requestUserVerification'
>;

export type ResetFanControllerMutationVariables = {
  fan_controller_id: Scalars['Int'];
};

export type ResetFanControllerMutation = { __typename: 'Mutation' } & {
  resetFanController: Maybe<
    { __typename: 'FanControllerResetRequest' } & FanControllerRequestFragmentFragment
  >;
};

export type ResetPasswordMutationVariables = {
  email_address: Scalars['String'];
};

export type ResetPasswordMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'requestPasswordReset'
>;

export type SaveBinEstimateTargetValuesMutationVariables = {
  grain_bin_id: Scalars['Int'];
  target_temperature_f: Scalars['Float'];
  target_mcwb_percent: Scalars['Float'];
};

export type SaveBinEstimateTargetValuesMutation = { __typename: 'Mutation' } & {
  saveBinEstimateTargetValues: Maybe<
    { __typename: 'GrainBinEstimateParameters' } & Pick<
      GrainBinEstimateParameters,
      | 'grain_bin_id'
      | 'target_temperature_f'
      | 'target_mcwb_percent'
      | 'last_breaking_change_date'
      | 'latest_result_requested_at_date'
    >
  >;
};

export type SaveOperationsViewRowVisibilityPrefsMutationVariables = {
  account_id: Scalars['Int'];
  pref_json: Scalars['String'];
};

export type SaveOperationsViewRowVisibilityPrefsMutation = { __typename: 'Mutation' } & {
  saveOperationsViewRowVisibilityPrefs: Maybe<
    { __typename: 'RowVisibilityPref' } & OpsViewRowVisibilityPrefsFragmentFragment
  >;
};

export type SaveOperationsViewUserLevelPrefsMutationVariables = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  pref_type_key: Scalars['Int'];
  pref_json: Scalars['String'];
};

export type SaveOperationsViewUserLevelPrefsMutation = { __typename: 'Mutation' } & {
  saveOperationsViewUserLevelPrefs: Maybe<
    { __typename: 'UserPref' } & OpsViewVisibilityPrefsFragmentFragment
  >;
};

export type SavePelletGroupPelletsMutationVariables = {
  pellet_group_id: Scalars['Int'];
  pellet_links: Array<PelletGroupPelletLinkInput>;
};

export type SavePelletGroupPelletsMutation = { __typename: 'Mutation' } & {
  savePelletGroupPellets: Array<{ __typename: 'PelletGroup' } & PelletGroupFragmentFragment>;
};

export type SaveTemporaryInputConditionsMutationVariables = {
  grain_bin_id: Scalars['Int'];
  current_grain_temp: Scalars['Float'];
  current_grain_emc: Scalars['Float'];
  target_grain_emc?: Maybe<Scalars['Float']>;
  recommendation_type: Scalars['String'];
};

export type SaveTemporaryInputConditionsMutation = { __typename: 'Mutation' } & {
  saveTemporaryInputConditions: {
    __typename: 'GrainTemporaryInputConditions';
  } & GrainTemporaryInputConditionsFragmentFragment;
};

export type SetFanControllerStateMutationVariables = {
  core_id: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  run_window?: Maybe<RunWindowInput>;
  vibration_threshold?: Maybe<Scalars['Float']>;
  safety_interval?: Maybe<Scalars['Int']>;
  config_reason: Scalars['Int'];
  clear_run_window?: Maybe<Scalars['Boolean']>;
  error_code?: Maybe<FanHeartbeatErrorCode>;
};

export type SetFanControllerStateMutation = { __typename: 'Mutation' } & {
  setFanControllerState: Maybe<
    { __typename: 'FanControllerState' } & FanControllerStateFragmentFragment
  >;
};

export type SetFanRunNowScheduleMutationVariables = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  container_id: Scalars['Int'];
  container_type: ContainerType;
  period: Scalars['String'];
};

export type SetFanRunNowScheduleMutation = { __typename: 'Mutation' } & {
  setFanRunNowSchedule: { __typename: 'GrainContainer' } & GrainBinGrainContainerFragmentFragment;
};

export type SetGrainBinFanSettingsMutationVariables = {
  grain_bin_id: Scalars['Int'];
  safety_weather?: Maybe<Scalars['Boolean']>;
  run_window?: Maybe<RunWindowInput>;
  clear_run_window?: Maybe<Scalars['Boolean']>;
};

export type SetGrainBinFanSettingsMutation = { __typename: 'Mutation' } & {
  setGrainBinFanSettings: { __typename: 'GrainBinFanSettings' } & FanSettingsFragmentFragment;
};

export type SetGrainContainerAerationScheduleMutationVariables = {
  container_id: Scalars['Int'];
  container_type: ContainerType;
  run_windows: Array<RunWindowInput>;
  start_recommendation_type: FanRunWindowRecommendedOption;
  end_recommendation_type: FanRunWindowRecommendedOption;
};

export type SetGrainContainerAerationScheduleMutation = { __typename: 'Mutation' } & {
  setGrainContainerAerationSchedule: {
    __typename: 'GrainContainer';
  } & GrainBinGrainContainerFragmentFragment;
};

export type SetNotificationsReadOnMutationVariables = {
  last_notifications_read_on: Scalars['Date'];
};

export type SetNotificationsReadOnMutation = { __typename: 'Mutation' } & {
  setNotificationsReadOn: { __typename: 'UserNotificationRead' } & Pick<
    UserNotificationRead,
    'user_id' | 'account_id' | 'last_notifications_read_on'
  >;
};

export type SetUserPasswordMutationVariables = {
  token: Scalars['String'];
  password: Scalars['String'];
};

export type SetUserPasswordMutation = { __typename: 'Mutation' } & {
  setUserPassword: { __typename: 'Viewer' } & ViewerFragmentFragment;
};

export type SetUserUnitPrefMutationVariables = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  temp_unit: TempUnitType;
  distance_unit: DistanceUnitType;
  mass_unit: MassUnitType;
  test_weight_unit: TestWeightUnitType;
};

export type SetUserUnitPrefMutation = { __typename: 'Mutation' } & {
  setUserUnitsPref: { __typename: 'UserUnitsPref' } & UserUnitsPrefFragmentFragment;
};

export type StopGrainContainerAerationMutationVariables = {
  container_id: Scalars['Int'];
  container_type: ContainerType;
};

export type StopGrainContainerAerationMutation = { __typename: 'Mutation' } & {
  stopGrainContainerAeration: {
    __typename: 'GrainContainer';
  } & GrainBinGrainContainerFragmentFragment;
};

export type SubscribeWeeklyGrainEmailMutationVariables = {
  account_id: Scalars['Int'];
  user_id: Scalars['Int'];
  schedule_day: Scalars['String'];
  schedule_hour: Scalars['Int'];
  schedule_timezone: Scalars['String'];
};

export type SubscribeWeeklyGrainEmailMutation = { __typename: 'Mutation' } & {
  subscribeWeeklyGrainEmail: {
    __typename: 'UserWeeklyGrainEmailSchedule';
  } & UserWeeklyGrainEmailScheduleFragmentFragment;
};

export type UnassignFanControllerMutationVariables = {
  grain_bin_fan_controller_id: Scalars['Int'];
};

export type UnassignFanControllerMutation = { __typename: 'Mutation' } & {
  unassignFanController: { __typename: 'GrainBinFanSettings' } & FanSettingsFragmentFragment;
};

export type UnlinkBargeCoverPelletMutationVariables = {
  barge_cover_pellet_link_id: Scalars['Int'];
};

export type UnlinkBargeCoverPelletMutation = { __typename: 'Mutation' } & {
  unlinkBargeCoverPellet: Array<
    { __typename: 'BargeCoverPelletLink' } & BargeCoverPelletLinkFragmentFragment
  >;
};

export type UnlinkPileCableMutationVariables = {
  pile_cable_link_id: Scalars['Int'];
};

export type UnlinkPileCableMutation = { __typename: 'Mutation' } & {
  unlinkPileCable: { __typename: 'PileCableLink' } & PileCableLinkFragmentFragment;
};

export type UnlinkPileCablePelletMutationVariables = {
  pile_cable_pellet_link_id: Scalars['Int'];
};

export type UnlinkPileCablePelletMutation = { __typename: 'Mutation' } & {
  unlinkPileCablePellet: Array<
    { __typename: 'PileCablePelletLink' } & PileCablePelletLinkFragmentFragment
  >;
};

export type UnsubscribeWeeklyGrainEmailMutationVariables = {
  user_id: Scalars['Int'];
};

export type UnsubscribeWeeklyGrainEmailMutation = { __typename: 'Mutation' } & {
  unsubscribeWeeklyGrainEmail: {
    __typename: 'UserWeeklyGrainEmailSchedule';
  } & UserWeeklyGrainEmailScheduleFragmentFragment;
};

export type UpdateAccountMutationVariables = {
  account_id: Scalars['Int'];
  organization: Scalars['String'];
  barge_support: Scalars['Boolean'];
  grain_bin_support: Scalars['Boolean'];
  pile_support: Scalars['Boolean'];
};

export type UpdateAccountMutation = { __typename: 'Mutation' } & {
  updateAccount: { __typename: 'Account' } & AccountFragmentFragment;
};

export type UpdateBargeMutationVariables = {
  barge_id: Scalars['Int'];
  alias?: Maybe<Scalars['String']>;
  default_grain_type?: Maybe<GrainType>;
  height_ft: Scalars['Float'];
  length_ft: Scalars['Float'];
  width_ft: Scalars['Float'];
};

export type UpdateBargeMutation = { __typename: 'Mutation' } & {
  updateBarge: { __typename: 'Barge' } & BargeFragmentFragment;
};

export type UpdateBinStoragePeriodMutationVariables = {
  grain_bin_id: Scalars['Int'];
  grain_bin_storage_cycle_id: Scalars['Int'];
  grain_type?: Maybe<GrainType>;
  storage_cycle_name?: Maybe<Scalars['String']>;
  start_epoch?: Maybe<Scalars['Date']>;
  end_epoch?: Maybe<Scalars['Date']>;
};

export type UpdateBinStoragePeriodMutation = { __typename: 'Mutation' } & {
  updateBinStoragePeriod: Maybe<
    { __typename: 'GrainBinStoragePeriod' } & Pick<
      GrainBinStoragePeriod,
      | 'grain_bin_storage_cycle_id'
      | 'grain_bin_id'
      | 'storage_cycle_name'
      | 'grain_type'
      | 'start_epoch'
      | 'end_epoch'
      | 'created_at'
    >
  >;
};

export type UpdateFanControllerMutationVariables = {
  fan_controller_id: Scalars['Int'];
  hardware_rev?: Maybe<Scalars['String']>;
  diag_test_is_enable?: Maybe<Scalars['Boolean']>;
};

export type UpdateFanControllerMutation = { __typename: 'Mutation' } & {
  updateFanController: Maybe<{ __typename: 'FanController' } & FanControllerFragmentFragment>;
};

export type UpdateGrainBinMutationVariables = {
  grain_bin_id: Scalars['Int'];
  bin_name?: Maybe<Scalars['String']>;
  grain_type?: Maybe<GrainType>;
  height_ft?: Maybe<Scalars['Float']>;
  diameter_ft?: Maybe<Scalars['Float']>;
  zip_code?: Maybe<Scalars['String']>;
  location?: Maybe<LocationInput>;
  has_grain_spreader?: Maybe<Scalars['Boolean']>;
  current_grain_emc?: Maybe<Scalars['Float']>;
  current_grain_temp?: Maybe<Scalars['Float']>;
  target_grain_emc?: Maybe<Scalars['Float']>;
  price_per_kwh?: Maybe<Scalars['Float']>;
  recommendation_type?: Maybe<Scalars['String']>;
  opt_in_fan_guidance?: Maybe<Scalars['Boolean']>;
  enable_fan_guidance?: Maybe<Scalars['Boolean']>;
  fan_guidance_start_date?: Maybe<Scalars['Date']>;
  fan_guidance_end_date?: Maybe<Scalars['Date']>;
  floor_type?: Maybe<FloorType>;
};

export type UpdateGrainBinMutation = { __typename: 'Mutation' } & {
  updateGrainBin: { __typename: 'GrainBin' } & GrainBinFragmentFragment;
};

export type UpdateGrainBinFanControllerMutationVariables = {
  grain_bin_fan_controller_id: Scalars['Int'];
  has_plenum_sensor?: Maybe<Scalars['Boolean']>;
  cfm?: Maybe<Scalars['Float']>;
  static_pressure_in?: Maybe<Scalars['Float']>;
  alias?: Maybe<Scalars['String']>;
  phase?: Maybe<Scalars['Int']>;
  power_factor?: Maybe<Scalars['Float']>;
  voltage?: Maybe<Scalars['Int']>;
  fan_model_id?: Maybe<Scalars['Int']>;
  make: Scalars['String'];
  fan_brand_name: Scalars['String'];
  fan_model_type: FanType;
  fan_horsepower: Scalars['String'];
  diameter: Scalars['Float'];
  fan_model_created_type: FanModelCreatedType;
  linked_generic_fan_model_id?: Maybe<Scalars['Int']>;
  no_of_fans_connected: Scalars['Int'];
};

export type UpdateGrainBinFanControllerMutation = { __typename: 'Mutation' } & {
  updateGrainBinFanController: {
    __typename: 'GrainBinFanController';
  } & GrainBinFanControllerFragmentFragment;
};

export type UpdateGrainBinNotificationPrefMutationVariables = {
  user_id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
  account_id: Scalars['Int'];
  opt_in: Scalars['Boolean'];
};

export type UpdateGrainBinNotificationPrefMutation = { __typename: 'Mutation' } & {
  updateGrainBinNotificationPref: { __typename: 'GrainBinNotificationOptIn' } & Pick<
    GrainBinNotificationOptIn,
    'user_id' | 'grain_bin_id' | 'account_id' | 'opt_in'
  >;
};

export type UpdateGrainBinSiteLinkMutationVariables = {
  grain_bin_id: Scalars['Int'];
  site_id: Scalars['Int'];
};

export type UpdateGrainBinSiteLinkMutation = { __typename: 'Mutation' } & {
  updateGrainBinSiteLink: Maybe<
    { __typename: 'GrainBinSiteLink' } & Pick<
      GrainBinSiteLink,
      'grain_bin_id' | 'site_id' | 'updated_at'
    >
  >;
};

export type UpdateGrainBinSpecificAlertPrefMutationVariables = {
  user_id: Scalars['Int'];
  fan_start_stop_alerts: Scalars['Boolean'];
  fan_error_alerts: Scalars['Boolean'];
  disable_fan_guidance_alerts: Scalars['Boolean'];
  grain_bin_co2_alerts: Scalars['Boolean'];
  grain_level_alerts: Scalars['Boolean'];
};

export type UpdateGrainBinSpecificAlertPrefMutation = { __typename: 'Mutation' } & {
  updateSpecificAlertPref: { __typename: 'GrainSpecificAlertPrefs' } & Pick<
    GrainSpecificAlertPrefs,
    | 'user_id'
    | 'fan_start_stop_alerts'
    | 'fan_error_alerts'
    | 'disable_fan_guidance_alerts'
    | 'grain_bin_co2_alerts'
    | 'grain_level_alerts'
  >;
};

export type UpdateGrainBinTicketMutationVariables = {
  grain_bin_id: Scalars['Int'];
  grain_bin_ticket_id: Scalars['Int'];
  epoch_time: Scalars['Date'];
  volume: Scalars['Float'];
  weight_in_lbs: Scalars['Float'];
  test_weight_in_lbs: Scalars['Float'];
  action_performed: GrainTicketActionType;
  grain_moisture_pct: Scalars['Float'];
  notes: Scalars['String'];
  grain_surface_shape: GrainSurfaceShapeType;
  grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
};

export type UpdateGrainBinTicketMutation = { __typename: 'Mutation' } & {
  updateGrainBinTicket: Maybe<
    { __typename: 'GrainBinTicketRow' } & GrainBinTicketRowFragmentFragment
  >;
};

export type UpdateGrainBinWeeklyEmailPrefMutationVariables = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  grain_bin_id: Scalars['Int'];
  opt_in: Scalars['Boolean'];
};

export type UpdateGrainBinWeeklyEmailPrefMutation = { __typename: 'Mutation' } & {
  updateGrainBinWeeklyEmailPref: {
    __typename: 'GrainBinWeeklyEmailPref';
  } & GrainBinWeeklyEmailPrefFragmentFragment;
};

export type UpdateHubAssignmentMutationVariables = {
  hub_container_link_id: Scalars['Int'];
  start_epoch?: Maybe<Scalars['Date']>;
  end_epoch?: Maybe<Scalars['Date']>;
  nickname?: Maybe<Scalars['String']>;
  hub_type?: Maybe<HubType>;
  has_level_sensor?: Maybe<Scalars['Boolean']>;
  hub_offset_ft?: Maybe<Scalars['Float']>;
};

export type UpdateHubAssignmentMutation = { __typename: 'Mutation' } & {
  updateHubAssignment: { __typename: 'HubContainerLink' } & HubContainerLinkBaseFragmentFragment;
};

export type UpdatePileMutationVariables = {
  pile_id: Scalars['Int'];
  alias?: Maybe<Scalars['String']>;
  default_grain_type?: Maybe<GrainType>;
  orientation: Orientation;
  shape?: Maybe<ShapeInput>;
};

export type UpdatePileMutation = { __typename: 'Mutation' } & {
  updatePile: { __typename: 'Pile' } & PileFragmentFragment;
};

export type UpdateUserMutationVariables = {
  user_id: Scalars['Int'];
  email_address?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

export type UpdateUserMutation = { __typename: 'Mutation' } & {
  updateUser: { __typename: 'User' } & UserFragmentFragment;
};

export type UpdateUserPasswordMutationVariables = {
  user_id: Scalars['Int'];
  old_password: Scalars['String'];
  new_password: Scalars['String'];
};

export type UpdateUserPasswordMutation = { __typename: 'Mutation' } & {
  updateUserPassword: { __typename: 'User' } & Pick<User, 'user_id'>;
};

export type UpdateUserRoleMutationVariables = {
  user_id: Scalars['Int'];
  role: UserRole;
};

export type UpdateUserRoleMutation = { __typename: 'Mutation' } & {
  updateUser: { __typename: 'User' } & UserFragmentFragment;
};

export type UpdateUserSettingsMutationVariables = {
  user_id: Scalars['Int'];
  settings: UserSettingsInput;
};

export type UpdateUserSettingsMutation = { __typename: 'Mutation' } & {
  updateUser: { __typename: 'User' } & UserFragmentFragment;
};

export type UpdateUserVerificationMutationVariables = {
  token: Scalars['String'];
};

export type UpdateUserVerificationMutation = { __typename: 'Mutation' } & {
  updateUserVerification: { __typename: 'Viewer' } & ViewerFragmentFragment;
};

export type UpdateWeeklyGrainEmailScheduleMutationVariables = {
  user_id: Scalars['Int'];
  schedule_day: Scalars['String'];
  schedule_hour: Scalars['Int'];
  schedule_timezone: Scalars['String'];
};

export type UpdateWeeklyGrainEmailScheduleMutation = { __typename: 'Mutation' } & {
  updateWeeklyGrainEmailSchedule: {
    __typename: 'UserWeeklyGrainEmailSchedule';
  } & UserWeeklyGrainEmailScheduleFragmentFragment;
};

export type UploadGrainFanPlotsMutationVariables = {
  grain_bin_fan_plots: Array<Maybe<GrainFanPlotsInput>>;
};

export type UploadGrainFanPlotsMutation = { __typename: 'Mutation' } & {
  uploadGrainFanPlots: Array<
    Maybe<{ __typename: 'GrainFanPlotsOutput' } & GrainFanPlotsOutputFragmentFragment>
  >;
};

export type ValidateStoragePeriodOverlapsMutationVariables = {
  grain_bin_id: Scalars['Int'];
  storage_cycle_name: Scalars['String'];
  grain_type: GrainType;
  start_epoch: Scalars['Date'];
  end_epoch?: Maybe<Scalars['Date']>;
  grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
};

export type ValidateStoragePeriodOverlapsMutation = { __typename: 'Mutation' } & {
  validateStoragePeriodOverlaps: {
    __typename: 'GrainBinStoragePeriodValidationResult';
  } & GrainBinStoragePeriodValidationResultFragmentFragment;
};

export type GetAccountQueryVariables = {
  account_id: Scalars['Int'];
};

export type GetAccountQuery = { __typename: 'Query' } & {
  account: { __typename: 'Account' } & AccountFragmentFragment;
};

export type GetAccountBargesQueryVariables = {
  account_id: Scalars['Int'];
};

export type GetAccountBargesQuery = { __typename: 'Query' } & {
  account: { __typename: 'Account' } & Pick<Account, 'account_id'> & {
      barge_links: Array<
        { __typename: 'BargeAccountLink' } & Pick<BargeAccountLink, 'barge_id'> & {
            barge: { __typename: 'Barge' } & BargeFragmentFragment;
            container: { __typename: 'GrainContainer' } & GrainContainerHubLinksFragmentFragment;
          }
      >;
    };
};

export type GetAccountGrainBinsQueryVariables = {
  account_id: Scalars['Int'];
};

export type GetAccountGrainBinsQuery = { __typename: 'Query' } & {
  account: { __typename: 'Account' } & Pick<Account, 'account_id'> & {
      grain_bin_links: Array<
        { __typename: 'GrainBinAccountLink' } & GrainBinAccountLinkInternalFragmentFragment
      >;
    };
};

export type GetAccountPilesQueryVariables = {
  account_id: Scalars['Int'];
};

export type GetAccountPilesQuery = { __typename: 'Query' } & {
  account: { __typename: 'Account' } & Pick<Account, 'account_id'> & {
      pile_links: Array<
        { __typename: 'PileAccountLink' } & PileAccountLinkInternalFragmentFragment
      >;
    };
};

export type GetAccountsQueryVariables = {};

export type GetAccountsQuery = { __typename: 'Query' } & {
  accounts: Array<{ __typename: 'Account' } & BaseAccountFragmentFragment>;
};

export type GetAccountUsersQueryVariables = {
  account_id: Scalars['Int'];
};

export type GetAccountUsersQuery = { __typename: 'Query' } & {
  account: { __typename: 'Account' } & Pick<Account, 'account_id'> & {
      users: Array<{ __typename: 'User' } & UserFragmentFragment>;
    };
};

export type GetActivePileCableLinksQueryVariables = {
  pile_id: Scalars['Int'];
};

export type GetActivePileCableLinksQuery = { __typename: 'Query' } & {
  active_pile_cable_links: Array<{ __typename: 'PileCableLink' } & PileCableLinkFragmentFragment>;
};

export type GetAreFanControllersAssignedQueryVariables = {
  device_ids: Array<Scalars['String']>;
};

export type GetAreFanControllersAssignedQuery = { __typename: 'Query' } & {
  are_fan_controllers_assigned: Array<
    { __typename: 'AssignmentStatus' } & Pick<AssignmentStatus, 'device_id' | 'is_assigned'>
  >;
};

export type GetAreHubsAssignedQueryVariables = {
  device_ids: Array<Scalars['String']>;
};

export type GetAreHubsAssignedQuery = { __typename: 'Query' } & {
  are_hubs_assigned: Array<
    { __typename: 'AssignmentStatus' } & Pick<AssignmentStatus, 'device_id' | 'is_assigned'>
  >;
};

export type GetBargeQueryVariables = {
  barge_id: Scalars['Int'];
};

export type GetBargeQuery = { __typename: 'Query' } & {
  barge: { __typename: 'Barge' } & BargeFragmentFragment;
};

export type GetBargeAccountQueryVariables = {
  barge_id: Scalars['Int'];
};

export type GetBargeAccountQuery = { __typename: 'Query' } & {
  barge: { __typename: 'Barge' } & Pick<Barge, 'barge_id'> & {
      account_link: { __typename: 'BargeAccountLink' } & Pick<
        BargeAccountLink,
        'barge_id' | 'account_id'
      >;
    };
};

export type GetBargeContainerQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
};

export type GetBargeContainerQuery = { __typename: 'Query' } & {
  grain_container: { __typename: 'GrainContainer' } & {
    telemetry: { __typename: 'GrainTelemetry' } & BargeTelemetryFragmentFragment;
  } & (GrainContainerHubLinksFragmentFragment &
      GrainContainerHubLinksWithFcPlenumHubsFragmentFragment);
};

export type GetBargeCoverPelletLinksQueryVariables = {
  barge_id: Scalars['Int'];
};

export type GetBargeCoverPelletLinksQuery = { __typename: 'Query' } & {
  barge_cover_pellet_links: Array<
    { __typename: 'BargeCoverPelletLink' } & BargeCoverPelletLinkFragmentFragment
  >;
};

export type GetBaseAccountQueryVariables = {
  account_id: Scalars['Int'];
};

export type GetBaseAccountQuery = { __typename: 'Query' } & {
  account: { __typename: 'Account' } & BaseAccountFragmentFragment;
};

export type GetBinEstimateProcessingStateQueryVariables = {
  grain_bin_id: Scalars['Int'];
};

export type GetBinEstimateProcessingStateQuery = { __typename: 'Query' } & {
  bin_estimate_processing_state: { __typename: 'BinEstimateProcessingStateInfo' } & Pick<
    BinEstimateProcessingStateInfo,
    'grain_bin_id' | 'processing'
  >;
};

export type GetBinGrainModelPrerequisitesQueryVariables = {
  grain_bin_id: Scalars['Int'];
};

export type GetBinGrainModelPrerequisitesQuery = { __typename: 'Query' } & {
  bin_grain_model_prerequisites: {
    __typename: 'BinGrainModelPrerequisitesInfo';
  } & BinGrainModelPrerequisitesInfoFragmentFragment;
};

export type GetContainerQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
};

export type GetContainerQuery = { __typename: 'Query' } & {
  grain_container: { __typename: 'GrainContainer' } & GrainContainerIdFragmentFragment;
};

export type GetContainerGrainLevelPercentQueryVariables = {
  grain_bin_id: Scalars['Int'];
};

export type GetContainerGrainLevelPercentQuery = { __typename: 'Query' } & {
  container_grain_level_percent: Maybe<
    { __typename: 'ContainerGrainLevelPercent' } & Pick<
      ContainerGrainLevelPercent,
      'grain_level_bushels' | 'grain_level_percent'
    >
  >;
};

export type GetContainersAerationScheduleQueryVariables = {
  grain_bin_ids: Array<Scalars['Int']>;
};

export type GetContainersAerationScheduleQuery = { __typename: 'Query' } & {
  containers_aeration_schedule: Array<
    Maybe<
      { __typename: 'ContainerAerationSchedule' } & Pick<
        ContainerAerationSchedule,
        'grain_bin_id' | 'aeration_schedule_type'
      > & {
          aeration_schedule: Array<
            { __typename: 'FanControllerRunWindow' } & Pick<
              FanControllerRunWindow,
              'start_epoch' | 'end_epoch'
            >
          >;
        }
    >
  >;
};

export type GetContainersGrainLevelQueryVariables = {
  grain_bin_ids: Array<Scalars['Int']>;
};

export type GetContainersGrainLevelQuery = { __typename: 'Query' } & {
  containers_grain_level: Array<
    Maybe<
      { __typename: 'ContainerGrainLevel' } & Pick<ContainerGrainLevel, 'grain_bin_id'> & {
          grain_level: Maybe<
            { __typename: 'GrainLevel' } & Pick<
              GrainLevel,
              'grain_level_bushels' | 'grain_level_percent'
            >
          >;
        }
    >
  >;
};

export type GetContainersLastAerationRunQueryVariables = {
  grain_bin_ids: Array<Scalars['Int']>;
};

export type GetContainersLastAerationRunQuery = { __typename: 'Query' } & {
  containers_last_aeration_run: Array<
    Maybe<
      { __typename: 'ContainerLastAerationRun' } & Pick<
        ContainerLastAerationRun,
        'grain_bin_id'
      > & {
          last_aeration_run: Maybe<
            { __typename: 'GrainContainerAerationRun' } & Pick<
              GrainContainerAerationRun,
              'container_id' | 'start_epoch' | 'end_epoch'
            >
          >;
        }
    >
  >;
};

export type GetContainersLastWeekAirspaceHistoryQueryVariables = {
  grain_bin_ids: Array<Scalars['Int']>;
};

export type GetContainersLastWeekAirspaceHistoryQuery = { __typename: 'Query' } & {
  containers_last_week_airspace_history: Array<
    Maybe<
      { __typename: 'ContainerLastWeekAirspaceHistory' } & Pick<
        ContainerLastWeekAirspaceHistory,
        'grain_bin_id' | 'last_week_co2_alert_sent_on'
      > & {
          last_week_airspace_history: Array<
            Maybe<
              { __typename: 'HeadspaceHubTelemetryHistory' } & Pick<
                HeadspaceHubTelemetryHistory,
                'hub_id'
              > & {
                  headspace_telemetry_history: Array<
                    { __typename: 'GrainEnvPointV2' } & HistoryPointV2Fragment
                  >;
                }
            >
          >;
        }
    >
  >;
};

export type GetContainersWeatherQueryVariables = {
  grain_bin_ids: Array<Scalars['Int']>;
};

export type GetContainersWeatherQuery = { __typename: 'Query' } & {
  containers_weather: Array<
    Maybe<
      { __typename: 'ContainerWeather' } & Pick<ContainerWeather, 'grain_bin_id'> & {
          current_weather: Maybe<
            { __typename: 'CurrentWeatherInfo' } & Pick<
              CurrentWeatherInfo,
              'icon' | 'temp_f' | 'humidity_rh' | 'emc'
            >
          >;
          today_weather: Maybe<{ __typename: 'WeatherInfo' } & GrainWeatherInfoFragmentFragment>;
          one_week_weather: Maybe<{ __typename: 'WeatherInfo' } & GrainWeatherInfoFragmentFragment>;
        }
    >
  >;
};

export type GetFanControllerQueryVariables = {
  fan_controller_id: Scalars['Int'];
};

export type GetFanControllerQuery = { __typename: 'Query' } & {
  fan_controller: Maybe<{ __typename: 'FanController' } & FanControllerDiagnosticsFragmentFragment>;
};

export type GetFanControllerEventHistoryQueryVariables = {
  fan_controller_id: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  request_as_of?: Maybe<Scalars['Date']>;
  response_as_of?: Maybe<Scalars['Date']>;
};

export type GetFanControllerEventHistoryQuery = { __typename: 'Query' } & {
  fan_controller_request_history: Array<FanControllerRequestFragmentFragment>;
  fan_controller_response_history: Array<FanControllerResponseFragmentFragment>;
};

export type GetFanControllersQueryVariables = {};

export type GetFanControllersQuery = { __typename: 'Query' } & {
  fan_controllers_query: Array<
    { __typename: 'FanController' } & FanControllerDiagnosticsFragmentFragment
  >;
};

export type GetFanControllersCountQueryVariables = {};

export type GetFanControllersCountQuery = { __typename: 'Query' } & {
  fan_controllers_count: { __typename: 'Count' } & Pick<Count, 'count'>;
};

export type GetFanControllersPageQueryVariables = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  sortBy: Scalars['String'];
  sortAsc: Scalars['Boolean'];
};

export type GetFanControllersPageQuery = { __typename: 'Query' } & {
  fan_controllers_page_query: Array<
    { __typename: 'FanController' } & FanControllerPageFragmentFragment
  >;
};

export type GetFanControllerStateQueryVariables = {
  fan_controller_id: Scalars['Int'];
};

export type GetFanControllerStateQuery = { __typename: 'Query' } & {
  fan_controller_state: { __typename: 'FanControllerState' } & FanControllerStateFragmentFragment;
};

export type GetGrainBinQueryVariables = {
  grain_bin_id: Scalars['Int'];
};

export type GetGrainBinQuery = { __typename: 'Query' } & {
  grain_bin: { __typename: 'GrainBin' } & GrainBinFragmentFragment;
};

export type GetGrainBinAccountQueryVariables = {
  grain_bin_id: Scalars['Int'];
};

export type GetGrainBinAccountQuery = { __typename: 'Query' } & {
  grain_bin: { __typename: 'GrainBin' } & Pick<GrainBin, 'grain_bin_id' | 'account_id'>;
};

export type GetGrainBinCfmSolutionQueryVariables = {
  grain_bin_id: Scalars['Int'];
};

export type GetGrainBinCfmSolutionQuery = { __typename: 'Query' } & {
  grain_bin_cfm_solution: Maybe<
    { __typename: 'GrainBinCfmSolutionOutput' } & GrainBinCfmSolutionOutputFragmentFragment
  >;
};

export type GetGrainBinContainerQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
};

export type GetGrainBinContainerQuery = { __typename: 'Query' } & {
  grain_container: { __typename: 'GrainContainer' } & {
    telemetry: { __typename: 'GrainTelemetry' } & GrainBinTelemetryFragmentFragment;
  } & (GrainContainerHubLinksFragmentFragment &
      GrainContainerHubLinksWithFcPlenumHubsFragmentFragment);
};

export type GetGrainBinDefaultExpValuesQueryVariables = {
  grain_bin_id: Scalars['Int'];
};

export type GetGrainBinDefaultExpValuesQuery = { __typename: 'Query' } & {
  grain_bin_default_experiment_bound_values: Maybe<
    { __typename: 'ExperimentBoundValues' } & ExperimentBoundValuesFragmentFragment
  >;
};

export type GetGrainBinEstimateQueryVariables = {
  grain_bin_id: Scalars['Int'];
};

export type GetGrainBinEstimateQuery = { __typename: 'Query' } & {
  grain_bin_estimate: Maybe<{ __typename: 'GrainBinEstimate' } & GrainBinEstimateFragmentFragment>;
};

export type GetGrainBinEventsQueryVariables = {
  grain_bin_id: Scalars['Int'];
};

export type GetGrainBinEventsQuery = { __typename: 'Query' } & {
  latest_grain_bin_events: Array<{ __typename: 'BinEvent' } & BinEventFragmentFragment>;
};

export type GetGrainBinExperimentCfmValuesQueryVariables = {
  grain_bin_id: Scalars['Int'];
};

export type GetGrainBinExperimentCfmValuesQuery = { __typename: 'Query' } & {
  grain_bin_experiment_cfm_values: {
    __typename: 'ExperimentCfmValues';
  } & ExperimentCfmValuesFragmentFragment;
};

export type GetGrainBinFanSettingsQueryVariables = {
  grain_bin_id: Scalars['Int'];
};

export type GetGrainBinFanSettingsQuery = { __typename: 'Query' } & {
  grain_bin_fan_settings: { __typename: 'GrainBinFanSettings' } & FanSettingsFragmentFragment;
};

export type GetGrainBinSitesQueryVariables = {
  account_id: Scalars['Int'];
  user_id: Scalars['Int'];
};

export type GetGrainBinSitesQuery = { __typename: 'Query' } & {
  grain_bin_sites: Array<
    { __typename: 'GrainBinSites' } & Pick<GrainBinSites, 'site_id' | 'site_name'> & {
        bins: Array<
          Maybe<
            { __typename: 'GrainBinAggregation' } & Pick<GrainBinAggregation, 'grain_bin_id'> & {
                grain_bin_attributes: { __typename: 'GrainBinDTO' } & GrainBinDtoFragmentFragment;
                fan_controllers: Array<
                  Maybe<
                    { __typename: 'FanControllerDto' } & Pick<
                      FanControllerDto,
                      'id' | 'state' | 'active'
                    > & {
                        fan_attributes: {
                          __typename: 'GrainBinFanController';
                        } & GrainBinFanControllerFragmentFragment;
                        fan_config_state: {
                          __typename: 'FanControllerState';
                        } & FanControllerStateFragmentFragment;
                      }
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type GetGrainBinStoragePeriodQueryVariables = {
  grain_bin_id: Scalars['Int'];
};

export type GetGrainBinStoragePeriodQuery = { __typename: 'Query' } & {
  grain_bin_storage_periods: Array<
    { __typename: 'GrainBinStoragePeriodWithOngoingStorageFlag' } & Pick<
      GrainBinStoragePeriodWithOngoingStorageFlag,
      | 'grain_bin_storage_cycle_id'
      | 'grain_bin_id'
      | 'storage_cycle_name'
      | 'is_ongoing_storage_period'
      | 'grain_type'
      | 'start_epoch'
      | 'end_epoch'
      | 'created_at'
    >
  >;
};

export type GetGrainBinTicketsQueryVariables = {
  grain_bin_id: Scalars['Int'];
  grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
};

export type GetGrainBinTicketsQuery = { __typename: 'Query' } & {
  grain_bin_tickets: { __typename: 'GrainBinTickets' } & Pick<
    GrainBinTickets,
    'grain_bin_tickets_total_weight_in_lbs' | 'grain_bin_tickets_total_bushels'
  > & {
      grain_bin_all_tickets: Array<
        { __typename: 'GrainBinTicket' } & GrainBinTicketFragmentFragment
      >;
    };
};

export type GetGrainBinWeeklyLevelDataQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
};

export type GetGrainBinWeeklyLevelDataQuery = { __typename: 'Query' } & {
  grain_container: { __typename: 'GrainContainer' } & Pick<
    GrainContainer,
    'container_id' | 'container_type'
  > & {
      grain_bin_tickets_level_history: { __typename: 'GrainBinTicketsLevelHistory' } & Pick<
        GrainBinTicketsLevelHistory,
        'period' | 'no_grain_tickets_created'
      > & {
          grain_tickets_bushel_level_on_period_start: Maybe<
            { __typename: 'GrainBinTicketLevelSample' } & Pick<
              GrainBinTicketLevelSample,
              'bushel' | 'epoch_time'
            >
          >;
          grain_tickets_bushel_level_on_period_end: Maybe<
            { __typename: 'GrainBinTicketLevelSample' } & Pick<
              GrainBinTicketLevelSample,
              'bushel' | 'epoch_time'
            >
          >;
          grain_tickets_level_samples: Array<
            Maybe<
              { __typename: 'GrainBinTicketLevelSample' } & Pick<
                GrainBinTicketLevelSample,
                'bushel' | 'epoch_time'
              >
            >
          >;
        };
      grain_bin_level_history: { __typename: 'GrainBinLevelHistory' } & Pick<
        GrainBinLevelHistory,
        'period' | 'max_bushels'
      > & {
          active_hubs_level_samples: Array<
            Maybe<
              { __typename: 'ActiveHubsLevelSamples' } & Pick<ActiveHubsLevelSamples, 'hub_id'> & {
                  level_samples: Array<
                    Maybe<
                      { __typename: 'GrainBinLevelSample' } & Pick<
                        GrainBinLevelSample,
                        'bushel' | 'epoch_time'
                      >
                    >
                  >;
                }
            >
          >;
        };
    };
};

export type GetGrainBinWithHubTelemetryQueryVariables = {
  grain_bin_id: Scalars['Int'];
};

export type GetGrainBinWithHubTelemetryQuery = { __typename: 'Query' } & {
  grain_bin_telemetry: Maybe<
    { __typename: 'GrainBinTelemetry' } & {
      interior: Maybe<{ __typename: 'GrainStatus' } & GrainStatusFragmentFragment>;
      headspace: Maybe<{ __typename: 'GrainStatus' } & GrainStatusFragmentFragment>;
      weather: Maybe<{ __typename: 'GrainStatus' } & GrainStatusFragmentFragment>;
      hourly_forecast: Maybe<
        { __typename: 'HourlyForecastStatus' } & HourlyForecastStatusFragmentFragment
      >;
    }
  >;
};

export type GetGrainContainerHistoryQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  period: Scalars['String'];
};

export type GetGrainContainerHistoryQuery = { __typename: 'Query' } & {
  grain_container: { __typename: 'GrainContainer' } & GrainContainerHistoryFragmentFragment;
};

export type GetGrainContainerHistoryBinSummaryQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  period: Scalars['String'];
};

export type GetGrainContainerHistoryBinSummaryQuery = { __typename: 'Query' } & {
  grain_container: {
    __typename: 'GrainContainer';
  } & GrainContainerHistoryBinSummaryFragmentFragment;
};

export type GetGrainContainerHistoryFanQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  period: Scalars['String'];
};

export type GetGrainContainerHistoryFanQuery = { __typename: 'Query' } & {
  grain_container: { __typename: 'GrainContainer' } & GrainContainerHistoryFanFragmentFragment;
};

export type GetGrainContainerHistoryAerationRunsQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
};

export type GetGrainContainerHistoryAerationRunsQuery = { __typename: 'Query' } & {
  grain_container: {
    __typename: 'GrainContainer';
  } & GrainContainerHistoryAerationRunsFragmentFragment;
};

export type GetGrainContainerHistoryAerationRunsWithinPeriodQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  period: Scalars['String'];
};

export type GetGrainContainerHistoryAerationRunsWithinPeriodQuery = { __typename: 'Query' } & {
  grain_container: {
    __typename: 'GrainContainer';
  } & GrainContainerHistoryAerationRunsWithinPeriodFragmentFragment;
};

export type GetGrainContainerHistoryAerationRunsWithLimitQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  current_grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
};

export type GetGrainContainerHistoryAerationRunsWithLimitQuery = { __typename: 'Query' } & {
  grain_container: {
    __typename: 'GrainContainer';
  } & GrainContainerHistoryAerationRunsWithLimitFragmentFragment;
};

export type GetGrainContainerHistoryNextAerationRunsWithLimitQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  current_grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
};

export type GetGrainContainerHistoryNextAerationRunsWithLimitQuery = { __typename: 'Query' } & {
  grain_container: {
    __typename: 'GrainContainer';
  } & GrainContainerHistoryNextAerationRunsWithLimitFragmentFragment;
};

export type GetGrainContainerHistoryHeadspaceQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  period: Scalars['String'];
};

export type GetGrainContainerHistoryHeadspaceQuery = { __typename: 'Query' } & {
  grain_container: {
    __typename: 'GrainContainer';
  } & GrainContainerHistoryHeadspaceFragmentFragment;
};

export type GetGrainContainerHistoryHeadspaceWeatherQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  period: Scalars['String'];
};

export type GetGrainContainerHistoryHeadspaceWeatherQuery = { __typename: 'Query' } & {
  grain_container: {
    __typename: 'GrainContainer';
  } & GrainContainerHistoryHeadspaceWeatherFragmentFragment;
};

export type GetGrainContainerHistoryGrainLevelQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  as_of: Scalars['Date'];
  period: Scalars['String'];
};

export type GetGrainContainerHistoryGrainLevelQuery = { __typename: 'Query' } & {
  grain_container: {
    __typename: 'GrainContainer';
  } & GrainContainerHistoryGrainLevelFragmentFragment;
};

export type GetGrainContainerHistoryGrainTicketsLevelQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  as_of: Scalars['Date'];
  period: Scalars['String'];
  grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
};

export type GetGrainContainerHistoryGrainTicketsLevelQuery = { __typename: 'Query' } & {
  grain_container: {
    __typename: 'GrainContainer';
  } & GrainContainerHistoryGrainTicketsLevelFragmentFragment;
};

export type GetGrainContainerHistoryInteriorQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  period: Scalars['String'];
};

export type GetGrainContainerHistoryInteriorQuery = { __typename: 'Query' } & {
  grain_container: { __typename: 'GrainContainer' } & GrainContainerHistoryInteriorFragmentFragment;
};

export type GetGrainContainerHistoryPelletQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  period: Scalars['String'];
  pellet_id: Scalars['String'];
};

export type GetGrainContainerHistoryPelletQuery = { __typename: 'Query' } & {
  grain_container: { __typename: 'GrainContainer' } & GrainContainerHistoryPelletFragmentFragment;
};

export type GetGrainContainerHistoryPelletsQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  period: Scalars['String'];
};

export type GetGrainContainerHistoryPelletsQuery = { __typename: 'Query' } & {
  grain_container: { __typename: 'GrainContainer' } & GrainContainerHistoryPelletsFragmentFragment;
};

export type GetGrainContainerHistoryWeatherQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  period: Scalars['String'];
};

export type GetGrainContainerHistoryWeatherQuery = { __typename: 'Query' } & {
  grain_container: { __typename: 'GrainContainer' } & GrainContainerHistoryWeatherFragmentFragment;
};

export type GetGrainContainerHubLinksQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
};

export type GetGrainContainerHubLinksQuery = { __typename: 'Query' } & {
  grain_container: { __typename: 'GrainContainer' } & GrainContainerHubLinksFragmentFragment;
};

export type GetGrainContainerMonthlyCo2HistoryWithAerationsQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
};

export type GetGrainContainerMonthlyCo2HistoryWithAerationsQuery = { __typename: 'Query' } & {
  grain_container: { __typename: 'GrainContainer' } & Pick<
    GrainContainer,
    'container_id' | 'container_type'
  > & {
      telemetry_history_v2: { __typename: 'GrainTelemetryHistoryV2' } & Pick<
        GrainTelemetryHistoryV2,
        'container_id' | 'container_type' | 'period'
      > & {
          headspace_history: { __typename: 'HeadspaceTelemetryHistory' } & {
            active_hubs_grain_telemetry_history: Array<
              Maybe<
                { __typename: 'HeadspaceHubTelemetryHistory' } & Pick<
                  HeadspaceHubTelemetryHistory,
                  'hub_id'
                > & {
                    headspace_telemetry_history: Array<
                      { __typename: 'GrainEnvPointV2' } & HistoryPointV2Fragment
                    >;
                  }
              >
            >;
          };
        };
      aeration_runs_within_period: { __typename: 'AerationRunWithinPeriod' } & Pick<
        AerationRunWithinPeriod,
        'period'
      > & {
          aeration_runs: Maybe<
            Array<
              Maybe<
                {
                  __typename: 'GrainContainerAerationRun';
                } & GrainContainerAerationRunFragmentFragment
              >
            >
          >;
        };
    };
};

export type GetGrainContainerWeeklyCo2HistoryWithAerationsQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
};

export type GetGrainContainerWeeklyCo2HistoryWithAerationsQuery = { __typename: 'Query' } & {
  grain_container: { __typename: 'GrainContainer' } & Pick<
    GrainContainer,
    'container_id' | 'container_type'
  > & {
      telemetry_history_v2: { __typename: 'GrainTelemetryHistoryV2' } & Pick<
        GrainTelemetryHistoryV2,
        'container_id' | 'container_type' | 'period'
      > & {
          headspace_history: { __typename: 'HeadspaceTelemetryHistory' } & {
            active_hubs_grain_telemetry_history: Array<
              Maybe<
                { __typename: 'HeadspaceHubTelemetryHistory' } & Pick<
                  HeadspaceHubTelemetryHistory,
                  'hub_id'
                > & {
                    headspace_telemetry_history: Array<
                      { __typename: 'GrainEnvPointV2' } & HistoryPointV2Fragment
                    >;
                  }
              >
            >;
          };
        };
      aeration_runs_within_period: { __typename: 'AerationRunWithinPeriod' } & Pick<
        AerationRunWithinPeriod,
        'period'
      > & {
          aeration_runs: Maybe<
            Array<
              Maybe<
                {
                  __typename: 'GrainContainerAerationRun';
                } & GrainContainerAerationRunFragmentFragment
              >
            >
          >;
        };
    };
};

export type GetGrainFanModelsQueryVariables = {
  fan_model_filter: Array<FanModelFilter>;
};

export type GetGrainFanModelsQuery = { __typename: 'Query' } & {
  grain_fan_models: Array<
    Maybe<{ __typename: 'FanModelResult' } & GetFanModelResultFragmentFragment>
  >;
};

export type GetGrainFanPlotsQueryVariables = {
  fan_brand_name?: Maybe<Scalars['String']>;
  fan_model_type?: Maybe<FanType>;
  fan_horsepower?: Maybe<Scalars['String']>;
};

export type GetGrainFanPlotsQuery = { __typename: 'Query' } & {
  grain_fan_plots: Maybe<
    Array<Maybe<{ __typename: 'GetGrainFanPlotsOutput' } & GetGrainFanPlotsOutputFragmentFragment>>
  >;
};

export type GetGrainTelemetryQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
};

export type GetGrainTelemetryQuery = { __typename: 'Query' } & {
  grain_container: { __typename: 'GrainContainer' } & Pick<GrainContainer, 'container_id'> & {
      telemetry: { __typename: 'GrainTelemetry' } & {
        weather_forecast: Maybe<
          { __typename: 'WeatherForecast' } & WeatherForecastFragmentFragment
        >;
        recommendation_windows: Maybe<
          { __typename: 'RecommendationWindows' } & RecommendationWindowsFragmentFragment
        >;
        temporary_recommendation_windows: Maybe<
          { __typename: 'TemporaryRecommendationWindows' } & Pick<
            TemporaryRecommendationWindows,
            'recommendation_type'
          > & {
              recommendation_windows: Maybe<
                { __typename: 'RecommendationWindows' } & RecommendationWindowsFragmentFragment
              >;
            }
        >;
      };
    };
};

export type GetHubContainerLinkQueryVariables = {
  hub_container_link_id: Scalars['Int'];
};

export type GetHubContainerLinkQuery = { __typename: 'Query' } & {
  hub_container_link: { __typename: 'HubContainerLink' } & Pick<
    HubContainerLink,
    'hub_container_link_id' | 'start_epoch' | 'end_epoch'
  >;
};

export type GetHubEventHistoryQueryVariables = {
  hub_id: Scalars['Int'];
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type GetHubEventHistoryQuery = { __typename: 'Query' } & {
  hub_event_history: { __typename: 'HubEventHistoryEdge' } & HubEventHistoryFragmentFragment;
};

export type GetHubParticleEventsQueryVariables = {
  core_id: Scalars['String'];
  as_of?: Maybe<Scalars['Date']>;
};

export type GetHubParticleEventsQuery = { __typename: 'Query' } & {
  particle_events: Array<{ __typename: 'ParticleEvent' } & ParticleEventFragmentFragment>;
};

export type GetHubsQueryVariables = {
  env: Scalars['String'];
};

export type GetHubsQuery = { __typename: 'Query' } & {
  hubs_query: Array<{ __typename: 'Hub' } & HubInfoFragmentFragment>;
};

export type GetIsFanControllerAssignedQueryVariables = {
  device_id: Scalars['String'];
};

export type GetIsFanControllerAssignedQuery = { __typename: 'Query' } & {
  is_fan_controller_assigned: Maybe<
    { __typename: 'AssignmentStatus' } & Pick<AssignmentStatus, 'is_assigned'>
  >;
};

export type GetIsHubAssignedQueryVariables = {
  device_id: Scalars['String'];
};

export type GetIsHubAssignedQuery = { __typename: 'Query' } & {
  is_hub_assigned: Maybe<
    { __typename: 'AssignmentStatus' } & Pick<AssignmentStatus, 'is_assigned'>
  >;
};

export type GetIsOtpValidQueryVariables = {
  token: Scalars['String'];
};

export type GetIsOtpValidQuery = { __typename: 'Query' } & Pick<Query, 'isOtpValid'>;

export type GetOpsViewColsVisibilityPrefsQueryVariables = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
};

export type GetOpsViewColsVisibilityPrefsQuery = { __typename: 'Query' } & {
  operations_view_cols_visibility_prefs: Maybe<
    { __typename: 'UserPref' } & OpsViewVisibilityPrefsFragmentFragment
  >;
};

export type GetOpsViewRowsVisibilityPrefsQueryVariables = {
  account_id: Scalars['Int'];
};

export type GetOpsViewRowsVisibilityPrefsQuery = { __typename: 'Query' } & {
  operations_view_rows_visibility_prefs: Maybe<
    { __typename: 'RowVisibilityPref' } & OpsViewRowVisibilityPrefsFragmentFragment
  >;
};

export type GetOrganizationUsersListQueryVariables = {
  account_id: Scalars['Int'];
};

export type GetOrganizationUsersListQuery = { __typename: 'Query' } & {
  account: { __typename: 'Account' } & Pick<Account, 'account_id'> & {
      organization_users_list: Array<{ __typename: 'UserModel' } & UserModelFragmentFragment>;
    };
};

export type GetPelletGroupQueryVariables = {
  pellet_group_id: Scalars['Int'];
};

export type GetPelletGroupQuery = { __typename: 'Query' } & {
  pellet_group: { __typename: 'PelletGroup' } & PelletGroupFragmentFragment;
};

export type GetPileQueryVariables = {
  pile_id: Scalars['Int'];
};

export type GetPileQuery = { __typename: 'Query' } & {
  pile: { __typename: 'Pile' } & PileFragmentFragment;
};

export type GetPileAccountQueryVariables = {
  pile_id: Scalars['Int'];
};

export type GetPileAccountQuery = { __typename: 'Query' } & {
  pile: { __typename: 'Pile' } & Pick<Pile, 'pile_id'> & {
      account_link: { __typename: 'PileAccountLink' } & Pick<
        PileAccountLink,
        'pile_id' | 'account_id'
      >;
    };
};

export type GetPileCablePelletLinksQueryVariables = {
  pile_id: Scalars['Int'];
};

export type GetPileCablePelletLinksQuery = { __typename: 'Query' } & {
  pile_cable_pellet_links: Array<
    { __typename: 'PileCablePelletLink' } & PileCablePelletLinkFragmentFragment
  >;
};

export type GetPileContainerQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
};

export type GetPileContainerQuery = { __typename: 'Query' } & {
  grain_container: { __typename: 'GrainContainer' } & {
    telemetry: { __typename: 'GrainTelemetry' } & BargeTelemetryFragmentFragment;
  } & GrainContainerHubLinksFragmentFragment;
};

export type GetPromptFanGuidanceExtensionQueryVariables = {
  grain_bin_id: Scalars['Int'];
};

export type GetPromptFanGuidanceExtensionQuery = { __typename: 'Query' } & Pick<
  Query,
  'prompt_fan_guidance_extension'
>;

export type GetRecommendedRunWindowsExperimentsQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  recommendation_type?: Maybe<Scalars['String']>;
  cfm_scaling?: Maybe<Scalars['Float']>;
  cfm_offset?: Maybe<Scalars['Float']>;
  cfm_min?: Maybe<Scalars['Float']>;
  cfm_max?: Maybe<Scalars['Float']>;
  cfm?: Maybe<Scalars['Float']>;
  upper_bound?: Maybe<Scalars['Float']>;
  lower_bound?: Maybe<Scalars['Float']>;
  from_weather_history?: Maybe<Scalars['Boolean']>;
  prev_week_no?: Maybe<Scalars['Int']>;
};

export type GetRecommendedRunWindowsExperimentsQuery = { __typename: 'Query' } & {
  recommended_run_windows_experiments: Array<
    { __typename: 'RecommRunWindowsExperiments' } & RecommRunWindowsExperimentsFragmentFragment
  >;
};

export type GetSensorGeneratedGrainTicketsQueryVariables = {
  grain_bin_id: Scalars['Int'];
  grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
};

export type GetSensorGeneratedGrainTicketsQuery = { __typename: 'Query' } & {
  sensor_generated_grain_tickets: Array<
    { __typename: 'SensorGeneratedGrainTicket' } & SensorGeneratedGrainTicketFragmentFragment
  >;
};

export type GetShowCo2LevelWarningQueryVariables = {
  container_id: Scalars['Int'];
};

export type GetShowCo2LevelWarningQuery = { __typename: 'Query' } & {
  show_co2_level_warning: Maybe<
    { __typename: 'CO2AlertInfo' } & Pick<
      Co2AlertInfo,
      'co2_alert_sent_on' | 'co2_alert_type' | 'co2_ppm'
    >
  >;
};

export type GetShowGrainTicketLevelDeviationWarningQueryVariables = {
  grain_bin_id: Scalars['Int'];
  grain_bin_storage_cycle_id?: Maybe<Scalars['Int']>;
};

export type GetShowGrainTicketLevelDeviationWarningQuery = { __typename: 'Query' } & Pick<
  Query,
  'show_grain_ticket_level_deviation_warning'
>;

export type GetUnreadNotificationsCountQueryVariables = {
  user_id: Scalars['Int'];
};

export type GetUnreadNotificationsCountQuery = { __typename: 'Query' } & {
  user: { __typename: 'User' } & Pick<User, 'user_new_notifications_count'>;
};

export type GetUserQueryVariables = {
  user_id: Scalars['Int'];
};

export type GetUserQuery = { __typename: 'Query' } & {
  user: { __typename: 'User' } & UserFragmentFragment;
};

export type GetUserDeviceNotificationsHistoryQueryVariables = {
  user_id: Scalars['Int'];
  account_id: Scalars['Int'];
  period: Scalars['String'];
};

export type GetUserDeviceNotificationsHistoryQuery = { __typename: 'Query' } & {
  user_device_notifications_history: Array<
    Maybe<{ __typename: 'GrainBinNotification' } & GrainBinNotificationFragmentFragment>
  >;
};

export type GetViewerQueryVariables = {};

export type GetViewerQuery = { __typename: 'Query' } & {
  viewer: Maybe<{ __typename: 'Viewer' } & ViewerFragmentFragment>;
};

export type GetWeatherHistoryQueryVariables = {
  container_id: Scalars['Int'];
  container_type: Scalars['Int'];
  prev_week_no: Scalars['Int'];
};

export type GetWeatherHistoryQuery = { __typename: 'Query' } & {
  grain_container: { __typename: 'GrainContainer' } & {
    telemetry: { __typename: 'GrainTelemetry' } & {
      weather_history: Maybe<{ __typename: 'WeatherHistory' } & WeatherHistoryFragmentFragment>;
    };
  };
};
export const BargeAccountLinkIdFragmentFragmentDoc = gql`
  fragment BargeAccountLinkIdFragment on BargeAccountLink {
    barge_id
  }
`;
export const BargeFragmentFragmentDoc = gql`
  fragment BargeFragment on Barge {
    barge_id
    alias
    default_grain_type
    length_ft
    width_ft
    height_ft
    archived
    updated_at
  }
`;
export const GrainContainerIdFragmentFragmentDoc = gql`
  fragment GrainContainerIdFragment on GrainContainer {
    container_id
    container_type
    alias
    default_grain_type
  }
`;
export const HubContainerLinkBaseFragmentFragmentDoc = gql`
  fragment HubContainerLinkBaseFragment on HubContainerLink {
    hub_container_link_id
    hub_id
    alias
    core_id
    container_id
    container_type
    start_epoch
    end_epoch
    nickname
    hub_type
    has_level_sensor
    hub_offset_ft
    hub {
      hub_id
      alias
    }
  }
`;
export const GrainContainerHubLinksFragmentFragmentDoc = gql`
  fragment GrainContainerHubLinksFragment on GrainContainer {
    ...GrainContainerIdFragment
    active_hub_links {
      ...HubContainerLinkBaseFragment
    }
  }
  ${GrainContainerIdFragmentFragmentDoc}
  ${HubContainerLinkBaseFragmentFragmentDoc}
`;
export const BargeAccountLinkInternalFragmentFragmentDoc = gql`
  fragment BargeAccountLinkInternalFragment on BargeAccountLink {
    ...BargeAccountLinkIdFragment
    barge {
      ...BargeFragment
    }
    container {
      ...GrainContainerHubLinksFragment
    }
  }
  ${BargeAccountLinkIdFragmentFragmentDoc}
  ${BargeFragmentFragmentDoc}
  ${GrainContainerHubLinksFragmentFragmentDoc}
`;
export const BargeCoverPelletLinkFragmentFragmentDoc = gql`
  fragment BargeCoverPelletLinkFragment on BargeCoverPelletLink {
    barge_cover_pellet_link_id
    barge_id
    cover_no
    pellet_id
    start_epoch
  }
`;
export const BinEventFragmentFragmentDoc = gql`
  fragment BinEventFragment on BinEvent {
    epoch_time
    event_type
    event_value
  }
`;
export const BinGrainModelPrerequisitesInfoFragmentFragmentDoc = gql`
  fragment BinGrainModelPrerequisitesInfoFragment on BinGrainModelPrerequisitesInfo {
    grain_bin_id
    has_active_fan_controller
    has_active_hub
    has_active_ongoing_storage_period
    has_active_storage_period_in_year
    has_active_grain_tickets
    has_acceptable_grain_type
    has_valid_fan_airflow
    is_meeting_all_prerequisites
  }
`;
export const ExperimentBoundValuesFragmentFragmentDoc = gql`
  fragment ExperimentBoundValuesFragment on ExperimentBoundValues {
    lower_bound
    upper_bound
  }
`;
export const ExperimentCfmValuesFragmentFragmentDoc = gql`
  fragment ExperimentCfmValuesFragment on ExperimentCfmValues {
    cfm_scaling
    cfm_offset
    cfm_min
    cfm_max
    cfm
  }
`;
export const PsychrometricRangeFragmentFragmentDoc = gql`
  fragment PsychrometricRangeFragment on PsychrometricRange {
    start
    end
    temp_diff
    emc_diff
  }
`;
export const ExperimentPsychrometricRangeFragmentFragmentDoc = gql`
  fragment ExperimentPsychrometricRangeFragment on ExperimentPsychrometricRange {
    name
    experiment
    type
    emc_ranges {
      ...PsychrometricRangeFragment
    }
    temp_ranges {
      ...PsychrometricRangeFragment
    }
    emc_reconditioning_ranges {
      ...PsychrometricRangeFragment
    }
  }
  ${PsychrometricRangeFragmentFragmentDoc}
`;
export const FanControllerStateFragmentFragmentDoc = gql`
  fragment FanControllerStateFragment on FanControllerState {
    value_next
    is_override
    config {
      enabled
      run_window {
        start_epoch
        end_epoch
      }
      safety_interval
      vibration_threshold
      config_epoch
    }
    updated_at
    fan_controller_id
    ping_attempts
    config_attempts
    is_on
    error_code
  }
`;
export const ParticleDeviceFragmentFragmentDoc = gql`
  fragment ParticleDeviceFragment on ParticleDevice {
    id
    name
    serial_number
    last_ip_address
    last_heard
    product_id
    connected
    platform_id
    cellular
    notes
    iccid
    imei
  }
`;
export const BaseAccountFragmentFragmentDoc = gql`
  fragment BaseAccountFragment on Account {
    account_id
    organization
    grain_bin_support
    barge_support
    pile_support
  }
`;
export const FanControllerDiagnosticsFragmentFragmentDoc = gql`
  fragment FanControllerDiagnosticsFragment on FanController {
    fan_controller_id_next
    core_id
    hardware_rev
    diag_test_is_enable
    state_next {
      ...FanControllerStateFragment
    }
    particle {
      ...ParticleDeviceFragment
    }
    grain_bin_link {
      grain_bin_fan_controller_id
      grain_bin {
        grain_bin_id
        alias
        account_link {
          grain_bin_id
          account_id
          account {
            ...BaseAccountFragment
          }
        }
      }
    }
  }
  ${FanControllerStateFragmentFragmentDoc}
  ${ParticleDeviceFragmentFragmentDoc}
  ${BaseAccountFragmentFragmentDoc}
`;
export const FanControllerPageFragmentFragmentDoc = gql`
  fragment FanControllerPageFragment on FanController {
    fan_controller_id_next
    core_id
    hardware_rev
    alias
    grain_bin_link {
      grain_bin_fan_controller_id
      grain_bin {
        grain_bin_id
        alias
        account_link {
          grain_bin_id
          account_id
          account {
            ...BaseAccountFragment
          }
        }
      }
    }
  }
  ${BaseAccountFragmentFragmentDoc}
`;
export const FanControllerRequestFragmentFragmentDoc = gql`
  fragment FanControllerRequestFragment on FanControllerRequest {
    ... on FanControllerPingRequest {
      fan_controller_id
      request_epoch
      updated_at
      sns_message_id
      delivered
    }
    ... on FanControllerResetRequest {
      fan_controller_id
      request_epoch
      updated_at
      sns_message_id
      delivered
    }
    ... on FanControllerSetConfigRequest {
      fan_controller_id
      request_epoch
      updated_at
      sns_message_id
      delivered
      enabled
      safety_interval
      first_request_epoch
      vibration_threshold
      error_code
      run_window {
        start_epoch
        end_epoch
      }
    }
  }
`;
export const FanControllerResponseFragmentFragmentDoc = gql`
  fragment FanControllerResponseFragment on FanControllerResponse {
    ... on FanControllerResetResponse {
      fan_controller_id
      response_epoch
      event_id
      sequence_number
      reset_reason: reason
    }
    ... on FanControllerGetConfigResponse {
      fan_controller_id
      response_epoch
      event_id
      sequence_number
      get_config_reason: reason
    }
    ... on FanControllerHeartbeatResponse {
      fan_controller_id
      response_epoch
      event_id
      sequence_number
      heartbeat_reason: reason
      enabled
      is_on
      is_override
      current_a
      safety_interval
      vibration_magnitude
      rssi
      request_epoch
      vibration_threshold
      error_code
      run_window {
        start_epoch
        end_epoch
      }
      signal_quality
    }
    ... on FanControllerDebugResponse {
      fan_controller_id
      response_epoch
      event_id
      sequence_number
      payload
    }
  }
`;
export const FanRunFragmentFragmentDoc = gql`
  fragment FanRunFragment on FanRun {
    fan_controller_id
    start_epoch
    end_epoch
    current_avg_a
    reason_start
    reason_end
  }
`;
export const GrainBinFanControllerIdFragmentFragmentDoc = gql`
  fragment GrainBinFanControllerIdFragment on GrainBinFanController {
    grain_bin_fan_controller_id
    fan_controller_id_next
    grain_bin_id
    alias
    horsepower
    fan_type
    has_plenum_sensor
    phase
    power_factor
    voltage
    fan_model_id
    no_of_fans_connected
  }
`;
export const FanControllerFragmentFragmentDoc = gql`
  fragment FanControllerFragment on FanController {
    fan_controller_id_next
    core_id
    environment
    alias
    diag_test_is_enable
    state_next {
      ...FanControllerStateFragment
    }
  }
  ${FanControllerStateFragmentFragmentDoc}
`;
export const GrainBinFanControllerFragmentFragmentDoc = gql`
  fragment GrainBinFanControllerFragment on GrainBinFanController {
    ...GrainBinFanControllerIdFragment
    start_date
    end_date
    fan_controller {
      ...FanControllerFragment
    }
  }
  ${GrainBinFanControllerIdFragmentFragmentDoc}
  ${FanControllerFragmentFragmentDoc}
`;
export const FanSettingsFragmentFragmentDoc = gql`
  fragment FanSettingsFragment on GrainBinFanSettings {
    grain_bin_id
    safety_weather
    run_window {
      start_epoch
      end_epoch
    }
    updated_at
    grain_bin {
      grain_bin_id
      fan_controllers {
        ...GrainBinFanControllerFragment
      }
    }
  }
  ${GrainBinFanControllerFragmentFragmentDoc}
`;
export const ComputedForecastHourFragmentFragmentDoc = gql`
  fragment ComputedForecastHourFragment on GrainStatus {
    epoch_time
    humidity_rh
    temp_f
    emc
    humidity_state
    aeration_state
  }
`;
export const AerationWindowFragmentFragmentDoc = gql`
  fragment AerationWindowFragment on AerationWindow {
    start
    end
  }
`;
export const HourlyForecastStatusFragmentFragmentDoc = gql`
  fragment HourlyForecastStatusFragment on HourlyForecastStatus {
    hours {
      ...ComputedForecastHourFragment
    }
    aeration_windows {
      ...AerationWindowFragment
    }
  }
  ${ComputedForecastHourFragmentFragmentDoc}
  ${AerationWindowFragmentFragmentDoc}
`;
export const DayNightStatsFragmentFragmentDoc = gql`
  fragment DayNightStatsFragment on DayNightRange {
    day {
      max
      min
      median
    }
    night {
      max
      min
      median
    }
  }
`;
export const ForecastDayFragmentFragmentDoc = gql`
  fragment ForecastDayFragment on ForecastDay {
    epoch_time
    daytime_range
    nighttime_range
    day_icon
    night_icon
    temp {
      ...DayNightStatsFragment
    }
    rh {
      ...DayNightStatsFragment
    }
    emc {
      ...DayNightStatsFragment
    }
  }
  ${DayNightStatsFragmentFragmentDoc}
`;
export const ForecastDataFragmentFragmentDoc = gql`
  fragment ForecastDataFragment on ForecastData {
    computed_forecast {
      ...HourlyForecastStatusFragment
    }
    daily_forecast {
      ...ForecastDayFragment
    }
    emc_reconditioning_ranges {
      ...PsychrometricRangeFragment
    }
    emc_ranges {
      ...PsychrometricRangeFragment
    }
    temp_ranges {
      ...PsychrometricRangeFragment
    }
  }
  ${HourlyForecastStatusFragmentFragmentDoc}
  ${ForecastDayFragmentFragmentDoc}
  ${PsychrometricRangeFragmentFragmentDoc}
`;
export const ComputedForecastHourV2FragmentFragmentDoc = gql`
  fragment ComputedForecastHourV2Fragment on GrainStatusV2 {
    epoch_time
    humidity_rh
    temp_f
    emc
    precip_intensity_inph
    humidity_state
    aeration_state
    solarradiation_watt_per_square_metre
    sunelevation
    ghiradiation
  }
`;
export const HourlyForecastStatusV2FragmentFragmentDoc = gql`
  fragment HourlyForecastStatusV2Fragment on HourlyForecastStatusV2 {
    hours {
      ...ComputedForecastHourV2Fragment
    }
    aeration_windows {
      ...AerationWindowFragment
    }
  }
  ${ComputedForecastHourV2FragmentFragmentDoc}
  ${AerationWindowFragmentFragmentDoc}
`;
export const ForecastDayV2FragmentFragmentDoc = gql`
  fragment ForecastDayV2Fragment on ForecastDayV2 {
    epoch_time
    daytime_range
    nighttime_range
    icon
    precip_intensity_inph
    solarradiation_watt_per_square_metre
    sunelevation
    ghiradiation
    temp_low_f
    temp_high_f
    temp {
      ...DayNightStatsFragment
    }
    rh {
      ...DayNightStatsFragment
    }
    emc {
      ...DayNightStatsFragment
    }
  }
  ${DayNightStatsFragmentFragmentDoc}
`;
export const ForecastDataV2FragmentFragmentDoc = gql`
  fragment ForecastDataV2Fragment on ForecastDataV2 {
    computed_forecast {
      ...HourlyForecastStatusV2Fragment
    }
    daily_forecast {
      ...ForecastDayV2Fragment
    }
    emc_reconditioning_ranges {
      ...PsychrometricRangeFragment
    }
    emc_ranges {
      ...PsychrometricRangeFragment
    }
    temp_ranges {
      ...PsychrometricRangeFragment
    }
  }
  ${HourlyForecastStatusV2FragmentFragmentDoc}
  ${ForecastDayV2FragmentFragmentDoc}
  ${PsychrometricRangeFragmentFragmentDoc}
`;
export const FanModelResultValFragmentFragmentDoc = gql`
  fragment FanModelResultValFragment on FanModelResultVal {
    fan_model_id
    fan_model_name
    fan_brand_name
    fan_horsepower
    fan_model_type
    make
    diameter
    fan_model_created_type
    is_generic
    linked_generic_fan_model_id
    __typename
  }
`;
export const FanModelResultValuesFragmentFragmentDoc = gql`
  fragment FanModelResultValuesFragment on FanModelResultValues {
    label {
      ...FanModelResultValFragment
    }
    key
  }
  ${FanModelResultValFragmentFragmentDoc}
`;
export const GetFanModelResultFragmentFragmentDoc = gql`
  fragment GetFanModelResultFragment on FanModelResult {
    label
    key
    values {
      ...FanModelResultValuesFragment
    }
  }
  ${FanModelResultValuesFragmentFragmentDoc}
`;
export const GetGrainFanPlotsOutputFragmentFragmentDoc = gql`
  fragment GetGrainFanPlotsOutputFragment on GetGrainFanPlotsOutput {
    fan_model {
      fan_model_id
      fan_model_name
      fan_model_type
      fan_brand_name
      fan_horsepower
      diameter
      is_generic
      created_at
      updated_at
    }
    number_of_plots
  }
`;
export const GrainBinAccountLinkIdFragmentFragmentDoc = gql`
  fragment GrainBinAccountLinkIdFragment on GrainBinAccountLink {
    grain_bin_id
  }
`;
export const GrainBinFragmentFragmentDoc = gql`
  fragment GrainBinFragment on GrainBin {
    grain_bin_id
    account_id
    bin_name
    grain_type
    has_grain_spreader
    height_ft
    diameter_ft
    zip_code
    pellet_order
    current_grain_emc
    current_grain_temp
    target_grain_emc
    price_per_kwh
    recommendation_type
    tz
    opt_in_fan_guidance
    enable_fan_guidance
    fan_guidance_start_date
    fan_guidance_end_date
    floor_type
    archived
    fan_guidance_extension_date
    sent_fan_guidance_ext_notif
    updated_at
    location {
      latitude
      longitude
    }
    fan_controllers {
      grain_bin_fan_controller_id
      fan_controller {
        fan_controller_id_next
        alias
        state_next {
          fan_controller_id
          value_next
          is_on
          error_code
        }
      }
    }
    container {
      aeration_schedule {
        start_epoch
        end_epoch
      }
      aeration_schedule_type
    }
    grain_bin_storage_periods {
      grain_bin_storage_cycle_id
      grain_bin_id
      storage_cycle_name
      is_ongoing_storage_period
      grain_type
      start_epoch
      end_epoch
      created_at
    }
  }
`;
export const GrainBinAccountLinkInternalFragmentFragmentDoc = gql`
  fragment GrainBinAccountLinkInternalFragment on GrainBinAccountLink {
    ...GrainBinAccountLinkIdFragment
    grain_bin {
      ...GrainBinFragment
      fan_controllers {
        ...GrainBinFanControllerIdFragment
      }
    }
    container {
      ...GrainContainerHubLinksFragment
    }
  }
  ${GrainBinAccountLinkIdFragmentFragmentDoc}
  ${GrainBinFragmentFragmentDoc}
  ${GrainBinFanControllerIdFragmentFragmentDoc}
  ${GrainContainerHubLinksFragmentFragmentDoc}
`;
export const StaticPressureCfmSolutionFragmentFragmentDoc = gql`
  fragment StaticPressureCfmSolutionFragment on StaticPressureCfmSolution {
    cfm
    static_pressure
    found_solution
    cfm_per_bushel
    total_bushels
    grain_depth_ft
  }
`;
export const GrainBinCfmSolutionOutputFragmentFragmentDoc = gql`
  fragment GrainBinCfmSolutionOutputFragment on GrainBinCfmSolutionOutput {
    grain_bin_id
    created_at
    updated_at
    fan_model {
      fan_model_name
    }
    grain_type
    grain_bin_solution {
      ...StaticPressureCfmSolutionFragment
    }
    grain_bin_height_solution {
      ...StaticPressureCfmSolutionFragment
    }
  }
  ${StaticPressureCfmSolutionFragmentFragmentDoc}
`;
export const GrainBinDTOFragmentFragmentDoc = gql`
  fragment GrainBinDTOFragment on GrainBinDTO {
    grain_bin_id
    bin_name
    grain_type
    has_grain_spreader
    height_ft
    diameter_ft
    zip_code
    current_grain_emc
    current_grain_temp
    target_grain_emc
    price_per_kwh
    recommendation_type
    tz
    opt_in_fan_guidance
    enable_fan_guidance
    fan_guidance_start_date
    fan_guidance_end_date
    floor_type
    archived
    fan_guidance_extension_date
    sent_fan_guidance_ext_notif
    updated_at
    location {
      latitude
      longitude
    }
    site {
      site_id
      site_name
      account_id
      created_at
      updated_at
    }
  }
`;
export const GrainBinEstimateFragmentFragmentDoc = gql`
  fragment GrainBinEstimateFragment on GrainBinEstimate {
    grain_bin_id
    moisture_colorbar_svg_url
    temperature_f_colorbar_svg_url
    temperature_f_colorbar_small_svg_url
    temperature_c_colorbar_svg_url
    temperature_c_colorbar_small_svg_url
    moisture_colorbar_small_svg_url
    internal_moisture_json
    internal_temperature_c_json
    internal_temperature_f_json
    internal_slider_c_json
    internal_slider_f_json
    is_bin_forecast_unavailable
    temperature_chart_data_url
    moisture_chart_data_url
    target_temperature_f
    target_moisture_percent
    bin_grain_type
    bin_diameter_ft
    bin_height_ft
    grain_bin_level_percent
    grain_bin_level_bu
    cfm_per_bushel
    total_aeration_runs
    total_hrs_runtime
    current_weather {
      temp_f
      humidity_rh
      emc
      icon
    }
    last_grain_ticket_date
    grain_surface_shape
    number_of_fans_connected_to_bin
    fan_model {
      fan_model_id
      fan_model_name
      make
      fan_brand_name
      fan_model_type
      fan_horsepower
      diameter
      fan_model_created_type
    }
    storage_period {
      grain_bin_storage_cycle_id
      grain_bin_id
      storage_cycle_name
      is_ongoing_storage_period
      grain_type
      start_epoch
      end_epoch
      created_at
    }
    result_updated_at
  }
`;
export const GrainBinFanModelOutputFragmentFragmentDoc = gql`
  fragment GrainBinFanModelOutputFragment on GrainBinFanModelOutput {
    fan_model_id
    fan_model_name
    make
    fan_brand_name
    fan_model_type
    fan_horsepower
    diameter
    fan_model_created_type
    created_at
    updated_at
    __typename
  }
`;
export const GrainBinNotificationFragmentFragmentDoc = gql`
  fragment GrainBinNotificationFragment on GrainBinNotification {
    grain_bin_id
    notification_id
    source_type
    epoch_time
    alert_content
    has_delivered_to_user
  }
`;
export const GrainBinSiteFragmentFragmentDoc = gql`
  fragment GrainBinSiteFragment on GrainBinSite {
    site_id
    site_name
    account_id
    created_at
    updated_at
  }
`;
export const GrainBinStoragePeriodValidationResultFragmentFragmentDoc = gql`
  fragment GrainBinStoragePeriodValidationResultFragment on GrainBinStoragePeriodValidationResult {
    error_message
    failed_validation
  }
`;
export const GrainBinTicketFragmentFragmentDoc = gql`
  fragment GrainBinTicketFragment on GrainBinTicket {
    grain_bin_ticket_id
    grain_bin_id
    epoch_time
    volume
    weight_in_lbs
    test_weight_in_lbs
    action_performed
    grain_moisture_pct
    notes
    grain_surface_shape
    new_grain_bin_bushels
    created_at
    updated_at
  }
`;
export const GrainBinTicketRowFragmentFragmentDoc = gql`
  fragment GrainBinTicketRowFragment on GrainBinTicketRow {
    grain_bin_ticket_id
    grain_bin_id
    epoch_time
    volume
    weight_in_lbs
    test_weight_in_lbs
    action_performed
    grain_moisture_pct
    grain_surface_shape
    notes
    created_at
    updated_at
  }
`;
export const GrainBinWeeklyEmailPrefFragmentFragmentDoc = gql`
  fragment GrainBinWeeklyEmailPrefFragment on GrainBinWeeklyEmailPref {
    user_id
    account_id
    grain_bin_id
    opt_in
  }
`;
export const GrainContainerHubLinksWithFCPlenumHubsFragmentFragmentDoc = gql`
  fragment GrainContainerHubLinksWithFCPlenumHubsFragment on GrainContainer {
    ...GrainContainerIdFragment
    active_hub_links_including_fc_plenum_hubs {
      ...HubContainerLinkBaseFragment
    }
  }
  ${GrainContainerIdFragmentFragmentDoc}
  ${HubContainerLinkBaseFragmentFragmentDoc}
`;
export const GrainBinGrainContainerFragmentFragmentDoc = gql`
  fragment GrainBinGrainContainerFragment on GrainContainer {
    ...GrainContainerIdFragment
    aeration_schedule {
      start_epoch
      end_epoch
    }
    aeration_schedule_type
  }
  ${GrainContainerIdFragmentFragmentDoc}
`;
export const HistoryPointFragmentDoc = gql`
  fragment HistoryPoint on GrainEnvPoint {
    epoch_time
    emc
    humidity_rh
    temp_f
    co2_ppm
  }
`;
export const GrainContainerFanPointFragmentFragmentDoc = gql`
  fragment GrainContainerFanPointFragment on GrainContainerFanPoint {
    epoch_time
    fan_count
  }
`;
export const GrainContainerHistoryFragmentFragmentDoc = gql`
  fragment GrainContainerHistoryFragment on GrainContainer {
    ...GrainContainerIdFragment
    telemetry_history(period: $period) {
      container_id
      container_type
      period
      weather {
        ...HistoryPoint
      }
      interior {
        ...HistoryPoint
      }
      headspace {
        ...HistoryPoint
      }
      tiers {
        ...HistoryPoint
      }
      pellets {
        pellet_id
        data {
          ...HistoryPoint
        }
      }
      fan {
        ...GrainContainerFanPointFragment
      }
    }
  }
  ${GrainContainerIdFragmentFragmentDoc}
  ${HistoryPointFragmentDoc}
  ${GrainContainerFanPointFragmentFragmentDoc}
`;
export const GrainContainerHistoryFanFragmentFragmentDoc = gql`
  fragment GrainContainerHistoryFanFragment on GrainContainer {
    ...GrainContainerIdFragment
    telemetry_history(period: $period) {
      container_id
      container_type
      period
      fan {
        ...GrainContainerFanPointFragment
      }
    }
  }
  ${GrainContainerIdFragmentFragmentDoc}
  ${GrainContainerFanPointFragmentFragmentDoc}
`;
export const GrainContainerFanRunFragmentFragmentDoc = gql`
  fragment GrainContainerFanRunFragment on GrainContainerFanRun {
    grain_bin_fan_controller_id
    grain_bin_fan_controller {
      grain_bin_fan_controller_id
      alias
    }
    start_epoch
    end_epoch
    current_avg_a
    start_recommendation_type
    end_recommendation_type
    fan_start_reason
    fan_end_reason
    enabled_fan_guidance
  }
`;
export const GrainContainerAerationRunFragmentFragmentDoc = gql`
  fragment GrainContainerAerationRunFragment on GrainContainerAerationRun {
    container_id
    container_type
    start_epoch
    start_humidity_rh
    start_temp_f
    end_epoch
    end_humidity_rh
    end_temp_f
    avg_humidity_rh
    avg_temp_f
    avg_emc
    grain_type
    current_grain_temp
    current_grain_emc
    target_grain_emc
    enabled_fan_guidance
    fan_guidance_start_date
    fan_guidance_end_date
    fan_runs {
      ...GrainContainerFanRunFragment
    }
  }
  ${GrainContainerFanRunFragmentFragmentDoc}
`;
export const GrainContainerHistoryAerationRunsFragmentFragmentDoc = gql`
  fragment GrainContainerHistoryAerationRunsFragment on GrainContainer {
    ...GrainContainerIdFragment
    aeration_runs {
      ...GrainContainerAerationRunFragment
    }
  }
  ${GrainContainerIdFragmentFragmentDoc}
  ${GrainContainerAerationRunFragmentFragmentDoc}
`;
export const GrainContainerHistoryAerationRunsWithinPeriodFragmentFragmentDoc = gql`
  fragment GrainContainerHistoryAerationRunsWithinPeriodFragment on GrainContainer {
    ...GrainContainerIdFragment
    aeration_runs_within_period(period: $period) {
      period
      aeration_runs {
        ...GrainContainerAerationRunFragment
      }
    }
  }
  ${GrainContainerIdFragmentFragmentDoc}
  ${GrainContainerAerationRunFragmentFragmentDoc}
`;
export const FanPowerStatsFragmentFragmentDoc = gql`
  fragment FanPowerStatsFragment on FanPowerStats {
    fan_controller_id_next
    alias
    total_run_hours
    total_kwh
    start_epoch
  }
`;
export const GrainFanPowerUsageFragmentFragmentDoc = gql`
  fragment GrainFanPowerUsageFragment on GrainFanPowerUsage {
    fan_controllers_with_missing_info {
      ...GrainBinFanControllerFragment
    }
    fan_power_stats {
      ...FanPowerStatsFragment
    }
    total_kwh
  }
  ${GrainBinFanControllerFragmentFragmentDoc}
  ${FanPowerStatsFragmentFragmentDoc}
`;
export const GrainContainerHistoryAerationRunsWithLimitFragmentFragmentDoc = gql`
  fragment GrainContainerHistoryAerationRunsWithLimitFragment on GrainContainer {
    ...GrainContainerIdFragment
    aeration_run_history(
      offset: $offset
      limit: $limit
      current_grain_bin_storage_cycle_id: $current_grain_bin_storage_cycle_id
    ) {
      aeration_runs {
        ...GrainContainerAerationRunFragment
      }
      has_more
      current_offset
      total_runs
      total_hrs_runtime
      fan_power_usage {
        ...GrainFanPowerUsageFragment
      }
    }
  }
  ${GrainContainerIdFragmentFragmentDoc}
  ${GrainContainerAerationRunFragmentFragmentDoc}
  ${GrainFanPowerUsageFragmentFragmentDoc}
`;
export const GrainContainerHistoryNextAerationRunsWithLimitFragmentFragmentDoc = gql`
  fragment GrainContainerHistoryNextAerationRunsWithLimitFragment on GrainContainer {
    ...GrainContainerIdFragment
    aeration_run_history(
      offset: $offset
      limit: $limit
      current_grain_bin_storage_cycle_id: $current_grain_bin_storage_cycle_id
    ) {
      aeration_runs {
        ...GrainContainerAerationRunFragment
      }
      has_more
      current_offset
    }
  }
  ${GrainContainerIdFragmentFragmentDoc}
  ${GrainContainerAerationRunFragmentFragmentDoc}
`;
export const HistoryPointV2FragmentDoc = gql`
  fragment HistoryPointV2 on GrainEnvPointV2 {
    epoch_time
    emc
    humidity_rh
    temp_f
    co2_ppm
  }
`;
export const GrainContainerHistoryHeadspaceFragmentFragmentDoc = gql`
  fragment GrainContainerHistoryHeadspaceFragment on GrainContainer {
    ...GrainContainerIdFragment
    telemetry_history_v2(period: $period) {
      container_id
      container_type
      period
      headspace_history {
        active_hubs_grain_telemetry_history {
          hub_id
          headspace_telemetry_history {
            ...HistoryPointV2
          }
        }
      }
    }
  }
  ${GrainContainerIdFragmentFragmentDoc}
  ${HistoryPointV2FragmentDoc}
`;
export const WeatherHistoryPointFragmentFragmentDoc = gql`
  fragment WeatherHistoryPointFragment on WeatherHistoryPoint {
    epoch_time
    emc
    humidity_rh
    temp_f
  }
`;
export const GrainContainerHistoryHeadspaceWeatherFragmentFragmentDoc = gql`
  fragment GrainContainerHistoryHeadspaceWeatherFragment on GrainContainer {
    ...GrainContainerIdFragment
    telemetry_history_v2(period: $period) {
      container_id
      container_type
      period
      weather_history {
        ...WeatherHistoryPointFragment
      }
    }
  }
  ${GrainContainerIdFragmentFragmentDoc}
  ${WeatherHistoryPointFragmentFragmentDoc}
`;
export const GrainContainerHistoryGrainLevelFragmentFragmentDoc = gql`
  fragment GrainContainerHistoryGrainLevelFragment on GrainContainer {
    ...GrainContainerIdFragment
    grain_bin_level_history(as_of: $as_of, period: $period) {
      period
      max_bushels
      active_hubs_level_samples {
        hub_id
        level_samples {
          bushel
          epoch_time
        }
      }
    }
  }
  ${GrainContainerIdFragmentFragmentDoc}
`;
export const GrainContainerHistoryGrainTicketsLevelFragmentFragmentDoc = gql`
  fragment GrainContainerHistoryGrainTicketsLevelFragment on GrainContainer {
    grain_bin_tickets_level_history(
      grain_bin_storage_cycle_id: $grain_bin_storage_cycle_id
      as_of: $as_of
      period: $period
    ) {
      period
      no_grain_tickets_created
      grain_tickets_bushel_level_on_period_start {
        bushel
        epoch_time
      }
      grain_tickets_bushel_level_on_period_end {
        bushel
        epoch_time
      }
      grain_tickets_level_samples {
        bushel
        epoch_time
      }
    }
  }
`;
export const GrainContainerHistoryInteriorFragmentFragmentDoc = gql`
  fragment GrainContainerHistoryInteriorFragment on GrainContainer {
    ...GrainContainerIdFragment
    telemetry_history(period: $period) {
      container_id
      container_type
      period
      interior {
        ...HistoryPoint
      }
    }
  }
  ${GrainContainerIdFragmentFragmentDoc}
  ${HistoryPointFragmentDoc}
`;
export const GrainContainerHistoryPelletFragmentFragmentDoc = gql`
  fragment GrainContainerHistoryPelletFragment on GrainContainer {
    ...GrainContainerIdFragment
    telemetry_history(period: $period) {
      container_id
      container_type
      period
      pellet(pellet_id: $pellet_id) {
        ...HistoryPoint
      }
    }
  }
  ${GrainContainerIdFragmentFragmentDoc}
  ${HistoryPointFragmentDoc}
`;
export const GrainContainerHistoryWeatherFragmentFragmentDoc = gql`
  fragment GrainContainerHistoryWeatherFragment on GrainContainer {
    ...GrainContainerIdFragment
    telemetry_history(period: $period) {
      container_id
      container_type
      period
      weather {
        ...HistoryPoint
      }
    }
  }
  ${GrainContainerIdFragmentFragmentDoc}
  ${HistoryPointFragmentDoc}
`;
export const GrainContainerHistoryBinSummaryFragmentFragmentDoc = gql`
  fragment GrainContainerHistoryBinSummaryFragment on GrainContainer {
    ...GrainContainerIdFragment
    telemetry_history(period: $period) {
      container_id
      container_type
      period
      interior {
        ...HistoryPoint
      }
      headspace {
        ...HistoryPoint
      }
      tiers {
        ...HistoryPoint
      }
    }
  }
  ${GrainContainerIdFragmentFragmentDoc}
  ${HistoryPointFragmentDoc}
`;
export const GrainContainerHistoryPelletsFragmentFragmentDoc = gql`
  fragment GrainContainerHistoryPelletsFragment on GrainContainer {
    ...GrainContainerIdFragment
    telemetry_history(period: $period) {
      container_id
      container_type
      period
      interior {
        ...HistoryPoint
      }
      pellets {
        pellet_id
        data {
          ...HistoryPoint
        }
      }
    }
  }
  ${GrainContainerIdFragmentFragmentDoc}
  ${HistoryPointFragmentDoc}
`;
export const GrainFanPlotsOutputFragmentFragmentDoc = gql`
  fragment GrainFanPlotsOutputFragment on GrainFanPlotsOutput {
    fan_model {
      fan_model_id
      fan_model_name
      fan_model_type
      fan_brand_name
      fan_horsepower
      created_at
      updated_at
    }
    fan_plots {
      fan_plot_id
      x1
      x2
      y1
      y2
      acceptable_range
      iteration
      created_at
      updated_at
    }
  }
`;
export const GrainStatusFragmentFragmentDoc = gql`
  fragment GrainStatusFragment on GrainStatus {
    emc
    emc_ranges {
      state
      low
      high
    }
    emc_state
    epoch_time
    humidity_state
    humidity_rh
    humidity_rh_ranges {
      state
      low
      high
    }
    temp_diff_state
    temp_f
    temp_f_ranges {
      state
      low
      high
    }
    temp_state
    temp_min_f
    temp_max_f
    temp_max_diff_f
  }
`;
export const WeatherForecastFragmentFragmentDoc = gql`
  fragment WeatherForecastFragment on WeatherForecast {
    daily_forecast {
      ...ForecastDayV2Fragment
    }
    hourly_forecast {
      ...ComputedForecastHourV2Fragment
    }
    aeration_windows {
      ...AerationWindowFragment
    }
  }
  ${ForecastDayV2FragmentFragmentDoc}
  ${ComputedForecastHourV2FragmentFragmentDoc}
  ${AerationWindowFragmentFragmentDoc}
`;
export const BargeTelemetryFragmentFragmentDoc = gql`
  fragment BargeTelemetryFragment on GrainTelemetry {
    headspace {
      ...GrainStatusFragment
      __typename
    }
    pellets {
      pellet_id
      is_emc_stable
      dod_temp_diff_f
      latest {
        ...GrainStatusFragment
      }
    }
    location_next {
      epoch_time
      latitude
      longitude
    }
    location_with_timezone {
      epoch_time
      latitude
      longitude
      timezone
      __typename
    }
    weather {
      ...GrainStatusFragment
    }
    weather_forecast {
      ...WeatherForecastFragment
    }
  }
  ${GrainStatusFragmentFragmentDoc}
  ${WeatherForecastFragmentFragmentDoc}
`;
export const RecommendationWindowsFragmentFragmentDoc = gql`
  fragment RecommendationWindowsFragment on RecommendationWindows {
    emc_ranges {
      ...PsychrometricRangeFragment
    }
    temp_ranges {
      ...PsychrometricRangeFragment
    }
    emc_reconditioning_ranges {
      ...PsychrometricRangeFragment
    }
  }
  ${PsychrometricRangeFragmentFragmentDoc}
`;
export const GrainBinTelemetryFragmentFragmentDoc = gql`
  fragment GrainBinTelemetryFragment on GrainTelemetry {
    interior {
      ...GrainStatusFragment
    }
    headspace {
      ...GrainStatusFragment
    }
    weather_forecast {
      ...WeatherForecastFragment
    }
    recommendation_windows {
      ...RecommendationWindowsFragment
    }
    temporary_recommendation_windows {
      recommendation_windows {
        ...RecommendationWindowsFragment
      }
      recommendation_type
    }
    location_next {
      epoch_time
      latitude
      longitude
    }
    tiers {
      ...GrainStatusFragment
    }
    weather {
      ...GrainStatusFragment
    }
  }
  ${GrainStatusFragmentFragmentDoc}
  ${WeatherForecastFragmentFragmentDoc}
  ${RecommendationWindowsFragmentFragmentDoc}
`;
export const GrainTemporaryInputConditionsFragmentFragmentDoc = gql`
  fragment GrainTemporaryInputConditionsFragment on GrainTemporaryInputConditions {
    grain_bin_id
    current_grain_temp
    current_grain_emc
    target_grain_emc
    recommendation_type
  }
`;
export const GrainWeatherInfoFragmentFragmentDoc = gql`
  fragment GrainWeatherInfoFragment on WeatherInfo {
    icon
    temp {
      temp_high_f
      temp_low_f
    }
    humidity {
      humidity_high_rh
      humidity_low_rh
    }
    emc {
      emc_high
      emc_low
    }
  }
`;
export const HubContainerLinkFragmentFragmentDoc = gql`
  fragment HubContainerLinkFragment on HubContainerLink {
    ...HubContainerLinkBaseFragment
    container {
      container_id
      container_type
      alias
    }
  }
  ${HubContainerLinkBaseFragmentFragmentDoc}
`;
export const HubDiagnosticsFragmentFragmentDoc = gql`
  fragment HubDiagnosticsFragment on Hub {
    hub_id
    core_id
    alias
    is_active
  }
`;
export const HubFragmentFragmentDoc = gql`
  fragment HubFragment on Hub {
    hub_id
    core_id
    is_active
    environment
    hardware_rev
    firmware
    notes
    ref_pellet_id
    hub_pressure_offset_pa
    created_at
    updated_at
    deleted_at
    particle {
      ...ParticleDeviceFragment
    }
  }
  ${ParticleDeviceFragmentFragmentDoc}
`;
export const HubStatusEventFragmentFragmentDoc = gql`
  fragment HubStatusEventFragment on HubStatusEvent {
    event_id
    hub_id
    epoch_time
    temp_0_f
    temp_1_f
    temp_2_f
    temp_f
    temp_min_f
    temp_max_f
    hum_0_rh
    hum_1_rh
    hum_2_rh
    hum_min_rh
    hum_max_rh
    humidity_rh
    pressure_0_pa
    pressure_1_pa
    pressure_2_pa
    pressure_2_abs_pa
    pressure_min_pa
    pressure_max_pa
    batt_voltage_uv
    panel_voltage_uv
    packets_in_queue
    reset_count
    updated_at
    batt_status
  }
`;
export const HubPelletEventFragmentFragmentDoc = gql`
  fragment HubPelletEventFragment on HubPelletEvent {
    event_id
    hub_id
    pellet_id
    epoch_time
    sequence_number
    sender_id
    time_offset_ms
    temp_0_f
    temp_1_f
    temp_2_f
    temp_f
    temp_min_f
    temp_max_f
    hum_0_rh
    hum_1_rh
    hum_2_rh
    hum_min_rh
    hum_max_rh
    humidity_rh
    pressure_2_pa
    batt_voltage_uv
    packets_in_queue
    reset_count
    rssi
    hop_count
    avg_current_ua
    co2_ppm
    drift_ppm
    epoch_time_raw
    neighbor_count
    network_etx
    network_packets_sent
    network_packets_ack
    packets_received
    parent_address
    pref_parent_rssi
    source_address
    voc_ppb
  }
`;
export const HubGpsEventFragmentFragmentDoc = gql`
  fragment HubGpsEventFragment on HubGpsEvent {
    hub_id
    epoch_time
    read_time
    event_id
    gps_method
    latitude
    longitude
    satellite_count
    hdop
    altitude_ft
    ground_speed_kn
    course_angle
    updated_at
  }
`;
export const HubSignalEventFragmentFragmentDoc = gql`
  fragment HubSignalEventFragment on HubSignalEvent {
    hub_id
    epoch_time
    event_id
    signal_rssi
    signal_quality
  }
`;
export const HubHeadspaceEventFragmentFragmentDoc = gql`
  fragment HubHeadspaceEventFragment on HubHeadspaceEvent {
    event_id
    hub_id
    epoch_time
    temp_0_f
    temp_1_f
    temp_2_f
    temp_min_f
    temp_max_f
    temp_f
    hum_0_rh
    hum_1_rh
    hum_2_rh
    hum_min_rh
    hum_max_rh
    humidity_rh
    pressure_0_pa
    pressure_1_pa
    pressure_2_pa
    pressure_2_abs_pa
    pressure_min_pa
    pressure_max_pa
    packets_in_queue
    node_count
    reset_reason
    packet_count
    t6713_co2_ppm
    mos_co2_ppm
    mos_tvoc_ppb
    reset_count
  }
`;
export const HubNetworkStatFragmentFragmentDoc = gql`
  fragment HubNetworkStatFragment on HubNetworkStat {
    neighbor_address
    etx
    link_quality
    rssi
  }
`;
export const HubNetworkDebugEventFragmentFragmentDoc = gql`
  fragment HubNetworkDebugEventFragment on HubNetworkDebugEvent {
    hub_id
    epoch_time
    node_address
    event_id
    packets_in_queue
    network_stats {
      ...HubNetworkStatFragment
    }
    updated_at
    root_id
  }
  ${HubNetworkStatFragmentFragmentDoc}
`;
export const HubEventHistoryFragmentFragmentDoc = gql`
  fragment HubEventHistoryFragment on HubEventHistoryEdge {
    hub_id
    core_id
    start
    end
    hub {
      ...HubFragment
    }
    status {
      ...HubStatusEventFragment
    }
    pellets {
      ...HubPelletEventFragment
    }
    gps {
      ...HubGpsEventFragment
    }
    signal {
      ...HubSignalEventFragment
    }
    headspace {
      ...HubHeadspaceEventFragment
    }
    network_debug {
      ...HubNetworkDebugEventFragment
    }
  }
  ${HubFragmentFragmentDoc}
  ${HubStatusEventFragmentFragmentDoc}
  ${HubPelletEventFragmentFragmentDoc}
  ${HubGpsEventFragmentFragmentDoc}
  ${HubSignalEventFragmentFragmentDoc}
  ${HubHeadspaceEventFragmentFragmentDoc}
  ${HubNetworkDebugEventFragmentFragmentDoc}
`;
export const HubInfoFragmentFragmentDoc = gql`
  fragment HubInfoFragment on Hub {
    hub_id
    core_id
    alias
    events {
      status {
        epoch_time
        batt_voltage_uv
        packets_in_queue
        reset_count
      }
      headspace {
        epoch_time
        packets_in_queue
        reset_count
      }
      pellets {
        epoch_time
        sequence_number
        batt_voltage_uv
        packets_in_queue
        reset_count
        rssi
      }
      gps {
        epoch_time
      }
    }
    container_link {
      ...HubContainerLinkBaseFragment
      grain_bin {
        grain_bin_id
        alias
        account_link {
          grain_bin_id
          account {
            ...BaseAccountFragment
          }
        }
      }
      barge {
        barge_id
        alias
        account_link {
          barge_id
          account {
            ...BaseAccountFragment
          }
        }
      }
      pile {
        pile_id
        alias
        account_link {
          pile_id
          account {
            ...BaseAccountFragment
          }
        }
      }
    }
  }
  ${HubContainerLinkBaseFragmentFragmentDoc}
  ${BaseAccountFragmentFragmentDoc}
`;
export const AssignmentStatusFragmentFragmentDoc = gql`
  fragment AssignmentStatusFragment on AssignmentStatus {
    is_assigned
  }
`;
export const OpsViewRowVisibilityPrefsFragmentFragmentDoc = gql`
  fragment OpsViewRowVisibilityPrefsFragment on RowVisibilityPref {
    account_id
    pref_json
  }
`;
export const OpsViewVisibilityPrefsFragmentFragmentDoc = gql`
  fragment OpsViewVisibilityPrefsFragment on UserPref {
    user_id
    account_id
    pref_type_key
    pref_json
  }
`;
export const ParticleEventFragmentFragmentDoc = gql`
  fragment ParticleEventFragment on ParticleEvent {
    core_id
    event_id
    event_name
    event_data
    published_at
    device {
      ...ParticleDeviceFragment
    }
  }
  ${ParticleDeviceFragmentFragmentDoc}
`;
export const PelletGroupPelletFragmentFragmentDoc = gql`
  fragment PelletGroupPelletFragment on PelletGroupPelletLink {
    pellet_group_pellet_link_id
    pellet_group_id
    pellet_id
    position_no
    created_at
    reassigned_at
  }
`;
export const PelletGroupFragmentFragmentDoc = gql`
  fragment PelletGroupFragment on PelletGroup {
    pellet_group_id
    hostname
    package_alias
    created_at
    pellet_group_type
    pellet_links {
      ...PelletGroupPelletFragment
    }
  }
  ${PelletGroupPelletFragmentFragmentDoc}
`;
export const PileAccountLinkIdFragmentFragmentDoc = gql`
  fragment PileAccountLinkIdFragment on PileAccountLink {
    pile_id
  }
`;
export const PileFragmentFragmentDoc = gql`
  fragment PileFragment on Pile {
    pile_id
    alias
    default_grain_type
    orientation
    shape {
      height_ft
      width_ft
      length_ft
      radius_ft
      shape_type
    }
  }
`;
export const PileAccountLinkInternalFragmentFragmentDoc = gql`
  fragment PileAccountLinkInternalFragment on PileAccountLink {
    ...PileAccountLinkIdFragment
    pile {
      ...PileFragment
    }
    container {
      ...GrainContainerHubLinksFragment
    }
  }
  ${PileAccountLinkIdFragmentFragmentDoc}
  ${PileFragmentFragmentDoc}
  ${GrainContainerHubLinksFragmentFragmentDoc}
`;
export const PileCablePelletLinkFragmentFragmentDoc = gql`
  fragment PileCablePelletLinkFragment on PileCablePelletLink {
    pile_cable_pellet_link_id
    pile_id
    cable_no
    pellet_id
    start_epoch
  }
`;
export const PileCableLinkFragmentFragmentDoc = gql`
  fragment PileCableLinkFragment on PileCableLink {
    pile_cable_link_id
    alias
    cable_id
    pile_id
    x_ft
    y_ft
    z_ft
    start_epoch
    end_epoch
    cable {
      pellet_group_id
      length_ft
      spacing_ft
      pellet_group {
        pellet_group_id
        package_alias
        pellet_links {
          pellet_group_pellet_link_id
          pellet_id
          position_no
          reassigned_at
        }
      }
    }
  }
`;
export const RecommRunWindowsExperimentsFragmentFragmentDoc = gql`
  fragment RecommRunWindowsExperimentsFragment on RecommRunWindowsExperiments {
    name
    experiment
    type
    emc_ranges {
      ...PsychrometricRangeFragment
      __typename
    }
    temp_ranges {
      ...PsychrometricRangeFragment
      __typename
    }
    emc_reconditioning_ranges {
      ...PsychrometricRangeFragment
      __typename
    }
    hasCfmPresent
    __typename
  }
  ${PsychrometricRangeFragmentFragmentDoc}
`;
export const SensorGeneratedGrainTicketFragmentFragmentDoc = gql`
  fragment SensorGeneratedGrainTicketFragment on SensorGeneratedGrainTicket {
    grain_bin_ticket_id
    grain_bin_id
    epoch_time
    bin_level_mm
    volume
    action_performed
    notes
    approve_state
    created_at
    updated_at
  }
`;
export const UserModelFragmentFragmentDoc = gql`
  fragment UserModelFragment on UserModel {
    user_id
    account_id
    first_name
    last_name
    email_address
    telephone
    role
    verified_email
  }
`;
export const UserSelectedGrainBinFragmentFragmentDoc = gql`
  fragment UserSelectedGrainBinFragment on UserSelectedGrainBin {
    account_id
    user_id
    container_id
    container_type
    container_name
  }
`;
export const UserWeeklyGrainEmailScheduleFragmentFragmentDoc = gql`
  fragment UserWeeklyGrainEmailScheduleFragment on UserWeeklyGrainEmailSchedule {
    user_id
    schedule_day
    schedule_hour
    schedule_timezone
    subscribed
    last_email_sent_at
    next_email_schedule_date
    updated_at
  }
`;
export const UserSettingsFragmentFragmentDoc = gql`
  fragment UserSettingsFragment on UserSettings {
    version
    sms_alerts {
      enabled
      daily_forecast_report
      fan_controller
    }
  }
`;
export const UserUnitsPrefFragmentFragmentDoc = gql`
  fragment UserUnitsPrefFragment on UserUnitsPref {
    user_id
    account_id
    temp_unit
    distance_unit
    mass_unit
    test_weight_unit
  }
`;
export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on User {
    user_id
    account_id
    first_name
    last_name
    email_address
    telephone
    role
    verified_email
    needs_pw_change
    settings {
      ...UserSettingsFragment
    }
    selected_grain_bin {
      account_id
      user_id
      container_id
      container_type
      container_name
    }
    alert_pref {
      user_id
      alert_type
    }
    grain_specific_alert_prefs {
      user_id
      fan_start_stop_alerts
      fan_error_alerts
      disable_fan_guidance_alerts
      grain_bin_co2_alerts
      grain_level_alerts
    }
    grain_notification_opt_ins {
      user_id
      grain_bin_id
      account_id
      opt_in
    }
    weekly_grain_email_schedule {
      user_id
      schedule_day
      schedule_hour
      schedule_timezone
      subscribed
      last_email_sent_at
      next_email_schedule_date
      updated_at
    }
    grain_weekly_email_opt_pref {
      user_id
      account_id
      grain_bin_id
      opt_in
    }
    grain_bin_fan_run_now_period_pref {
      user_id
      account_id
      grain_bin_id
      fan_run_now_period
    }
    user_last_notifications_read_on
    user_new_notifications_count(period: "2W")
    user_units_pref {
      ...UserUnitsPrefFragment
    }
  }
  ${UserSettingsFragmentFragmentDoc}
  ${UserUnitsPrefFragmentFragmentDoc}
`;
export const AccountFragmentFragmentDoc = gql`
  fragment AccountFragment on Account {
    account_id
    barge_support
    barge_links {
      barge_id
      barge {
        default_grain_type
        barge_id
        alias
        archived
      }
    }
    grain_bin_support
    pile_support
    pile_links {
      pile_id
      pile {
        default_grain_type
        pile_id
        alias
      }
    }
    grain_bin_links {
      grain_bin_id
      grain_bin {
        grain_bin_id
        alias
        grain_type
        archived
        location {
          latitude
          longitude
        }
        fan_controllers {
          grain_bin_fan_controller_id
          alias
          fan_controller {
            fan_controller_id_next
            state_next {
              fan_controller_id
              value_next
              is_on
              error_code
            }
          }
        }
      }
    }
    organization
    system_account
  }
`;
export const ViewerFragmentFragmentDoc = gql`
  fragment ViewerFragment on Viewer {
    user_id
    account_id
    token
    user {
      ...UserFragment
    }
    account {
      ...AccountFragment
    }
  }
  ${UserFragmentFragmentDoc}
  ${AccountFragmentFragmentDoc}
`;
export const WeatherHistoryFragmentFragmentDoc = gql`
  fragment WeatherHistoryFragment on WeatherHistory {
    daily_weather_history {
      ...ForecastDayV2Fragment
      __typename
    }
    hourly_weather_history {
      ...ComputedForecastHourV2Fragment
      __typename
    }
    aeration_run_history {
      ...GrainContainerAerationRunFragment
    }
  }
  ${ForecastDayV2FragmentFragmentDoc}
  ${ComputedForecastHourV2FragmentFragmentDoc}
  ${GrainContainerAerationRunFragmentFragmentDoc}
`;
export const UserSelectedGrainContainerFragmentFragmentDoc = gql`
  fragment UserSelectedGrainContainerFragment on UserSelectedGrainContainer {
    account_id
    user_id
    container_id
    container_type
  }
`;
export const ApproveSensorGeneratedGrainTicketDocument = gql`
  mutation ApproveSensorGeneratedGrainTicket(
    $grain_bin_id: Int!
    $grain_bin_ticket_id: Int!
    $epoch_time: Date!
    $volume: Float!
    $weight_in_lbs: Float!
    $test_weight_in_lbs: Float!
    $grain_moisture_pct: Float!
    $action_performed: GrainTicketActionType!
    $notes: String!
    $grain_surface_shape: GrainSurfaceShapeType!
    $grain_bin_storage_cycle_id: Int
  ) {
    approveSensorGeneratedGrainTicket(
      grain_bin_ticket_id: $grain_bin_ticket_id
      grain_bin_id: $grain_bin_id
      epoch_time: $epoch_time
      volume: $volume
      weight_in_lbs: $weight_in_lbs
      test_weight_in_lbs: $test_weight_in_lbs
      grain_moisture_pct: $grain_moisture_pct
      action_performed: $action_performed
      notes: $notes
      grain_surface_shape: $grain_surface_shape
      grain_bin_storage_cycle_id: $grain_bin_storage_cycle_id
    ) {
      ...SensorGeneratedGrainTicketFragment
    }
  }
  ${SensorGeneratedGrainTicketFragmentFragmentDoc}
`;
export type ApproveSensorGeneratedGrainTicketMutationFn = ReactApollo.MutationFn<
  ApproveSensorGeneratedGrainTicketMutation,
  ApproveSensorGeneratedGrainTicketMutationVariables
>;
export type ApproveSensorGeneratedGrainTicketProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ApproveSensorGeneratedGrainTicketMutation,
    ApproveSensorGeneratedGrainTicketMutationVariables
  >
> &
  TChildProps;
export function withApproveSensorGeneratedGrainTicket<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ApproveSensorGeneratedGrainTicketMutation,
    ApproveSensorGeneratedGrainTicketMutationVariables,
    ApproveSensorGeneratedGrainTicketProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ApproveSensorGeneratedGrainTicketMutation,
    ApproveSensorGeneratedGrainTicketMutationVariables,
    ApproveSensorGeneratedGrainTicketProps<TChildProps>
  >(ApproveSensorGeneratedGrainTicketDocument, {
    alias: 'withApproveSensorGeneratedGrainTicket',
    ...operationOptions,
  });
}
export const ArchiveGrainContainerDocument = gql`
  mutation ArchiveGrainContainer(
    $container_id: Int!
    $container_type: ContainerType!
    $archive_unarchive_flag: ArchiveUnArchiveType!
  ) {
    archiveUnArchiveGrainContainer(
      container_id: $container_id
      container_type: $container_type
      archive_unarchive_flag: $archive_unarchive_flag
    ) {
      ...GrainBinGrainContainerFragment
      __typename
    }
  }
  ${GrainBinGrainContainerFragmentFragmentDoc}
`;
export type ArchiveGrainContainerMutationFn = ReactApollo.MutationFn<
  ArchiveGrainContainerMutation,
  ArchiveGrainContainerMutationVariables
>;
export type ArchiveGrainContainerProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<ArchiveGrainContainerMutation, ArchiveGrainContainerMutationVariables>
> &
  TChildProps;
export function withArchiveGrainContainer<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ArchiveGrainContainerMutation,
    ArchiveGrainContainerMutationVariables,
    ArchiveGrainContainerProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ArchiveGrainContainerMutation,
    ArchiveGrainContainerMutationVariables,
    ArchiveGrainContainerProps<TChildProps>
  >(ArchiveGrainContainerDocument, {
    alias: 'withArchiveGrainContainer',
    ...operationOptions,
  });
}
export const AssignFanControllerDocument = gql`
  mutation AssignFanController(
    $grain_bin_id: Int!
    $core_id: String!
    $alias: String!
    $has_plenum_sensor: Boolean
    $phase: Int
    $power_factor: Float
    $voltage: Int
    $fan_model_id: Int
    $make: String!
    $fan_brand_name: String!
    $fan_model_type: FanType!
    $fan_horsepower: String!
    $diameter: Float!
    $fan_model_created_type: FanModelCreatedType!
    $linked_generic_fan_model_id: Int
    $no_of_fans_connected: Int!
  ) {
    assignFanController(
      grain_bin_id: $grain_bin_id
      core_id: $core_id
      alias: $alias
      has_plenum_sensor: $has_plenum_sensor
      phase: $phase
      power_factor: $power_factor
      voltage: $voltage
      fan_model_id: $fan_model_id
      make: $make
      fan_brand_name: $fan_brand_name
      fan_model_type: $fan_model_type
      fan_horsepower: $fan_horsepower
      diameter: $diameter
      fan_model_created_type: $fan_model_created_type
      linked_generic_fan_model_id: $linked_generic_fan_model_id
      no_of_fans_connected: $no_of_fans_connected
    ) {
      ...FanSettingsFragment
    }
  }
  ${FanSettingsFragmentFragmentDoc}
`;
export type AssignFanControllerMutationFn = ReactApollo.MutationFn<
  AssignFanControllerMutation,
  AssignFanControllerMutationVariables
>;
export type AssignFanControllerProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<AssignFanControllerMutation, AssignFanControllerMutationVariables>
> &
  TChildProps;
export function withAssignFanController<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    AssignFanControllerMutation,
    AssignFanControllerMutationVariables,
    AssignFanControllerProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    AssignFanControllerMutation,
    AssignFanControllerMutationVariables,
    AssignFanControllerProps<TChildProps>
  >(AssignFanControllerDocument, {
    alias: 'withAssignFanController',
    ...operationOptions,
  });
}
export const AssignHubDocument = gql`
  mutation AssignHub(
    $core_id: String!
    $nickname: String!
    $hub_type: HubType!
    $has_level_sensor: Boolean!
    $hub_offset_ft: Float!
    $container_id: Int!
    $container_type: Int!
    $start_epoch: Date
    $end_epoch: Date
  ) {
    assignHub(
      core_id: $core_id
      nickname: $nickname
      hub_type: $hub_type
      has_level_sensor: $has_level_sensor
      hub_offset_ft: $hub_offset_ft
      container_id: $container_id
      container_type: $container_type
      start_epoch: $start_epoch
      end_epoch: $end_epoch
    ) {
      ...HubContainerLinkBaseFragment
    }
  }
  ${HubContainerLinkBaseFragmentFragmentDoc}
`;
export type AssignHubMutationFn = ReactApollo.MutationFn<
  AssignHubMutation,
  AssignHubMutationVariables
>;
export type AssignHubProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<AssignHubMutation, AssignHubMutationVariables>
> &
  TChildProps;
export function withAssignHub<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    AssignHubMutation,
    AssignHubMutationVariables,
    AssignHubProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    AssignHubMutation,
    AssignHubMutationVariables,
    AssignHubProps<TChildProps>
  >(AssignHubDocument, {
    alias: 'withAssignHub',
    ...operationOptions,
  });
}
export const ConfigureAlertPrefDocument = gql`
  mutation ConfigureAlertPref($user_id: Int!, $alert_type: String!) {
    configureAlertPref(user_id: $user_id, alert_type: $alert_type) {
      user_id
      alert_type
    }
  }
`;
export type ConfigureAlertPrefMutationFn = ReactApollo.MutationFn<
  ConfigureAlertPrefMutation,
  ConfigureAlertPrefMutationVariables
>;
export type ConfigureAlertPrefProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<ConfigureAlertPrefMutation, ConfigureAlertPrefMutationVariables>
> &
  TChildProps;
export function withConfigureAlertPref<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ConfigureAlertPrefMutation,
    ConfigureAlertPrefMutationVariables,
    ConfigureAlertPrefProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ConfigureAlertPrefMutation,
    ConfigureAlertPrefMutationVariables,
    ConfigureAlertPrefProps<TChildProps>
  >(ConfigureAlertPrefDocument, {
    alias: 'withConfigureAlertPref',
    ...operationOptions,
  });
}
export const CreateAccountDocument = gql`
  mutation CreateAccount(
    $organization: String!
    $barge_support: Boolean!
    $grain_bin_support: Boolean!
    $pile_support: Boolean!
  ) {
    createAccount(
      organization: $organization
      barge_support: $barge_support
      grain_bin_support: $grain_bin_support
      pile_support: $pile_support
      system_account: false
    ) {
      ...AccountFragment
    }
  }
  ${AccountFragmentFragmentDoc}
`;
export type CreateAccountMutationFn = ReactApollo.MutationFn<
  CreateAccountMutation,
  CreateAccountMutationVariables
>;
export type CreateAccountProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<CreateAccountMutation, CreateAccountMutationVariables>
> &
  TChildProps;
export function withCreateAccount<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateAccountMutation,
    CreateAccountMutationVariables,
    CreateAccountProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CreateAccountMutation,
    CreateAccountMutationVariables,
    CreateAccountProps<TChildProps>
  >(CreateAccountDocument, {
    alias: 'withCreateAccount',
    ...operationOptions,
  });
}
export const CreateBargeDocument = gql`
  mutation CreateBarge(
    $account_id: Int!
    $alias: String!
    $default_grain_type: GrainType!
    $height_ft: Float!
    $length_ft: Float!
    $width_ft: Float!
  ) {
    createBarge(
      account_id: $account_id
      alias: $alias
      default_grain_type: $default_grain_type
      height_ft: $height_ft
      length_ft: $length_ft
      width_ft: $width_ft
    ) {
      barge_id
      account_id
      barge {
        ...BargeFragment
      }
    }
  }
  ${BargeFragmentFragmentDoc}
`;
export type CreateBargeMutationFn = ReactApollo.MutationFn<
  CreateBargeMutation,
  CreateBargeMutationVariables
>;
export type CreateBargeProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<CreateBargeMutation, CreateBargeMutationVariables>
> &
  TChildProps;
export function withCreateBarge<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateBargeMutation,
    CreateBargeMutationVariables,
    CreateBargeProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CreateBargeMutation,
    CreateBargeMutationVariables,
    CreateBargeProps<TChildProps>
  >(CreateBargeDocument, {
    alias: 'withCreateBarge',
    ...operationOptions,
  });
}
export const CreateBinEventDocument = gql`
  mutation CreateBinEvent(
    $grain_bin_id: Int!
    $epoch_time: Date!
    $event_type: BinEventType!
    $event_value: String
  ) {
    createBinEvent(
      grain_bin_id: $grain_bin_id
      epoch_time: $epoch_time
      event_type: $event_type
      event_value: $event_value
    ) {
      ...BinEventFragment
    }
  }
  ${BinEventFragmentFragmentDoc}
`;
export type CreateBinEventMutationFn = ReactApollo.MutationFn<
  CreateBinEventMutation,
  CreateBinEventMutationVariables
>;
export type CreateBinEventProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<CreateBinEventMutation, CreateBinEventMutationVariables>
> &
  TChildProps;
export function withCreateBinEvent<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateBinEventMutation,
    CreateBinEventMutationVariables,
    CreateBinEventProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CreateBinEventMutation,
    CreateBinEventMutationVariables,
    CreateBinEventProps<TChildProps>
  >(CreateBinEventDocument, {
    alias: 'withCreateBinEvent',
    ...operationOptions,
  });
}
export const CreateBinStoragePeriodDocument = gql`
  mutation CreateBinStoragePeriod(
    $grain_bin_id: Int!
    $storage_cycle_name: String!
    $grain_type: GrainType!
    $start_epoch: Date!
    $end_epoch: Date
  ) {
    createBinStoragePeriod(
      grain_bin_id: $grain_bin_id
      storage_cycle_name: $storage_cycle_name
      grain_type: $grain_type
      start_epoch: $start_epoch
      end_epoch: $end_epoch
    ) {
      grain_bin_storage_cycle_id
      grain_bin_id
      storage_cycle_name
      grain_type
      start_epoch
      end_epoch
      created_at
    }
  }
`;
export type CreateBinStoragePeriodMutationFn = ReactApollo.MutationFn<
  CreateBinStoragePeriodMutation,
  CreateBinStoragePeriodMutationVariables
>;
export type CreateBinStoragePeriodProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<CreateBinStoragePeriodMutation, CreateBinStoragePeriodMutationVariables>
> &
  TChildProps;
export function withCreateBinStoragePeriod<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateBinStoragePeriodMutation,
    CreateBinStoragePeriodMutationVariables,
    CreateBinStoragePeriodProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CreateBinStoragePeriodMutation,
    CreateBinStoragePeriodMutationVariables,
    CreateBinStoragePeriodProps<TChildProps>
  >(CreateBinStoragePeriodDocument, {
    alias: 'withCreateBinStoragePeriod',
    ...operationOptions,
  });
}
export const CreateCustomerCareTokenDocument = gql`
  mutation CreateCustomerCareToken($username: String!, $auth_token: String!) {
    createCustomerCareToken(username: $username, auth_token: $auth_token) {
      token
    }
  }
`;
export type CreateCustomerCareTokenMutationFn = ReactApollo.MutationFn<
  CreateCustomerCareTokenMutation,
  CreateCustomerCareTokenMutationVariables
>;
export type CreateCustomerCareTokenProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<CreateCustomerCareTokenMutation, CreateCustomerCareTokenMutationVariables>
> &
  TChildProps;
export function withCreateCustomerCareToken<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateCustomerCareTokenMutation,
    CreateCustomerCareTokenMutationVariables,
    CreateCustomerCareTokenProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CreateCustomerCareTokenMutation,
    CreateCustomerCareTokenMutationVariables,
    CreateCustomerCareTokenProps<TChildProps>
  >(CreateCustomerCareTokenDocument, {
    alias: 'withCreateCustomerCareToken',
    ...operationOptions,
  });
}
export const CreateGrainBinDocument = gql`
  mutation CreateGrainBin(
    $account_id: Int!
    $bin_name: String!
    $grain_type: GrainType!
    $height_ft: Float!
    $diameter_ft: Float!
    $zip_code: String
    $location: LocationInput!
    $current_grain_emc: Float
    $current_grain_temp: Float
    $has_grain_spreader: Boolean
    $floor_type: FloorType!
  ) {
    createGrainBin(
      account_id: $account_id
      alias: $bin_name
      grain_type: $grain_type
      height_ft: $height_ft
      diameter_ft: $diameter_ft
      zip_code: $zip_code
      location: $location
      has_grain_spreader: $has_grain_spreader
      current_grain_emc: $current_grain_emc
      current_grain_temp: $current_grain_temp
      floor_type: $floor_type
    ) {
      grain_bin_id
      account_id
      grain_bin {
        ...GrainBinFragment
      }
    }
  }
  ${GrainBinFragmentFragmentDoc}
`;
export type CreateGrainBinMutationFn = ReactApollo.MutationFn<
  CreateGrainBinMutation,
  CreateGrainBinMutationVariables
>;
export type CreateGrainBinProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<CreateGrainBinMutation, CreateGrainBinMutationVariables>
> &
  TChildProps;
export function withCreateGrainBin<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateGrainBinMutation,
    CreateGrainBinMutationVariables,
    CreateGrainBinProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CreateGrainBinMutation,
    CreateGrainBinMutationVariables,
    CreateGrainBinProps<TChildProps>
  >(CreateGrainBinDocument, {
    alias: 'withCreateGrainBin',
    ...operationOptions,
  });
}
export const CreateGrainBinSiteDocument = gql`
  mutation CreateGrainBinSite($account_id: Int!, $site_name: String!) {
    createGrainBinSite(account_id: $account_id, site_name: $site_name) {
      ...GrainBinSiteFragment
    }
  }
  ${GrainBinSiteFragmentFragmentDoc}
`;
export type CreateGrainBinSiteMutationFn = ReactApollo.MutationFn<
  CreateGrainBinSiteMutation,
  CreateGrainBinSiteMutationVariables
>;
export type CreateGrainBinSiteProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<CreateGrainBinSiteMutation, CreateGrainBinSiteMutationVariables>
> &
  TChildProps;
export function withCreateGrainBinSite<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateGrainBinSiteMutation,
    CreateGrainBinSiteMutationVariables,
    CreateGrainBinSiteProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CreateGrainBinSiteMutation,
    CreateGrainBinSiteMutationVariables,
    CreateGrainBinSiteProps<TChildProps>
  >(CreateGrainBinSiteDocument, {
    alias: 'withCreateGrainBinSite',
    ...operationOptions,
  });
}
export const CreateGrainBinSystemDocument = gql`
  mutation CreateGrainBinSystem(
    $account_id: Int!
    $alias: String!
    $grain_type: GrainType!
    $height_ft: Float!
    $diameter_ft: Float!
    $zip_code: String
    $location: LocationInput!
    $has_grain_spreader: Boolean!
    $current_grain_emc: Float
    $current_grain_temp: Float
    $fan_controllers: [AssignFanControllerInput]!
    $hubs: [AssignHubInput]!
    $floor_type: FloorType!
  ) {
    createGrainBinSystem(
      account_id: $account_id
      alias: $alias
      grain_type: $grain_type
      height_ft: $height_ft
      diameter_ft: $diameter_ft
      zip_code: $zip_code
      location: $location
      has_grain_spreader: $has_grain_spreader
      current_grain_emc: $current_grain_emc
      current_grain_temp: $current_grain_temp
      fan_controllers: $fan_controllers
      hubs: $hubs
      floor_type: $floor_type
    ) {
      grain_bin_id
      account_id
      grain_bin {
        ...GrainBinFragment
      }
    }
  }
  ${GrainBinFragmentFragmentDoc}
`;
export type CreateGrainBinSystemMutationFn = ReactApollo.MutationFn<
  CreateGrainBinSystemMutation,
  CreateGrainBinSystemMutationVariables
>;
export type CreateGrainBinSystemProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<CreateGrainBinSystemMutation, CreateGrainBinSystemMutationVariables>
> &
  TChildProps;
export function withCreateGrainBinSystem<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateGrainBinSystemMutation,
    CreateGrainBinSystemMutationVariables,
    CreateGrainBinSystemProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CreateGrainBinSystemMutation,
    CreateGrainBinSystemMutationVariables,
    CreateGrainBinSystemProps<TChildProps>
  >(CreateGrainBinSystemDocument, {
    alias: 'withCreateGrainBinSystem',
    ...operationOptions,
  });
}
export const CreateGrainBinTicketDocument = gql`
  mutation CreateGrainBinTicket(
    $grain_bin_id: Int!
    $epoch_time: Date!
    $volume: Float!
    $weight_in_lbs: Float!
    $test_weight_in_lbs: Float!
    $action_performed: GrainTicketActionType!
    $grain_moisture_pct: Float!
    $notes: String!
    $grain_surface_shape: GrainSurfaceShapeType!
    $grain_bin_storage_cycle_id: Int
  ) {
    createGrainBinTicket(
      grain_bin_id: $grain_bin_id
      epoch_time: $epoch_time
      volume: $volume
      weight_in_lbs: $weight_in_lbs
      test_weight_in_lbs: $test_weight_in_lbs
      action_performed: $action_performed
      grain_moisture_pct: $grain_moisture_pct
      notes: $notes
      grain_surface_shape: $grain_surface_shape
      grain_bin_storage_cycle_id: $grain_bin_storage_cycle_id
    ) {
      ...GrainBinTicketRowFragment
    }
  }
  ${GrainBinTicketRowFragmentFragmentDoc}
`;
export type CreateGrainBinTicketMutationFn = ReactApollo.MutationFn<
  CreateGrainBinTicketMutation,
  CreateGrainBinTicketMutationVariables
>;
export type CreateGrainBinTicketProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<CreateGrainBinTicketMutation, CreateGrainBinTicketMutationVariables>
> &
  TChildProps;
export function withCreateGrainBinTicket<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateGrainBinTicketMutation,
    CreateGrainBinTicketMutationVariables,
    CreateGrainBinTicketProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CreateGrainBinTicketMutation,
    CreateGrainBinTicketMutationVariables,
    CreateGrainBinTicketProps<TChildProps>
  >(CreateGrainBinTicketDocument, {
    alias: 'withCreateGrainBinTicket',
    ...operationOptions,
  });
}
export const CreatePileDocument = gql`
  mutation CreatePile(
    $account_id: Int!
    $alias: String!
    $default_grain_type: GrainType!
    $orientation: Orientation!
    $shape: ShapeInput
  ) {
    createPile(
      account_id: $account_id
      alias: $alias
      default_grain_type: $default_grain_type
      orientation: $orientation
      shape: $shape
    ) {
      pile_id
      account_id
      pile {
        ...PileFragment
      }
    }
  }
  ${PileFragmentFragmentDoc}
`;
export type CreatePileMutationFn = ReactApollo.MutationFn<
  CreatePileMutation,
  CreatePileMutationVariables
>;
export type CreatePileProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<CreatePileMutation, CreatePileMutationVariables>
> &
  TChildProps;
export function withCreatePile<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreatePileMutation,
    CreatePileMutationVariables,
    CreatePileProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CreatePileMutation,
    CreatePileMutationVariables,
    CreatePileProps<TChildProps>
  >(CreatePileDocument, {
    alias: 'withCreatePile',
    ...operationOptions,
  });
}
export const CreateTokenDocument = gql`
  mutation CreateToken($email_address: String!, $password: String!) {
    createToken(email_address: $email_address, password: $password) {
      user_id
      account_id
      token
    }
  }
`;
export type CreateTokenMutationFn = ReactApollo.MutationFn<
  CreateTokenMutation,
  CreateTokenMutationVariables
>;
export type CreateTokenProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<CreateTokenMutation, CreateTokenMutationVariables>
> &
  TChildProps;
export function withCreateToken<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateTokenMutation,
    CreateTokenMutationVariables,
    CreateTokenProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CreateTokenMutation,
    CreateTokenMutationVariables,
    CreateTokenProps<TChildProps>
  >(CreateTokenDocument, {
    alias: 'withCreateToken',
    ...operationOptions,
  });
}
export const CreateTokenByOAuthDocument = gql`
  mutation CreateTokenByOAuth($oauth_token: String!) {
    createTokenByOAuth(oauth_token: $oauth_token) {
      ...ViewerFragment
    }
  }
  ${ViewerFragmentFragmentDoc}
`;
export type CreateTokenByOAuthMutationFn = ReactApollo.MutationFn<
  CreateTokenByOAuthMutation,
  CreateTokenByOAuthMutationVariables
>;
export type CreateTokenByOAuthProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<CreateTokenByOAuthMutation, CreateTokenByOAuthMutationVariables>
> &
  TChildProps;
export function withCreateTokenByOAuth<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateTokenByOAuthMutation,
    CreateTokenByOAuthMutationVariables,
    CreateTokenByOAuthProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CreateTokenByOAuthMutation,
    CreateTokenByOAuthMutationVariables,
    CreateTokenByOAuthProps<TChildProps>
  >(CreateTokenByOAuthDocument, {
    alias: 'withCreateTokenByOAuth',
    ...operationOptions,
  });
}
export const CreateUserDocument = gql`
  mutation createUser(
    $token: String!
    $email_address: String!
    $first_name: String!
    $last_name: String!
    $telephone: String!
    $password: String!
  ) {
    createUser(
      token: $token
      first_name: $first_name
      last_name: $last_name
      telephone: $telephone
      email_address: $email_address
      password: $password
    ) {
      ...ViewerFragment
    }
  }
  ${ViewerFragmentFragmentDoc}
`;
export type CreateUserMutationFn = ReactApollo.MutationFn<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export type CreateUserProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<CreateUserMutation, CreateUserMutationVariables>
> &
  TChildProps;
export function withCreateUser<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateUserMutation,
    CreateUserMutationVariables,
    CreateUserProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CreateUserMutation,
    CreateUserMutationVariables,
    CreateUserProps<TChildProps>
  >(CreateUserDocument, {
    alias: 'withCreateUser',
    ...operationOptions,
  });
}
export const DeclineSensorGeneratedGrainTicketDocument = gql`
  mutation DeclineSensorGeneratedGrainTicket($grain_bin_id: Int!, $grain_bin_ticket_id: Int!) {
    declineSensorGeneratedGrainTicket(
      grain_bin_ticket_id: $grain_bin_ticket_id
      grain_bin_id: $grain_bin_id
    ) {
      ...SensorGeneratedGrainTicketFragment
    }
  }
  ${SensorGeneratedGrainTicketFragmentFragmentDoc}
`;
export type DeclineSensorGeneratedGrainTicketMutationFn = ReactApollo.MutationFn<
  DeclineSensorGeneratedGrainTicketMutation,
  DeclineSensorGeneratedGrainTicketMutationVariables
>;
export type DeclineSensorGeneratedGrainTicketProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    DeclineSensorGeneratedGrainTicketMutation,
    DeclineSensorGeneratedGrainTicketMutationVariables
  >
> &
  TChildProps;
export function withDeclineSensorGeneratedGrainTicket<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    DeclineSensorGeneratedGrainTicketMutation,
    DeclineSensorGeneratedGrainTicketMutationVariables,
    DeclineSensorGeneratedGrainTicketProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    DeclineSensorGeneratedGrainTicketMutation,
    DeclineSensorGeneratedGrainTicketMutationVariables,
    DeclineSensorGeneratedGrainTicketProps<TChildProps>
  >(DeclineSensorGeneratedGrainTicketDocument, {
    alias: 'withDeclineSensorGeneratedGrainTicket',
    ...operationOptions,
  });
}
export const DecommissionFanControllerDocument = gql`
  mutation DecommissionFanController($fan_controller_id: Int!, $as_of: Date) {
    decommissionFanController(fan_controller_id: $fan_controller_id, as_of: $as_of) {
      ...FanControllerFragment
    }
  }
  ${FanControllerFragmentFragmentDoc}
`;
export type DecommissionFanControllerMutationFn = ReactApollo.MutationFn<
  DecommissionFanControllerMutation,
  DecommissionFanControllerMutationVariables
>;
export type DecommissionFanControllerProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    DecommissionFanControllerMutation,
    DecommissionFanControllerMutationVariables
  >
> &
  TChildProps;
export function withDecommissionFanController<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    DecommissionFanControllerMutation,
    DecommissionFanControllerMutationVariables,
    DecommissionFanControllerProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    DecommissionFanControllerMutation,
    DecommissionFanControllerMutationVariables,
    DecommissionFanControllerProps<TChildProps>
  >(DecommissionFanControllerDocument, {
    alias: 'withDecommissionFanController',
    ...operationOptions,
  });
}
export const DecommissionHubDocument = gql`
  mutation DecommissionHub($hub_id: Int!, $as_of: Date) {
    decommissionHub(hub_id: $hub_id, as_of: $as_of) {
      ...HubFragment
    }
  }
  ${HubFragmentFragmentDoc}
`;
export type DecommissionHubMutationFn = ReactApollo.MutationFn<
  DecommissionHubMutation,
  DecommissionHubMutationVariables
>;
export type DecommissionHubProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<DecommissionHubMutation, DecommissionHubMutationVariables>
> &
  TChildProps;
export function withDecommissionHub<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    DecommissionHubMutation,
    DecommissionHubMutationVariables,
    DecommissionHubProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    DecommissionHubMutation,
    DecommissionHubMutationVariables,
    DecommissionHubProps<TChildProps>
  >(DecommissionHubDocument, {
    alias: 'withDecommissionHub',
    ...operationOptions,
  });
}
export const DeleteBinStoragePeriodDocument = gql`
  mutation DeleteBinStoragePeriod($grain_bin_id: Int!, $grain_bin_storage_cycle_id: Int!) {
    deleteBinStoragePeriod(
      grain_bin_id: $grain_bin_id
      grain_bin_storage_cycle_id: $grain_bin_storage_cycle_id
    ) {
      grain_bin_storage_cycle_id
      grain_bin_id
      storage_cycle_name
      grain_type
      start_epoch
      end_epoch
      created_at
    }
  }
`;
export type DeleteBinStoragePeriodMutationFn = ReactApollo.MutationFn<
  DeleteBinStoragePeriodMutation,
  DeleteBinStoragePeriodMutationVariables
>;
export type DeleteBinStoragePeriodProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<DeleteBinStoragePeriodMutation, DeleteBinStoragePeriodMutationVariables>
> &
  TChildProps;
export function withDeleteBinStoragePeriod<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    DeleteBinStoragePeriodMutation,
    DeleteBinStoragePeriodMutationVariables,
    DeleteBinStoragePeriodProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    DeleteBinStoragePeriodMutation,
    DeleteBinStoragePeriodMutationVariables,
    DeleteBinStoragePeriodProps<TChildProps>
  >(DeleteBinStoragePeriodDocument, {
    alias: 'withDeleteBinStoragePeriod',
    ...operationOptions,
  });
}
export const DeleteGrainBinSiteDocument = gql`
  mutation DeleteGrainBinSite($site_id: Int!) {
    deleteGrainBinSite(site_id: $site_id) {
      ...GrainBinSiteFragment
    }
  }
  ${GrainBinSiteFragmentFragmentDoc}
`;
export type DeleteGrainBinSiteMutationFn = ReactApollo.MutationFn<
  DeleteGrainBinSiteMutation,
  DeleteGrainBinSiteMutationVariables
>;
export type DeleteGrainBinSiteProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<DeleteGrainBinSiteMutation, DeleteGrainBinSiteMutationVariables>
> &
  TChildProps;
export function withDeleteGrainBinSite<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    DeleteGrainBinSiteMutation,
    DeleteGrainBinSiteMutationVariables,
    DeleteGrainBinSiteProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    DeleteGrainBinSiteMutation,
    DeleteGrainBinSiteMutationVariables,
    DeleteGrainBinSiteProps<TChildProps>
  >(DeleteGrainBinSiteDocument, {
    alias: 'withDeleteGrainBinSite',
    ...operationOptions,
  });
}
export const DeleteGrainBinTicketDocument = gql`
  mutation DeleteGrainBinTicket($grain_bin_id: Int!, $grain_bin_ticket_id: Int!) {
    deleteGrainBinTicket(grain_bin_id: $grain_bin_id, grain_bin_ticket_id: $grain_bin_ticket_id) {
      ...GrainBinTicketRowFragment
    }
  }
  ${GrainBinTicketRowFragmentFragmentDoc}
`;
export type DeleteGrainBinTicketMutationFn = ReactApollo.MutationFn<
  DeleteGrainBinTicketMutation,
  DeleteGrainBinTicketMutationVariables
>;
export type DeleteGrainBinTicketProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<DeleteGrainBinTicketMutation, DeleteGrainBinTicketMutationVariables>
> &
  TChildProps;
export function withDeleteGrainBinTicket<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    DeleteGrainBinTicketMutation,
    DeleteGrainBinTicketMutationVariables,
    DeleteGrainBinTicketProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    DeleteGrainBinTicketMutation,
    DeleteGrainBinTicketMutationVariables,
    DeleteGrainBinTicketProps<TChildProps>
  >(DeleteGrainBinTicketDocument, {
    alias: 'withDeleteGrainBinTicket',
    ...operationOptions,
  });
}
export const DeleteUserDocument = gql`
  mutation DeleteUser($user_id: Int!, $account_id: Int!) {
    deleteUser(user_id: $user_id, account_id: $account_id) {
      user_id
    }
  }
`;
export type DeleteUserMutationFn = ReactApollo.MutationFn<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export type DeleteUserProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<DeleteUserMutation, DeleteUserMutationVariables>
> &
  TChildProps;
export function withDeleteUser<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    DeleteUserMutation,
    DeleteUserMutationVariables,
    DeleteUserProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    DeleteUserMutation,
    DeleteUserMutationVariables,
    DeleteUserProps<TChildProps>
  >(DeleteUserDocument, {
    alias: 'withDeleteUser',
    ...operationOptions,
  });
}
export const DeleteUserBySuperUserDocument = gql`
  mutation DeleteUserBySuperUser($user_id: Int!, $account_id: Int!) {
    deleteUserBySuperUser(user_id: $user_id, account_id: $account_id) {
      user_id
    }
  }
`;
export type DeleteUserBySuperUserMutationFn = ReactApollo.MutationFn<
  DeleteUserBySuperUserMutation,
  DeleteUserBySuperUserMutationVariables
>;
export type DeleteUserBySuperUserProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<DeleteUserBySuperUserMutation, DeleteUserBySuperUserMutationVariables>
> &
  TChildProps;
export function withDeleteUserBySuperUser<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    DeleteUserBySuperUserMutation,
    DeleteUserBySuperUserMutationVariables,
    DeleteUserBySuperUserProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    DeleteUserBySuperUserMutation,
    DeleteUserBySuperUserMutationVariables,
    DeleteUserBySuperUserProps<TChildProps>
  >(DeleteUserBySuperUserDocument, {
    alias: 'withDeleteUserBySuperUser',
    ...operationOptions,
  });
}
export const InitiateGuidedRecommendationsDocument = gql`
  mutation InitiateGuidedRecommendations(
    $container_id: Int!
    $container_type: ContainerType!
    $automated_period: Int!
  ) {
    initiateGuidedRecommendations(
      container_id: $container_id
      container_type: $container_type
      automated_period: $automated_period
    ) {
      ...GrainBinGrainContainerFragment
    }
  }
  ${GrainBinGrainContainerFragmentFragmentDoc}
`;
export type InitiateGuidedRecommendationsMutationFn = ReactApollo.MutationFn<
  InitiateGuidedRecommendationsMutation,
  InitiateGuidedRecommendationsMutationVariables
>;
export type InitiateGuidedRecommendationsProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    InitiateGuidedRecommendationsMutation,
    InitiateGuidedRecommendationsMutationVariables
  >
> &
  TChildProps;
export function withInitiateGuidedRecommendations<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    InitiateGuidedRecommendationsMutation,
    InitiateGuidedRecommendationsMutationVariables,
    InitiateGuidedRecommendationsProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    InitiateGuidedRecommendationsMutation,
    InitiateGuidedRecommendationsMutationVariables,
    InitiateGuidedRecommendationsProps<TChildProps>
  >(InitiateGuidedRecommendationsDocument, {
    alias: 'withInitiateGuidedRecommendations',
    ...operationOptions,
  });
}
export const InviteUserDocument = gql`
  mutation InviteUser($account_id: Int!, $email_address: String!, $role: UserRole!) {
    inviteUser(account_id: $account_id, email_address: $email_address, role: $role)
  }
`;
export type InviteUserMutationFn = ReactApollo.MutationFn<
  InviteUserMutation,
  InviteUserMutationVariables
>;
export type InviteUserProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<InviteUserMutation, InviteUserMutationVariables>
> &
  TChildProps;
export function withInviteUser<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    InviteUserMutation,
    InviteUserMutationVariables,
    InviteUserProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    InviteUserMutation,
    InviteUserMutationVariables,
    InviteUserProps<TChildProps>
  >(InviteUserDocument, {
    alias: 'withInviteUser',
    ...operationOptions,
  });
}
export const LinkBargeCoverPelletDocument = gql`
  mutation LinkBargeCoverPellet($barge_id: Int!, $cover_no: Int!, $pellet_id: String!) {
    linkBargeCoverPellet(barge_id: $barge_id, cover_no: $cover_no, pellet_id: $pellet_id) {
      ...BargeCoverPelletLinkFragment
    }
  }
  ${BargeCoverPelletLinkFragmentFragmentDoc}
`;
export type LinkBargeCoverPelletMutationFn = ReactApollo.MutationFn<
  LinkBargeCoverPelletMutation,
  LinkBargeCoverPelletMutationVariables
>;
export type LinkBargeCoverPelletProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<LinkBargeCoverPelletMutation, LinkBargeCoverPelletMutationVariables>
> &
  TChildProps;
export function withLinkBargeCoverPellet<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    LinkBargeCoverPelletMutation,
    LinkBargeCoverPelletMutationVariables,
    LinkBargeCoverPelletProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    LinkBargeCoverPelletMutation,
    LinkBargeCoverPelletMutationVariables,
    LinkBargeCoverPelletProps<TChildProps>
  >(LinkBargeCoverPelletDocument, {
    alias: 'withLinkBargeCoverPellet',
    ...operationOptions,
  });
}
export const LinkPileCableDocument = gql`
  mutation LinkPileCable(
    $alias: String!
    $pile_id: Int!
    $cable_id: Int!
    $x_ft: Float!
    $y_ft: Float!
  ) {
    linkPileCable(alias: $alias, pile_id: $pile_id, cable_id: $cable_id, x_ft: $x_ft, y_ft: $y_ft) {
      ...PileCableLinkFragment
    }
  }
  ${PileCableLinkFragmentFragmentDoc}
`;
export type LinkPileCableMutationFn = ReactApollo.MutationFn<
  LinkPileCableMutation,
  LinkPileCableMutationVariables
>;
export type LinkPileCableProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<LinkPileCableMutation, LinkPileCableMutationVariables>
> &
  TChildProps;
export function withLinkPileCable<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    LinkPileCableMutation,
    LinkPileCableMutationVariables,
    LinkPileCableProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    LinkPileCableMutation,
    LinkPileCableMutationVariables,
    LinkPileCableProps<TChildProps>
  >(LinkPileCableDocument, {
    alias: 'withLinkPileCable',
    ...operationOptions,
  });
}
export const LinkPileCablePelletDocument = gql`
  mutation LinkPileCablePellet($pile_id: Int!, $cable_no: Int!, $pellet_id: String!) {
    linkPileCablePellet(pile_id: $pile_id, cable_no: $cable_no, pellet_id: $pellet_id) {
      ...PileCablePelletLinkFragment
    }
  }
  ${PileCablePelletLinkFragmentFragmentDoc}
`;
export type LinkPileCablePelletMutationFn = ReactApollo.MutationFn<
  LinkPileCablePelletMutation,
  LinkPileCablePelletMutationVariables
>;
export type LinkPileCablePelletProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<LinkPileCablePelletMutation, LinkPileCablePelletMutationVariables>
> &
  TChildProps;
export function withLinkPileCablePellet<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    LinkPileCablePelletMutation,
    LinkPileCablePelletMutationVariables,
    LinkPileCablePelletProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    LinkPileCablePelletMutation,
    LinkPileCablePelletMutationVariables,
    LinkPileCablePelletProps<TChildProps>
  >(LinkPileCablePelletDocument, {
    alias: 'withLinkPileCablePellet',
    ...operationOptions,
  });
}
export const ManuallyAddUserDocument = gql`
  mutation ManuallyAddUser($account_id: Int!, $email_address: String!, $role: UserRole!) {
    manuallyAddUser(account_id: $account_id, email_address: $email_address, role: $role)
  }
`;
export type ManuallyAddUserMutationFn = ReactApollo.MutationFn<
  ManuallyAddUserMutation,
  ManuallyAddUserMutationVariables
>;
export type ManuallyAddUserProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<ManuallyAddUserMutation, ManuallyAddUserMutationVariables>
> &
  TChildProps;
export function withManuallyAddUser<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ManuallyAddUserMutation,
    ManuallyAddUserMutationVariables,
    ManuallyAddUserProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ManuallyAddUserMutation,
    ManuallyAddUserMutationVariables,
    ManuallyAddUserProps<TChildProps>
  >(ManuallyAddUserDocument, {
    alias: 'withManuallyAddUser',
    ...operationOptions,
  });
}
export const PerformGrainBinSelectionDocument = gql`
  mutation PerformGrainBinSelection($user_id: Int!, $account_id: Int!, $container_id: Int!) {
    performGrainBinSelection(
      user_id: $user_id
      account_id: $account_id
      container_id: $container_id
    ) {
      ...UserSelectedGrainBinFragment
    }
  }
  ${UserSelectedGrainBinFragmentFragmentDoc}
`;
export type PerformGrainBinSelectionMutationFn = ReactApollo.MutationFn<
  PerformGrainBinSelectionMutation,
  PerformGrainBinSelectionMutationVariables
>;
export type PerformGrainBinSelectionProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    PerformGrainBinSelectionMutation,
    PerformGrainBinSelectionMutationVariables
  >
> &
  TChildProps;
export function withPerformGrainBinSelection<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    PerformGrainBinSelectionMutation,
    PerformGrainBinSelectionMutationVariables,
    PerformGrainBinSelectionProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    PerformGrainBinSelectionMutation,
    PerformGrainBinSelectionMutationVariables,
    PerformGrainBinSelectionProps<TChildProps>
  >(PerformGrainBinSelectionDocument, {
    alias: 'withPerformGrainBinSelection',
    ...operationOptions,
  });
}
export const PerformGrainBinUnselectionDocument = gql`
  mutation PerformGrainBinUnselection($user_id: Int!, $account_id: Int!) {
    performGrainBinUnselection(user_id: $user_id, account_id: $account_id) {
      ...UserSelectedGrainContainerFragment
    }
  }
  ${UserSelectedGrainContainerFragmentFragmentDoc}
`;
export type PerformGrainBinUnselectionMutationFn = ReactApollo.MutationFn<
  PerformGrainBinUnselectionMutation,
  PerformGrainBinUnselectionMutationVariables
>;
export type PerformGrainBinUnselectionProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    PerformGrainBinUnselectionMutation,
    PerformGrainBinUnselectionMutationVariables
  >
> &
  TChildProps;
export function withPerformGrainBinUnselection<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    PerformGrainBinUnselectionMutation,
    PerformGrainBinUnselectionMutationVariables,
    PerformGrainBinUnselectionProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    PerformGrainBinUnselectionMutation,
    PerformGrainBinUnselectionMutationVariables,
    PerformGrainBinUnselectionProps<TChildProps>
  >(PerformGrainBinUnselectionDocument, {
    alias: 'withPerformGrainBinUnselection',
    ...operationOptions,
  });
}
export const PingFanControllerDocument = gql`
  mutation PingFanController($fan_controller_id: Int!) {
    pingFanController(fan_controller_id: $fan_controller_id) {
      ...FanControllerRequestFragment
    }
  }
  ${FanControllerRequestFragmentFragmentDoc}
`;
export type PingFanControllerMutationFn = ReactApollo.MutationFn<
  PingFanControllerMutation,
  PingFanControllerMutationVariables
>;
export type PingFanControllerProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<PingFanControllerMutation, PingFanControllerMutationVariables>
> &
  TChildProps;
export function withPingFanController<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    PingFanControllerMutation,
    PingFanControllerMutationVariables,
    PingFanControllerProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    PingFanControllerMutation,
    PingFanControllerMutationVariables,
    PingFanControllerProps<TChildProps>
  >(PingFanControllerDocument, {
    alias: 'withPingFanController',
    ...operationOptions,
  });
}
export const RegisterFanControllerDocument = gql`
  mutation RegisterFanController(
    $core_id: String!
    $environment: String!
    $hardware_rev: String!
    $firmware: String!
    $notes: String!
    $created_at: Date
    $is_plenum_sensored_fc: Boolean
  ) {
    registerFanController(
      core_id: $core_id
      environment: $environment
      hardware_rev: $hardware_rev
      firmware: $firmware
      notes: $notes
      created_at: $created_at
      is_plenum_sensored_fc: $is_plenum_sensored_fc
    ) {
      ...FanControllerFragment
    }
  }
  ${FanControllerFragmentFragmentDoc}
`;
export type RegisterFanControllerMutationFn = ReactApollo.MutationFn<
  RegisterFanControllerMutation,
  RegisterFanControllerMutationVariables
>;
export type RegisterFanControllerProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<RegisterFanControllerMutation, RegisterFanControllerMutationVariables>
> &
  TChildProps;
export function withRegisterFanController<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    RegisterFanControllerMutation,
    RegisterFanControllerMutationVariables,
    RegisterFanControllerProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    RegisterFanControllerMutation,
    RegisterFanControllerMutationVariables,
    RegisterFanControllerProps<TChildProps>
  >(RegisterFanControllerDocument, {
    alias: 'withRegisterFanController',
    ...operationOptions,
  });
}
export const RegisterHubDocument = gql`
  mutation RegisterHub(
    $core_id: String!
    $is_active: Boolean!
    $environment: String!
    $hardware_rev: String!
    $firmware: String!
    $notes: String!
    $ref_pellet_id: String
    $hub_pressure_offset_pa: Int
    $created_at: Date
    $is_plenum_sensored_fc: Boolean
  ) {
    registerHub(
      core_id: $core_id
      is_active: $is_active
      environment: $environment
      hardware_rev: $hardware_rev
      firmware: $firmware
      notes: $notes
      ref_pellet_id: $ref_pellet_id
      hub_pressure_offset_pa: $hub_pressure_offset_pa
      created_at: $created_at
      is_plenum_sensored_fc: $is_plenum_sensored_fc
    ) {
      ...HubFragment
    }
  }
  ${HubFragmentFragmentDoc}
`;
export type RegisterHubMutationFn = ReactApollo.MutationFn<
  RegisterHubMutation,
  RegisterHubMutationVariables
>;
export type RegisterHubProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<RegisterHubMutation, RegisterHubMutationVariables>
> &
  TChildProps;
export function withRegisterHub<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    RegisterHubMutation,
    RegisterHubMutationVariables,
    RegisterHubProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    RegisterHubMutation,
    RegisterHubMutationVariables,
    RegisterHubProps<TChildProps>
  >(RegisterHubDocument, {
    alias: 'withRegisterHub',
    ...operationOptions,
  });
}
export const RemoveBinEventDocument = gql`
  mutation RemoveBinEvent($grain_bin_id: Int!, $epoch_time: Date!, $event_type: BinEventType!) {
    removeBinEvent(grain_bin_id: $grain_bin_id, epoch_time: $epoch_time, event_type: $event_type) {
      ...BinEventFragment
    }
  }
  ${BinEventFragmentFragmentDoc}
`;
export type RemoveBinEventMutationFn = ReactApollo.MutationFn<
  RemoveBinEventMutation,
  RemoveBinEventMutationVariables
>;
export type RemoveBinEventProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<RemoveBinEventMutation, RemoveBinEventMutationVariables>
> &
  TChildProps;
export function withRemoveBinEvent<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    RemoveBinEventMutation,
    RemoveBinEventMutationVariables,
    RemoveBinEventProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    RemoveBinEventMutation,
    RemoveBinEventMutationVariables,
    RemoveBinEventProps<TChildProps>
  >(RemoveBinEventDocument, {
    alias: 'withRemoveBinEvent',
    ...operationOptions,
  });
}
export const RenameGrainBinSiteDocument = gql`
  mutation RenameGrainBinSite($site_id: Int!, $site_name: String!) {
    renameGrainBinSite(site_id: $site_id, site_name: $site_name) {
      ...GrainBinSiteFragment
    }
  }
  ${GrainBinSiteFragmentFragmentDoc}
`;
export type RenameGrainBinSiteMutationFn = ReactApollo.MutationFn<
  RenameGrainBinSiteMutation,
  RenameGrainBinSiteMutationVariables
>;
export type RenameGrainBinSiteProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<RenameGrainBinSiteMutation, RenameGrainBinSiteMutationVariables>
> &
  TChildProps;
export function withRenameGrainBinSite<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    RenameGrainBinSiteMutation,
    RenameGrainBinSiteMutationVariables,
    RenameGrainBinSiteProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    RenameGrainBinSiteMutation,
    RenameGrainBinSiteMutationVariables,
    RenameGrainBinSiteProps<TChildProps>
  >(RenameGrainBinSiteDocument, {
    alias: 'withRenameGrainBinSite',
    ...operationOptions,
  });
}
export const RenewTokenDocument = gql`
  mutation RenewToken {
    renewToken {
      ...ViewerFragment
    }
  }
  ${ViewerFragmentFragmentDoc}
`;
export type RenewTokenMutationFn = ReactApollo.MutationFn<
  RenewTokenMutation,
  RenewTokenMutationVariables
>;
export type RenewTokenProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<RenewTokenMutation, RenewTokenMutationVariables>
> &
  TChildProps;
export function withRenewToken<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    RenewTokenMutation,
    RenewTokenMutationVariables,
    RenewTokenProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    RenewTokenMutation,
    RenewTokenMutationVariables,
    RenewTokenProps<TChildProps>
  >(RenewTokenDocument, {
    alias: 'withRenewToken',
    ...operationOptions,
  });
}
export const RequestUserVerificationDocument = gql`
  mutation RequestUserVerification($user_id: Int!) {
    requestUserVerification(user_id: $user_id)
  }
`;
export type RequestUserVerificationMutationFn = ReactApollo.MutationFn<
  RequestUserVerificationMutation,
  RequestUserVerificationMutationVariables
>;
export type RequestUserVerificationProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<RequestUserVerificationMutation, RequestUserVerificationMutationVariables>
> &
  TChildProps;
export function withRequestUserVerification<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    RequestUserVerificationMutation,
    RequestUserVerificationMutationVariables,
    RequestUserVerificationProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    RequestUserVerificationMutation,
    RequestUserVerificationMutationVariables,
    RequestUserVerificationProps<TChildProps>
  >(RequestUserVerificationDocument, {
    alias: 'withRequestUserVerification',
    ...operationOptions,
  });
}
export const ResetFanControllerDocument = gql`
  mutation ResetFanController($fan_controller_id: Int!) {
    resetFanController(fan_controller_id: $fan_controller_id) {
      ...FanControllerRequestFragment
    }
  }
  ${FanControllerRequestFragmentFragmentDoc}
`;
export type ResetFanControllerMutationFn = ReactApollo.MutationFn<
  ResetFanControllerMutation,
  ResetFanControllerMutationVariables
>;
export type ResetFanControllerProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<ResetFanControllerMutation, ResetFanControllerMutationVariables>
> &
  TChildProps;
export function withResetFanController<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ResetFanControllerMutation,
    ResetFanControllerMutationVariables,
    ResetFanControllerProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ResetFanControllerMutation,
    ResetFanControllerMutationVariables,
    ResetFanControllerProps<TChildProps>
  >(ResetFanControllerDocument, {
    alias: 'withResetFanController',
    ...operationOptions,
  });
}
export const ResetPasswordDocument = gql`
  mutation ResetPassword($email_address: String!) {
    requestPasswordReset(email_address: $email_address)
  }
`;
export type ResetPasswordMutationFn = ReactApollo.MutationFn<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export type ResetPasswordProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<ResetPasswordMutation, ResetPasswordMutationVariables>
> &
  TChildProps;
export function withResetPassword<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ResetPasswordMutation,
    ResetPasswordMutationVariables,
    ResetPasswordProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ResetPasswordMutation,
    ResetPasswordMutationVariables,
    ResetPasswordProps<TChildProps>
  >(ResetPasswordDocument, {
    alias: 'withResetPassword',
    ...operationOptions,
  });
}
export const SaveBinEstimateTargetValuesDocument = gql`
  mutation SaveBinEstimateTargetValues(
    $grain_bin_id: Int!
    $target_temperature_f: Float!
    $target_mcwb_percent: Float!
  ) {
    saveBinEstimateTargetValues(
      grain_bin_id: $grain_bin_id
      target_temperature_f: $target_temperature_f
      target_mcwb_percent: $target_mcwb_percent
    ) {
      grain_bin_id
      target_temperature_f
      target_mcwb_percent
      last_breaking_change_date
      latest_result_requested_at_date
    }
  }
`;
export type SaveBinEstimateTargetValuesMutationFn = ReactApollo.MutationFn<
  SaveBinEstimateTargetValuesMutation,
  SaveBinEstimateTargetValuesMutationVariables
>;
export type SaveBinEstimateTargetValuesProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    SaveBinEstimateTargetValuesMutation,
    SaveBinEstimateTargetValuesMutationVariables
  >
> &
  TChildProps;
export function withSaveBinEstimateTargetValues<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SaveBinEstimateTargetValuesMutation,
    SaveBinEstimateTargetValuesMutationVariables,
    SaveBinEstimateTargetValuesProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    SaveBinEstimateTargetValuesMutation,
    SaveBinEstimateTargetValuesMutationVariables,
    SaveBinEstimateTargetValuesProps<TChildProps>
  >(SaveBinEstimateTargetValuesDocument, {
    alias: 'withSaveBinEstimateTargetValues',
    ...operationOptions,
  });
}
export const SaveOperationsViewRowVisibilityPrefsDocument = gql`
  mutation SaveOperationsViewRowVisibilityPrefs($account_id: Int!, $pref_json: String!) {
    saveOperationsViewRowVisibilityPrefs(account_id: $account_id, pref_json: $pref_json) {
      ...OpsViewRowVisibilityPrefsFragment
    }
  }
  ${OpsViewRowVisibilityPrefsFragmentFragmentDoc}
`;
export type SaveOperationsViewRowVisibilityPrefsMutationFn = ReactApollo.MutationFn<
  SaveOperationsViewRowVisibilityPrefsMutation,
  SaveOperationsViewRowVisibilityPrefsMutationVariables
>;
export type SaveOperationsViewRowVisibilityPrefsProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    SaveOperationsViewRowVisibilityPrefsMutation,
    SaveOperationsViewRowVisibilityPrefsMutationVariables
  >
> &
  TChildProps;
export function withSaveOperationsViewRowVisibilityPrefs<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SaveOperationsViewRowVisibilityPrefsMutation,
    SaveOperationsViewRowVisibilityPrefsMutationVariables,
    SaveOperationsViewRowVisibilityPrefsProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    SaveOperationsViewRowVisibilityPrefsMutation,
    SaveOperationsViewRowVisibilityPrefsMutationVariables,
    SaveOperationsViewRowVisibilityPrefsProps<TChildProps>
  >(SaveOperationsViewRowVisibilityPrefsDocument, {
    alias: 'withSaveOperationsViewRowVisibilityPrefs',
    ...operationOptions,
  });
}
export const SaveOperationsViewUserLevelPrefsDocument = gql`
  mutation SaveOperationsViewUserLevelPrefs(
    $user_id: Int!
    $account_id: Int!
    $pref_type_key: Int!
    $pref_json: String!
  ) {
    saveOperationsViewUserLevelPrefs(
      user_id: $user_id
      account_id: $account_id
      pref_type_key: $pref_type_key
      pref_json: $pref_json
    ) {
      ...OpsViewVisibilityPrefsFragment
    }
  }
  ${OpsViewVisibilityPrefsFragmentFragmentDoc}
`;
export type SaveOperationsViewUserLevelPrefsMutationFn = ReactApollo.MutationFn<
  SaveOperationsViewUserLevelPrefsMutation,
  SaveOperationsViewUserLevelPrefsMutationVariables
>;
export type SaveOperationsViewUserLevelPrefsProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    SaveOperationsViewUserLevelPrefsMutation,
    SaveOperationsViewUserLevelPrefsMutationVariables
  >
> &
  TChildProps;
export function withSaveOperationsViewUserLevelPrefs<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SaveOperationsViewUserLevelPrefsMutation,
    SaveOperationsViewUserLevelPrefsMutationVariables,
    SaveOperationsViewUserLevelPrefsProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    SaveOperationsViewUserLevelPrefsMutation,
    SaveOperationsViewUserLevelPrefsMutationVariables,
    SaveOperationsViewUserLevelPrefsProps<TChildProps>
  >(SaveOperationsViewUserLevelPrefsDocument, {
    alias: 'withSaveOperationsViewUserLevelPrefs',
    ...operationOptions,
  });
}
export const SavePelletGroupPelletsDocument = gql`
  mutation SavePelletGroupPellets(
    $pellet_group_id: Int!
    $pellet_links: [PelletGroupPelletLinkInput!]!
  ) {
    savePelletGroupPellets(pellet_group_id: $pellet_group_id, pellet_links: $pellet_links) {
      ...PelletGroupFragment
    }
  }
  ${PelletGroupFragmentFragmentDoc}
`;
export type SavePelletGroupPelletsMutationFn = ReactApollo.MutationFn<
  SavePelletGroupPelletsMutation,
  SavePelletGroupPelletsMutationVariables
>;
export type SavePelletGroupPelletsProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<SavePelletGroupPelletsMutation, SavePelletGroupPelletsMutationVariables>
> &
  TChildProps;
export function withSavePelletGroupPellets<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SavePelletGroupPelletsMutation,
    SavePelletGroupPelletsMutationVariables,
    SavePelletGroupPelletsProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    SavePelletGroupPelletsMutation,
    SavePelletGroupPelletsMutationVariables,
    SavePelletGroupPelletsProps<TChildProps>
  >(SavePelletGroupPelletsDocument, {
    alias: 'withSavePelletGroupPellets',
    ...operationOptions,
  });
}
export const SaveTemporaryInputConditionsDocument = gql`
  mutation saveTemporaryInputConditions(
    $grain_bin_id: Int!
    $current_grain_temp: Float!
    $current_grain_emc: Float!
    $target_grain_emc: Float
    $recommendation_type: String!
  ) {
    saveTemporaryInputConditions(
      grain_bin_id: $grain_bin_id
      current_grain_temp: $current_grain_temp
      current_grain_emc: $current_grain_emc
      target_grain_emc: $target_grain_emc
      recommendation_type: $recommendation_type
    ) {
      ...GrainTemporaryInputConditionsFragment
    }
  }
  ${GrainTemporaryInputConditionsFragmentFragmentDoc}
`;
export type SaveTemporaryInputConditionsMutationFn = ReactApollo.MutationFn<
  SaveTemporaryInputConditionsMutation,
  SaveTemporaryInputConditionsMutationVariables
>;
export type SaveTemporaryInputConditionsProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    SaveTemporaryInputConditionsMutation,
    SaveTemporaryInputConditionsMutationVariables
  >
> &
  TChildProps;
export function withSaveTemporaryInputConditions<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SaveTemporaryInputConditionsMutation,
    SaveTemporaryInputConditionsMutationVariables,
    SaveTemporaryInputConditionsProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    SaveTemporaryInputConditionsMutation,
    SaveTemporaryInputConditionsMutationVariables,
    SaveTemporaryInputConditionsProps<TChildProps>
  >(SaveTemporaryInputConditionsDocument, {
    alias: 'withSaveTemporaryInputConditions',
    ...operationOptions,
  });
}
export const SetFanControllerStateDocument = gql`
  mutation SetFanControllerState(
    $core_id: String!
    $enabled: Boolean
    $run_window: RunWindowInput
    $vibration_threshold: Float
    $safety_interval: Int
    $config_reason: Int!
    $clear_run_window: Boolean
    $error_code: FanHeartbeatErrorCode
  ) {
    setFanControllerState(
      core_id: $core_id
      enabled: $enabled
      run_window: $run_window
      vibration_threshold: $vibration_threshold
      safety_interval: $safety_interval
      config_reason: $config_reason
      clear_run_window: $clear_run_window
      error_code: $error_code
    ) {
      ...FanControllerStateFragment
    }
  }
  ${FanControllerStateFragmentFragmentDoc}
`;
export type SetFanControllerStateMutationFn = ReactApollo.MutationFn<
  SetFanControllerStateMutation,
  SetFanControllerStateMutationVariables
>;
export type SetFanControllerStateProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<SetFanControllerStateMutation, SetFanControllerStateMutationVariables>
> &
  TChildProps;
export function withSetFanControllerState<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SetFanControllerStateMutation,
    SetFanControllerStateMutationVariables,
    SetFanControllerStateProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    SetFanControllerStateMutation,
    SetFanControllerStateMutationVariables,
    SetFanControllerStateProps<TChildProps>
  >(SetFanControllerStateDocument, {
    alias: 'withSetFanControllerState',
    ...operationOptions,
  });
}
export const SetFanRunNowScheduleDocument = gql`
  mutation SetFanRunNowSchedule(
    $user_id: Int!
    $account_id: Int!
    $container_id: Int!
    $container_type: ContainerType!
    $period: String!
  ) {
    setFanRunNowSchedule(
      user_id: $user_id
      account_id: $account_id
      container_id: $container_id
      container_type: $container_type
      period: $period
    ) {
      ...GrainBinGrainContainerFragment
    }
  }
  ${GrainBinGrainContainerFragmentFragmentDoc}
`;
export type SetFanRunNowScheduleMutationFn = ReactApollo.MutationFn<
  SetFanRunNowScheduleMutation,
  SetFanRunNowScheduleMutationVariables
>;
export type SetFanRunNowScheduleProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<SetFanRunNowScheduleMutation, SetFanRunNowScheduleMutationVariables>
> &
  TChildProps;
export function withSetFanRunNowSchedule<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SetFanRunNowScheduleMutation,
    SetFanRunNowScheduleMutationVariables,
    SetFanRunNowScheduleProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    SetFanRunNowScheduleMutation,
    SetFanRunNowScheduleMutationVariables,
    SetFanRunNowScheduleProps<TChildProps>
  >(SetFanRunNowScheduleDocument, {
    alias: 'withSetFanRunNowSchedule',
    ...operationOptions,
  });
}
export const SetGrainBinFanSettingsDocument = gql`
  mutation SetGrainBinFanSettings(
    $grain_bin_id: Int!
    $safety_weather: Boolean
    $run_window: RunWindowInput
    $clear_run_window: Boolean
  ) {
    setGrainBinFanSettings(
      grain_bin_id: $grain_bin_id
      safety_weather: $safety_weather
      run_window: $run_window
      clear_run_window: $clear_run_window
    ) {
      ...FanSettingsFragment
    }
  }
  ${FanSettingsFragmentFragmentDoc}
`;
export type SetGrainBinFanSettingsMutationFn = ReactApollo.MutationFn<
  SetGrainBinFanSettingsMutation,
  SetGrainBinFanSettingsMutationVariables
>;
export type SetGrainBinFanSettingsProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<SetGrainBinFanSettingsMutation, SetGrainBinFanSettingsMutationVariables>
> &
  TChildProps;
export function withSetGrainBinFanSettings<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SetGrainBinFanSettingsMutation,
    SetGrainBinFanSettingsMutationVariables,
    SetGrainBinFanSettingsProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    SetGrainBinFanSettingsMutation,
    SetGrainBinFanSettingsMutationVariables,
    SetGrainBinFanSettingsProps<TChildProps>
  >(SetGrainBinFanSettingsDocument, {
    alias: 'withSetGrainBinFanSettings',
    ...operationOptions,
  });
}
export const SetGrainContainerAerationScheduleDocument = gql`
  mutation SetGrainContainerAerationSchedule(
    $container_id: Int!
    $container_type: ContainerType!
    $run_windows: [RunWindowInput!]!
    $start_recommendation_type: FanRunWindowRecommendedOption!
    $end_recommendation_type: FanRunWindowRecommendedOption!
  ) {
    setGrainContainerAerationSchedule(
      container_id: $container_id
      container_type: $container_type
      run_windows: $run_windows
      start_recommendation_type: $start_recommendation_type
      end_recommendation_type: $end_recommendation_type
    ) {
      ...GrainBinGrainContainerFragment
    }
  }
  ${GrainBinGrainContainerFragmentFragmentDoc}
`;
export type SetGrainContainerAerationScheduleMutationFn = ReactApollo.MutationFn<
  SetGrainContainerAerationScheduleMutation,
  SetGrainContainerAerationScheduleMutationVariables
>;
export type SetGrainContainerAerationScheduleProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    SetGrainContainerAerationScheduleMutation,
    SetGrainContainerAerationScheduleMutationVariables
  >
> &
  TChildProps;
export function withSetGrainContainerAerationSchedule<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SetGrainContainerAerationScheduleMutation,
    SetGrainContainerAerationScheduleMutationVariables,
    SetGrainContainerAerationScheduleProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    SetGrainContainerAerationScheduleMutation,
    SetGrainContainerAerationScheduleMutationVariables,
    SetGrainContainerAerationScheduleProps<TChildProps>
  >(SetGrainContainerAerationScheduleDocument, {
    alias: 'withSetGrainContainerAerationSchedule',
    ...operationOptions,
  });
}
export const SetNotificationsReadOnDocument = gql`
  mutation SetNotificationsReadOn($last_notifications_read_on: Date!) {
    setNotificationsReadOn(last_notifications_read_on: $last_notifications_read_on) {
      user_id
      account_id
      last_notifications_read_on
    }
  }
`;
export type SetNotificationsReadOnMutationFn = ReactApollo.MutationFn<
  SetNotificationsReadOnMutation,
  SetNotificationsReadOnMutationVariables
>;
export type SetNotificationsReadOnProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<SetNotificationsReadOnMutation, SetNotificationsReadOnMutationVariables>
> &
  TChildProps;
export function withSetNotificationsReadOn<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SetNotificationsReadOnMutation,
    SetNotificationsReadOnMutationVariables,
    SetNotificationsReadOnProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    SetNotificationsReadOnMutation,
    SetNotificationsReadOnMutationVariables,
    SetNotificationsReadOnProps<TChildProps>
  >(SetNotificationsReadOnDocument, {
    alias: 'withSetNotificationsReadOn',
    ...operationOptions,
  });
}
export const SetUserPasswordDocument = gql`
  mutation SetUserPassword($token: String!, $password: String!) {
    setUserPassword(token: $token, password: $password) {
      ...ViewerFragment
    }
  }
  ${ViewerFragmentFragmentDoc}
`;
export type SetUserPasswordMutationFn = ReactApollo.MutationFn<
  SetUserPasswordMutation,
  SetUserPasswordMutationVariables
>;
export type SetUserPasswordProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<SetUserPasswordMutation, SetUserPasswordMutationVariables>
> &
  TChildProps;
export function withSetUserPassword<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SetUserPasswordMutation,
    SetUserPasswordMutationVariables,
    SetUserPasswordProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    SetUserPasswordMutation,
    SetUserPasswordMutationVariables,
    SetUserPasswordProps<TChildProps>
  >(SetUserPasswordDocument, {
    alias: 'withSetUserPassword',
    ...operationOptions,
  });
}
export const SetUserUnitPrefDocument = gql`
  mutation SetUserUnitPref(
    $user_id: Int!
    $account_id: Int!
    $temp_unit: TempUnitType!
    $distance_unit: DistanceUnitType!
    $mass_unit: MassUnitType!
    $test_weight_unit: TestWeightUnitType!
  ) {
    setUserUnitsPref(
      user_id: $user_id
      account_id: $account_id
      temp_unit: $temp_unit
      distance_unit: $distance_unit
      mass_unit: $mass_unit
      test_weight_unit: $test_weight_unit
    ) {
      ...UserUnitsPrefFragment
    }
  }
  ${UserUnitsPrefFragmentFragmentDoc}
`;
export type SetUserUnitPrefMutationFn = ReactApollo.MutationFn<
  SetUserUnitPrefMutation,
  SetUserUnitPrefMutationVariables
>;
export type SetUserUnitPrefProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<SetUserUnitPrefMutation, SetUserUnitPrefMutationVariables>
> &
  TChildProps;
export function withSetUserUnitPref<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SetUserUnitPrefMutation,
    SetUserUnitPrefMutationVariables,
    SetUserUnitPrefProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    SetUserUnitPrefMutation,
    SetUserUnitPrefMutationVariables,
    SetUserUnitPrefProps<TChildProps>
  >(SetUserUnitPrefDocument, {
    alias: 'withSetUserUnitPref',
    ...operationOptions,
  });
}
export const StopGrainContainerAerationDocument = gql`
  mutation StopGrainContainerAeration($container_id: Int!, $container_type: ContainerType!) {
    stopGrainContainerAeration(container_id: $container_id, container_type: $container_type) {
      ...GrainBinGrainContainerFragment
    }
  }
  ${GrainBinGrainContainerFragmentFragmentDoc}
`;
export type StopGrainContainerAerationMutationFn = ReactApollo.MutationFn<
  StopGrainContainerAerationMutation,
  StopGrainContainerAerationMutationVariables
>;
export type StopGrainContainerAerationProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    StopGrainContainerAerationMutation,
    StopGrainContainerAerationMutationVariables
  >
> &
  TChildProps;
export function withStopGrainContainerAeration<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    StopGrainContainerAerationMutation,
    StopGrainContainerAerationMutationVariables,
    StopGrainContainerAerationProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    StopGrainContainerAerationMutation,
    StopGrainContainerAerationMutationVariables,
    StopGrainContainerAerationProps<TChildProps>
  >(StopGrainContainerAerationDocument, {
    alias: 'withStopGrainContainerAeration',
    ...operationOptions,
  });
}
export const SubscribeWeeklyGrainEmailDocument = gql`
  mutation SubscribeWeeklyGrainEmail(
    $account_id: Int!
    $user_id: Int!
    $schedule_day: String!
    $schedule_hour: Int!
    $schedule_timezone: String!
  ) {
    subscribeWeeklyGrainEmail(
      account_id: $account_id
      user_id: $user_id
      schedule_day: $schedule_day
      schedule_hour: $schedule_hour
      schedule_timezone: $schedule_timezone
    ) {
      ...UserWeeklyGrainEmailScheduleFragment
    }
  }
  ${UserWeeklyGrainEmailScheduleFragmentFragmentDoc}
`;
export type SubscribeWeeklyGrainEmailMutationFn = ReactApollo.MutationFn<
  SubscribeWeeklyGrainEmailMutation,
  SubscribeWeeklyGrainEmailMutationVariables
>;
export type SubscribeWeeklyGrainEmailProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    SubscribeWeeklyGrainEmailMutation,
    SubscribeWeeklyGrainEmailMutationVariables
  >
> &
  TChildProps;
export function withSubscribeWeeklyGrainEmail<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SubscribeWeeklyGrainEmailMutation,
    SubscribeWeeklyGrainEmailMutationVariables,
    SubscribeWeeklyGrainEmailProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    SubscribeWeeklyGrainEmailMutation,
    SubscribeWeeklyGrainEmailMutationVariables,
    SubscribeWeeklyGrainEmailProps<TChildProps>
  >(SubscribeWeeklyGrainEmailDocument, {
    alias: 'withSubscribeWeeklyGrainEmail',
    ...operationOptions,
  });
}
export const UnassignFanControllerDocument = gql`
  mutation UnassignFanController($grain_bin_fan_controller_id: Int!) {
    unassignFanController(grain_bin_fan_controller_id: $grain_bin_fan_controller_id) {
      ...FanSettingsFragment
    }
  }
  ${FanSettingsFragmentFragmentDoc}
`;
export type UnassignFanControllerMutationFn = ReactApollo.MutationFn<
  UnassignFanControllerMutation,
  UnassignFanControllerMutationVariables
>;
export type UnassignFanControllerProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UnassignFanControllerMutation, UnassignFanControllerMutationVariables>
> &
  TChildProps;
export function withUnassignFanController<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UnassignFanControllerMutation,
    UnassignFanControllerMutationVariables,
    UnassignFanControllerProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UnassignFanControllerMutation,
    UnassignFanControllerMutationVariables,
    UnassignFanControllerProps<TChildProps>
  >(UnassignFanControllerDocument, {
    alias: 'withUnassignFanController',
    ...operationOptions,
  });
}
export const UnlinkBargeCoverPelletDocument = gql`
  mutation UnlinkBargeCoverPellet($barge_cover_pellet_link_id: Int!) {
    unlinkBargeCoverPellet(barge_cover_pellet_link_id: $barge_cover_pellet_link_id) {
      ...BargeCoverPelletLinkFragment
    }
  }
  ${BargeCoverPelletLinkFragmentFragmentDoc}
`;
export type UnlinkBargeCoverPelletMutationFn = ReactApollo.MutationFn<
  UnlinkBargeCoverPelletMutation,
  UnlinkBargeCoverPelletMutationVariables
>;
export type UnlinkBargeCoverPelletProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UnlinkBargeCoverPelletMutation, UnlinkBargeCoverPelletMutationVariables>
> &
  TChildProps;
export function withUnlinkBargeCoverPellet<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UnlinkBargeCoverPelletMutation,
    UnlinkBargeCoverPelletMutationVariables,
    UnlinkBargeCoverPelletProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UnlinkBargeCoverPelletMutation,
    UnlinkBargeCoverPelletMutationVariables,
    UnlinkBargeCoverPelletProps<TChildProps>
  >(UnlinkBargeCoverPelletDocument, {
    alias: 'withUnlinkBargeCoverPellet',
    ...operationOptions,
  });
}
export const UnlinkPileCableDocument = gql`
  mutation UnlinkPileCable($pile_cable_link_id: Int!) {
    unlinkPileCable(pile_cable_link_id: $pile_cable_link_id) {
      ...PileCableLinkFragment
    }
  }
  ${PileCableLinkFragmentFragmentDoc}
`;
export type UnlinkPileCableMutationFn = ReactApollo.MutationFn<
  UnlinkPileCableMutation,
  UnlinkPileCableMutationVariables
>;
export type UnlinkPileCableProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UnlinkPileCableMutation, UnlinkPileCableMutationVariables>
> &
  TChildProps;
export function withUnlinkPileCable<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UnlinkPileCableMutation,
    UnlinkPileCableMutationVariables,
    UnlinkPileCableProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UnlinkPileCableMutation,
    UnlinkPileCableMutationVariables,
    UnlinkPileCableProps<TChildProps>
  >(UnlinkPileCableDocument, {
    alias: 'withUnlinkPileCable',
    ...operationOptions,
  });
}
export const UnlinkPileCablePelletDocument = gql`
  mutation UnlinkPileCablePellet($pile_cable_pellet_link_id: Int!) {
    unlinkPileCablePellet(pile_cable_pellet_link_id: $pile_cable_pellet_link_id) {
      ...PileCablePelletLinkFragment
    }
  }
  ${PileCablePelletLinkFragmentFragmentDoc}
`;
export type UnlinkPileCablePelletMutationFn = ReactApollo.MutationFn<
  UnlinkPileCablePelletMutation,
  UnlinkPileCablePelletMutationVariables
>;
export type UnlinkPileCablePelletProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UnlinkPileCablePelletMutation, UnlinkPileCablePelletMutationVariables>
> &
  TChildProps;
export function withUnlinkPileCablePellet<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UnlinkPileCablePelletMutation,
    UnlinkPileCablePelletMutationVariables,
    UnlinkPileCablePelletProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UnlinkPileCablePelletMutation,
    UnlinkPileCablePelletMutationVariables,
    UnlinkPileCablePelletProps<TChildProps>
  >(UnlinkPileCablePelletDocument, {
    alias: 'withUnlinkPileCablePellet',
    ...operationOptions,
  });
}
export const UnsubscribeWeeklyGrainEmailDocument = gql`
  mutation UnsubscribeWeeklyGrainEmail($user_id: Int!) {
    unsubscribeWeeklyGrainEmail(user_id: $user_id) {
      ...UserWeeklyGrainEmailScheduleFragment
    }
  }
  ${UserWeeklyGrainEmailScheduleFragmentFragmentDoc}
`;
export type UnsubscribeWeeklyGrainEmailMutationFn = ReactApollo.MutationFn<
  UnsubscribeWeeklyGrainEmailMutation,
  UnsubscribeWeeklyGrainEmailMutationVariables
>;
export type UnsubscribeWeeklyGrainEmailProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    UnsubscribeWeeklyGrainEmailMutation,
    UnsubscribeWeeklyGrainEmailMutationVariables
  >
> &
  TChildProps;
export function withUnsubscribeWeeklyGrainEmail<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UnsubscribeWeeklyGrainEmailMutation,
    UnsubscribeWeeklyGrainEmailMutationVariables,
    UnsubscribeWeeklyGrainEmailProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UnsubscribeWeeklyGrainEmailMutation,
    UnsubscribeWeeklyGrainEmailMutationVariables,
    UnsubscribeWeeklyGrainEmailProps<TChildProps>
  >(UnsubscribeWeeklyGrainEmailDocument, {
    alias: 'withUnsubscribeWeeklyGrainEmail',
    ...operationOptions,
  });
}
export const UpdateAccountDocument = gql`
  mutation UpdateAccount(
    $account_id: Int!
    $organization: String!
    $barge_support: Boolean!
    $grain_bin_support: Boolean!
    $pile_support: Boolean!
  ) {
    updateAccount(
      account_id: $account_id
      organization: $organization
      barge_support: $barge_support
      grain_bin_support: $grain_bin_support
      pile_support: $pile_support
    ) {
      ...AccountFragment
    }
  }
  ${AccountFragmentFragmentDoc}
`;
export type UpdateAccountMutationFn = ReactApollo.MutationFn<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
export type UpdateAccountProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UpdateAccountMutation, UpdateAccountMutationVariables>
> &
  TChildProps;
export function withUpdateAccount<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateAccountMutation,
    UpdateAccountMutationVariables,
    UpdateAccountProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateAccountMutation,
    UpdateAccountMutationVariables,
    UpdateAccountProps<TChildProps>
  >(UpdateAccountDocument, {
    alias: 'withUpdateAccount',
    ...operationOptions,
  });
}
export const UpdateBargeDocument = gql`
  mutation UpdateBarge(
    $barge_id: Int!
    $alias: String
    $default_grain_type: GrainType
    $height_ft: Float!
    $length_ft: Float!
    $width_ft: Float!
  ) {
    updateBarge(
      barge_id: $barge_id
      alias: $alias
      default_grain_type: $default_grain_type
      height_ft: $height_ft
      length_ft: $length_ft
      width_ft: $width_ft
    ) {
      ...BargeFragment
    }
  }
  ${BargeFragmentFragmentDoc}
`;
export type UpdateBargeMutationFn = ReactApollo.MutationFn<
  UpdateBargeMutation,
  UpdateBargeMutationVariables
>;
export type UpdateBargeProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UpdateBargeMutation, UpdateBargeMutationVariables>
> &
  TChildProps;
export function withUpdateBarge<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateBargeMutation,
    UpdateBargeMutationVariables,
    UpdateBargeProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateBargeMutation,
    UpdateBargeMutationVariables,
    UpdateBargeProps<TChildProps>
  >(UpdateBargeDocument, {
    alias: 'withUpdateBarge',
    ...operationOptions,
  });
}
export const UpdateBinStoragePeriodDocument = gql`
  mutation UpdateBinStoragePeriod(
    $grain_bin_id: Int!
    $grain_bin_storage_cycle_id: Int!
    $grain_type: GrainType
    $storage_cycle_name: String
    $start_epoch: Date
    $end_epoch: Date
  ) {
    updateBinStoragePeriod(
      grain_bin_id: $grain_bin_id
      grain_bin_storage_cycle_id: $grain_bin_storage_cycle_id
      grain_type: $grain_type
      storage_cycle_name: $storage_cycle_name
      start_epoch: $start_epoch
      end_epoch: $end_epoch
    ) {
      grain_bin_storage_cycle_id
      grain_bin_id
      storage_cycle_name
      grain_type
      start_epoch
      end_epoch
      created_at
    }
  }
`;
export type UpdateBinStoragePeriodMutationFn = ReactApollo.MutationFn<
  UpdateBinStoragePeriodMutation,
  UpdateBinStoragePeriodMutationVariables
>;
export type UpdateBinStoragePeriodProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UpdateBinStoragePeriodMutation, UpdateBinStoragePeriodMutationVariables>
> &
  TChildProps;
export function withUpdateBinStoragePeriod<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateBinStoragePeriodMutation,
    UpdateBinStoragePeriodMutationVariables,
    UpdateBinStoragePeriodProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateBinStoragePeriodMutation,
    UpdateBinStoragePeriodMutationVariables,
    UpdateBinStoragePeriodProps<TChildProps>
  >(UpdateBinStoragePeriodDocument, {
    alias: 'withUpdateBinStoragePeriod',
    ...operationOptions,
  });
}
export const UpdateFanControllerDocument = gql`
  mutation UpdateFanController(
    $fan_controller_id: Int!
    $hardware_rev: String
    $diag_test_is_enable: Boolean
  ) {
    updateFanController(
      fan_controller_id: $fan_controller_id
      hardware_rev: $hardware_rev
      diag_test_is_enable: $diag_test_is_enable
    ) {
      ...FanControllerFragment
    }
  }
  ${FanControllerFragmentFragmentDoc}
`;
export type UpdateFanControllerMutationFn = ReactApollo.MutationFn<
  UpdateFanControllerMutation,
  UpdateFanControllerMutationVariables
>;
export type UpdateFanControllerProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UpdateFanControllerMutation, UpdateFanControllerMutationVariables>
> &
  TChildProps;
export function withUpdateFanController<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateFanControllerMutation,
    UpdateFanControllerMutationVariables,
    UpdateFanControllerProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateFanControllerMutation,
    UpdateFanControllerMutationVariables,
    UpdateFanControllerProps<TChildProps>
  >(UpdateFanControllerDocument, {
    alias: 'withUpdateFanController',
    ...operationOptions,
  });
}
export const UpdateGrainBinDocument = gql`
  mutation UpdateGrainBin(
    $grain_bin_id: Int!
    $bin_name: String
    $grain_type: GrainType
    $height_ft: Float
    $diameter_ft: Float
    $zip_code: String
    $location: LocationInput
    $has_grain_spreader: Boolean
    $current_grain_emc: Float
    $current_grain_temp: Float
    $target_grain_emc: Float
    $price_per_kwh: Float
    $recommendation_type: String
    $opt_in_fan_guidance: Boolean
    $enable_fan_guidance: Boolean
    $fan_guidance_start_date: Date
    $fan_guidance_end_date: Date
    $floor_type: FloorType
  ) {
    updateGrainBin(
      grain_bin_id: $grain_bin_id
      bin_name: $bin_name
      grain_type: $grain_type
      height_ft: $height_ft
      diameter_ft: $diameter_ft
      zip_code: $zip_code
      location: $location
      has_grain_spreader: $has_grain_spreader
      current_grain_emc: $current_grain_emc
      current_grain_temp: $current_grain_temp
      target_grain_emc: $target_grain_emc
      price_per_kwh: $price_per_kwh
      recommendation_type: $recommendation_type
      opt_in_fan_guidance: $opt_in_fan_guidance
      enable_fan_guidance: $enable_fan_guidance
      fan_guidance_start_date: $fan_guidance_start_date
      fan_guidance_end_date: $fan_guidance_end_date
      floor_type: $floor_type
    ) {
      ...GrainBinFragment
    }
  }
  ${GrainBinFragmentFragmentDoc}
`;
export type UpdateGrainBinMutationFn = ReactApollo.MutationFn<
  UpdateGrainBinMutation,
  UpdateGrainBinMutationVariables
>;
export type UpdateGrainBinProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UpdateGrainBinMutation, UpdateGrainBinMutationVariables>
> &
  TChildProps;
export function withUpdateGrainBin<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateGrainBinMutation,
    UpdateGrainBinMutationVariables,
    UpdateGrainBinProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateGrainBinMutation,
    UpdateGrainBinMutationVariables,
    UpdateGrainBinProps<TChildProps>
  >(UpdateGrainBinDocument, {
    alias: 'withUpdateGrainBin',
    ...operationOptions,
  });
}
export const UpdateGrainBinFanControllerDocument = gql`
  mutation UpdateGrainBinFanController(
    $grain_bin_fan_controller_id: Int!
    $has_plenum_sensor: Boolean
    $cfm: Float
    $static_pressure_in: Float
    $alias: String
    $phase: Int
    $power_factor: Float
    $voltage: Int
    $fan_model_id: Int
    $make: String!
    $fan_brand_name: String!
    $fan_model_type: FanType!
    $fan_horsepower: String!
    $diameter: Float!
    $fan_model_created_type: FanModelCreatedType!
    $linked_generic_fan_model_id: Int
    $no_of_fans_connected: Int!
  ) {
    updateGrainBinFanController(
      grain_bin_fan_controller_id: $grain_bin_fan_controller_id
      has_plenum_sensor: $has_plenum_sensor
      cfm: $cfm
      static_pressure_in: $static_pressure_in
      alias: $alias
      phase: $phase
      power_factor: $power_factor
      voltage: $voltage
      fan_model_id: $fan_model_id
      make: $make
      fan_brand_name: $fan_brand_name
      fan_model_type: $fan_model_type
      fan_horsepower: $fan_horsepower
      diameter: $diameter
      fan_model_created_type: $fan_model_created_type
      linked_generic_fan_model_id: $linked_generic_fan_model_id
      no_of_fans_connected: $no_of_fans_connected
    ) {
      ...GrainBinFanControllerFragment
    }
  }
  ${GrainBinFanControllerFragmentFragmentDoc}
`;
export type UpdateGrainBinFanControllerMutationFn = ReactApollo.MutationFn<
  UpdateGrainBinFanControllerMutation,
  UpdateGrainBinFanControllerMutationVariables
>;
export type UpdateGrainBinFanControllerProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    UpdateGrainBinFanControllerMutation,
    UpdateGrainBinFanControllerMutationVariables
  >
> &
  TChildProps;
export function withUpdateGrainBinFanController<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateGrainBinFanControllerMutation,
    UpdateGrainBinFanControllerMutationVariables,
    UpdateGrainBinFanControllerProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateGrainBinFanControllerMutation,
    UpdateGrainBinFanControllerMutationVariables,
    UpdateGrainBinFanControllerProps<TChildProps>
  >(UpdateGrainBinFanControllerDocument, {
    alias: 'withUpdateGrainBinFanController',
    ...operationOptions,
  });
}
export const UpdateGrainBinNotificationPrefDocument = gql`
  mutation UpdateGrainBinNotificationPref(
    $user_id: Int!
    $grain_bin_id: Int!
    $account_id: Int!
    $opt_in: Boolean!
  ) {
    updateGrainBinNotificationPref(
      user_id: $user_id
      grain_bin_id: $grain_bin_id
      account_id: $account_id
      opt_in: $opt_in
    ) {
      user_id
      grain_bin_id
      account_id
      opt_in
    }
  }
`;
export type UpdateGrainBinNotificationPrefMutationFn = ReactApollo.MutationFn<
  UpdateGrainBinNotificationPrefMutation,
  UpdateGrainBinNotificationPrefMutationVariables
>;
export type UpdateGrainBinNotificationPrefProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    UpdateGrainBinNotificationPrefMutation,
    UpdateGrainBinNotificationPrefMutationVariables
  >
> &
  TChildProps;
export function withUpdateGrainBinNotificationPref<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateGrainBinNotificationPrefMutation,
    UpdateGrainBinNotificationPrefMutationVariables,
    UpdateGrainBinNotificationPrefProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateGrainBinNotificationPrefMutation,
    UpdateGrainBinNotificationPrefMutationVariables,
    UpdateGrainBinNotificationPrefProps<TChildProps>
  >(UpdateGrainBinNotificationPrefDocument, {
    alias: 'withUpdateGrainBinNotificationPref',
    ...operationOptions,
  });
}
export const UpdateGrainBinSiteLinkDocument = gql`
  mutation UpdateGrainBinSiteLink($grain_bin_id: Int!, $site_id: Int!) {
    updateGrainBinSiteLink(grain_bin_id: $grain_bin_id, site_id: $site_id) {
      grain_bin_id
      site_id
      updated_at
    }
  }
`;
export type UpdateGrainBinSiteLinkMutationFn = ReactApollo.MutationFn<
  UpdateGrainBinSiteLinkMutation,
  UpdateGrainBinSiteLinkMutationVariables
>;
export type UpdateGrainBinSiteLinkProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UpdateGrainBinSiteLinkMutation, UpdateGrainBinSiteLinkMutationVariables>
> &
  TChildProps;
export function withUpdateGrainBinSiteLink<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateGrainBinSiteLinkMutation,
    UpdateGrainBinSiteLinkMutationVariables,
    UpdateGrainBinSiteLinkProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateGrainBinSiteLinkMutation,
    UpdateGrainBinSiteLinkMutationVariables,
    UpdateGrainBinSiteLinkProps<TChildProps>
  >(UpdateGrainBinSiteLinkDocument, {
    alias: 'withUpdateGrainBinSiteLink',
    ...operationOptions,
  });
}
export const UpdateGrainBinSpecificAlertPrefDocument = gql`
  mutation UpdateGrainBinSpecificAlertPref(
    $user_id: Int!
    $fan_start_stop_alerts: Boolean!
    $fan_error_alerts: Boolean!
    $disable_fan_guidance_alerts: Boolean!
    $grain_bin_co2_alerts: Boolean!
    $grain_level_alerts: Boolean!
  ) {
    updateSpecificAlertPref(
      user_id: $user_id
      fan_start_stop_alerts: $fan_start_stop_alerts
      fan_error_alerts: $fan_error_alerts
      disable_fan_guidance_alerts: $disable_fan_guidance_alerts
      grain_bin_co2_alerts: $grain_bin_co2_alerts
      grain_level_alerts: $grain_level_alerts
    ) {
      user_id
      fan_start_stop_alerts
      fan_error_alerts
      disable_fan_guidance_alerts
      grain_bin_co2_alerts
      grain_level_alerts
    }
  }
`;
export type UpdateGrainBinSpecificAlertPrefMutationFn = ReactApollo.MutationFn<
  UpdateGrainBinSpecificAlertPrefMutation,
  UpdateGrainBinSpecificAlertPrefMutationVariables
>;
export type UpdateGrainBinSpecificAlertPrefProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    UpdateGrainBinSpecificAlertPrefMutation,
    UpdateGrainBinSpecificAlertPrefMutationVariables
  >
> &
  TChildProps;
export function withUpdateGrainBinSpecificAlertPref<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateGrainBinSpecificAlertPrefMutation,
    UpdateGrainBinSpecificAlertPrefMutationVariables,
    UpdateGrainBinSpecificAlertPrefProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateGrainBinSpecificAlertPrefMutation,
    UpdateGrainBinSpecificAlertPrefMutationVariables,
    UpdateGrainBinSpecificAlertPrefProps<TChildProps>
  >(UpdateGrainBinSpecificAlertPrefDocument, {
    alias: 'withUpdateGrainBinSpecificAlertPref',
    ...operationOptions,
  });
}
export const UpdateGrainBinTicketDocument = gql`
  mutation UpdateGrainBinTicket(
    $grain_bin_id: Int!
    $grain_bin_ticket_id: Int!
    $epoch_time: Date!
    $volume: Float!
    $weight_in_lbs: Float!
    $test_weight_in_lbs: Float!
    $action_performed: GrainTicketActionType!
    $grain_moisture_pct: Float!
    $notes: String!
    $grain_surface_shape: GrainSurfaceShapeType!
    $grain_bin_storage_cycle_id: Int
  ) {
    updateGrainBinTicket(
      grain_bin_ticket_id: $grain_bin_ticket_id
      grain_bin_id: $grain_bin_id
      epoch_time: $epoch_time
      volume: $volume
      weight_in_lbs: $weight_in_lbs
      test_weight_in_lbs: $test_weight_in_lbs
      action_performed: $action_performed
      grain_moisture_pct: $grain_moisture_pct
      notes: $notes
      grain_surface_shape: $grain_surface_shape
      grain_bin_storage_cycle_id: $grain_bin_storage_cycle_id
    ) {
      ...GrainBinTicketRowFragment
    }
  }
  ${GrainBinTicketRowFragmentFragmentDoc}
`;
export type UpdateGrainBinTicketMutationFn = ReactApollo.MutationFn<
  UpdateGrainBinTicketMutation,
  UpdateGrainBinTicketMutationVariables
>;
export type UpdateGrainBinTicketProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UpdateGrainBinTicketMutation, UpdateGrainBinTicketMutationVariables>
> &
  TChildProps;
export function withUpdateGrainBinTicket<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateGrainBinTicketMutation,
    UpdateGrainBinTicketMutationVariables,
    UpdateGrainBinTicketProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateGrainBinTicketMutation,
    UpdateGrainBinTicketMutationVariables,
    UpdateGrainBinTicketProps<TChildProps>
  >(UpdateGrainBinTicketDocument, {
    alias: 'withUpdateGrainBinTicket',
    ...operationOptions,
  });
}
export const UpdateGrainBinWeeklyEmailPrefDocument = gql`
  mutation UpdateGrainBinWeeklyEmailPref(
    $user_id: Int!
    $account_id: Int!
    $grain_bin_id: Int!
    $opt_in: Boolean!
  ) {
    updateGrainBinWeeklyEmailPref(
      user_id: $user_id
      account_id: $account_id
      grain_bin_id: $grain_bin_id
      opt_in: $opt_in
    ) {
      ...GrainBinWeeklyEmailPrefFragment
    }
  }
  ${GrainBinWeeklyEmailPrefFragmentFragmentDoc}
`;
export type UpdateGrainBinWeeklyEmailPrefMutationFn = ReactApollo.MutationFn<
  UpdateGrainBinWeeklyEmailPrefMutation,
  UpdateGrainBinWeeklyEmailPrefMutationVariables
>;
export type UpdateGrainBinWeeklyEmailPrefProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    UpdateGrainBinWeeklyEmailPrefMutation,
    UpdateGrainBinWeeklyEmailPrefMutationVariables
  >
> &
  TChildProps;
export function withUpdateGrainBinWeeklyEmailPref<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateGrainBinWeeklyEmailPrefMutation,
    UpdateGrainBinWeeklyEmailPrefMutationVariables,
    UpdateGrainBinWeeklyEmailPrefProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateGrainBinWeeklyEmailPrefMutation,
    UpdateGrainBinWeeklyEmailPrefMutationVariables,
    UpdateGrainBinWeeklyEmailPrefProps<TChildProps>
  >(UpdateGrainBinWeeklyEmailPrefDocument, {
    alias: 'withUpdateGrainBinWeeklyEmailPref',
    ...operationOptions,
  });
}
export const UpdateHubAssignmentDocument = gql`
  mutation UpdateHubAssignment(
    $hub_container_link_id: Int!
    $start_epoch: Date
    $end_epoch: Date
    $nickname: String
    $hub_type: HubType
    $has_level_sensor: Boolean
    $hub_offset_ft: Float
  ) {
    updateHubAssignment(
      hub_container_link_id: $hub_container_link_id
      start_epoch: $start_epoch
      end_epoch: $end_epoch
      nickname: $nickname
      hub_type: $hub_type
      has_level_sensor: $has_level_sensor
      hub_offset_ft: $hub_offset_ft
    ) {
      ...HubContainerLinkBaseFragment
    }
  }
  ${HubContainerLinkBaseFragmentFragmentDoc}
`;
export type UpdateHubAssignmentMutationFn = ReactApollo.MutationFn<
  UpdateHubAssignmentMutation,
  UpdateHubAssignmentMutationVariables
>;
export type UpdateHubAssignmentProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UpdateHubAssignmentMutation, UpdateHubAssignmentMutationVariables>
> &
  TChildProps;
export function withUpdateHubAssignment<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateHubAssignmentMutation,
    UpdateHubAssignmentMutationVariables,
    UpdateHubAssignmentProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateHubAssignmentMutation,
    UpdateHubAssignmentMutationVariables,
    UpdateHubAssignmentProps<TChildProps>
  >(UpdateHubAssignmentDocument, {
    alias: 'withUpdateHubAssignment',
    ...operationOptions,
  });
}
export const UpdatePileDocument = gql`
  mutation UpdatePile(
    $pile_id: Int!
    $alias: String
    $default_grain_type: GrainType
    $orientation: Orientation!
    $shape: ShapeInput
  ) {
    updatePile(
      pile_id: $pile_id
      alias: $alias
      default_grain_type: $default_grain_type
      orientation: $orientation
      shape: $shape
    ) {
      ...PileFragment
    }
  }
  ${PileFragmentFragmentDoc}
`;
export type UpdatePileMutationFn = ReactApollo.MutationFn<
  UpdatePileMutation,
  UpdatePileMutationVariables
>;
export type UpdatePileProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UpdatePileMutation, UpdatePileMutationVariables>
> &
  TChildProps;
export function withUpdatePile<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdatePileMutation,
    UpdatePileMutationVariables,
    UpdatePileProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdatePileMutation,
    UpdatePileMutationVariables,
    UpdatePileProps<TChildProps>
  >(UpdatePileDocument, {
    alias: 'withUpdatePile',
    ...operationOptions,
  });
}
export const UpdateUserDocument = gql`
  mutation UpdateUser(
    $user_id: Int!
    $email_address: String
    $first_name: String
    $last_name: String
    $telephone: String
  ) {
    updateUser(
      user_id: $user_id
      first_name: $first_name
      last_name: $last_name
      telephone: $telephone
      email_address: $email_address
    ) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UpdateUserMutationFn = ReactApollo.MutationFn<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export type UpdateUserProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UpdateUserMutation, UpdateUserMutationVariables>
> &
  TChildProps;
export function withUpdateUser<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateUserMutation,
    UpdateUserMutationVariables,
    UpdateUserProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateUserMutation,
    UpdateUserMutationVariables,
    UpdateUserProps<TChildProps>
  >(UpdateUserDocument, {
    alias: 'withUpdateUser',
    ...operationOptions,
  });
}
export const UpdateUserPasswordDocument = gql`
  mutation UpdateUserPassword($user_id: Int!, $old_password: String!, $new_password: String!) {
    updateUserPassword(
      user_id: $user_id
      old_password: $old_password
      new_password: $new_password
    ) {
      user_id
    }
  }
`;
export type UpdateUserPasswordMutationFn = ReactApollo.MutationFn<
  UpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables
>;
export type UpdateUserPasswordProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>
> &
  TChildProps;
export function withUpdateUserPassword<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables,
    UpdateUserPasswordProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables,
    UpdateUserPasswordProps<TChildProps>
  >(UpdateUserPasswordDocument, {
    alias: 'withUpdateUserPassword',
    ...operationOptions,
  });
}
export const UpdateUserRoleDocument = gql`
  mutation UpdateUserRole($user_id: Int!, $role: UserRole!) {
    updateUser(user_id: $user_id, role: $role) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UpdateUserRoleMutationFn = ReactApollo.MutationFn<
  UpdateUserRoleMutation,
  UpdateUserRoleMutationVariables
>;
export type UpdateUserRoleProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>
> &
  TChildProps;
export function withUpdateUserRole<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateUserRoleMutation,
    UpdateUserRoleMutationVariables,
    UpdateUserRoleProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateUserRoleMutation,
    UpdateUserRoleMutationVariables,
    UpdateUserRoleProps<TChildProps>
  >(UpdateUserRoleDocument, {
    alias: 'withUpdateUserRole',
    ...operationOptions,
  });
}
export const UpdateUserSettingsDocument = gql`
  mutation UpdateUserSettings($user_id: Int!, $settings: UserSettingsInput!) {
    updateUser(user_id: $user_id, settings: $settings) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UpdateUserSettingsMutationFn = ReactApollo.MutationFn<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;
export type UpdateUserSettingsProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>
> &
  TChildProps;
export function withUpdateUserSettings<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables,
    UpdateUserSettingsProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables,
    UpdateUserSettingsProps<TChildProps>
  >(UpdateUserSettingsDocument, {
    alias: 'withUpdateUserSettings',
    ...operationOptions,
  });
}
export const UpdateUserVerificationDocument = gql`
  mutation UpdateUserVerification($token: String!) {
    updateUserVerification(token: $token) {
      ...ViewerFragment
    }
  }
  ${ViewerFragmentFragmentDoc}
`;
export type UpdateUserVerificationMutationFn = ReactApollo.MutationFn<
  UpdateUserVerificationMutation,
  UpdateUserVerificationMutationVariables
>;
export type UpdateUserVerificationProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UpdateUserVerificationMutation, UpdateUserVerificationMutationVariables>
> &
  TChildProps;
export function withUpdateUserVerification<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateUserVerificationMutation,
    UpdateUserVerificationMutationVariables,
    UpdateUserVerificationProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateUserVerificationMutation,
    UpdateUserVerificationMutationVariables,
    UpdateUserVerificationProps<TChildProps>
  >(UpdateUserVerificationDocument, {
    alias: 'withUpdateUserVerification',
    ...operationOptions,
  });
}
export const UpdateWeeklyGrainEmailScheduleDocument = gql`
  mutation UpdateWeeklyGrainEmailSchedule(
    $user_id: Int!
    $schedule_day: String!
    $schedule_hour: Int!
    $schedule_timezone: String!
  ) {
    updateWeeklyGrainEmailSchedule(
      user_id: $user_id
      schedule_day: $schedule_day
      schedule_hour: $schedule_hour
      schedule_timezone: $schedule_timezone
    ) {
      ...UserWeeklyGrainEmailScheduleFragment
    }
  }
  ${UserWeeklyGrainEmailScheduleFragmentFragmentDoc}
`;
export type UpdateWeeklyGrainEmailScheduleMutationFn = ReactApollo.MutationFn<
  UpdateWeeklyGrainEmailScheduleMutation,
  UpdateWeeklyGrainEmailScheduleMutationVariables
>;
export type UpdateWeeklyGrainEmailScheduleProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    UpdateWeeklyGrainEmailScheduleMutation,
    UpdateWeeklyGrainEmailScheduleMutationVariables
  >
> &
  TChildProps;
export function withUpdateWeeklyGrainEmailSchedule<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateWeeklyGrainEmailScheduleMutation,
    UpdateWeeklyGrainEmailScheduleMutationVariables,
    UpdateWeeklyGrainEmailScheduleProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateWeeklyGrainEmailScheduleMutation,
    UpdateWeeklyGrainEmailScheduleMutationVariables,
    UpdateWeeklyGrainEmailScheduleProps<TChildProps>
  >(UpdateWeeklyGrainEmailScheduleDocument, {
    alias: 'withUpdateWeeklyGrainEmailSchedule',
    ...operationOptions,
  });
}
export const UploadGrainFanPlotsDocument = gql`
  mutation UploadGrainFanPlots($grain_bin_fan_plots: [GrainFanPlotsInput]!) {
    uploadGrainFanPlots(grain_bin_fan_plots: $grain_bin_fan_plots) {
      ...GrainFanPlotsOutputFragment
      __typename
    }
  }
  ${GrainFanPlotsOutputFragmentFragmentDoc}
`;
export type UploadGrainFanPlotsMutationFn = ReactApollo.MutationFn<
  UploadGrainFanPlotsMutation,
  UploadGrainFanPlotsMutationVariables
>;
export type UploadGrainFanPlotsProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UploadGrainFanPlotsMutation, UploadGrainFanPlotsMutationVariables>
> &
  TChildProps;
export function withUploadGrainFanPlots<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UploadGrainFanPlotsMutation,
    UploadGrainFanPlotsMutationVariables,
    UploadGrainFanPlotsProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UploadGrainFanPlotsMutation,
    UploadGrainFanPlotsMutationVariables,
    UploadGrainFanPlotsProps<TChildProps>
  >(UploadGrainFanPlotsDocument, {
    alias: 'withUploadGrainFanPlots',
    ...operationOptions,
  });
}
export const ValidateStoragePeriodOverlapsDocument = gql`
  mutation ValidateStoragePeriodOverlaps(
    $grain_bin_id: Int!
    $storage_cycle_name: String!
    $grain_type: GrainType!
    $start_epoch: Date!
    $end_epoch: Date
    $grain_bin_storage_cycle_id: Int
  ) {
    validateStoragePeriodOverlaps(
      grain_bin_id: $grain_bin_id
      storage_cycle_name: $storage_cycle_name
      grain_type: $grain_type
      start_epoch: $start_epoch
      end_epoch: $end_epoch
      grain_bin_storage_cycle_id: $grain_bin_storage_cycle_id
    ) {
      ...GrainBinStoragePeriodValidationResultFragment
    }
  }
  ${GrainBinStoragePeriodValidationResultFragmentFragmentDoc}
`;
export type ValidateStoragePeriodOverlapsMutationFn = ReactApollo.MutationFn<
  ValidateStoragePeriodOverlapsMutation,
  ValidateStoragePeriodOverlapsMutationVariables
>;
export type ValidateStoragePeriodOverlapsProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ValidateStoragePeriodOverlapsMutation,
    ValidateStoragePeriodOverlapsMutationVariables
  >
> &
  TChildProps;
export function withValidateStoragePeriodOverlaps<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ValidateStoragePeriodOverlapsMutation,
    ValidateStoragePeriodOverlapsMutationVariables,
    ValidateStoragePeriodOverlapsProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ValidateStoragePeriodOverlapsMutation,
    ValidateStoragePeriodOverlapsMutationVariables,
    ValidateStoragePeriodOverlapsProps<TChildProps>
  >(ValidateStoragePeriodOverlapsDocument, {
    alias: 'withValidateStoragePeriodOverlaps',
    ...operationOptions,
  });
}
export const GetAccountDocument = gql`
  query GetAccount($account_id: Int!) {
    account(account_id: $account_id) {
      ...AccountFragment
    }
  }
  ${AccountFragmentFragmentDoc}
`;
export type GetAccountProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetAccountQuery, GetAccountQueryVariables>
> &
  TChildProps;
export function withGetAccount<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetAccountQuery,
    GetAccountQueryVariables,
    GetAccountProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetAccountQuery,
    GetAccountQueryVariables,
    GetAccountProps<TChildProps>
  >(GetAccountDocument, {
    alias: 'withGetAccount',
    ...operationOptions,
  });
}
export const GetAccountBargesDocument = gql`
  query GetAccountBarges($account_id: Int!) {
    account(account_id: $account_id) {
      account_id
      barge_links {
        barge_id
        barge {
          ...BargeFragment
        }
        container {
          ...GrainContainerHubLinksFragment
        }
      }
    }
  }
  ${BargeFragmentFragmentDoc}
  ${GrainContainerHubLinksFragmentFragmentDoc}
`;
export type GetAccountBargesProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetAccountBargesQuery, GetAccountBargesQueryVariables>
> &
  TChildProps;
export function withGetAccountBarges<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetAccountBargesQuery,
    GetAccountBargesQueryVariables,
    GetAccountBargesProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetAccountBargesQuery,
    GetAccountBargesQueryVariables,
    GetAccountBargesProps<TChildProps>
  >(GetAccountBargesDocument, {
    alias: 'withGetAccountBarges',
    ...operationOptions,
  });
}
export const GetAccountGrainBinsDocument = gql`
  query GetAccountGrainBins($account_id: Int!) {
    account(account_id: $account_id) {
      account_id
      grain_bin_links {
        ...GrainBinAccountLinkInternalFragment
      }
    }
  }
  ${GrainBinAccountLinkInternalFragmentFragmentDoc}
`;
export type GetAccountGrainBinsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetAccountGrainBinsQuery, GetAccountGrainBinsQueryVariables>
> &
  TChildProps;
export function withGetAccountGrainBins<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetAccountGrainBinsQuery,
    GetAccountGrainBinsQueryVariables,
    GetAccountGrainBinsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetAccountGrainBinsQuery,
    GetAccountGrainBinsQueryVariables,
    GetAccountGrainBinsProps<TChildProps>
  >(GetAccountGrainBinsDocument, {
    alias: 'withGetAccountGrainBins',
    ...operationOptions,
  });
}
export const GetAccountPilesDocument = gql`
  query GetAccountPiles($account_id: Int!) {
    account(account_id: $account_id) {
      account_id
      pile_links {
        ...PileAccountLinkInternalFragment
      }
    }
  }
  ${PileAccountLinkInternalFragmentFragmentDoc}
`;
export type GetAccountPilesProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetAccountPilesQuery, GetAccountPilesQueryVariables>
> &
  TChildProps;
export function withGetAccountPiles<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetAccountPilesQuery,
    GetAccountPilesQueryVariables,
    GetAccountPilesProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetAccountPilesQuery,
    GetAccountPilesQueryVariables,
    GetAccountPilesProps<TChildProps>
  >(GetAccountPilesDocument, {
    alias: 'withGetAccountPiles',
    ...operationOptions,
  });
}
export const GetAccountsDocument = gql`
  query GetAccounts {
    accounts {
      ...BaseAccountFragment
    }
  }
  ${BaseAccountFragmentFragmentDoc}
`;
export type GetAccountsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetAccountsQuery, GetAccountsQueryVariables>
> &
  TChildProps;
export function withGetAccounts<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetAccountsQuery,
    GetAccountsQueryVariables,
    GetAccountsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetAccountsQuery,
    GetAccountsQueryVariables,
    GetAccountsProps<TChildProps>
  >(GetAccountsDocument, {
    alias: 'withGetAccounts',
    ...operationOptions,
  });
}
export const GetAccountUsersDocument = gql`
  query GetAccountUsers($account_id: Int!) {
    account(account_id: $account_id) {
      account_id
      users {
        ...UserFragment
      }
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type GetAccountUsersProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetAccountUsersQuery, GetAccountUsersQueryVariables>
> &
  TChildProps;
export function withGetAccountUsers<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetAccountUsersQuery,
    GetAccountUsersQueryVariables,
    GetAccountUsersProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetAccountUsersQuery,
    GetAccountUsersQueryVariables,
    GetAccountUsersProps<TChildProps>
  >(GetAccountUsersDocument, {
    alias: 'withGetAccountUsers',
    ...operationOptions,
  });
}
export const GetActivePileCableLinksDocument = gql`
  query GetActivePileCableLinks($pile_id: Int!) {
    active_pile_cable_links(pile_id: $pile_id) {
      ...PileCableLinkFragment
    }
  }
  ${PileCableLinkFragmentFragmentDoc}
`;
export type GetActivePileCableLinksProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetActivePileCableLinksQuery, GetActivePileCableLinksQueryVariables>
> &
  TChildProps;
export function withGetActivePileCableLinks<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetActivePileCableLinksQuery,
    GetActivePileCableLinksQueryVariables,
    GetActivePileCableLinksProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetActivePileCableLinksQuery,
    GetActivePileCableLinksQueryVariables,
    GetActivePileCableLinksProps<TChildProps>
  >(GetActivePileCableLinksDocument, {
    alias: 'withGetActivePileCableLinks',
    ...operationOptions,
  });
}
export const GetAreFanControllersAssignedDocument = gql`
  query GetAreFanControllersAssigned($device_ids: [String!]!) {
    are_fan_controllers_assigned(device_ids: $device_ids) {
      device_id
      is_assigned
    }
  }
`;
export type GetAreFanControllersAssignedProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetAreFanControllersAssignedQuery,
    GetAreFanControllersAssignedQueryVariables
  >
> &
  TChildProps;
export function withGetAreFanControllersAssigned<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetAreFanControllersAssignedQuery,
    GetAreFanControllersAssignedQueryVariables,
    GetAreFanControllersAssignedProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetAreFanControllersAssignedQuery,
    GetAreFanControllersAssignedQueryVariables,
    GetAreFanControllersAssignedProps<TChildProps>
  >(GetAreFanControllersAssignedDocument, {
    alias: 'withGetAreFanControllersAssigned',
    ...operationOptions,
  });
}
export const GetAreHubsAssignedDocument = gql`
  query GetAreHubsAssigned($device_ids: [String!]!) {
    are_hubs_assigned(device_ids: $device_ids) {
      device_id
      is_assigned
    }
  }
`;
export type GetAreHubsAssignedProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetAreHubsAssignedQuery, GetAreHubsAssignedQueryVariables>
> &
  TChildProps;
export function withGetAreHubsAssigned<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetAreHubsAssignedQuery,
    GetAreHubsAssignedQueryVariables,
    GetAreHubsAssignedProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetAreHubsAssignedQuery,
    GetAreHubsAssignedQueryVariables,
    GetAreHubsAssignedProps<TChildProps>
  >(GetAreHubsAssignedDocument, {
    alias: 'withGetAreHubsAssigned',
    ...operationOptions,
  });
}
export const GetBargeDocument = gql`
  query GetBarge($barge_id: Int!) {
    barge(barge_id: $barge_id) {
      ...BargeFragment
    }
  }
  ${BargeFragmentFragmentDoc}
`;
export type GetBargeProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetBargeQuery, GetBargeQueryVariables>
> &
  TChildProps;
export function withGetBarge<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetBargeQuery,
    GetBargeQueryVariables,
    GetBargeProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetBargeQuery,
    GetBargeQueryVariables,
    GetBargeProps<TChildProps>
  >(GetBargeDocument, {
    alias: 'withGetBarge',
    ...operationOptions,
  });
}
export const GetBargeAccountDocument = gql`
  query GetBargeAccount($barge_id: Int!) {
    barge(barge_id: $barge_id) {
      barge_id
      account_link {
        barge_id
        account_id
      }
    }
  }
`;
export type GetBargeAccountProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetBargeAccountQuery, GetBargeAccountQueryVariables>
> &
  TChildProps;
export function withGetBargeAccount<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetBargeAccountQuery,
    GetBargeAccountQueryVariables,
    GetBargeAccountProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetBargeAccountQuery,
    GetBargeAccountQueryVariables,
    GetBargeAccountProps<TChildProps>
  >(GetBargeAccountDocument, {
    alias: 'withGetBargeAccount',
    ...operationOptions,
  });
}
export const GetBargeContainerDocument = gql`
  query GetBargeContainer($container_id: Int!, $container_type: Int!) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerHubLinksFragment
      ...GrainContainerHubLinksWithFCPlenumHubsFragment
      telemetry {
        ...BargeTelemetryFragment
      }
    }
  }
  ${GrainContainerHubLinksFragmentFragmentDoc}
  ${GrainContainerHubLinksWithFCPlenumHubsFragmentFragmentDoc}
  ${BargeTelemetryFragmentFragmentDoc}
`;
export type GetBargeContainerProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetBargeContainerQuery, GetBargeContainerQueryVariables>
> &
  TChildProps;
export function withGetBargeContainer<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetBargeContainerQuery,
    GetBargeContainerQueryVariables,
    GetBargeContainerProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetBargeContainerQuery,
    GetBargeContainerQueryVariables,
    GetBargeContainerProps<TChildProps>
  >(GetBargeContainerDocument, {
    alias: 'withGetBargeContainer',
    ...operationOptions,
  });
}
export const GetBargeCoverPelletLinksDocument = gql`
  query GetBargeCoverPelletLinks($barge_id: Int!) {
    barge_cover_pellet_links(barge_id: $barge_id) {
      ...BargeCoverPelletLinkFragment
    }
  }
  ${BargeCoverPelletLinkFragmentFragmentDoc}
`;
export type GetBargeCoverPelletLinksProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetBargeCoverPelletLinksQuery, GetBargeCoverPelletLinksQueryVariables>
> &
  TChildProps;
export function withGetBargeCoverPelletLinks<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetBargeCoverPelletLinksQuery,
    GetBargeCoverPelletLinksQueryVariables,
    GetBargeCoverPelletLinksProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetBargeCoverPelletLinksQuery,
    GetBargeCoverPelletLinksQueryVariables,
    GetBargeCoverPelletLinksProps<TChildProps>
  >(GetBargeCoverPelletLinksDocument, {
    alias: 'withGetBargeCoverPelletLinks',
    ...operationOptions,
  });
}
export const GetBaseAccountDocument = gql`
  query GetBaseAccount($account_id: Int!) {
    account(account_id: $account_id) {
      ...BaseAccountFragment
    }
  }
  ${BaseAccountFragmentFragmentDoc}
`;
export type GetBaseAccountProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetBaseAccountQuery, GetBaseAccountQueryVariables>
> &
  TChildProps;
export function withGetBaseAccount<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetBaseAccountQuery,
    GetBaseAccountQueryVariables,
    GetBaseAccountProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetBaseAccountQuery,
    GetBaseAccountQueryVariables,
    GetBaseAccountProps<TChildProps>
  >(GetBaseAccountDocument, {
    alias: 'withGetBaseAccount',
    ...operationOptions,
  });
}
export const GetBinEstimateProcessingStateDocument = gql`
  query GetBinEstimateProcessingState($grain_bin_id: Int!) {
    bin_estimate_processing_state(grain_bin_id: $grain_bin_id) {
      grain_bin_id
      processing
    }
  }
`;
export type GetBinEstimateProcessingStateProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetBinEstimateProcessingStateQuery,
    GetBinEstimateProcessingStateQueryVariables
  >
> &
  TChildProps;
export function withGetBinEstimateProcessingState<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetBinEstimateProcessingStateQuery,
    GetBinEstimateProcessingStateQueryVariables,
    GetBinEstimateProcessingStateProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetBinEstimateProcessingStateQuery,
    GetBinEstimateProcessingStateQueryVariables,
    GetBinEstimateProcessingStateProps<TChildProps>
  >(GetBinEstimateProcessingStateDocument, {
    alias: 'withGetBinEstimateProcessingState',
    ...operationOptions,
  });
}
export const GetBinGrainModelPrerequisitesDocument = gql`
  query GetBinGrainModelPrerequisites($grain_bin_id: Int!) {
    bin_grain_model_prerequisites(grain_bin_id: $grain_bin_id) {
      ...BinGrainModelPrerequisitesInfoFragment
    }
  }
  ${BinGrainModelPrerequisitesInfoFragmentFragmentDoc}
`;
export type GetBinGrainModelPrerequisitesProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetBinGrainModelPrerequisitesQuery,
    GetBinGrainModelPrerequisitesQueryVariables
  >
> &
  TChildProps;
export function withGetBinGrainModelPrerequisites<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetBinGrainModelPrerequisitesQuery,
    GetBinGrainModelPrerequisitesQueryVariables,
    GetBinGrainModelPrerequisitesProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetBinGrainModelPrerequisitesQuery,
    GetBinGrainModelPrerequisitesQueryVariables,
    GetBinGrainModelPrerequisitesProps<TChildProps>
  >(GetBinGrainModelPrerequisitesDocument, {
    alias: 'withGetBinGrainModelPrerequisites',
    ...operationOptions,
  });
}
export const GetContainerDocument = gql`
  query GetContainer($container_id: Int!, $container_type: Int!) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerIdFragment
    }
  }
  ${GrainContainerIdFragmentFragmentDoc}
`;
export type GetContainerProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetContainerQuery, GetContainerQueryVariables>
> &
  TChildProps;
export function withGetContainer<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetContainerQuery,
    GetContainerQueryVariables,
    GetContainerProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetContainerQuery,
    GetContainerQueryVariables,
    GetContainerProps<TChildProps>
  >(GetContainerDocument, {
    alias: 'withGetContainer',
    ...operationOptions,
  });
}
export const GetContainerGrainLevelPercentDocument = gql`
  query GetContainerGrainLevelPercent($grain_bin_id: Int!) {
    container_grain_level_percent(grain_bin_id: $grain_bin_id) {
      grain_level_bushels
      grain_level_percent
    }
  }
`;
export type GetContainerGrainLevelPercentProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetContainerGrainLevelPercentQuery,
    GetContainerGrainLevelPercentQueryVariables
  >
> &
  TChildProps;
export function withGetContainerGrainLevelPercent<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetContainerGrainLevelPercentQuery,
    GetContainerGrainLevelPercentQueryVariables,
    GetContainerGrainLevelPercentProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetContainerGrainLevelPercentQuery,
    GetContainerGrainLevelPercentQueryVariables,
    GetContainerGrainLevelPercentProps<TChildProps>
  >(GetContainerGrainLevelPercentDocument, {
    alias: 'withGetContainerGrainLevelPercent',
    ...operationOptions,
  });
}
export const GetContainersAerationScheduleDocument = gql`
  query GetContainersAerationSchedule($grain_bin_ids: [Int!]!) {
    containers_aeration_schedule(grain_bin_ids: $grain_bin_ids) {
      grain_bin_id
      aeration_schedule {
        start_epoch
        end_epoch
      }
      aeration_schedule_type
    }
  }
`;
export type GetContainersAerationScheduleProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetContainersAerationScheduleQuery,
    GetContainersAerationScheduleQueryVariables
  >
> &
  TChildProps;
export function withGetContainersAerationSchedule<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetContainersAerationScheduleQuery,
    GetContainersAerationScheduleQueryVariables,
    GetContainersAerationScheduleProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetContainersAerationScheduleQuery,
    GetContainersAerationScheduleQueryVariables,
    GetContainersAerationScheduleProps<TChildProps>
  >(GetContainersAerationScheduleDocument, {
    alias: 'withGetContainersAerationSchedule',
    ...operationOptions,
  });
}
export const GetContainersGrainLevelDocument = gql`
  query GetContainersGrainLevel($grain_bin_ids: [Int!]!) {
    containers_grain_level(grain_bin_ids: $grain_bin_ids) {
      grain_bin_id
      grain_level {
        grain_level_bushels
        grain_level_percent
      }
    }
  }
`;
export type GetContainersGrainLevelProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetContainersGrainLevelQuery, GetContainersGrainLevelQueryVariables>
> &
  TChildProps;
export function withGetContainersGrainLevel<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetContainersGrainLevelQuery,
    GetContainersGrainLevelQueryVariables,
    GetContainersGrainLevelProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetContainersGrainLevelQuery,
    GetContainersGrainLevelQueryVariables,
    GetContainersGrainLevelProps<TChildProps>
  >(GetContainersGrainLevelDocument, {
    alias: 'withGetContainersGrainLevel',
    ...operationOptions,
  });
}
export const GetContainersLastAerationRunDocument = gql`
  query GetContainersLastAerationRun($grain_bin_ids: [Int!]!) {
    containers_last_aeration_run(grain_bin_ids: $grain_bin_ids) {
      grain_bin_id
      last_aeration_run {
        container_id
        start_epoch
        end_epoch
      }
    }
  }
`;
export type GetContainersLastAerationRunProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetContainersLastAerationRunQuery,
    GetContainersLastAerationRunQueryVariables
  >
> &
  TChildProps;
export function withGetContainersLastAerationRun<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetContainersLastAerationRunQuery,
    GetContainersLastAerationRunQueryVariables,
    GetContainersLastAerationRunProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetContainersLastAerationRunQuery,
    GetContainersLastAerationRunQueryVariables,
    GetContainersLastAerationRunProps<TChildProps>
  >(GetContainersLastAerationRunDocument, {
    alias: 'withGetContainersLastAerationRun',
    ...operationOptions,
  });
}
export const GetContainersLastWeekAirspaceHistoryDocument = gql`
  query GetContainersLastWeekAirspaceHistory($grain_bin_ids: [Int!]!) {
    containers_last_week_airspace_history(grain_bin_ids: $grain_bin_ids) {
      grain_bin_id
      last_week_co2_alert_sent_on
      last_week_airspace_history {
        hub_id
        headspace_telemetry_history {
          ...HistoryPointV2
        }
      }
    }
  }
  ${HistoryPointV2FragmentDoc}
`;
export type GetContainersLastWeekAirspaceHistoryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetContainersLastWeekAirspaceHistoryQuery,
    GetContainersLastWeekAirspaceHistoryQueryVariables
  >
> &
  TChildProps;
export function withGetContainersLastWeekAirspaceHistory<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetContainersLastWeekAirspaceHistoryQuery,
    GetContainersLastWeekAirspaceHistoryQueryVariables,
    GetContainersLastWeekAirspaceHistoryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetContainersLastWeekAirspaceHistoryQuery,
    GetContainersLastWeekAirspaceHistoryQueryVariables,
    GetContainersLastWeekAirspaceHistoryProps<TChildProps>
  >(GetContainersLastWeekAirspaceHistoryDocument, {
    alias: 'withGetContainersLastWeekAirspaceHistory',
    ...operationOptions,
  });
}
export const GetContainersWeatherDocument = gql`
  query GetContainersWeather($grain_bin_ids: [Int!]!) {
    containers_weather(grain_bin_ids: $grain_bin_ids) {
      grain_bin_id
      current_weather {
        icon
        temp_f
        humidity_rh
        emc
      }
      today_weather {
        ...GrainWeatherInfoFragment
      }
      one_week_weather {
        ...GrainWeatherInfoFragment
      }
    }
  }
  ${GrainWeatherInfoFragmentFragmentDoc}
`;
export type GetContainersWeatherProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetContainersWeatherQuery, GetContainersWeatherQueryVariables>
> &
  TChildProps;
export function withGetContainersWeather<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetContainersWeatherQuery,
    GetContainersWeatherQueryVariables,
    GetContainersWeatherProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetContainersWeatherQuery,
    GetContainersWeatherQueryVariables,
    GetContainersWeatherProps<TChildProps>
  >(GetContainersWeatherDocument, {
    alias: 'withGetContainersWeather',
    ...operationOptions,
  });
}
export const GetFanControllerDocument = gql`
  query GetFanController($fan_controller_id: Int!) {
    fan_controller(fan_controller_id: $fan_controller_id) {
      ...FanControllerDiagnosticsFragment
    }
  }
  ${FanControllerDiagnosticsFragmentFragmentDoc}
`;
export type GetFanControllerProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetFanControllerQuery, GetFanControllerQueryVariables>
> &
  TChildProps;
export function withGetFanController<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetFanControllerQuery,
    GetFanControllerQueryVariables,
    GetFanControllerProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetFanControllerQuery,
    GetFanControllerQueryVariables,
    GetFanControllerProps<TChildProps>
  >(GetFanControllerDocument, {
    alias: 'withGetFanController',
    ...operationOptions,
  });
}
export const GetFanControllerEventHistoryDocument = gql`
  query GetFanControllerEventHistory(
    $fan_controller_id: Int!
    $limit: Int
    $request_as_of: Date
    $response_as_of: Date
  ) {
    fan_controller_request_history(
      fan_controller_id: $fan_controller_id
      limit: $limit
      as_of: $request_as_of
    ) {
      ...FanControllerRequestFragment
    }
    fan_controller_response_history(
      fan_controller_id: $fan_controller_id
      limit: $limit
      as_of: $response_as_of
    ) {
      ...FanControllerResponseFragment
    }
  }
  ${FanControllerRequestFragmentFragmentDoc}
  ${FanControllerResponseFragmentFragmentDoc}
`;
export type GetFanControllerEventHistoryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetFanControllerEventHistoryQuery,
    GetFanControllerEventHistoryQueryVariables
  >
> &
  TChildProps;
export function withGetFanControllerEventHistory<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetFanControllerEventHistoryQuery,
    GetFanControllerEventHistoryQueryVariables,
    GetFanControllerEventHistoryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetFanControllerEventHistoryQuery,
    GetFanControllerEventHistoryQueryVariables,
    GetFanControllerEventHistoryProps<TChildProps>
  >(GetFanControllerEventHistoryDocument, {
    alias: 'withGetFanControllerEventHistory',
    ...operationOptions,
  });
}
export const GetFanControllersDocument = gql`
  query GetFanControllers {
    fan_controllers_query {
      ...FanControllerDiagnosticsFragment
    }
  }
  ${FanControllerDiagnosticsFragmentFragmentDoc}
`;
export type GetFanControllersProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetFanControllersQuery, GetFanControllersQueryVariables>
> &
  TChildProps;
export function withGetFanControllers<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetFanControllersQuery,
    GetFanControllersQueryVariables,
    GetFanControllersProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetFanControllersQuery,
    GetFanControllersQueryVariables,
    GetFanControllersProps<TChildProps>
  >(GetFanControllersDocument, {
    alias: 'withGetFanControllers',
    ...operationOptions,
  });
}
export const GetFanControllersCountDocument = gql`
  query GetFanControllersCount {
    fan_controllers_count {
      count
    }
  }
`;
export type GetFanControllersCountProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetFanControllersCountQuery, GetFanControllersCountQueryVariables>
> &
  TChildProps;
export function withGetFanControllersCount<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetFanControllersCountQuery,
    GetFanControllersCountQueryVariables,
    GetFanControllersCountProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetFanControllersCountQuery,
    GetFanControllersCountQueryVariables,
    GetFanControllersCountProps<TChildProps>
  >(GetFanControllersCountDocument, {
    alias: 'withGetFanControllersCount',
    ...operationOptions,
  });
}
export const GetFanControllersPageDocument = gql`
  query GetFanControllersPage($offset: Int!, $limit: Int!, $sortBy: String!, $sortAsc: Boolean!) {
    fan_controllers_page_query(offset: $offset, limit: $limit, sortBy: $sortBy, sortAsc: $sortAsc) {
      ...FanControllerPageFragment
    }
  }
  ${FanControllerPageFragmentFragmentDoc}
`;
export type GetFanControllersPageProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetFanControllersPageQuery, GetFanControllersPageQueryVariables>
> &
  TChildProps;
export function withGetFanControllersPage<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetFanControllersPageQuery,
    GetFanControllersPageQueryVariables,
    GetFanControllersPageProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetFanControllersPageQuery,
    GetFanControllersPageQueryVariables,
    GetFanControllersPageProps<TChildProps>
  >(GetFanControllersPageDocument, {
    alias: 'withGetFanControllersPage',
    ...operationOptions,
  });
}
export const GetFanControllerStateDocument = gql`
  query GetFanControllerState($fan_controller_id: Int!) {
    fan_controller_state(fan_controller_id: $fan_controller_id) {
      ...FanControllerStateFragment
    }
  }
  ${FanControllerStateFragmentFragmentDoc}
`;
export type GetFanControllerStateProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetFanControllerStateQuery, GetFanControllerStateQueryVariables>
> &
  TChildProps;
export function withGetFanControllerState<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetFanControllerStateQuery,
    GetFanControllerStateQueryVariables,
    GetFanControllerStateProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetFanControllerStateQuery,
    GetFanControllerStateQueryVariables,
    GetFanControllerStateProps<TChildProps>
  >(GetFanControllerStateDocument, {
    alias: 'withGetFanControllerState',
    ...operationOptions,
  });
}
export const GetGrainBinDocument = gql`
  query GetGrainBin($grain_bin_id: Int!) {
    grain_bin(grain_bin_id: $grain_bin_id) {
      ...GrainBinFragment
    }
  }
  ${GrainBinFragmentFragmentDoc}
`;
export type GetGrainBinProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetGrainBinQuery, GetGrainBinQueryVariables>
> &
  TChildProps;
export function withGetGrainBin<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainBinQuery,
    GetGrainBinQueryVariables,
    GetGrainBinProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainBinQuery,
    GetGrainBinQueryVariables,
    GetGrainBinProps<TChildProps>
  >(GetGrainBinDocument, {
    alias: 'withGetGrainBin',
    ...operationOptions,
  });
}
export const GetGrainBinAccountDocument = gql`
  query GetGrainBinAccount($grain_bin_id: Int!) {
    grain_bin(grain_bin_id: $grain_bin_id) {
      grain_bin_id
      account_id
    }
  }
`;
export type GetGrainBinAccountProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetGrainBinAccountQuery, GetGrainBinAccountQueryVariables>
> &
  TChildProps;
export function withGetGrainBinAccount<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainBinAccountQuery,
    GetGrainBinAccountQueryVariables,
    GetGrainBinAccountProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainBinAccountQuery,
    GetGrainBinAccountQueryVariables,
    GetGrainBinAccountProps<TChildProps>
  >(GetGrainBinAccountDocument, {
    alias: 'withGetGrainBinAccount',
    ...operationOptions,
  });
}
export const GetGrainBinCfmSolutionDocument = gql`
  query GetGrainBinCfmSolution($grain_bin_id: Int!) {
    grain_bin_cfm_solution(grain_bin_id: $grain_bin_id) {
      ...GrainBinCfmSolutionOutputFragment
    }
  }
  ${GrainBinCfmSolutionOutputFragmentFragmentDoc}
`;
export type GetGrainBinCfmSolutionProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetGrainBinCfmSolutionQuery, GetGrainBinCfmSolutionQueryVariables>
> &
  TChildProps;
export function withGetGrainBinCfmSolution<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainBinCfmSolutionQuery,
    GetGrainBinCfmSolutionQueryVariables,
    GetGrainBinCfmSolutionProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainBinCfmSolutionQuery,
    GetGrainBinCfmSolutionQueryVariables,
    GetGrainBinCfmSolutionProps<TChildProps>
  >(GetGrainBinCfmSolutionDocument, {
    alias: 'withGetGrainBinCfmSolution',
    ...operationOptions,
  });
}
export const GetGrainBinContainerDocument = gql`
  query GetGrainBinContainer($container_id: Int!, $container_type: Int!) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerHubLinksFragment
      ...GrainContainerHubLinksWithFCPlenumHubsFragment
      telemetry {
        ...GrainBinTelemetryFragment
      }
    }
  }
  ${GrainContainerHubLinksFragmentFragmentDoc}
  ${GrainContainerHubLinksWithFCPlenumHubsFragmentFragmentDoc}
  ${GrainBinTelemetryFragmentFragmentDoc}
`;
export type GetGrainBinContainerProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetGrainBinContainerQuery, GetGrainBinContainerQueryVariables>
> &
  TChildProps;
export function withGetGrainBinContainer<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainBinContainerQuery,
    GetGrainBinContainerQueryVariables,
    GetGrainBinContainerProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainBinContainerQuery,
    GetGrainBinContainerQueryVariables,
    GetGrainBinContainerProps<TChildProps>
  >(GetGrainBinContainerDocument, {
    alias: 'withGetGrainBinContainer',
    ...operationOptions,
  });
}
export const GetGrainBinDefaultExpValuesDocument = gql`
  query GetGrainBinDefaultExpValues($grain_bin_id: Int!) {
    grain_bin_default_experiment_bound_values(grain_bin_id: $grain_bin_id) {
      ...ExperimentBoundValuesFragment
    }
  }
  ${ExperimentBoundValuesFragmentFragmentDoc}
`;
export type GetGrainBinDefaultExpValuesProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetGrainBinDefaultExpValuesQuery, GetGrainBinDefaultExpValuesQueryVariables>
> &
  TChildProps;
export function withGetGrainBinDefaultExpValues<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainBinDefaultExpValuesQuery,
    GetGrainBinDefaultExpValuesQueryVariables,
    GetGrainBinDefaultExpValuesProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainBinDefaultExpValuesQuery,
    GetGrainBinDefaultExpValuesQueryVariables,
    GetGrainBinDefaultExpValuesProps<TChildProps>
  >(GetGrainBinDefaultExpValuesDocument, {
    alias: 'withGetGrainBinDefaultExpValues',
    ...operationOptions,
  });
}
export const GetGrainBinEstimateDocument = gql`
  query GetGrainBinEstimate($grain_bin_id: Int!) {
    grain_bin_estimate(grain_bin_id: $grain_bin_id) {
      ...GrainBinEstimateFragment
    }
  }
  ${GrainBinEstimateFragmentFragmentDoc}
`;
export type GetGrainBinEstimateProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetGrainBinEstimateQuery, GetGrainBinEstimateQueryVariables>
> &
  TChildProps;
export function withGetGrainBinEstimate<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainBinEstimateQuery,
    GetGrainBinEstimateQueryVariables,
    GetGrainBinEstimateProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainBinEstimateQuery,
    GetGrainBinEstimateQueryVariables,
    GetGrainBinEstimateProps<TChildProps>
  >(GetGrainBinEstimateDocument, {
    alias: 'withGetGrainBinEstimate',
    ...operationOptions,
  });
}
export const GetGrainBinEventsDocument = gql`
  query GetGrainBinEvents($grain_bin_id: Int!) {
    latest_grain_bin_events(grain_bin_id: $grain_bin_id) {
      ...BinEventFragment
    }
  }
  ${BinEventFragmentFragmentDoc}
`;
export type GetGrainBinEventsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetGrainBinEventsQuery, GetGrainBinEventsQueryVariables>
> &
  TChildProps;
export function withGetGrainBinEvents<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainBinEventsQuery,
    GetGrainBinEventsQueryVariables,
    GetGrainBinEventsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainBinEventsQuery,
    GetGrainBinEventsQueryVariables,
    GetGrainBinEventsProps<TChildProps>
  >(GetGrainBinEventsDocument, {
    alias: 'withGetGrainBinEvents',
    ...operationOptions,
  });
}
export const GetGrainBinExperimentCfmValuesDocument = gql`
  query GetGrainBinExperimentCfmValues($grain_bin_id: Int!) {
    grain_bin_experiment_cfm_values(grain_bin_id: $grain_bin_id) {
      ...ExperimentCfmValuesFragment
    }
  }
  ${ExperimentCfmValuesFragmentFragmentDoc}
`;
export type GetGrainBinExperimentCfmValuesProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetGrainBinExperimentCfmValuesQuery,
    GetGrainBinExperimentCfmValuesQueryVariables
  >
> &
  TChildProps;
export function withGetGrainBinExperimentCfmValues<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainBinExperimentCfmValuesQuery,
    GetGrainBinExperimentCfmValuesQueryVariables,
    GetGrainBinExperimentCfmValuesProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainBinExperimentCfmValuesQuery,
    GetGrainBinExperimentCfmValuesQueryVariables,
    GetGrainBinExperimentCfmValuesProps<TChildProps>
  >(GetGrainBinExperimentCfmValuesDocument, {
    alias: 'withGetGrainBinExperimentCfmValues',
    ...operationOptions,
  });
}
export const GetGrainBinFanSettingsDocument = gql`
  query GetGrainBinFanSettings($grain_bin_id: Int!) {
    grain_bin_fan_settings(grain_bin_id: $grain_bin_id) {
      ...FanSettingsFragment
    }
  }
  ${FanSettingsFragmentFragmentDoc}
`;
export type GetGrainBinFanSettingsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetGrainBinFanSettingsQuery, GetGrainBinFanSettingsQueryVariables>
> &
  TChildProps;
export function withGetGrainBinFanSettings<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainBinFanSettingsQuery,
    GetGrainBinFanSettingsQueryVariables,
    GetGrainBinFanSettingsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainBinFanSettingsQuery,
    GetGrainBinFanSettingsQueryVariables,
    GetGrainBinFanSettingsProps<TChildProps>
  >(GetGrainBinFanSettingsDocument, {
    alias: 'withGetGrainBinFanSettings',
    ...operationOptions,
  });
}
export const GetGrainBinSitesDocument = gql`
  query GetGrainBinSites($account_id: Int!, $user_id: Int!) {
    grain_bin_sites(account_id: $account_id, user_id: $user_id) {
      site_id
      site_name
      bins {
        grain_bin_id
        grain_bin_attributes {
          ...GrainBinDTOFragment
        }
        fan_controllers {
          id
          state
          active
          fan_attributes {
            ...GrainBinFanControllerFragment
          }
          fan_config_state {
            ...FanControllerStateFragment
          }
        }
      }
    }
  }
  ${GrainBinDTOFragmentFragmentDoc}
  ${GrainBinFanControllerFragmentFragmentDoc}
  ${FanControllerStateFragmentFragmentDoc}
`;
export type GetGrainBinSitesProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetGrainBinSitesQuery, GetGrainBinSitesQueryVariables>
> &
  TChildProps;
export function withGetGrainBinSites<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainBinSitesQuery,
    GetGrainBinSitesQueryVariables,
    GetGrainBinSitesProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainBinSitesQuery,
    GetGrainBinSitesQueryVariables,
    GetGrainBinSitesProps<TChildProps>
  >(GetGrainBinSitesDocument, {
    alias: 'withGetGrainBinSites',
    ...operationOptions,
  });
}
export const GetGrainBinStoragePeriodDocument = gql`
  query GetGrainBinStoragePeriod($grain_bin_id: Int!) {
    grain_bin_storage_periods(grain_bin_id: $grain_bin_id) {
      grain_bin_storage_cycle_id
      grain_bin_id
      storage_cycle_name
      is_ongoing_storage_period
      grain_type
      start_epoch
      end_epoch
      created_at
    }
  }
`;
export type GetGrainBinStoragePeriodProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetGrainBinStoragePeriodQuery, GetGrainBinStoragePeriodQueryVariables>
> &
  TChildProps;
export function withGetGrainBinStoragePeriod<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainBinStoragePeriodQuery,
    GetGrainBinStoragePeriodQueryVariables,
    GetGrainBinStoragePeriodProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainBinStoragePeriodQuery,
    GetGrainBinStoragePeriodQueryVariables,
    GetGrainBinStoragePeriodProps<TChildProps>
  >(GetGrainBinStoragePeriodDocument, {
    alias: 'withGetGrainBinStoragePeriod',
    ...operationOptions,
  });
}
export const GetGrainBinTicketsDocument = gql`
  query GetGrainBinTickets($grain_bin_id: Int!, $grain_bin_storage_cycle_id: Int) {
    grain_bin_tickets(
      grain_bin_id: $grain_bin_id
      grain_bin_storage_cycle_id: $grain_bin_storage_cycle_id
    ) {
      grain_bin_tickets_total_weight_in_lbs
      grain_bin_tickets_total_bushels
      grain_bin_all_tickets {
        ...GrainBinTicketFragment
      }
    }
  }
  ${GrainBinTicketFragmentFragmentDoc}
`;
export type GetGrainBinTicketsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetGrainBinTicketsQuery, GetGrainBinTicketsQueryVariables>
> &
  TChildProps;
export function withGetGrainBinTickets<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainBinTicketsQuery,
    GetGrainBinTicketsQueryVariables,
    GetGrainBinTicketsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainBinTicketsQuery,
    GetGrainBinTicketsQueryVariables,
    GetGrainBinTicketsProps<TChildProps>
  >(GetGrainBinTicketsDocument, {
    alias: 'withGetGrainBinTickets',
    ...operationOptions,
  });
}
export const GetGrainBinWeeklyLevelDataDocument = gql`
  query GetGrainBinWeeklyLevelData(
    $container_id: Int!
    $container_type: Int!
    $grain_bin_storage_cycle_id: Int
  ) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      container_id
      container_type
      grain_bin_tickets_level_history(
        period: "1W"
        grain_bin_storage_cycle_id: $grain_bin_storage_cycle_id
      ) {
        period
        no_grain_tickets_created
        grain_tickets_bushel_level_on_period_start {
          bushel
          epoch_time
        }
        grain_tickets_bushel_level_on_period_end {
          bushel
          epoch_time
        }
        grain_tickets_level_samples {
          bushel
          epoch_time
        }
      }
      grain_bin_level_history(period: "1W") {
        period
        max_bushels
        active_hubs_level_samples {
          hub_id
          level_samples {
            bushel
            epoch_time
          }
        }
      }
    }
  }
`;
export type GetGrainBinWeeklyLevelDataProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetGrainBinWeeklyLevelDataQuery, GetGrainBinWeeklyLevelDataQueryVariables>
> &
  TChildProps;
export function withGetGrainBinWeeklyLevelData<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainBinWeeklyLevelDataQuery,
    GetGrainBinWeeklyLevelDataQueryVariables,
    GetGrainBinWeeklyLevelDataProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainBinWeeklyLevelDataQuery,
    GetGrainBinWeeklyLevelDataQueryVariables,
    GetGrainBinWeeklyLevelDataProps<TChildProps>
  >(GetGrainBinWeeklyLevelDataDocument, {
    alias: 'withGetGrainBinWeeklyLevelData',
    ...operationOptions,
  });
}
export const GetGrainBinWithHubTelemetryDocument = gql`
  query GetGrainBinWithHubTelemetry($grain_bin_id: Int!) {
    grain_bin_telemetry(grain_bin_id: $grain_bin_id) {
      interior {
        ...GrainStatusFragment
      }
      headspace {
        ...GrainStatusFragment
      }
      weather {
        ...GrainStatusFragment
      }
      hourly_forecast {
        ...HourlyForecastStatusFragment
      }
    }
  }
  ${GrainStatusFragmentFragmentDoc}
  ${HourlyForecastStatusFragmentFragmentDoc}
`;
export type GetGrainBinWithHubTelemetryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetGrainBinWithHubTelemetryQuery, GetGrainBinWithHubTelemetryQueryVariables>
> &
  TChildProps;
export function withGetGrainBinWithHubTelemetry<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainBinWithHubTelemetryQuery,
    GetGrainBinWithHubTelemetryQueryVariables,
    GetGrainBinWithHubTelemetryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainBinWithHubTelemetryQuery,
    GetGrainBinWithHubTelemetryQueryVariables,
    GetGrainBinWithHubTelemetryProps<TChildProps>
  >(GetGrainBinWithHubTelemetryDocument, {
    alias: 'withGetGrainBinWithHubTelemetry',
    ...operationOptions,
  });
}
export const GetGrainContainerHistoryDocument = gql`
  query GetGrainContainerHistory($container_id: Int!, $container_type: Int!, $period: String!) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerHistoryFragment
    }
  }
  ${GrainContainerHistoryFragmentFragmentDoc}
`;
export type GetGrainContainerHistoryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetGrainContainerHistoryQuery, GetGrainContainerHistoryQueryVariables>
> &
  TChildProps;
export function withGetGrainContainerHistory<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainContainerHistoryQuery,
    GetGrainContainerHistoryQueryVariables,
    GetGrainContainerHistoryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainContainerHistoryQuery,
    GetGrainContainerHistoryQueryVariables,
    GetGrainContainerHistoryProps<TChildProps>
  >(GetGrainContainerHistoryDocument, {
    alias: 'withGetGrainContainerHistory',
    ...operationOptions,
  });
}
export const GetGrainContainerHistoryBinSummaryDocument = gql`
  query GetGrainContainerHistoryBinSummary(
    $container_id: Int!
    $container_type: Int!
    $period: String!
  ) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerHistoryBinSummaryFragment
    }
  }
  ${GrainContainerHistoryBinSummaryFragmentFragmentDoc}
`;
export type GetGrainContainerHistoryBinSummaryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetGrainContainerHistoryBinSummaryQuery,
    GetGrainContainerHistoryBinSummaryQueryVariables
  >
> &
  TChildProps;
export function withGetGrainContainerHistoryBinSummary<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainContainerHistoryBinSummaryQuery,
    GetGrainContainerHistoryBinSummaryQueryVariables,
    GetGrainContainerHistoryBinSummaryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainContainerHistoryBinSummaryQuery,
    GetGrainContainerHistoryBinSummaryQueryVariables,
    GetGrainContainerHistoryBinSummaryProps<TChildProps>
  >(GetGrainContainerHistoryBinSummaryDocument, {
    alias: 'withGetGrainContainerHistoryBinSummary',
    ...operationOptions,
  });
}
export const GetGrainContainerHistoryFanDocument = gql`
  query GetGrainContainerHistoryFan($container_id: Int!, $container_type: Int!, $period: String!) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerHistoryFanFragment
    }
  }
  ${GrainContainerHistoryFanFragmentFragmentDoc}
`;
export type GetGrainContainerHistoryFanProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetGrainContainerHistoryFanQuery, GetGrainContainerHistoryFanQueryVariables>
> &
  TChildProps;
export function withGetGrainContainerHistoryFan<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainContainerHistoryFanQuery,
    GetGrainContainerHistoryFanQueryVariables,
    GetGrainContainerHistoryFanProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainContainerHistoryFanQuery,
    GetGrainContainerHistoryFanQueryVariables,
    GetGrainContainerHistoryFanProps<TChildProps>
  >(GetGrainContainerHistoryFanDocument, {
    alias: 'withGetGrainContainerHistoryFan',
    ...operationOptions,
  });
}
export const GetGrainContainerHistoryAerationRunsDocument = gql`
  query GetGrainContainerHistoryAerationRuns($container_id: Int!, $container_type: Int!) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerHistoryAerationRunsFragment
    }
  }
  ${GrainContainerHistoryAerationRunsFragmentFragmentDoc}
`;
export type GetGrainContainerHistoryAerationRunsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetGrainContainerHistoryAerationRunsQuery,
    GetGrainContainerHistoryAerationRunsQueryVariables
  >
> &
  TChildProps;
export function withGetGrainContainerHistoryAerationRuns<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainContainerHistoryAerationRunsQuery,
    GetGrainContainerHistoryAerationRunsQueryVariables,
    GetGrainContainerHistoryAerationRunsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainContainerHistoryAerationRunsQuery,
    GetGrainContainerHistoryAerationRunsQueryVariables,
    GetGrainContainerHistoryAerationRunsProps<TChildProps>
  >(GetGrainContainerHistoryAerationRunsDocument, {
    alias: 'withGetGrainContainerHistoryAerationRuns',
    ...operationOptions,
  });
}
export const GetGrainContainerHistoryAerationRunsWithinPeriodDocument = gql`
  query GetGrainContainerHistoryAerationRunsWithinPeriod(
    $container_id: Int!
    $container_type: Int!
    $period: String!
  ) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerHistoryAerationRunsWithinPeriodFragment
    }
  }
  ${GrainContainerHistoryAerationRunsWithinPeriodFragmentFragmentDoc}
`;
export type GetGrainContainerHistoryAerationRunsWithinPeriodProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetGrainContainerHistoryAerationRunsWithinPeriodQuery,
    GetGrainContainerHistoryAerationRunsWithinPeriodQueryVariables
  >
> &
  TChildProps;
export function withGetGrainContainerHistoryAerationRunsWithinPeriod<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainContainerHistoryAerationRunsWithinPeriodQuery,
    GetGrainContainerHistoryAerationRunsWithinPeriodQueryVariables,
    GetGrainContainerHistoryAerationRunsWithinPeriodProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainContainerHistoryAerationRunsWithinPeriodQuery,
    GetGrainContainerHistoryAerationRunsWithinPeriodQueryVariables,
    GetGrainContainerHistoryAerationRunsWithinPeriodProps<TChildProps>
  >(GetGrainContainerHistoryAerationRunsWithinPeriodDocument, {
    alias: 'withGetGrainContainerHistoryAerationRunsWithinPeriod',
    ...operationOptions,
  });
}
export const GetGrainContainerHistoryAerationRunsWithLimitDocument = gql`
  query GetGrainContainerHistoryAerationRunsWithLimit(
    $container_id: Int!
    $container_type: Int!
    $offset: Int!
    $limit: Int!
    $current_grain_bin_storage_cycle_id: Int
  ) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerHistoryAerationRunsWithLimitFragment
    }
  }
  ${GrainContainerHistoryAerationRunsWithLimitFragmentFragmentDoc}
`;
export type GetGrainContainerHistoryAerationRunsWithLimitProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetGrainContainerHistoryAerationRunsWithLimitQuery,
    GetGrainContainerHistoryAerationRunsWithLimitQueryVariables
  >
> &
  TChildProps;
export function withGetGrainContainerHistoryAerationRunsWithLimit<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainContainerHistoryAerationRunsWithLimitQuery,
    GetGrainContainerHistoryAerationRunsWithLimitQueryVariables,
    GetGrainContainerHistoryAerationRunsWithLimitProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainContainerHistoryAerationRunsWithLimitQuery,
    GetGrainContainerHistoryAerationRunsWithLimitQueryVariables,
    GetGrainContainerHistoryAerationRunsWithLimitProps<TChildProps>
  >(GetGrainContainerHistoryAerationRunsWithLimitDocument, {
    alias: 'withGetGrainContainerHistoryAerationRunsWithLimit',
    ...operationOptions,
  });
}
export const GetGrainContainerHistoryNextAerationRunsWithLimitDocument = gql`
  query GetGrainContainerHistoryNextAerationRunsWithLimit(
    $container_id: Int!
    $container_type: Int!
    $offset: Int!
    $limit: Int!
    $current_grain_bin_storage_cycle_id: Int
  ) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerHistoryNextAerationRunsWithLimitFragment
    }
  }
  ${GrainContainerHistoryNextAerationRunsWithLimitFragmentFragmentDoc}
`;
export type GetGrainContainerHistoryNextAerationRunsWithLimitProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetGrainContainerHistoryNextAerationRunsWithLimitQuery,
    GetGrainContainerHistoryNextAerationRunsWithLimitQueryVariables
  >
> &
  TChildProps;
export function withGetGrainContainerHistoryNextAerationRunsWithLimit<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainContainerHistoryNextAerationRunsWithLimitQuery,
    GetGrainContainerHistoryNextAerationRunsWithLimitQueryVariables,
    GetGrainContainerHistoryNextAerationRunsWithLimitProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainContainerHistoryNextAerationRunsWithLimitQuery,
    GetGrainContainerHistoryNextAerationRunsWithLimitQueryVariables,
    GetGrainContainerHistoryNextAerationRunsWithLimitProps<TChildProps>
  >(GetGrainContainerHistoryNextAerationRunsWithLimitDocument, {
    alias: 'withGetGrainContainerHistoryNextAerationRunsWithLimit',
    ...operationOptions,
  });
}
export const GetGrainContainerHistoryHeadspaceDocument = gql`
  query GetGrainContainerHistoryHeadspace(
    $container_id: Int!
    $container_type: Int!
    $period: String!
  ) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerHistoryHeadspaceFragment
    }
  }
  ${GrainContainerHistoryHeadspaceFragmentFragmentDoc}
`;
export type GetGrainContainerHistoryHeadspaceProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetGrainContainerHistoryHeadspaceQuery,
    GetGrainContainerHistoryHeadspaceQueryVariables
  >
> &
  TChildProps;
export function withGetGrainContainerHistoryHeadspace<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainContainerHistoryHeadspaceQuery,
    GetGrainContainerHistoryHeadspaceQueryVariables,
    GetGrainContainerHistoryHeadspaceProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainContainerHistoryHeadspaceQuery,
    GetGrainContainerHistoryHeadspaceQueryVariables,
    GetGrainContainerHistoryHeadspaceProps<TChildProps>
  >(GetGrainContainerHistoryHeadspaceDocument, {
    alias: 'withGetGrainContainerHistoryHeadspace',
    ...operationOptions,
  });
}
export const GetGrainContainerHistoryHeadspaceWeatherDocument = gql`
  query GetGrainContainerHistoryHeadspaceWeather(
    $container_id: Int!
    $container_type: Int!
    $period: String!
  ) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerHistoryHeadspaceWeatherFragment
    }
  }
  ${GrainContainerHistoryHeadspaceWeatherFragmentFragmentDoc}
`;
export type GetGrainContainerHistoryHeadspaceWeatherProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetGrainContainerHistoryHeadspaceWeatherQuery,
    GetGrainContainerHistoryHeadspaceWeatherQueryVariables
  >
> &
  TChildProps;
export function withGetGrainContainerHistoryHeadspaceWeather<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainContainerHistoryHeadspaceWeatherQuery,
    GetGrainContainerHistoryHeadspaceWeatherQueryVariables,
    GetGrainContainerHistoryHeadspaceWeatherProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainContainerHistoryHeadspaceWeatherQuery,
    GetGrainContainerHistoryHeadspaceWeatherQueryVariables,
    GetGrainContainerHistoryHeadspaceWeatherProps<TChildProps>
  >(GetGrainContainerHistoryHeadspaceWeatherDocument, {
    alias: 'withGetGrainContainerHistoryHeadspaceWeather',
    ...operationOptions,
  });
}
export const GetGrainContainerHistoryGrainLevelDocument = gql`
  query GetGrainContainerHistoryGrainLevel(
    $container_id: Int!
    $container_type: Int!
    $as_of: Date!
    $period: String!
  ) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerHistoryGrainLevelFragment
    }
  }
  ${GrainContainerHistoryGrainLevelFragmentFragmentDoc}
`;
export type GetGrainContainerHistoryGrainLevelProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetGrainContainerHistoryGrainLevelQuery,
    GetGrainContainerHistoryGrainLevelQueryVariables
  >
> &
  TChildProps;
export function withGetGrainContainerHistoryGrainLevel<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainContainerHistoryGrainLevelQuery,
    GetGrainContainerHistoryGrainLevelQueryVariables,
    GetGrainContainerHistoryGrainLevelProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainContainerHistoryGrainLevelQuery,
    GetGrainContainerHistoryGrainLevelQueryVariables,
    GetGrainContainerHistoryGrainLevelProps<TChildProps>
  >(GetGrainContainerHistoryGrainLevelDocument, {
    alias: 'withGetGrainContainerHistoryGrainLevel',
    ...operationOptions,
  });
}
export const GetGrainContainerHistoryGrainTicketsLevelDocument = gql`
  query GetGrainContainerHistoryGrainTicketsLevel(
    $container_id: Int!
    $container_type: Int!
    $as_of: Date!
    $period: String!
    $grain_bin_storage_cycle_id: Int
  ) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerHistoryGrainTicketsLevelFragment
    }
  }
  ${GrainContainerHistoryGrainTicketsLevelFragmentFragmentDoc}
`;
export type GetGrainContainerHistoryGrainTicketsLevelProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetGrainContainerHistoryGrainTicketsLevelQuery,
    GetGrainContainerHistoryGrainTicketsLevelQueryVariables
  >
> &
  TChildProps;
export function withGetGrainContainerHistoryGrainTicketsLevel<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainContainerHistoryGrainTicketsLevelQuery,
    GetGrainContainerHistoryGrainTicketsLevelQueryVariables,
    GetGrainContainerHistoryGrainTicketsLevelProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainContainerHistoryGrainTicketsLevelQuery,
    GetGrainContainerHistoryGrainTicketsLevelQueryVariables,
    GetGrainContainerHistoryGrainTicketsLevelProps<TChildProps>
  >(GetGrainContainerHistoryGrainTicketsLevelDocument, {
    alias: 'withGetGrainContainerHistoryGrainTicketsLevel',
    ...operationOptions,
  });
}
export const GetGrainContainerHistoryInteriorDocument = gql`
  query GetGrainContainerHistoryInterior(
    $container_id: Int!
    $container_type: Int!
    $period: String!
  ) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerHistoryInteriorFragment
    }
  }
  ${GrainContainerHistoryInteriorFragmentFragmentDoc}
`;
export type GetGrainContainerHistoryInteriorProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetGrainContainerHistoryInteriorQuery,
    GetGrainContainerHistoryInteriorQueryVariables
  >
> &
  TChildProps;
export function withGetGrainContainerHistoryInterior<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainContainerHistoryInteriorQuery,
    GetGrainContainerHistoryInteriorQueryVariables,
    GetGrainContainerHistoryInteriorProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainContainerHistoryInteriorQuery,
    GetGrainContainerHistoryInteriorQueryVariables,
    GetGrainContainerHistoryInteriorProps<TChildProps>
  >(GetGrainContainerHistoryInteriorDocument, {
    alias: 'withGetGrainContainerHistoryInterior',
    ...operationOptions,
  });
}
export const GetGrainContainerHistoryPelletDocument = gql`
  query GetGrainContainerHistoryPellet(
    $container_id: Int!
    $container_type: Int!
    $period: String!
    $pellet_id: String!
  ) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerHistoryPelletFragment
    }
  }
  ${GrainContainerHistoryPelletFragmentFragmentDoc}
`;
export type GetGrainContainerHistoryPelletProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetGrainContainerHistoryPelletQuery,
    GetGrainContainerHistoryPelletQueryVariables
  >
> &
  TChildProps;
export function withGetGrainContainerHistoryPellet<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainContainerHistoryPelletQuery,
    GetGrainContainerHistoryPelletQueryVariables,
    GetGrainContainerHistoryPelletProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainContainerHistoryPelletQuery,
    GetGrainContainerHistoryPelletQueryVariables,
    GetGrainContainerHistoryPelletProps<TChildProps>
  >(GetGrainContainerHistoryPelletDocument, {
    alias: 'withGetGrainContainerHistoryPellet',
    ...operationOptions,
  });
}
export const GetGrainContainerHistoryPelletsDocument = gql`
  query GetGrainContainerHistoryPellets(
    $container_id: Int!
    $container_type: Int!
    $period: String!
  ) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerHistoryPelletsFragment
    }
  }
  ${GrainContainerHistoryPelletsFragmentFragmentDoc}
`;
export type GetGrainContainerHistoryPelletsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetGrainContainerHistoryPelletsQuery,
    GetGrainContainerHistoryPelletsQueryVariables
  >
> &
  TChildProps;
export function withGetGrainContainerHistoryPellets<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainContainerHistoryPelletsQuery,
    GetGrainContainerHistoryPelletsQueryVariables,
    GetGrainContainerHistoryPelletsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainContainerHistoryPelletsQuery,
    GetGrainContainerHistoryPelletsQueryVariables,
    GetGrainContainerHistoryPelletsProps<TChildProps>
  >(GetGrainContainerHistoryPelletsDocument, {
    alias: 'withGetGrainContainerHistoryPellets',
    ...operationOptions,
  });
}
export const GetGrainContainerHistoryWeatherDocument = gql`
  query GetGrainContainerHistoryWeather(
    $container_id: Int!
    $container_type: Int!
    $period: String!
  ) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerHistoryWeatherFragment
    }
  }
  ${GrainContainerHistoryWeatherFragmentFragmentDoc}
`;
export type GetGrainContainerHistoryWeatherProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetGrainContainerHistoryWeatherQuery,
    GetGrainContainerHistoryWeatherQueryVariables
  >
> &
  TChildProps;
export function withGetGrainContainerHistoryWeather<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainContainerHistoryWeatherQuery,
    GetGrainContainerHistoryWeatherQueryVariables,
    GetGrainContainerHistoryWeatherProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainContainerHistoryWeatherQuery,
    GetGrainContainerHistoryWeatherQueryVariables,
    GetGrainContainerHistoryWeatherProps<TChildProps>
  >(GetGrainContainerHistoryWeatherDocument, {
    alias: 'withGetGrainContainerHistoryWeather',
    ...operationOptions,
  });
}
export const GetGrainContainerHubLinksDocument = gql`
  query GetGrainContainerHubLinks($container_id: Int!, $container_type: Int!) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerHubLinksFragment
    }
  }
  ${GrainContainerHubLinksFragmentFragmentDoc}
`;
export type GetGrainContainerHubLinksProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetGrainContainerHubLinksQuery, GetGrainContainerHubLinksQueryVariables>
> &
  TChildProps;
export function withGetGrainContainerHubLinks<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainContainerHubLinksQuery,
    GetGrainContainerHubLinksQueryVariables,
    GetGrainContainerHubLinksProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainContainerHubLinksQuery,
    GetGrainContainerHubLinksQueryVariables,
    GetGrainContainerHubLinksProps<TChildProps>
  >(GetGrainContainerHubLinksDocument, {
    alias: 'withGetGrainContainerHubLinks',
    ...operationOptions,
  });
}
export const GetGrainContainerMonthlyCo2HistoryWithAerationsDocument = gql`
  query GetGrainContainerMonthlyCO2HistoryWithAerations(
    $container_id: Int!
    $container_type: Int!
  ) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      container_id
      container_type
      telemetry_history_v2(period: "1M") {
        container_id
        container_type
        period
        headspace_history {
          active_hubs_grain_telemetry_history {
            hub_id
            headspace_telemetry_history {
              ...HistoryPointV2
            }
          }
        }
      }
      aeration_runs_within_period(period: "1M") {
        period
        aeration_runs {
          ...GrainContainerAerationRunFragment
        }
      }
    }
  }
  ${HistoryPointV2FragmentDoc}
  ${GrainContainerAerationRunFragmentFragmentDoc}
`;
export type GetGrainContainerMonthlyCo2HistoryWithAerationsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetGrainContainerMonthlyCo2HistoryWithAerationsQuery,
    GetGrainContainerMonthlyCo2HistoryWithAerationsQueryVariables
  >
> &
  TChildProps;
export function withGetGrainContainerMonthlyCo2HistoryWithAerations<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainContainerMonthlyCo2HistoryWithAerationsQuery,
    GetGrainContainerMonthlyCo2HistoryWithAerationsQueryVariables,
    GetGrainContainerMonthlyCo2HistoryWithAerationsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainContainerMonthlyCo2HistoryWithAerationsQuery,
    GetGrainContainerMonthlyCo2HistoryWithAerationsQueryVariables,
    GetGrainContainerMonthlyCo2HistoryWithAerationsProps<TChildProps>
  >(GetGrainContainerMonthlyCo2HistoryWithAerationsDocument, {
    alias: 'withGetGrainContainerMonthlyCo2HistoryWithAerations',
    ...operationOptions,
  });
}
export const GetGrainContainerWeeklyCo2HistoryWithAerationsDocument = gql`
  query GetGrainContainerWeeklyCO2HistoryWithAerations($container_id: Int!, $container_type: Int!) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      container_id
      container_type
      telemetry_history_v2(period: "1W") {
        container_id
        container_type
        period
        headspace_history {
          active_hubs_grain_telemetry_history {
            hub_id
            headspace_telemetry_history {
              ...HistoryPointV2
            }
          }
        }
      }
      aeration_runs_within_period(period: "1W") {
        period
        aeration_runs {
          ...GrainContainerAerationRunFragment
        }
      }
    }
  }
  ${HistoryPointV2FragmentDoc}
  ${GrainContainerAerationRunFragmentFragmentDoc}
`;
export type GetGrainContainerWeeklyCo2HistoryWithAerationsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetGrainContainerWeeklyCo2HistoryWithAerationsQuery,
    GetGrainContainerWeeklyCo2HistoryWithAerationsQueryVariables
  >
> &
  TChildProps;
export function withGetGrainContainerWeeklyCo2HistoryWithAerations<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainContainerWeeklyCo2HistoryWithAerationsQuery,
    GetGrainContainerWeeklyCo2HistoryWithAerationsQueryVariables,
    GetGrainContainerWeeklyCo2HistoryWithAerationsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainContainerWeeklyCo2HistoryWithAerationsQuery,
    GetGrainContainerWeeklyCo2HistoryWithAerationsQueryVariables,
    GetGrainContainerWeeklyCo2HistoryWithAerationsProps<TChildProps>
  >(GetGrainContainerWeeklyCo2HistoryWithAerationsDocument, {
    alias: 'withGetGrainContainerWeeklyCo2HistoryWithAerations',
    ...operationOptions,
  });
}
export const GetGrainFanModelsDocument = gql`
  query GetGrainFanModels($fan_model_filter: [FanModelFilter!]!) {
    grain_fan_models(fan_model_filter: $fan_model_filter) {
      ...GetFanModelResultFragment
    }
  }
  ${GetFanModelResultFragmentFragmentDoc}
`;
export type GetGrainFanModelsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetGrainFanModelsQuery, GetGrainFanModelsQueryVariables>
> &
  TChildProps;
export function withGetGrainFanModels<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainFanModelsQuery,
    GetGrainFanModelsQueryVariables,
    GetGrainFanModelsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainFanModelsQuery,
    GetGrainFanModelsQueryVariables,
    GetGrainFanModelsProps<TChildProps>
  >(GetGrainFanModelsDocument, {
    alias: 'withGetGrainFanModels',
    ...operationOptions,
  });
}
export const GetGrainFanPlotsDocument = gql`
  query GetGrainFanPlots(
    $fan_brand_name: String
    $fan_model_type: FanType
    $fan_horsepower: String
  ) {
    grain_fan_plots(
      fan_brand_name: $fan_brand_name
      fan_model_type: $fan_model_type
      fan_horsepower: $fan_horsepower
    ) {
      ...GetGrainFanPlotsOutputFragment
    }
  }
  ${GetGrainFanPlotsOutputFragmentFragmentDoc}
`;
export type GetGrainFanPlotsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetGrainFanPlotsQuery, GetGrainFanPlotsQueryVariables>
> &
  TChildProps;
export function withGetGrainFanPlots<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainFanPlotsQuery,
    GetGrainFanPlotsQueryVariables,
    GetGrainFanPlotsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainFanPlotsQuery,
    GetGrainFanPlotsQueryVariables,
    GetGrainFanPlotsProps<TChildProps>
  >(GetGrainFanPlotsDocument, {
    alias: 'withGetGrainFanPlots',
    ...operationOptions,
  });
}
export const GetGrainTelemetryDocument = gql`
  query GetGrainTelemetry($container_id: Int!, $container_type: Int!) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      container_id
      telemetry {
        weather_forecast {
          ...WeatherForecastFragment
        }
        recommendation_windows {
          ...RecommendationWindowsFragment
        }
        temporary_recommendation_windows {
          recommendation_windows {
            ...RecommendationWindowsFragment
          }
          recommendation_type
        }
      }
    }
  }
  ${WeatherForecastFragmentFragmentDoc}
  ${RecommendationWindowsFragmentFragmentDoc}
`;
export type GetGrainTelemetryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetGrainTelemetryQuery, GetGrainTelemetryQueryVariables>
> &
  TChildProps;
export function withGetGrainTelemetry<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetGrainTelemetryQuery,
    GetGrainTelemetryQueryVariables,
    GetGrainTelemetryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetGrainTelemetryQuery,
    GetGrainTelemetryQueryVariables,
    GetGrainTelemetryProps<TChildProps>
  >(GetGrainTelemetryDocument, {
    alias: 'withGetGrainTelemetry',
    ...operationOptions,
  });
}
export const GetHubContainerLinkDocument = gql`
  query GetHubContainerLink($hub_container_link_id: Int!) {
    hub_container_link(hub_container_link_id: $hub_container_link_id) {
      hub_container_link_id
      start_epoch
      end_epoch
    }
  }
`;
export type GetHubContainerLinkProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetHubContainerLinkQuery, GetHubContainerLinkQueryVariables>
> &
  TChildProps;
export function withGetHubContainerLink<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetHubContainerLinkQuery,
    GetHubContainerLinkQueryVariables,
    GetHubContainerLinkProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetHubContainerLinkQuery,
    GetHubContainerLinkQueryVariables,
    GetHubContainerLinkProps<TChildProps>
  >(GetHubContainerLinkDocument, {
    alias: 'withGetHubContainerLink',
    ...operationOptions,
  });
}
export const GetHubEventHistoryDocument = gql`
  query GetHubEventHistory($hub_id: Int!, $start: Date, $end: Date) {
    hub_event_history(hub_id: $hub_id, start: $start, end: $end) {
      ...HubEventHistoryFragment
    }
  }
  ${HubEventHistoryFragmentFragmentDoc}
`;
export type GetHubEventHistoryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetHubEventHistoryQuery, GetHubEventHistoryQueryVariables>
> &
  TChildProps;
export function withGetHubEventHistory<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetHubEventHistoryQuery,
    GetHubEventHistoryQueryVariables,
    GetHubEventHistoryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetHubEventHistoryQuery,
    GetHubEventHistoryQueryVariables,
    GetHubEventHistoryProps<TChildProps>
  >(GetHubEventHistoryDocument, {
    alias: 'withGetHubEventHistory',
    ...operationOptions,
  });
}
export const GetHubParticleEventsDocument = gql`
  query GetHubParticleEvents($core_id: String!, $as_of: Date) {
    particle_events(device_id: $core_id, as_of: $as_of) {
      ...ParticleEventFragment
    }
  }
  ${ParticleEventFragmentFragmentDoc}
`;
export type GetHubParticleEventsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetHubParticleEventsQuery, GetHubParticleEventsQueryVariables>
> &
  TChildProps;
export function withGetHubParticleEvents<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetHubParticleEventsQuery,
    GetHubParticleEventsQueryVariables,
    GetHubParticleEventsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetHubParticleEventsQuery,
    GetHubParticleEventsQueryVariables,
    GetHubParticleEventsProps<TChildProps>
  >(GetHubParticleEventsDocument, {
    alias: 'withGetHubParticleEvents',
    ...operationOptions,
  });
}
export const GetHubsDocument = gql`
  query GetHubs($env: String!) {
    hubs_query(env: $env) {
      ...HubInfoFragment
    }
  }
  ${HubInfoFragmentFragmentDoc}
`;
export type GetHubsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetHubsQuery, GetHubsQueryVariables>
> &
  TChildProps;
export function withGetHubs<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetHubsQuery,
    GetHubsQueryVariables,
    GetHubsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetHubsQuery,
    GetHubsQueryVariables,
    GetHubsProps<TChildProps>
  >(GetHubsDocument, {
    alias: 'withGetHubs',
    ...operationOptions,
  });
}
export const GetIsFanControllerAssignedDocument = gql`
  query GetIsFanControllerAssigned($device_id: String!) {
    is_fan_controller_assigned(device_id: $device_id) {
      is_assigned
    }
  }
`;
export type GetIsFanControllerAssignedProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetIsFanControllerAssignedQuery, GetIsFanControllerAssignedQueryVariables>
> &
  TChildProps;
export function withGetIsFanControllerAssigned<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetIsFanControllerAssignedQuery,
    GetIsFanControllerAssignedQueryVariables,
    GetIsFanControllerAssignedProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetIsFanControllerAssignedQuery,
    GetIsFanControllerAssignedQueryVariables,
    GetIsFanControllerAssignedProps<TChildProps>
  >(GetIsFanControllerAssignedDocument, {
    alias: 'withGetIsFanControllerAssigned',
    ...operationOptions,
  });
}
export const GetIsHubAssignedDocument = gql`
  query GetIsHubAssigned($device_id: String!) {
    is_hub_assigned(device_id: $device_id) {
      is_assigned
    }
  }
`;
export type GetIsHubAssignedProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetIsHubAssignedQuery, GetIsHubAssignedQueryVariables>
> &
  TChildProps;
export function withGetIsHubAssigned<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetIsHubAssignedQuery,
    GetIsHubAssignedQueryVariables,
    GetIsHubAssignedProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetIsHubAssignedQuery,
    GetIsHubAssignedQueryVariables,
    GetIsHubAssignedProps<TChildProps>
  >(GetIsHubAssignedDocument, {
    alias: 'withGetIsHubAssigned',
    ...operationOptions,
  });
}
export const GetIsOtpValidDocument = gql`
  query GetIsOtpValid($token: String!) {
    isOtpValid(token: $token)
  }
`;
export type GetIsOtpValidProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetIsOtpValidQuery, GetIsOtpValidQueryVariables>
> &
  TChildProps;
export function withGetIsOtpValid<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetIsOtpValidQuery,
    GetIsOtpValidQueryVariables,
    GetIsOtpValidProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetIsOtpValidQuery,
    GetIsOtpValidQueryVariables,
    GetIsOtpValidProps<TChildProps>
  >(GetIsOtpValidDocument, {
    alias: 'withGetIsOtpValid',
    ...operationOptions,
  });
}
export const GetOpsViewColsVisibilityPrefsDocument = gql`
  query GetOpsViewColsVisibilityPrefs($user_id: Int!, $account_id: Int!) {
    operations_view_cols_visibility_prefs(user_id: $user_id, account_id: $account_id) {
      ...OpsViewVisibilityPrefsFragment
    }
  }
  ${OpsViewVisibilityPrefsFragmentFragmentDoc}
`;
export type GetOpsViewColsVisibilityPrefsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetOpsViewColsVisibilityPrefsQuery,
    GetOpsViewColsVisibilityPrefsQueryVariables
  >
> &
  TChildProps;
export function withGetOpsViewColsVisibilityPrefs<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetOpsViewColsVisibilityPrefsQuery,
    GetOpsViewColsVisibilityPrefsQueryVariables,
    GetOpsViewColsVisibilityPrefsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetOpsViewColsVisibilityPrefsQuery,
    GetOpsViewColsVisibilityPrefsQueryVariables,
    GetOpsViewColsVisibilityPrefsProps<TChildProps>
  >(GetOpsViewColsVisibilityPrefsDocument, {
    alias: 'withGetOpsViewColsVisibilityPrefs',
    ...operationOptions,
  });
}
export const GetOpsViewRowsVisibilityPrefsDocument = gql`
  query GetOpsViewRowsVisibilityPrefs($account_id: Int!) {
    operations_view_rows_visibility_prefs(account_id: $account_id) {
      ...OpsViewRowVisibilityPrefsFragment
    }
  }
  ${OpsViewRowVisibilityPrefsFragmentFragmentDoc}
`;
export type GetOpsViewRowsVisibilityPrefsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetOpsViewRowsVisibilityPrefsQuery,
    GetOpsViewRowsVisibilityPrefsQueryVariables
  >
> &
  TChildProps;
export function withGetOpsViewRowsVisibilityPrefs<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetOpsViewRowsVisibilityPrefsQuery,
    GetOpsViewRowsVisibilityPrefsQueryVariables,
    GetOpsViewRowsVisibilityPrefsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetOpsViewRowsVisibilityPrefsQuery,
    GetOpsViewRowsVisibilityPrefsQueryVariables,
    GetOpsViewRowsVisibilityPrefsProps<TChildProps>
  >(GetOpsViewRowsVisibilityPrefsDocument, {
    alias: 'withGetOpsViewRowsVisibilityPrefs',
    ...operationOptions,
  });
}
export const GetOrganizationUsersListDocument = gql`
  query GetOrganizationUsersList($account_id: Int!) {
    account(account_id: $account_id) {
      account_id
      organization_users_list {
        ...UserModelFragment
      }
    }
  }
  ${UserModelFragmentFragmentDoc}
`;
export type GetOrganizationUsersListProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetOrganizationUsersListQuery, GetOrganizationUsersListQueryVariables>
> &
  TChildProps;
export function withGetOrganizationUsersList<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetOrganizationUsersListQuery,
    GetOrganizationUsersListQueryVariables,
    GetOrganizationUsersListProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetOrganizationUsersListQuery,
    GetOrganizationUsersListQueryVariables,
    GetOrganizationUsersListProps<TChildProps>
  >(GetOrganizationUsersListDocument, {
    alias: 'withGetOrganizationUsersList',
    ...operationOptions,
  });
}
export const GetPelletGroupDocument = gql`
  query GetPelletGroup($pellet_group_id: Int!) {
    pellet_group(pellet_group_id: $pellet_group_id) {
      ...PelletGroupFragment
    }
  }
  ${PelletGroupFragmentFragmentDoc}
`;
export type GetPelletGroupProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetPelletGroupQuery, GetPelletGroupQueryVariables>
> &
  TChildProps;
export function withGetPelletGroup<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetPelletGroupQuery,
    GetPelletGroupQueryVariables,
    GetPelletGroupProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetPelletGroupQuery,
    GetPelletGroupQueryVariables,
    GetPelletGroupProps<TChildProps>
  >(GetPelletGroupDocument, {
    alias: 'withGetPelletGroup',
    ...operationOptions,
  });
}
export const GetPileDocument = gql`
  query GetPile($pile_id: Int!) {
    pile(pile_id: $pile_id) {
      ...PileFragment
    }
  }
  ${PileFragmentFragmentDoc}
`;
export type GetPileProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetPileQuery, GetPileQueryVariables>
> &
  TChildProps;
export function withGetPile<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetPileQuery,
    GetPileQueryVariables,
    GetPileProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetPileQuery,
    GetPileQueryVariables,
    GetPileProps<TChildProps>
  >(GetPileDocument, {
    alias: 'withGetPile',
    ...operationOptions,
  });
}
export const GetPileAccountDocument = gql`
  query GetPileAccount($pile_id: Int!) {
    pile(pile_id: $pile_id) {
      pile_id
      account_link {
        pile_id
        account_id
      }
    }
  }
`;
export type GetPileAccountProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetPileAccountQuery, GetPileAccountQueryVariables>
> &
  TChildProps;
export function withGetPileAccount<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetPileAccountQuery,
    GetPileAccountQueryVariables,
    GetPileAccountProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetPileAccountQuery,
    GetPileAccountQueryVariables,
    GetPileAccountProps<TChildProps>
  >(GetPileAccountDocument, {
    alias: 'withGetPileAccount',
    ...operationOptions,
  });
}
export const GetPileCablePelletLinksDocument = gql`
  query GetPileCablePelletLinks($pile_id: Int!) {
    pile_cable_pellet_links(pile_id: $pile_id) {
      ...PileCablePelletLinkFragment
    }
  }
  ${PileCablePelletLinkFragmentFragmentDoc}
`;
export type GetPileCablePelletLinksProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetPileCablePelletLinksQuery, GetPileCablePelletLinksQueryVariables>
> &
  TChildProps;
export function withGetPileCablePelletLinks<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetPileCablePelletLinksQuery,
    GetPileCablePelletLinksQueryVariables,
    GetPileCablePelletLinksProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetPileCablePelletLinksQuery,
    GetPileCablePelletLinksQueryVariables,
    GetPileCablePelletLinksProps<TChildProps>
  >(GetPileCablePelletLinksDocument, {
    alias: 'withGetPileCablePelletLinks',
    ...operationOptions,
  });
}
export const GetPileContainerDocument = gql`
  query GetPileContainer($container_id: Int!, $container_type: Int!) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      ...GrainContainerHubLinksFragment
      telemetry {
        ...BargeTelemetryFragment
      }
    }
  }
  ${GrainContainerHubLinksFragmentFragmentDoc}
  ${BargeTelemetryFragmentFragmentDoc}
`;
export type GetPileContainerProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetPileContainerQuery, GetPileContainerQueryVariables>
> &
  TChildProps;
export function withGetPileContainer<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetPileContainerQuery,
    GetPileContainerQueryVariables,
    GetPileContainerProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetPileContainerQuery,
    GetPileContainerQueryVariables,
    GetPileContainerProps<TChildProps>
  >(GetPileContainerDocument, {
    alias: 'withGetPileContainer',
    ...operationOptions,
  });
}
export const GetPromptFanGuidanceExtensionDocument = gql`
  query GetPromptFanGuidanceExtension($grain_bin_id: Int!) {
    prompt_fan_guidance_extension(grain_bin_id: $grain_bin_id)
  }
`;
export type GetPromptFanGuidanceExtensionProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetPromptFanGuidanceExtensionQuery,
    GetPromptFanGuidanceExtensionQueryVariables
  >
> &
  TChildProps;
export function withGetPromptFanGuidanceExtension<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetPromptFanGuidanceExtensionQuery,
    GetPromptFanGuidanceExtensionQueryVariables,
    GetPromptFanGuidanceExtensionProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetPromptFanGuidanceExtensionQuery,
    GetPromptFanGuidanceExtensionQueryVariables,
    GetPromptFanGuidanceExtensionProps<TChildProps>
  >(GetPromptFanGuidanceExtensionDocument, {
    alias: 'withGetPromptFanGuidanceExtension',
    ...operationOptions,
  });
}
export const GetRecommendedRunWindowsExperimentsDocument = gql`
  query GetRecommendedRunWindowsExperiments(
    $container_id: Int!
    $container_type: Int!
    $recommendation_type: String
    $cfm_scaling: Float
    $cfm_offset: Float
    $cfm_min: Float
    $cfm_max: Float
    $cfm: Float
    $upper_bound: Float
    $lower_bound: Float
    $from_weather_history: Boolean
    $prev_week_no: Int
  ) {
    recommended_run_windows_experiments(
      container_id: $container_id
      container_type: $container_type
      recommendation_type: $recommendation_type
      cfm_scaling: $cfm_scaling
      cfm_offset: $cfm_offset
      cfm_min: $cfm_min
      cfm_max: $cfm_max
      cfm: $cfm
      upper_bound: $upper_bound
      lower_bound: $lower_bound
      from_weather_history: $from_weather_history
      prev_week_no: $prev_week_no
    ) {
      ...RecommRunWindowsExperimentsFragment
    }
  }
  ${RecommRunWindowsExperimentsFragmentFragmentDoc}
`;
export type GetRecommendedRunWindowsExperimentsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetRecommendedRunWindowsExperimentsQuery,
    GetRecommendedRunWindowsExperimentsQueryVariables
  >
> &
  TChildProps;
export function withGetRecommendedRunWindowsExperiments<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetRecommendedRunWindowsExperimentsQuery,
    GetRecommendedRunWindowsExperimentsQueryVariables,
    GetRecommendedRunWindowsExperimentsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetRecommendedRunWindowsExperimentsQuery,
    GetRecommendedRunWindowsExperimentsQueryVariables,
    GetRecommendedRunWindowsExperimentsProps<TChildProps>
  >(GetRecommendedRunWindowsExperimentsDocument, {
    alias: 'withGetRecommendedRunWindowsExperiments',
    ...operationOptions,
  });
}
export const GetSensorGeneratedGrainTicketsDocument = gql`
  query GetSensorGeneratedGrainTickets($grain_bin_id: Int!, $grain_bin_storage_cycle_id: Int) {
    sensor_generated_grain_tickets(
      grain_bin_id: $grain_bin_id
      grain_bin_storage_cycle_id: $grain_bin_storage_cycle_id
    ) {
      ...SensorGeneratedGrainTicketFragment
    }
  }
  ${SensorGeneratedGrainTicketFragmentFragmentDoc}
`;
export type GetSensorGeneratedGrainTicketsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetSensorGeneratedGrainTicketsQuery,
    GetSensorGeneratedGrainTicketsQueryVariables
  >
> &
  TChildProps;
export function withGetSensorGeneratedGrainTickets<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetSensorGeneratedGrainTicketsQuery,
    GetSensorGeneratedGrainTicketsQueryVariables,
    GetSensorGeneratedGrainTicketsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetSensorGeneratedGrainTicketsQuery,
    GetSensorGeneratedGrainTicketsQueryVariables,
    GetSensorGeneratedGrainTicketsProps<TChildProps>
  >(GetSensorGeneratedGrainTicketsDocument, {
    alias: 'withGetSensorGeneratedGrainTickets',
    ...operationOptions,
  });
}
export const GetShowCo2LevelWarningDocument = gql`
  query GetShowCo2LevelWarning($container_id: Int!) {
    show_co2_level_warning(container_id: $container_id) {
      co2_alert_sent_on
      co2_alert_type
      co2_ppm
    }
  }
`;
export type GetShowCo2LevelWarningProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetShowCo2LevelWarningQuery, GetShowCo2LevelWarningQueryVariables>
> &
  TChildProps;
export function withGetShowCo2LevelWarning<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetShowCo2LevelWarningQuery,
    GetShowCo2LevelWarningQueryVariables,
    GetShowCo2LevelWarningProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetShowCo2LevelWarningQuery,
    GetShowCo2LevelWarningQueryVariables,
    GetShowCo2LevelWarningProps<TChildProps>
  >(GetShowCo2LevelWarningDocument, {
    alias: 'withGetShowCo2LevelWarning',
    ...operationOptions,
  });
}
export const GetShowGrainTicketLevelDeviationWarningDocument = gql`
  query GetShowGrainTicketLevelDeviationWarning(
    $grain_bin_id: Int!
    $grain_bin_storage_cycle_id: Int
  ) {
    show_grain_ticket_level_deviation_warning(
      grain_bin_id: $grain_bin_id
      grain_bin_storage_cycle_id: $grain_bin_storage_cycle_id
    )
  }
`;
export type GetShowGrainTicketLevelDeviationWarningProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetShowGrainTicketLevelDeviationWarningQuery,
    GetShowGrainTicketLevelDeviationWarningQueryVariables
  >
> &
  TChildProps;
export function withGetShowGrainTicketLevelDeviationWarning<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetShowGrainTicketLevelDeviationWarningQuery,
    GetShowGrainTicketLevelDeviationWarningQueryVariables,
    GetShowGrainTicketLevelDeviationWarningProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetShowGrainTicketLevelDeviationWarningQuery,
    GetShowGrainTicketLevelDeviationWarningQueryVariables,
    GetShowGrainTicketLevelDeviationWarningProps<TChildProps>
  >(GetShowGrainTicketLevelDeviationWarningDocument, {
    alias: 'withGetShowGrainTicketLevelDeviationWarning',
    ...operationOptions,
  });
}
export const GetUnreadNotificationsCountDocument = gql`
  query GetUnreadNotificationsCount($user_id: Int!) {
    user(user_id: $user_id) {
      user_new_notifications_count(period: "2W")
    }
  }
`;
export type GetUnreadNotificationsCountProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetUnreadNotificationsCountQuery, GetUnreadNotificationsCountQueryVariables>
> &
  TChildProps;
export function withGetUnreadNotificationsCount<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetUnreadNotificationsCountQuery,
    GetUnreadNotificationsCountQueryVariables,
    GetUnreadNotificationsCountProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetUnreadNotificationsCountQuery,
    GetUnreadNotificationsCountQueryVariables,
    GetUnreadNotificationsCountProps<TChildProps>
  >(GetUnreadNotificationsCountDocument, {
    alias: 'withGetUnreadNotificationsCount',
    ...operationOptions,
  });
}
export const GetUserDocument = gql`
  query GetUser($user_id: Int!) {
    user(user_id: $user_id) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type GetUserProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetUserQuery, GetUserQueryVariables>
> &
  TChildProps;
export function withGetUser<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetUserQuery,
    GetUserQueryVariables,
    GetUserProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetUserQuery,
    GetUserQueryVariables,
    GetUserProps<TChildProps>
  >(GetUserDocument, {
    alias: 'withGetUser',
    ...operationOptions,
  });
}
export const GetUserDeviceNotificationsHistoryDocument = gql`
  query GetUserDeviceNotificationsHistory($user_id: Int!, $account_id: Int!, $period: String!) {
    user_device_notifications_history(user_id: $user_id, account_id: $account_id, period: $period) {
      ...GrainBinNotificationFragment
    }
  }
  ${GrainBinNotificationFragmentFragmentDoc}
`;
export type GetUserDeviceNotificationsHistoryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    GetUserDeviceNotificationsHistoryQuery,
    GetUserDeviceNotificationsHistoryQueryVariables
  >
> &
  TChildProps;
export function withGetUserDeviceNotificationsHistory<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetUserDeviceNotificationsHistoryQuery,
    GetUserDeviceNotificationsHistoryQueryVariables,
    GetUserDeviceNotificationsHistoryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetUserDeviceNotificationsHistoryQuery,
    GetUserDeviceNotificationsHistoryQueryVariables,
    GetUserDeviceNotificationsHistoryProps<TChildProps>
  >(GetUserDeviceNotificationsHistoryDocument, {
    alias: 'withGetUserDeviceNotificationsHistory',
    ...operationOptions,
  });
}
export const GetViewerDocument = gql`
  query GetViewer {
    viewer {
      ...ViewerFragment
    }
  }
  ${ViewerFragmentFragmentDoc}
`;
export type GetViewerProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetViewerQuery, GetViewerQueryVariables>
> &
  TChildProps;
export function withGetViewer<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetViewerQuery,
    GetViewerQueryVariables,
    GetViewerProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetViewerQuery,
    GetViewerQueryVariables,
    GetViewerProps<TChildProps>
  >(GetViewerDocument, {
    alias: 'withGetViewer',
    ...operationOptions,
  });
}
export const GetWeatherHistoryDocument = gql`
  query GetWeatherHistory($container_id: Int!, $container_type: Int!, $prev_week_no: Int!) {
    grain_container(container_id: $container_id, container_type: $container_type) {
      telemetry {
        weather_history(prev_week_no: $prev_week_no) {
          ...WeatherHistoryFragment
        }
      }
    }
  }
  ${WeatherHistoryFragmentFragmentDoc}
`;
export type GetWeatherHistoryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<GetWeatherHistoryQuery, GetWeatherHistoryQueryVariables>
> &
  TChildProps;
export function withGetWeatherHistory<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GetWeatherHistoryQuery,
    GetWeatherHistoryQueryVariables,
    GetWeatherHistoryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    GetWeatherHistoryQuery,
    GetWeatherHistoryQueryVariables,
    GetWeatherHistoryProps<TChildProps>
  >(GetWeatherHistoryDocument, {
    alias: 'withGetWeatherHistory',
    ...operationOptions,
  });
}
