import * as React from 'react';
const TargetMoistureIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} viewBox="0 0 40 40" {...props}>
    <g id="TargetMoistureIcon" transform="translate(0.439 0.42)">
      <g
        id="Rectangle_285"
        data-name="Rectangle 285"
        transform="translate(-0.439 -0.42)"
        fill="#eff1f4"
        stroke="rgba(0,0,0,0)"
        strokeWidth={1}
      >
        <rect width={40} height={40} rx={20} stroke="none" />
        <rect x={0.5} y={0.5} width={39} height={39} rx={19.5} fill="none" />
      </g>
      <path
        id="Path_317"
        data-name="Path 317"
        d="M22.7,14.318l-3.642-3.635a.733.733,0,0,0-1.038,0l-3.65,3.635a5.887,5.887,0,1,0,8.33,0Zm-8.58,4.415a4.164,4.164,0,0,1,1.3-3.238l3.12-3.186,3.12,3.223a4.1,4.1,0,0,1,1.3,3.2Z"
        transform="translate(1.028 1.609)"
        fill="#242a33"
      />
      <path
        id="Path_318"
        data-name="Path 318"
        d="M29.854,14.68l-3.282-.015h0a11.444,11.444,0,0,0-10.854-10.7V.545a.54.54,0,0,0-.155-.383.524.524,0,0,0-.758,0,.548.548,0,0,0-.147.375l-.015,3.429h0A11.441,11.441,0,0,0,3.768,14.673H.537a.537.537,0,0,0,0,1.074l3.238.015h0A11.426,11.426,0,0,0,14.658,26.785v3.083a.528.528,0,0,0,.155.383.534.534,0,0,0,.383.162.511.511,0,0,0,.375-.155.523.523,0,0,0,.155-.375l.015-3.091A11.427,11.427,0,0,0,26.586,15.74h3.26a.537.537,0,0,0,0-1.074v.015ZM15.74,25.7V24.246a.547.547,0,0,0-.162-.383.528.528,0,0,0-.383-.155.535.535,0,0,0-.537.537v1.464a10.361,10.361,0,0,1-9.816-9.963h1.31a.537.537,0,0,0,0-1.074h-1.3a10.351,10.351,0,0,1,9.794-9.64V6.152a.534.534,0,0,0,.162.383.542.542,0,0,0,.758,0,.523.523,0,0,0,.155-.375V5.033a10.368,10.368,0,0,1,9.8,9.625H24.261a.537.537,0,0,0,0,1.074h1.288a10.361,10.361,0,0,1-9.809,9.978h0Z"
        transform="translate(4.369 4.374)"
      />
    </g>
  </svg>
);

const TargetTempIcon = (props) => (
  <svg
    id="TargetTempIcon"
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    viewBox="0 0 40 40"
    {...props}
  >
    <rect
      id="Rectangle_293"
      data-name="Rectangle 293"
      width={40}
      height={40}
      rx={20}
      fill="#eff1f4"
    />
    <path
      id="Path_316"
      data-name="Path 316"
      d="M23.257,19.109V12.484A2.662,2.662,0,0,0,20.442,10a2.662,2.662,0,0,0-2.815,2.484v6.625a4.009,4.009,0,0,0-1.877,3.312,4.444,4.444,0,0,0,4.692,4.14,4.444,4.444,0,0,0,4.692-4.14A4.009,4.009,0,0,0,23.257,19.109ZM19.5,12.484a.946.946,0,0,1,1.877,0h-.938v.828h.938v1.656h-.938V15.8h.938v1.656H19.5Z"
      transform="translate(-0.441 1.719)"
      fill="#242a33"
    />
    <path
      id="Path_330"
      data-name="Path 330"
      d="M29.854,14.68l-3.282-.015h0a11.444,11.444,0,0,0-10.854-10.7V.545a.54.54,0,0,0-.155-.383.524.524,0,0,0-.758,0,.548.548,0,0,0-.147.375l-.015,3.429h0A11.441,11.441,0,0,0,3.768,14.673H.537a.537.537,0,0,0,0,1.074l3.238.015h0A11.426,11.426,0,0,0,14.658,26.785v3.083a.528.528,0,0,0,.155.383.534.534,0,0,0,.383.162.511.511,0,0,0,.375-.155.523.523,0,0,0,.155-.375l.015-3.091A11.427,11.427,0,0,0,26.586,15.74h3.26a.537.537,0,0,0,0-1.074v.015ZM15.74,25.7V24.246a.547.547,0,0,0-.162-.383.528.528,0,0,0-.383-.155.535.535,0,0,0-.537.537v1.464a10.361,10.361,0,0,1-9.816-9.963h1.31a.537.537,0,0,0,0-1.074h-1.3a10.351,10.351,0,0,1,9.794-9.64V6.152a.534.534,0,0,0,.162.383.542.542,0,0,0,.758,0,.523.523,0,0,0,.155-.375V5.033a10.368,10.368,0,0,1,9.8,9.625H24.261a.537.537,0,0,0,0,1.074h1.288a10.361,10.361,0,0,1-9.809,9.978h0Z"
      transform="translate(4.809 4.794)"
    />
  </svg>
);

const BlurTargetMoistureIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26.261}
    height={26.285}
    viewBox="0 0 26.261 26.285"
    {...props}
  >
    <g id="Group_48" data-name="Group 48" transform="translate(-4232.193 -434.306)">
      <path
        id="Path_101"
        data-name="Path 101"
        d="M4248.926,444.291l-3.148-3.142a.634.634,0,0,0-.9,0l-3.154,3.142a5.089,5.089,0,1,0,7.2,0Zm-7.415,3.816a3.6,3.6,0,0,1,1.119-2.8l2.7-2.753,2.7,2.785a3.545,3.545,0,0,1,1.12,2.767Z"
        fill="gray"
      />
      <path
        id="Path_102"
        data-name="Path 102"
        d="M4258,446.994l-2.837-.013h0a9.889,9.889,0,0,0-9.381-9.247v-2.958a.466.466,0,0,0-.464-.47.456.456,0,0,0-.324.14.472.472,0,0,0-.128.324l-.012,2.964h0a9.887,9.887,0,0,0-9.4,9.253h-2.792a.464.464,0,1,0,0,.929l2.8.013h0a9.876,9.876,0,0,0,9.406,9.527v2.665a.456.456,0,0,0,.134.33.461.461,0,0,0,.331.14.442.442,0,0,0,.324-.133.453.453,0,0,0,.134-.325l.012-2.671a9.875,9.875,0,0,0,9.375-9.552h2.817a.465.465,0,1,0,0-.929v.013Zm-12.2,9.527v-1.259a.474.474,0,0,0-.14-.331.456.456,0,0,0-.33-.134.464.464,0,0,0-.465.465v1.265a8.956,8.956,0,0,1-8.484-8.611h1.132a.464.464,0,0,0,0-.929h-1.119a8.946,8.946,0,0,1,8.465-8.331v.967a.461.461,0,0,0,.14.33.467.467,0,0,0,.655,0,.45.45,0,0,0,.134-.324v-.973a8.96,8.96,0,0,1,8.471,8.319h-1.094a.464.464,0,0,0,0,.928h1.113a8.953,8.953,0,0,1-8.478,8.624h0Z"
        fill="gray"
      />
    </g>
  </svg>
);

export { TargetMoistureIcon, BlurTargetMoistureIcon, TargetTempIcon };
