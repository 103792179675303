import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import NoPlotPlaceholder from '../../../../../site-public-webapp/media/noplotholder.png';
import { BlockDialogSpinner } from '../../spinner';

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
    // [theme.breakpoints.up('sm')]: { marginTop: 10 },
  },
}));
export const BinForecastUnavailable = ({ title, subTitle, hideSpinner = false }) => {
  const classes = useStyles();

  return (
    <BlockDialogSpinner
      open={true}
      title={title}
      subTitle={subTitle}
      hideSpinner={Boolean(hideSpinner)}
    >
      <div style={{ width: '100%', height: '100%' }}>
        <img className={classes.img} src={NoPlotPlaceholder} alt="No plot placeholder" />
      </div>
    </BlockDialogSpinner>
  );
};
