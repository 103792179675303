import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  DialogContent,
  Divider,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as d3 from 'd3';
import Plotly from 'plotly.js-cartesian-dist-min';
import React, { useEffect, useRef, useState } from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
import {
  GrainBinEstimate,
  withGetBinEstimateProcessingStateHoc,
  WithGetBinEstimateProcessingStateHocChildProps,
  WithGetGrainBinEstimateHocChildProps,
  withGetGrainBinEstimatesHoc,
} from '../../../api';
import { getChartDataApi } from '../../../api/rest';
import {
  ColorBarSVGOptions,
  downloadAllColorBarSVGs,
} from '../../../api/rest/bin-colorbar-svg-download';
import { amber_dark_grey } from '../../../style';
import { BlockDialogSpinner, CenteredSpinner } from '../../spinner';
import { SimpleDialog } from '../../util/SimpleDialog';
import { SummaryCard } from '../summary-card';
import BinEstimate from './BinEstimate';
import BinEstimateInputParamsCard from './BinEstimateInputParamsCard';
import BinEstimateMoreInfo from './BinEstimateMoreInfo';
import { BinEstimateTabPanel } from './BinEstimateTabPanel';
import { BinSimulationInputsForm } from './BinSimulationInputsForm';
import { ColorBar, selectTemperatureOrMoistureValue } from './ColorBar';
import { GrainBinInfo } from './GrainBinInfo';
import MobileBinEstimateInputParamsCard from './MobileBinEstimateInputParamsCard';

const Plot = createPlotlyComponent(Plotly);

const useStyles = makeStyles({
  paperClass: {
    minWidth: 300,
    maxWidth: 553,
    margin: 20,
    width: '100%',
    overflowY: 'initial',
  },
  accordionHeader: {
    marginTop: '10px',
    border: '1px solid red',
    paddingTop: '5px',
    borderRadius: 5,
  },
  tab: {
    width: '50%',
    fontSize: 14,
    maxWidth: '50%',
  },
});

type BinEstimateCardProps = {
  grain_bin_id: number;
  diagnostic_mode: boolean;
} & WithGetGrainBinEstimateHocChildProps &
  WithGetBinEstimateProcessingStateHocChildProps;

export type BinEstimateChartDataType = {
  data: object[];
  layout: object;
  config?: object;
};

const checkIfHasTargetInputs = (grainBinEstimateMetaData: GrainBinEstimate | null) => {
  return Boolean(
    grainBinEstimateMetaData &&
      grainBinEstimateMetaData.target_moisture_percent !== undefined &&
      grainBinEstimateMetaData.target_moisture_percent !== null &&
      grainBinEstimateMetaData.target_temperature_f !== undefined &&
      grainBinEstimateMetaData.target_temperature_f !== null
  );
};

const InternalFigureAccordion = ({
  title,
  chartData,
  containerHeight,
  svgWidth,
  svgHeight,
  grainBinEstimateMetaData,
  renderColorBar,
  isMoisture,
  colorBarSVGOptions,
}: {
  svgWidth: number;
  svgHeight: number;
  title: string;
  chartData: BinEstimateChartDataType | null;
  grainBinEstimateMetaData: GrainBinEstimate | null;
  renderColorBar: boolean;
  colorBarSVGOptions: ColorBarSVGOptions | null;
  isMoisture: boolean;
  containerHeight: number;
}) => {
  const plotRef = useRef(null);
  const classes = useStyles();
  const [expandedState, setExpandedState] = useState<boolean>(false);

  const onExpandChange = () => {
    if (plotRef.current && renderColorBar) {
      d3.select(plotRef.current)
        .select('.infolayer')
        .style('display', 'none');
    }
    setExpandedState(!expandedState);
  };

  return (
    <>
      {
        <Accordion
          style={{ width: '100%' }}
          className={classes.accordionHeader}
          expanded={expandedState}
          onChange={onExpandChange}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6">{title} </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0, width: '100%' }}>
            <Grid container={true}>
              {chartData ? (
                <div
                  ref={plotRef}
                  style={{
                    width: '100%',
                    display: 'flex',
                    height: `${containerHeight}px`,
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Plot
                    {...chartData}
                    layout={{
                      ...chartData.layout,
                      autosize: true,
                      width: undefined,
                      height: undefined,
                    }}
                    useResizeHandler={true}
                    style={{
                      maxWidth: svgWidth,
                      maxHeight: svgHeight,
                      width: '100%',
                      height: '100%',
                    }}
                    config={{
                      responsive: true,
                      modeBarButtonsToRemove: ['toImage', 'pan2d', 'zoom', 'resetScale2d'],
                      displaylogo: false,
                    }}
                  />
                </div>
              ) : (
                <Typography variant="h6"> No Figure Available</Typography>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      }
    </>
  );
};

export const BinEstimateCard = withGetBinEstimateProcessingStateHoc(
  withGetGrainBinEstimatesHoc(
    ({
      grain_bin_id,
      get_grain_bin_estimate_loading,
      grain_bin_estimate,
      bin_estimate_processing_state_loading,
      bin_estimate_processing_state,
      refetch_grain_bin_estimates,
      diagnostic_mode,
    }: BinEstimateCardProps) => {
      const classes = useStyles();

      const theme = useTheme();
      const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

      //
      // Handle Edit Input Params Modal.
      //
      const [showEditInputParams, setShowEditInputParams] = useState<boolean>(false);
      const openInputParamsModal: () => void = () => {
        setShowEditInputParams(true);
      };
      const handleClose = () => {
        setShowEditInputParams(false);
      };

      const [
        internalMoistureData,
        setInternalMoistureData,
      ] = useState<BinEstimateChartDataType | null>(null);
      const [internalSliderData, setInternalSliderData] = useState<BinEstimateChartDataType | null>(
        null
      );
      const [
        internalTemperatureData,
        setInternalTemperatureData,
      ] = useState<BinEstimateChartDataType | null>(null);

      const [loading, setLoading] = useState(false);
      const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
      const [isBinEstimateProcessing, setIsBinEstimateProcessing] = useState<boolean>(false);
      const [
        grainBinEstimateMetaData,
        setGrainBinEstimateMetaData,
      ] = useState<GrainBinEstimate | null>(null);

      const [
        binEstimateChartData,
        setBinEstimateChartData,
      ] = useState<BinEstimateChartDataType | null>(null);

      const [colorBarSVGOptions, setColorBarSVGOptions] = useState<ColorBarSVGOptions | null>(null);

      const TEMPERATURE_TAB_VALUE = 0;
      const MOISTURE_TAB_VALUE = 1;
      const estimatesTabs = [{ label: 'Temperature' }, { label: 'Moisture' }];
      const [tabValue, setTabValue] = useState(TEMPERATURE_TAB_VALUE);
      const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
      };
      const fetchTemperatureChartData = async (temperature_chart_data_url: string) => {
        try {
          setLoading(true);
          const response = await getChartDataApi(temperature_chart_data_url);

          console.log('chartData', response.data);
          await setBinEstimateChartData(response.data);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      const getChart = async (binEstimate: GrainBinEstimate, tabValue: number) => {
        {
          if (binEstimate.internal_moisture_json) {
            const response = await getChartDataApi(binEstimate.internal_moisture_json);
            setInternalMoistureData(response.data);
          }
          if (binEstimate.internal_slider_f_json) {
            const response = await getChartDataApi(binEstimate.internal_slider_f_json);
            setInternalSliderData(response.data);
          }
          if (binEstimate.internal_temperature_f_json) {
            const response = await getChartDataApi(binEstimate.internal_temperature_f_json);
            setInternalTemperatureData(response.data);
          }
        }

        {
          const svgOptions = binEstimate && (await downloadAllColorBarSVGs(binEstimate));
          console.log('svgOptions', { svgOptions });
          await setColorBarSVGOptions(svgOptions);
        }

        const { temperature_chart_data_url, moisture_chart_data_url } = binEstimate;
        if (tabValue === TEMPERATURE_TAB_VALUE && temperature_chart_data_url) {
          await fetchTemperatureChartData(temperature_chart_data_url);
        } else if (tabValue === MOISTURE_TAB_VALUE && moisture_chart_data_url) {
          await fetchTemperatureChartData(moisture_chart_data_url);
        }
      };

      useEffect(() => {
        if (!bin_estimate_processing_state_loading) {
          const { processing } = bin_estimate_processing_state;
          console.log({ processing, bin_estimate_processing_state });
          setIsBinEstimateProcessing(processing);
        }
      }, [bin_estimate_processing_state_loading, JSON.stringify(bin_estimate_processing_state)]);

      useEffect(() => {
        console.log('grain_bin_estimate', grain_bin_estimate);
        if (grain_bin_estimate) {
          setGrainBinEstimateMetaData(grain_bin_estimate);
          // Fire and forget results.
          getChart(grain_bin_estimate, tabValue).then(() => null);
        }
      }, [grain_bin_estimate, tabValue]);

      const hasTargetInputs = checkIfHasTargetInputs(grainBinEstimateMetaData);

      console.log('grainBinEstimateMetaData', grainBinEstimateMetaData);

      const inputPanel = (
        <>
          {grainBinEstimateMetaData ? (
            <Grid
              item
              xs={6}
              style={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                paddingRight: 30,
              }}
            >
              <BinEstimateInputParamsCard
                grainBinEstimateMetaData={grainBinEstimateMetaData}
                openInputParamsModal={openInputParamsModal}
              />
            </Grid>
          ) : null}
        </>
      );

      const mobileInputPanel = (
        <>
          {grainBinEstimateMetaData && (
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <MobileBinEstimateInputParamsCard
                grainBinEstimateMetaData={grainBinEstimateMetaData}
                openInputParamsModal={openInputParamsModal}
              />
            </Grid>
          )}
        </>
      );

      const tabPanel = (
        <>
          <Grid item xs={isMobile ? 12 : 6} style={{ maxWidth: isMobile ? '100%' : '50%' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="bin simulation">
              {estimatesTabs.map(({ label }) => (
                <Tab key={label} className={classes.tab} label={label} />
              ))}
            </Tabs>
            {estimatesTabs.map(({ label }, idx) => (
              <BinEstimateTabPanel key={label} value={tabValue} index={idx}>
                {loading || get_grain_bin_estimate_loading ? (
                  <CenteredSpinner fadeIn="none" />
                ) : (
                  <BinEstimate
                    is_mobile={isMobile}
                    grain_bin_id={grain_bin_id}
                    chartData={binEstimateChartData}
                    colorBarSVGOptions={colorBarSVGOptions}
                    estimate_type_label={label}
                    grainBinEstimateMetaData={grainBinEstimateMetaData}
                    openInputParamsModal={openInputParamsModal}
                  />
                )}
              </BinEstimateTabPanel>
            ))}
          </Grid>
        </>
      );

      const simpleDialog = (
        <>
          <SimpleDialog
            paperClass={classes.paperClass}
            open={showEditInputParams}
            handleClose={handleClose}
          >
            <div style={{ padding: '20px 10px' }}>
              <Typography
                variant="h5"
                style={{ fontSize: 20, color: amber_dark_grey, textAlign: 'start' }}
              >
                Input Parameters
              </Typography>
            </div>
            <Divider style={{ marginBottom: 20 }} />
            <DialogContent style={{ paddingTop: 0 }}>
              {grainBinEstimateMetaData ? (
                <>
                  <GrainBinInfo grainBinEstimateMetaData={grainBinEstimateMetaData} />
                  <Divider style={{ marginBottom: 20, marginTop: 10 }} />
                  <BinSimulationInputsForm
                    colorBarSVGOptions={colorBarSVGOptions}
                    grain_bin_id={grain_bin_id}
                    target_temperature_f={
                      grainBinEstimateMetaData.target_temperature_f !== undefined &&
                      grainBinEstimateMetaData.target_temperature_f !== null
                        ? Number(grainBinEstimateMetaData.target_temperature_f)
                        : ''
                    }
                    target_mcwb_percent={
                      grainBinEstimateMetaData.target_moisture_percent !== undefined &&
                      grainBinEstimateMetaData.target_moisture_percent !== null
                        ? Number(grainBinEstimateMetaData.target_moisture_percent)
                        : ''
                    }
                    onCancel={handleClose}
                    refetchGrainBinEstimates={refetch_grain_bin_estimates}
                  />
                </>
              ) : (
                <>Bin Forecast information unavailable</>
              )}
            </DialogContent>
          </SimpleDialog>
        </>
      );

      return (
        <>
          <SummaryCard
            title="Bin Simulation"
            title_badge="BETA"
            header_action={
              <Button style={{ maxHeight: 26 }} onClick={() => setShowMoreInfo(true)}>
                MORE INFO
              </Button>
            }
            contentStyle={{ padding: isBinEstimateProcessing ? 5 : undefined }}
          >
            <BlockDialogSpinner
              open={isBinEstimateProcessing && hasTargetInputs}
              title="Computing..."
              subTitle={
                <>
                  Bin Simulation is computing,
                  <br />
                  Please check back later
                </>
              }
            >
              <Grid container={true}>
                {isMobile ? (
                  <>
                    {tabPanel}
                    {mobileInputPanel}
                  </>
                ) : (
                  <>
                    {inputPanel}
                    {tabPanel}
                  </>
                )}
              </Grid>
            </BlockDialogSpinner>
            <BinEstimateMoreInfo showMoreInfo={showMoreInfo} setShowMoreInfo={setShowMoreInfo} />
          </SummaryCard>

          {diagnostic_mode && (
            <>
              <InternalFigureAccordion
                isMoisture={false}
                colorBarSVGOptions={colorBarSVGOptions}
                renderColorBar={false}
                grainBinEstimateMetaData={grainBinEstimateMetaData}
                svgWidth={380}
                svgHeight={380}
                containerHeight={440}
                title={'Internal Figure - Temperature'}
                chartData={internalTemperatureData}
              />
              <InternalFigureAccordion
                isMoisture={true}
                colorBarSVGOptions={colorBarSVGOptions}
                renderColorBar={false}
                grainBinEstimateMetaData={grainBinEstimateMetaData}
                svgWidth={380}
                svgHeight={380}
                containerHeight={440}
                title={'Internal Figure - Moisture'}
                chartData={internalMoistureData}
              />
              <InternalFigureAccordion
                isMoisture={false}
                colorBarSVGOptions={null}
                renderColorBar={false}
                grainBinEstimateMetaData={grainBinEstimateMetaData}
                svgWidth={1300}
                svgHeight={700}
                containerHeight={700}
                title={'Internal' + ' Figure - Slider'}
                chartData={internalSliderData}
              />
            </>
          )}

          {simpleDialog}
        </>
      );
    }
  )
);
