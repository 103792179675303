import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const BinEstimateTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`bin-estimate-tabpanel-${index}`}
      aria-labelledby={`bin-estimate-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          style={{
            minHeight: 368,
            display: 'flex',
            // paddingTop: isMobile ? 0 : 20,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};
