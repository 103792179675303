import { Link, Typography } from '@material-ui/core';
import * as d3 from 'd3';
import { DateTime } from 'luxon';
import Plotly from 'plotly.js-cartesian-dist-min';
import React, { useEffect, useRef } from 'react';
import { ColorBarSVGOptions } from '../../../api/rest/bin-colorbar-svg-download';
// import Plot from 'react-plotly.js';
import { BinEstimateChartDataType } from './BinEstimateCard';

import createPlotlyComponent from 'react-plotly.js/factory';
import { GrainBinEstimate } from '../../../../../core/src/api';
import { amber_link, gray_shade_6 } from '../../../../../core/src/style';
import { LaunchLinkIcon } from '../../../media';
import { BinForecastUnavailable } from './BinForecastUnavailable';
import { ColorBar, selectTemperatureOrMoistureValue } from './ColorBar';
const Plot = createPlotlyComponent(Plotly);

const delay = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const hidePlotLegend = (rootElement: any) => {
  d3.select(rootElement)
    .select('.infolayer')
    .style('display', 'none');
};

export const BinEstimatePlot = ({
  colorBarSVGOptions,
  is_mobile,
  estimate_type_label,
  openInputParamsModal,
  chartData,
  lastUpdatedDate,
  grainBinEstimateMetaData,
}: {
  is_mobile: boolean;
  colorBarSVGOptions: ColorBarSVGOptions | null;
  estimate_type_label: string;
  openInputParamsModal: () => void;
  chartData: BinEstimateChartDataType | null;
  noEstimatesFoundMsg: string;
  lastUpdatedDate: Date | null;
  grainBinEstimateMetaData: GrainBinEstimate | null;
}) => {
  const plotRef = useRef(null);
  const isMoisture = estimate_type_label === 'Moisture';

  useEffect(() => {
    if (plotRef.current) {
      hidePlotLegend(plotRef.current);
    }
  }, []);

  const lastUpdatedDateDt = lastUpdatedDate
    ? DateTime.fromMillis(new Date(lastUpdatedDate).getTime())
    : null;

  const lastUpdatedDateStr = lastUpdatedDateDt
    ? `${lastUpdatedDateDt.toFormat('MM/dd/yyyy')} at ${lastUpdatedDateDt.toFormat('hh:mm a')}`
    : 'N/A';

  const hasTargetInputs =
    grainBinEstimateMetaData &&
    grainBinEstimateMetaData.target_moisture_percent !== undefined &&
    grainBinEstimateMetaData.target_moisture_percent !== null &&
    grainBinEstimateMetaData.target_temperature_f !== undefined &&
    grainBinEstimateMetaData.target_temperature_f !== null;

  console.log('BinEstimate hasTargetInputs', { hasTargetInputs });

  const placeholderPlot = !hasTargetInputs ? (
    <>
      <BinForecastUnavailable
        hideSpinner={true}
        title={'Bin Simulation Unavailable'}
        subTitle={
          <Link
            style={{
              color: amber_link,
              fontSize: 14,
              textDecoration: 'underline',
              display: 'inline-flex',
              alignItems: 'center',
            }}
            onClick={openInputParamsModal}
          >
            Missing Input Parameters
            <LaunchLinkIcon width={20} height={18} fill={amber_link} />
          </Link>
        }
      />
    </>
  ) : (
    <>
      <BinForecastUnavailable
        title={'Bin Simulation Unavailable'}
        subTitle={
          <>
            We may have encountered an error.
            <br />
            Please email support@amber.ag
          </>
        }
      />
    </>
  );

  const canRenderPlot =
    hasTargetInputs &&
    grainBinEstimateMetaData &&
    !grainBinEstimateMetaData.is_bin_forecast_unavailable &&
    chartData;

  return (
    <>
      {canRenderPlot && chartData && grainBinEstimateMetaData ? (
        /* Rendering of the available bin forecast for simulation*/
        <>
          <div
            ref={plotRef}
            style={{
              width: '100%',
              display: 'flex',
              height: '440px',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Plot
              {...chartData}
              layout={{
                ...chartData.layout,
                autosize: true,
                width: undefined,
                height: undefined,
              }}
              useResizeHandler={true}
              style={{ maxWidth: 380, maxHeight: 380, width: '100%', height: '100%' }}
              config={{
                responsive: true,
                modeBarButtonsToRemove: ['toImage', 'pan2d', 'zoom', 'resetScale2d'],
                displaylogo: false,
              }}
            />

            {colorBarSVGOptions && (
              <ColorBar
                useSmallColorBar={false}
                isMobile={is_mobile}
                colorBarSVGOptions={colorBarSVGOptions}
                isMoisture={isMoisture}
                value={selectTemperatureOrMoistureValue(isMoisture, grainBinEstimateMetaData)}
              />
            )}
          </div>

          <div style={{ maxWidth: '100%', marginBottom: 5 }}>
            <Typography style={{ color: gray_shade_6, fontSize: 14, letterSpacing: 0 }}>
              Last Updated: {lastUpdatedDateStr}
            </Typography>
          </div>
        </>
      ) : (
        /* Rendering of the placeholder plot when the bin forecast is not available */
        <>
          <div
            style={{
              width: '100%',
              boxSizing: 'border-box',
              padding: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {placeholderPlot}
          </div>
        </>
      )}
    </>
  );
};
