import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { memo } from 'react';
import { CloseRoundedBtnIcon } from '../../../../core/src/media';

const useStyles = makeStyles({
  paper: {
    minWidth: 300,
    maxWidth: 302,
    margin: 20,
    width: '100%',
    overflowY: 'initial',
  },

  close: {
    position: 'absolute',
    right: -15,
    top: -15,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
    border: '1px solid #707070',
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor: 'white',
  },
});

// Simple dialog for user-facing applciations with built in title formatting, width,  and more intuitive close button
export const SimpleDialog: React.FunctionComponent<{
  open: boolean;
  children: React.ReactNode;
  handleClose: () => void;
  showCloseButton?: boolean;
  preventOutsideClick?: boolean;
  classes?: any;
  paperClass?: any;
}> = memo(
  ({
    open,
    children,
    handleClose,
    showCloseButton = true,
    preventOutsideClick = false,
    paperClass = '',
    ...props
  }) => {
    const classes = useStyles();
    const handleDialogClose = (event, reason) => {
      if (preventOutsideClick && reason && reason === 'backdropClick') return;
      handleClose();
    };
    return (
      <Dialog
        open={open}
        onClose={handleDialogClose}
        classes={{ paper: paperClass ? paperClass : classes.paper }}
        fullWidth
        {...props}
      >
        {showCloseButton && (
          <div className={classes.close} onClick={handleClose}>
            <CloseRoundedBtnIcon />
          </div>
        )}

        {children}
      </Dialog>
    );
  }
);
