import axios from 'axios';
import { GrainBinEstimate } from '../graphql';

export type ColorBarSVGOptions = {
  smallTemperatureColorBarSVG: string;
  smallMoistureColorBarSVG: string;
  temperatureColorBarSVG: string;
  moistureColorBarSVG: string;
};

const TEMPERATURE_COLOR_BAR_SVG_URL =
  'https://sagemaker-prod-amber-grain-bin-data-model.s3.us-east-2.amazonaws.com/grain_container/grain_bin/819/2024-08-20T00%3A11%3A02.944Z/temperature_f_colorbar.svg';
const MOISTURE_COLOR_BAR_SVG_URL =
  'https://sagemaker-prod-amber-grain-bin-data-model.s3.us-east-2.amazonaws.com/grain_container/grain_bin/819/2024-08-20T00%3A11%3A02.944Z/moisture_colorbar.svg';

const SMALL_TEMPERATURE_COLOR_BAR_SVG_URL =
  'https://sagemaker-prod-amber-grain-bin-data-model.s3.us-east-2.amazonaws.com/grain_container/grain_bin/819/2024-08-20T00%3A11%3A02.944Z/temperature_f_colorbar_small.svg';
const SMALL_MOISTURE_COLOR_BAR_SVG_URL =
  'https://sagemaker-prod-amber-grain-bin-data-model.s3.us-east-2.amazonaws.com/grain_container/grain_bin/819/2024-08-20T00%3A11%3A02.944Z/moisture_colorbar_small.svg';

const downloadSVG = async (url: string) => {
  const response = await axios.get(url);
  return response.data as string;
};

const downloadMoistureColorBarSVG = async () => {
  const response = await axios.get(MOISTURE_COLOR_BAR_SVG_URL);
  return response.data as string;
};

const downloadSmallMoistureColorBarSVG = async () => {
  const response = await axios.get(SMALL_MOISTURE_COLOR_BAR_SVG_URL);
  return response.data as string;
};

const downloadTemperatureColorBarSVG = async () => {
  const response = await axios.get(TEMPERATURE_COLOR_BAR_SVG_URL);
  return response.data as string;
};

const downloadSmallTemperatureColorBarSVG = async () => {
  const response = await axios.get(SMALL_TEMPERATURE_COLOR_BAR_SVG_URL);
  return response.data as string;
};

export const downloadColorBarSVGs = async () => {
  const temperatureColorBarSVG = await downloadTemperatureColorBarSVG();
  const moistureColorBarSVG = await downloadMoistureColorBarSVG();
  const smallTemperatureColorBarSVG = await downloadSmallTemperatureColorBarSVG();
  const smallMoistureColorBarSVG = await downloadSmallMoistureColorBarSVG();
  return {
    smallTemperatureColorBarSVG,
    smallMoistureColorBarSVG,
    temperatureColorBarSVG,
    moistureColorBarSVG,
  } as ColorBarSVGOptions;
};

export const downloadAllColorBarSVGs = async (
  grain_bin_estimate: GrainBinEstimate
): Promise<ColorBarSVGOptions | null> => {
  if (
    grain_bin_estimate.moisture_colorbar_svg_url &&
    grain_bin_estimate.temperature_f_colorbar_svg_url &&
    grain_bin_estimate.temperature_f_colorbar_small_svg_url &&
    grain_bin_estimate.moisture_colorbar_small_svg_url
  ) {
    const temperatureColorBarSVG = await downloadSVG(
      grain_bin_estimate.temperature_f_colorbar_svg_url
    );
    const moistureColorBarSVG = await downloadSVG(grain_bin_estimate.moisture_colorbar_svg_url);
    const smallTemperatureColorBarSVG = await downloadSVG(
      grain_bin_estimate.temperature_f_colorbar_small_svg_url
    );
    const smallMoistureColorBarSVG = await downloadSVG(
      grain_bin_estimate.moisture_colorbar_small_svg_url
    );

    return {
      smallTemperatureColorBarSVG,
      smallMoistureColorBarSVG,
      temperatureColorBarSVG,
      moistureColorBarSVG,
    } as ColorBarSVGOptions;
  }

  return null;
};
