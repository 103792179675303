import { ApolloError } from 'apollo-client';
import { AmberHoc, AmberHocProps } from '../../../util';
import {
  BinGrainModelPrerequisitesInfoFragmentFragment,
  withGetBinGrainModelPrerequisites as HOC,
} from '../__generated';

export type WithGetBinGrainModelPrerequisitesHocOwnProps = {
  grain_bin_id: number;
  loading?: boolean;
  onError?: (error: ApolloError) => void;
};
export type WithGetBinGrainModelPrerequisitesHocChildProps = {
  bin_grain_model_prerequisites_loading: boolean;
  bin_grain_model_prerequisites: BinGrainModelPrerequisitesInfoFragmentFragment;
};
export const withGetBinGrainModelPrerequisitesHoc: AmberHoc<
  WithGetBinGrainModelPrerequisitesHocOwnProps,
  WithGetBinGrainModelPrerequisitesHocChildProps
> = (component) =>
  HOC<
    AmberHocProps<
      WithGetBinGrainModelPrerequisitesHocOwnProps,
      WithGetBinGrainModelPrerequisitesHocChildProps,
      typeof component
    >,
    WithGetBinGrainModelPrerequisitesHocChildProps
  >({
    options: ({ grain_bin_id, onError }) => ({
      onError,
      fetchPolicy: 'network-only',
      errorPolicy: onError ? 'all' : 'none',
      variables: { grain_bin_id },
    }),
    props: ({ data, ownProps: { loading } }) => {
      if (!data || data.loading) {
        return {
          bin_grain_model_prerequisites_loading: true,
          bin_grain_model_prerequisites: {
            grain_bin_id: -1,
            has_active_fan_controller: false,
            has_active_hub: false,
            has_active_ongoing_storage_period: false,
            has_active_grain_tickets: false,
            is_meeting_all_prerequisites: false,
            has_active_storage_period_in_year: false,
            has_acceptable_grain_type: false,
            has_valid_fan_airflow: false,
            __typename: 'BinGrainModelPrerequisitesInfo',
          },
        };
      }
      return {
        bin_grain_model_prerequisites_loading: loading || false,
        bin_grain_model_prerequisites: data.bin_grain_model_prerequisites as BinGrainModelPrerequisitesInfoFragmentFragment,
      };
    },
  })(component as any);
