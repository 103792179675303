import { Grid, Link, Typography } from '@material-ui/core';
import React from 'react';
import {
  withGetBinGrainModelPrerequisitesHoc,
  WithGetBinGrainModelPrerequisitesHocChildProps,
  withGetContainerGrainLevelPercentHoc,
  WithGetContainerGrainLevelPercentHocChildProps,
} from '../../../../../core/src/api';
import { LaunchLinkIcon } from '../../../../../core/src/media';
import { amber_link } from '../../../../../core/src/style';
import { formatNumberWithCommas } from '../../../../../core/src/util/format-number';
import { CenteredSpinner } from '../../spinner';

const FanRunHistoryMoreInfoBase = ({
  fullBinCfmPerBu,
  cfmPerBu,
  goToManageGrainTickets,
  gotoConfigureDevices,
  container_grain_level_percent,
  container_grain_level_percent_loading,
  bin_grain_model_prerequisites,
  bin_grain_model_prerequisites_loading,
}: {
  fullBinCfmPerBu: number | null;
  cfmPerBu: number | null;
  goToManageGrainTickets: () => void;
  gotoConfigureDevices: () => void;
} & WithGetContainerGrainLevelPercentHocChildProps &
  WithGetBinGrainModelPrerequisitesHocChildProps) => {
  if (container_grain_level_percent_loading || bin_grain_model_prerequisites_loading) {
    return <CenteredSpinner fadeIn="none" />;
  }

  const cfmPerBuValue = container_grain_level_percent
    ? container_grain_level_percent.grain_level_percent === 100
      ? fullBinCfmPerBu
      : cfmPerBu
    : null;

  const hasActiveGrainTicketsInStorePeriod = bin_grain_model_prerequisites.has_active_grain_tickets;
  const hasValidFanAirflow = bin_grain_model_prerequisites.has_valid_fan_airflow;

  const hrsToMoveTempFront = cfmPerBuValue !== null ? 15 / cfmPerBuValue : null;
  const hrsToMoveMoistureFront = cfmPerBuValue !== null ? 50 * (15 / cfmPerBuValue) : null;
  const grainLevelPercentage = container_grain_level_percent
    ? container_grain_level_percent.grain_level_percent
    : null;
  const grainLevelBu = container_grain_level_percent
    ? container_grain_level_percent.grain_level_bushels
    : null;
  const grainLevelPercentageText =
    grainLevelPercentage !== null ? grainLevelPercentage.toFixed(1) : '-';
  const grainLevelBuText =
    grainLevelBu !== null ? `${formatNumberWithCommas(grainLevelBu, 0)} bu` : '-';
  const cfmPerBuValueText = cfmPerBuValue ? `${cfmPerBuValue.toFixed(2)} CFM/bu` : 'N/A';
  const noCMFContactMsg = (
    <>
      No CFM curve found. Please contact <a href={'mailto:support@amber.ag'}>support@amber.ag</a>
    </>
  );
  const validAirflowInfo = (
    <>
      <Grid container>
        {cfmPerBuValue !== null &&
        hrsToMoveTempFront !== null &&
        hrsToMoveMoistureFront !== null ? (
          <div style={{ marginBottom: 10, lineHeight: 1.6, fontSize: 14 }}>
            <Typography variant="subtitle1" style={{ lineHeight: 1.6, fontSize: 14 }}>
              With this bin's fans and grain level of{' '}
              <strong>
                {grainLevelPercentageText}% fill ({grainLevelBuText})*
              </strong>
              , estimated airflow is <strong>{cfmPerBuValueText} </strong>.
            </Typography>

            <Typography
              variant="subtitle1"
              style={{ lineHeight: 1.6, fontSize: 14, marginTop: 14 }}
            >
              Hours to Move Fronts at this airflow rate:{' '}
            </Typography>
            <ul style={{ paddingInlineStart: 16, fontSize: 14, marginTop: 8 }}>
              <li>
                Temperature Front:{' '}
                <strong>
                  {hrsToMoveTempFront === Infinity
                    ? 'Bin is empty'
                    : `${Math.round(hrsToMoveTempFront)} hrs`}
                </strong>
              </li>
              <li>
                Moisture Front:{' '}
                <strong>
                  {hrsToMoveMoistureFront === Infinity
                    ? 'Bin is empty'
                    : `${Math.round(hrsToMoveMoistureFront)} hrs`}
                </strong>
              </li>
            </ul>
            <Typography variant="subtitle1" style={{ lineHeight: 1.6, fontSize: 14 }}>
              The Amber Ag Grain Science Team recommends at least <strong>0.1 CFM/bu</strong> for
              good cooling potential and at least <strong>0.8 CFM/bu</strong> for good moisture
              moving potential.
            </Typography>

            <Typography
              variant="subtitle1"
              style={{ lineHeight: 1.6, fontSize: 14, marginTop: 14 }}
            >
              *Airflow decreases as grain depth increases. If the grain level reported here is off
              by more than 5%, update grain level using{' '}
              <Link
                style={{
                  color: amber_link,
                  textDecoration: 'underline',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
                onClick={goToManageGrainTickets}
              >
                Grain Tickets
                <LaunchLinkIcon width={20} height={18} fill={amber_link} />
              </Link>
              .
            </Typography>
          </div>
        ) : (
          <Typography variant="body1" style={{ marginBottom: 10, fontSize: 14 }}>
            {noCMFContactMsg}
          </Typography>
        )}
      </Grid>
    </>
  );

  const hasNoActiveGrainTicketsElement = (
    <>
      <Grid container>
        <div style={{ marginBottom: 10, lineHeight: 1.6, fontSize: 14 }}>
          <Typography variant="subtitle1" style={{ lineHeight: 1.6, fontSize: 14, marginTop: 14 }}>
            Airflow Estimate Unavailable
          </Typography>

          <Typography variant="subtitle1" style={{ lineHeight: 1.6, fontSize: 14, marginTop: 14 }}>
            Airflow decreases as grain depth increases. Grain depth is required to generate an
            Airflow Estimate. Input grain level using{' '}
            <Link
              style={{
                color: amber_link,
                textDecoration: 'underline',
                display: 'inline-flex',
                alignItems: 'center',
              }}
              onClick={goToManageGrainTickets}
            >
              Grain Tickets
              <LaunchLinkIcon width={20} height={18} fill={amber_link} />
            </Link>
            .
          </Typography>
        </div>
      </Grid>
    </>
  );

  const hasNoValidAirflowElement = (
    <>
      <Grid container>
        <div style={{ marginBottom: 10, lineHeight: 1.6, fontSize: 14 }}>
          <Typography variant="subtitle1" style={{ lineHeight: 1.6, fontSize: 14, marginTop: 14 }}>
            Airflow Estimate Unavailable
          </Typography>

          <Typography variant="subtitle1" style={{ fontSize: 14, marginTop: 14 }}>
            Please update Fan information in{' '}
            <Link
              style={{
                color: amber_link,
                textDecoration: 'underline',
                display: 'inline-flex',
                alignItems: 'center',
              }}
              onClick={gotoConfigureDevices}
            >
              Add/Configure Devices
              <LaunchLinkIcon width={20} height={18} fill={amber_link} />
            </Link>
            . If you believe this is an error, please email support@amber.ag
          </Typography>
        </div>
      </Grid>
    </>
  );
  if (!hasActiveGrainTicketsInStorePeriod) {
    return hasNoActiveGrainTicketsElement;
  }
  if (!hasValidFanAirflow) {
    return hasNoValidAirflowElement;
  }
  return validAirflowInfo;
};

export const FanRunHistoryMoreInfo = withGetBinGrainModelPrerequisitesHoc(
  withGetContainerGrainLevelPercentHoc(FanRunHistoryMoreInfoBase)
);
