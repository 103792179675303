import { Grid, InputAdornment, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { GetProps, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { setIsUserOnTempRecommWindowsPage } from '../../../../core/src/action';
import {
  GrainMoistureIcon,
  GrainTemperature,
  TargetMoistureIcon,
} from '../../../../core/src/media/icons';
import { amber_dark_grey } from '../../../../core/src/style';
import { getTemperatureUnitLabelWithDegreeSuffix } from '../../../../core/src/util';
import {
  GrainBinFragmentFragment,
  GrainTemporaryInputConditionsFragmentFragment,
  SaveTemporaryInputConditionsMutationVariables,
  withSaveTemporaryInputConditionsHoc,
  WithSaveTemporaryInputConditionsHocChildProps,
} from '../../api';
import { UiFormError } from '../../util/format-error';
import { BaseForm, FormikWrapper, FormikWrapperProps } from '../util/form2/BaseForm';
import { ErrorBox } from '../util/form2/ErrorBox';
import { SubmitButtonsGroup } from '../util/form2/SubmitButtonsGroup';
import { AirflowInfo } from './aeration/AirflowInfo';
import { GrainCondiFormSubmittedValuesType } from './aeration/FanControls';
import {
  createNavigationTypeFromOptionString,
  FanControlNavigationOption,
} from './FanControlNavigationOption';
import { asJSON, GrainBinForm, Values } from './GrainBinForm';
import { GrainConditionInput } from './GrainConditionInput';
import { RecommendedOption } from './RecommendationOptionValue';
import RecommSchedulingInfo from './RecommSchedulingInfo';
import { SelectRecommendationType } from './SelectRecommendationType';

const useStyles = makeStyles((theme: Theme) => ({
  paraConatiner: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  subText: {
    marginTop: '0',
    marginBottom: '0',
  },
  grid: {
    minWidth: 250,
    maxWidth: 500,
    color: amber_dark_grey,
  },
  centered: {
    textAlign: 'center',
  },
  grainConditionsHeading: {
    fontSize: 20,
    lineHeight: '28px',
  },
  grainInputCardContainer: {
    width: '100%',
    display: 'flex',
    // direct child
    '& > :not(:last-child)': {
      marginRight: 14,
      [theme.breakpoints.only('xs')]: {
        marginRight: 12,
      },
    },
  },
}));

const validationSchema = yup.object().shape({
  target_grain_emc: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .label('Target Grain Moisture %')
    .positive()
    .min(0)
    .max(100),
  recommendation_type: yup.string().required(),
  current_grain_emc: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required()
    .label('Current Grain Moisture %')
    .positive()
    .min(0)
    .max(100),
  current_grain_temp: yup
    .number()
    .typeError('A number is required')
    .required()
    .transform((value) => (isNaN(value) ? undefined : value))
    .label('Current Grain Temp F'),
});

export const GrainConditionInputFormBase = ({
  grain_bin,
  saveTemporaryInputConditions,
  onCancel,
  setRecommendation,
  navigation,
  setNavigation,
  grainCondiFormSubmittedValues,
  setGrainCondiFormSubmittedValues,
  fullBinCfmPerBu,
  cfmPerBu,
  goToManageGrainTickets,
  goToConfigureDevices,
  ...props
}: WithSaveTemporaryInputConditionsHocChildProps &
  Pick<
    FormikWrapperProps<Values, GrainBinFragmentFragment>,
    'onSubmitSuccess' | 'onSubmitFailure'
  > & { grain_bin: GrainBinFragmentFragment } & { onCancel: () => void } & {
    navigation: FanControlNavigationOption;
    setNavigation: (navigation: FanControlNavigationOption) => void;
    setRecommendation: (navigation: FanControlNavigationOption) => void;
    grainCondiFormSubmittedValues: GrainCondiFormSubmittedValuesType;
    setGrainCondiFormSubmittedValues: React.Dispatch<
      React.SetStateAction<GrainCondiFormSubmittedValuesType>
    >;
    fullBinCfmPerBu: number | null;
    cfmPerBu: number | null;
    goToManageGrainTickets: () => void;
    goToConfigureDevices: () => void;
  }) => {
  const classes = useStyles();
  const initial_values = useMemo(() => grainCondiFormSubmittedValues || asJSON(grain_bin!), [
    grain_bin,
    grainCondiFormSubmittedValues,
  ]);
  const dispatch = useDispatch();

  return (
    <FormikWrapper<any, { result: GrainTemporaryInputConditionsFragmentFragment }>
      {...props}
      onSubmitSuccess={(result) => {
        // Set flag to indicate that user has got temporary recommendations windows
        dispatch(setIsUserOnTempRecommWindowsPage({ isUserOnTempRecommWindowsPage: true }));

        if (result && result.result) {
          const savedResult = result.result;
          setGrainCondiFormSubmittedValues({
            current_grain_emc: savedResult.current_grain_emc,
            current_grain_temp: savedResult.current_grain_temp,
            recommendation_type: savedResult.recommendation_type,
            target_grain_emc: savedResult.target_grain_emc,
          });
        }
        const newNav = createNavigationTypeFromOptionString(
          navigation.navigationType,
          result.result.recommendation_type
        );
        setNavigation(newNav);
      }}
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initial_values}
      onSubmit={async (values) => {
        const {
          current_grain_emc,
          current_grain_temp,
          target_grain_emc,
          recommendation_type,
        } = validationSchema.validateSync(values);
        const variables: SaveTemporaryInputConditionsMutationVariables = {
          current_grain_emc,
          current_grain_temp,
          target_grain_emc,
          recommendation_type,
          grain_bin_id: grain_bin.grain_bin_id,
        };

        if (
          (recommendation_type === RecommendedOption.DRYING.toString() ||
            recommendation_type === RecommendedOption.RECONDITIONING.toString()) &&
          !target_grain_emc
        ) {
          throw new UiFormError('The Target Grain Moisture % is required');
        }

        if (
          recommendation_type === RecommendedOption.DRYING.toString() &&
          target_grain_emc &&
          current_grain_emc &&
          target_grain_emc >= current_grain_emc
        ) {
          throw new UiFormError(
            'In Drying Mode the Target Grain Moisture must be less than the Current' +
              ' Grain Moisture'
          );
        } else if (
          recommendation_type === RecommendedOption.RECONDITIONING.toString() &&
          target_grain_emc &&
          current_grain_emc &&
          current_grain_emc >= target_grain_emc
        ) {
          throw new UiFormError(
            'In Reconditioning Mode the Target Grain Moisture must be greater than the Current' +
              ' Grain Moisture'
          );
        }

        return {
          result: await saveTemporaryInputConditions(variables),
          recommendation_type: recommendation_type.toString(),
        };
      }}
      render={({ values: { recommendation_type }, errors, touched }) => {
        // for current_grain_emc
        const currentGrainEmcError = errors['current_grain_emc'];
        const touchedCurrentGrainEmcError = touched['current_grain_emc'];
        const HasCurrentGrainEmcError = Boolean(
          touchedCurrentGrainEmcError && currentGrainEmcError
        );
        // for current_grain_temp
        const currentGrainTempError = errors['current_grain_temp'];
        const touchedCurrentGrainTempError = touched['current_grain_temp'];
        const HasCurrentGrainTempError = Boolean(
          touchedCurrentGrainTempError && currentGrainTempError
        );
        // for target_grain_emc
        const targetGrainEmcError = errors['target_grain_emc'];
        const touchedTargetGrainEmcError = touched['target_grain_emc'];
        const HasTargetGrainEmcError = Boolean(touchedTargetGrainEmcError && targetGrainEmcError);

        const hasRecommedationTypeCooling =
          recommendation_type && recommendation_type.toString() === RecommendedOption.COOLING;
        const tempUnit = getTemperatureUnitLabelWithDegreeSuffix();
        return (
          <BaseForm submitting_message={'Saving changes...'} style={{ padding: 0 }}>
            <Grid
              container
              direction="row"
              alignContent="flex-start"
              alignItems="center"
              justify="center"
              spacing={2}
              className={classes.grid}
            >
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <Typography variant="h5" className={classes.grainConditionsHeading}>
                  Input Grain Conditions
                </Typography>
              </Grid>
              {/* grain conditions inputs */}
              <Grid item style={{ width: '100%' }}>
                <div className={classes.grainInputCardContainer}>
                  <GrainConditionInput
                    title="Current Moisture"
                    name="current_grain_emc"
                    GrainConditionIcon={GrainMoistureIcon}
                    hasError={HasCurrentGrainEmcError}
                    inputError={currentGrainEmcError}
                    containerStyles={{ width: hasRecommedationTypeCooling ? '50%' : undefined }}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                  />
                  <GrainConditionInput
                    title="Current Temperature"
                    name="current_grain_temp"
                    GrainConditionIcon={GrainTemperature}
                    hasError={HasCurrentGrainTempError}
                    inputError={currentGrainTempError}
                    containerStyles={{ width: hasRecommedationTypeCooling ? '50%' : undefined }}
                    endAdornment={<InputAdornment position="end">{tempUnit}</InputAdornment>}
                  />
                  {recommendation_type &&
                    recommendation_type.toString() !== RecommendedOption.COOLING.toString() && (
                      <GrainConditionInput
                        title="Target Moisture"
                        name="target_grain_emc"
                        GrainConditionIcon={TargetMoistureIcon}
                        hasError={HasTargetGrainEmcError}
                        inputError={targetGrainEmcError}
                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      />
                    )}
                </div>
              </Grid>
              <Grid item style={{ width: '100%', marginBottom: 20 }}>
                <SelectRecommendationType
                  defaultRecommendationType={
                    grainCondiFormSubmittedValues
                      ? grainCondiFormSubmittedValues.recommendation_type
                      : grain_bin.recommendation_type
                  }
                  navigation={navigation}
                  setRecommendation={setRecommendation}
                />
              </Grid>
              <ErrorBox />
              {cfmPerBu !== null && fullBinCfmPerBu !== null && (
                <Grid item xs={12}>
                  <AirflowInfo
                    grain_bin_id={grain_bin.grain_bin_id}
                    cfmPerBu={cfmPerBu}
                    goToManageGrainTickets={goToManageGrainTickets}
                    gotoConfigureDevices={goToConfigureDevices}
                    fullBinCfmPerBu={fullBinCfmPerBu}
                  />
                </Grid>
              )}
              <Grid item xs={12} style={{ marginBottom: 38 }}>
                <RecommSchedulingInfo />
              </Grid>
              <SubmitButtonsGroup
                onCancel={onCancel}
                cancelText="Cancel"
                submitButtonText="Generate Schedule"
              />
            </Grid>
          </BaseForm>
        );
      }}
    />
  );
};

export const GrainConditionInputForm = withSaveTemporaryInputConditionsHoc(
  GrainConditionInputFormBase
);

export const AskGrainConditionsForm = ({
  grain_bin,
  onCancel,
  navigation,
  setNavigation,
  grainCondiFormSubmittedValues,
  setGrainCondiFormSubmittedValues,
  fullBinCfmPerBu,
  cfmPerBu,
  goToManageGrainTickets,
  goToConfigureDevices,
  ...props
}: Omit<GetProps<typeof GrainBinForm>, 'account_id'> & { grain_bin: GrainBinFragmentFragment } & {
  onCancel: () => void;
} & {
  navigation: FanControlNavigationOption;
  setNavigation: (navigation: FanControlNavigationOption) => void;
  setRecommendation: (navigation: FanControlNavigationOption) => void;
  grainCondiFormSubmittedValues: GrainCondiFormSubmittedValuesType;
  setGrainCondiFormSubmittedValues: React.Dispatch<
    React.SetStateAction<GrainCondiFormSubmittedValuesType>
  >;
  fullBinCfmPerBu: number | null;
  cfmPerBu: number | null;
  goToManageGrainTickets: () => void;
  goToConfigureDevices: () => void;
}) => (
  <GrainConditionInputForm
    {...props}
    grain_bin={grain_bin}
    onCancel={onCancel}
    navigation={navigation}
    setNavigation={setNavigation}
    setGrainCondiFormSubmittedValues={setGrainCondiFormSubmittedValues}
    grainCondiFormSubmittedValues={grainCondiFormSubmittedValues}
    cfmPerBu={cfmPerBu}
    goToManageGrainTickets={goToManageGrainTickets}
    goToConfigureDevices={goToConfigureDevices}
    fullBinCfmPerBu={fullBinCfmPerBu}
  />
);
