import {
  Button,
  Card,
  CardContent,
  CardHeader,
  DialogContent,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import {
  withGetContainerGrainLevelPercentHoc,
  WithGetContainerGrainLevelPercentHocChildProps,
} from '../../../../../core/src/api';
import { light_gray2 } from '../../../../../core/src/style';
import { formatNumberWithCommas } from '../../../../../core/src/util/format-number';
import { CenteredSpinner } from '../../spinner';
import { BaseDialog, TitleBadge } from '../../util';
import { FanRunHistoryMoreInfo } from './FanRunHistoryMoreInfo';

const useStyles = makeStyles({
  root: {
    backgroundColor: light_gray2,
  },
  title: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
});

const AirflowInfoBase = ({
  grain_bin_id,
  fullBinCfmPerBu,
  cfmPerBu,
  goToManageGrainTickets,
  gotoConfigureDevices,
  container_grain_level_percent,
  container_grain_level_percent_loading,
}: {
  grain_bin_id: number;
  fullBinCfmPerBu: number | null;
  cfmPerBu: number | null;
  goToManageGrainTickets: () => void;
  gotoConfigureDevices: () => void;
} & WithGetContainerGrainLevelPercentHocChildProps) => {
  const classes = useStyles();
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);

  if (container_grain_level_percent_loading) {
    return <CenteredSpinner fadeIn="none" />;
  }

  const cfmPerBuValue = container_grain_level_percent
    ? container_grain_level_percent.grain_level_percent === 100
      ? fullBinCfmPerBu
      : cfmPerBu
    : null;
  const hrsToMoveTempFront = cfmPerBuValue !== null ? 15 / cfmPerBuValue : null;
  const hrsToMoveMoistureFront = cfmPerBuValue !== null ? 50 * (15 / cfmPerBuValue) : null;
  const grainLevelPercentage = container_grain_level_percent
    ? container_grain_level_percent.grain_level_percent
    : null;
  const grainLevelBu = container_grain_level_percent
    ? container_grain_level_percent.grain_level_bushels
    : null;
  const grainLevelPercentageText =
    grainLevelPercentage !== null ? grainLevelPercentage.toFixed(1) : '-';
  const grainLevelBuText =
    grainLevelBu !== null ? `${formatNumberWithCommas(grainLevelBu, 0)} bu` : '-';
  const cfmPerBuValueText = cfmPerBuValue ? `${cfmPerBuValue.toFixed(2)} CFM/bu` : 'N/A';
  const noCMFContactMsg = (
    <>
      No CFM curve found. Please contact <a href={'mailto:support@amber.ag'}>support@amber.ag</a>
    </>
  );

  return (
    <>
      <Card raised className={classes.root}>
        <CardHeader
          title={
            <div className={classes.title}>
              <div style={{ fontSize: 17, marginRight: 8 }}>Airflow Info</div>
              <TitleBadge text="BETA" />
            </div>
          }
          action={
            <Button
              style={{ maxHeight: 26, backgroundColor: '#fff', height: 20, borderRadius: 4 }}
              onClick={() => setShowMoreInfo(true)}
            >
              More Info
            </Button>
          }
        />
        <Divider />
        <CardContent style={{ paddingBottom: 10 }}>
          <Grid container>
            {cfmPerBuValue !== null &&
            hrsToMoveTempFront !== null &&
            hrsToMoveMoistureFront !== null ? (
              <div style={{ lineHeight: 1.6, fontSize: 14 }}>
                <Typography variant="subtitle1" style={{ lineHeight: 1.6, fontSize: 14 }}>
                  With this bin's fans and grain level of{' '}
                  <strong>
                    {grainLevelPercentageText}% fill ({grainLevelBuText})
                  </strong>
                  , estimated airflow is <strong>{cfmPerBuValueText} </strong>.
                </Typography>

                <Typography
                  variant="subtitle1"
                  style={{ lineHeight: 1.6, fontSize: 14, marginTop: 14 }}
                >
                  Hours to Move Fronts at this airflow rate:{' '}
                </Typography>
                <ul style={{ paddingInlineStart: 16, fontSize: 14, margin: 0 }}>
                  <li>
                    Temperature Front:{' '}
                    <strong>
                      {hrsToMoveTempFront === Infinity
                        ? 'Bin is empty'
                        : `${Math.round(hrsToMoveTempFront)} hrs`}
                    </strong>
                  </li>
                  <li>
                    Moisture Front:{' '}
                    <strong>
                      {hrsToMoveMoistureFront === Infinity
                        ? 'Bin is empty'
                        : `${Math.round(hrsToMoveMoistureFront)} hrs`}
                    </strong>
                  </li>
                </ul>
              </div>
            ) : (
              <Typography variant="body1" style={{ marginBottom: 10, fontSize: 14 }}>
                {noCMFContactMsg}
              </Typography>
            )}
          </Grid>
        </CardContent>
      </Card>
      <BaseDialog
        title="Airflow Info"
        open={showMoreInfo}
        handleClose={() => setShowMoreInfo(false)}
      >
        <DialogContent style={{ textAlign: 'start' }}>
          <FanRunHistoryMoreInfo
            grain_bin_id={grain_bin_id}
            cfmPerBu={cfmPerBu}
            goToManageGrainTickets={goToManageGrainTickets}
            gotoConfigureDevices={gotoConfigureDevices}
            fullBinCfmPerBu={fullBinCfmPerBu}
          />
        </DialogContent>
      </BaseDialog>
    </>
  );
};

export const AirflowInfo = withGetContainerGrainLevelPercentHoc(AirflowInfoBase);
