import { makeStyles } from '@material-ui/core';
import React from 'react';
import { light_gray2 } from '../../../../core/src/style';

const useStyles = makeStyles({
  root: {
    backgroundColor: light_gray2,
  },
  title: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  betaBadge: {
    backgroundColor: 'lightgrey',
    borderRadius: 20,
    width: 40,
    height: 16,
    fontSize: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const TitleBadge = ({ text, styles }: { text: string; styles?: object }) => {
  const classes = useStyles();

  return (
    <div className={classes.betaBadge} style={styles}>
      {text}
    </div>
  );
};
