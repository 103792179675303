import { ApolloClient } from 'apollo-client';
import { delay } from 'redux-saga';
import { cancel, fork, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { pollBackgroundGrainBinEstimateState } from '../action';
import {
  GetBinEstimateProcessingStateDocument,
  GetBinEstimateProcessingStateQuery,
  GetBinEstimateProcessingStateQueryVariables,
} from '../api';

let bg_grain_bin_estimate_state_poll_task: any;

function* pollForGrainBinEstimateStateLoop(apollo_client: ApolloClient<any>, grain_bin_id: number) {
  try {
    while (true) {
      // poll every 10 sec
      yield delay(1000 * 10);

      const grain_bin_results = yield apollo_client.query<GetBinEstimateProcessingStateQuery>({
        query: GetBinEstimateProcessingStateDocument,
        variables: { grain_bin_id },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      });

      // update cache
      yield apollo_client.writeQuery<
        GetBinEstimateProcessingStateQuery,
        GetBinEstimateProcessingStateQueryVariables
      >({
        query: GetBinEstimateProcessingStateDocument,
        data: grain_bin_results.data,
        variables: { grain_bin_id },
      });
    }
  } finally {
    // console.log('pollForGrainBinEstimateStateLoop ended');
  }
}

function* pollBackgroundGrainBinEstimateStateSaga(
  apollo_client: ApolloClient<any>,
  action: ReturnType<typeof pollBackgroundGrainBinEstimateState>
) {
  if (!action.payload) return;

  try {
    const {
      payload: { grain_bin_id },
    } = action;

    // cancel existing poll if there is one
    if (bg_grain_bin_estimate_state_poll_task) {
      yield cancel(bg_grain_bin_estimate_state_poll_task);
      bg_grain_bin_estimate_state_poll_task = null;
    }

    if (grain_bin_id) {
      bg_grain_bin_estimate_state_poll_task = yield fork(
        pollForGrainBinEstimateStateLoop,
        apollo_client,
        grain_bin_id
      );
    }
  } catch (e) {
    console.error(e);
  }
}

export function* backgroundGrainBinEstimateStatePollWatcher(apollo_client: ApolloClient<any>) {
  yield takeEvery(
    getType(pollBackgroundGrainBinEstimateState),
    pollBackgroundGrainBinEstimateStateSaga,
    apollo_client
  );
}
