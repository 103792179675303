import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { DateTime } from 'luxon';
import React, { useCallback, useContext, useState } from 'react';
import * as yup from 'yup';
import {
  DoubleArrowIcon,
  HalfInvertedGrainSurfaceIcon,
  HalfPeakedGrainSurfaceIcon,
  InvertedGrainSurfaceIcon,
  LevelGrainSurfaceIcon,
  PeakedGrainSurfaceIcon,
  SideDrawGrainSurfaceIcon,
  SmallRightArrowIcon,
} from '../../../../../core/src/media';
import {
  getHasUserSelectedKgPerHectoliterUnit,
  getMassUnitLabel,
  getTestWeightLabel,
  UnitsConverter,
} from '../../../../../core/src/util';
import {
  GrainBinTicketFragmentFragment,
  GrainSurfaceShapeType,
  GrainTicketActionType,
  GrainType,
  withCreateGrainBinTicketHoc,
  WithCreateGrainBinTicketHocChildProps,
  withDeleteGrainBinTicketHoc,
  WithDeleteGrainBinTicketHocChildProps,
  withUpdateGrainBinTicketHoc,
  WithUpdateGrainBinTicketHocChildProps,
} from '../../../api';
import { ActiveStoragePeriodContext } from '../../../contexts';
import { amber_red, blue_shade_2 } from '../../../style';
import { UiFormError } from '../../../util/format-error';
import ConfirmationModal from '../../util/ConfirmationModal';
import { BaseForm, FormikWrapper } from '../../util/form2/BaseForm';
import { Button, ButtonSubmit } from '../../util/form2/Button';
import { ErrorBox } from '../../util/form2/ErrorBox';
import {
  SelectGrainTicketActionType,
  yup_grain_ticket_action_type,
} from '../../util/form2/SelectGrainTicketActionType';
import { TextField } from '../../util/form2/TextField';
import {
  convertTestWeightInLbsPerBuFromSelectedUnit,
  convertWeightInLbsFromSelectedUnit,
  getTestWeightInSelectedUnit,
  getWeightInSelectedUnit,
} from './grainTicketFormHelpers';

const useStyles = makeStyles((theme: Theme) => ({
  grainSurfaceShape: {
    '& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)': {
      marginLeft: '4px',
    },
    '& .Mui-selected': {
      borderColor: 'gray !important',
    },
  },
  buttonsContainer: {
    marginTop: 10,
    [theme.breakpoints.up(theme.breakpoints.width('xs') + 390)]: {
      padding: '0px 8px',
    },
  },
  grainSurfaceShapeContainer: {
    width: '100%',
    [theme.breakpoints.up(theme.breakpoints.width('xs') + 390)]: {
      padding: '0px 8px',
    },
  },
  grainSurfaceShapeOptLabel: {
    marginTop: 4,
  },
  grainSurfaceShapeOptBtn: {
    lineHeight: 1,
    width: 50,
    border: '1px solid lightgray !important',
    borderRadius: '4px !important',
    padding: '4px 2px',
    [theme.breakpoints.up(theme.breakpoints.width('xs') + 440)]: {
      marginRight: 8,
    },
    [theme.breakpoints.up(theme.breakpoints.width('xs') + 470)]: {
      width: 60,
      marginRight: 8,
    },
    [theme.breakpoints.up(theme.breakpoints.width('xs') + 500)]: {
      width: 60,
      marginRight: 10,
    },
    '&:last-child': {
      marginRight: 0,
    },
    '& .MuiToggleButton-label': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      textTransform: 'capitalize',
      [theme.breakpoints.only('xs')]: {
        lineHeight: 1,
      },
    },
  },
}));

export type GrainBinTicketInput = {
  ticket_date: string;
  ticket_time: string;
  volume: number | string;
  grain_ticket_action: GrainTicketActionType;
  grain_moisture_pct: number | string;
  notes: string;
};

export const grainSurfaeShapeTexts = {
  [GrainSurfaceShapeType.Peaked]: 'Peaked',
  [GrainSurfaceShapeType.HalfPeaked]: '1/2 Peaked',
  [GrainSurfaceShapeType.Level]: 'Level',
  [GrainSurfaceShapeType.HalfInverted]: '1/2 Inverted',
  [GrainSurfaceShapeType.Inverted]: 'Inverted',
  [GrainSurfaceShapeType.SideDraw]: 'Side Draw',
};

type CreateOrUpdateGrainTicketFormProps = {
  grain_bin_id: number;
  handleCloseForm: () => void;
  setUpdating: (shouldUpdate: boolean) => void;
  refetchGrainBin: () => void;
  refetchGrainBinTickets: () => void;
  // below all fields for the update ticket Form
  grain_bin_ticket_id?: number;
  grain_bin_ticket_epoch_time?: Date;
  action_performed?: GrainTicketActionType;
  volume?: number;
  weight_in_lbs?: number;
  test_weight_in_lbs?: number;
  grain_moisture_pct?: number;
  notes?: string;
  grain_surface_shape?: GrainSurfaceShapeType;
  grain_type?: GrainType;
} & WithCreateGrainBinTicketHocChildProps &
  WithUpdateGrainBinTicketHocChildProps &
  WithDeleteGrainBinTicketHocChildProps;

const massUnitLabel = getMassUnitLabel();
const testWeightLabel = getTestWeightLabel();
// form input fields validations
export const grainTicketFormValidationSchema = yup.object().shape({
  ticket_date: yup
    .string()
    .required()
    .label('Ticket Date'),
  ticket_time: yup
    .string()
    .required()
    .label('Ticket Time'),
  volume: yup
    .number()
    .typeError('A number is required')
    .required()
    .label('Volume')
    .positive()
    .min(0),
  weight_in_lbs: yup
    .number()
    .typeError('A number is required')
    .required()
    .label(`Total Weight(${massUnitLabel})`)
    .positive()
    .min(0),
  test_weight_in_lbs: yup
    .number()
    .typeError('A number is required')
    .required()
    .label(`Test Weight(${testWeightLabel})`)
    .positive()
    .min(0),
  grain_ticket_action: yup_grain_ticket_action_type.label('Action').required(),
  grain_moisture_pct: yup
    .number()
    .typeError('A number is required')
    .required()
    .label('Grain Moisture(%)')
    .positive()
    .min(1)
    .max(30),
  notes: yup
    .string()
    .label('Notes')
    .max(50),
});

export const getEquationByGrainType = (grain_type: GrainType, equation_type) => {
  switch (grain_type) {
    case GrainType.Barley:
      switch (equation_type) {
        case 'Brusewitz':
          return (grain_moisture_pct) =>
            705.4 -
            1142 * (grain_moisture_pct / 100) +
            1950 * Math.pow(grain_moisture_pct / 100, 2);
      }
      break;
    case GrainType.BeanRed:
    case GrainType.BeanBlack:
    case GrainType.BeanPinto:
    case GrainType.BeanLentil:
      return (grain_moisture_pct) => 772;
      break;
    case GrainType.BeanWhite:
      return (grain_moisture_pct) => 721;
      break;
    case GrainType.Canola:
    case GrainType.CanolaTobinPolish:
    case GrainType.CanolaWestarArgentine:
      return (grain_moisture_pct) => 669;
      break;
    case GrainType.Corn:
    case GrainType.CornWaxy:
    case GrainType.CornYellow:
    case GrainType.CornWhite:
    case GrainType.CornPopcorn:
    case GrainType.CornPopcornWhite:
      switch (equation_type) {
        case 'Brusewitz':
          return (grain_moisture_pct) =>
            1086.3 -
            2971 * (grain_moisture_pct / 100) +
            4810 * Math.pow(grain_moisture_pct / 100, 2);
        case 'Nelson':
          return (grain_moisture_pct) =>
            701.9 +
            1676 * (grain_moisture_pct / 100) -
            11598 * Math.pow(grain_moisture_pct / 100, 2) +
            18240 * Math.pow(grain_moisture_pct / 100, 3);
      }
      break;
    case GrainType.Ddg:
    case GrainType.DdgSolubles_10:
    case GrainType.DdgSolubles_15:
    case GrainType.DdgSolubles_20:
    case GrainType.DdgSolubles_25:
      return (grain_moisture_pct) => 481;
    case GrainType.Oats:
      switch (equation_type) {
        case 'Brusewitz':
          return (grain_moisture_pct) =>
            773 - 2311 * (grain_moisture_pct / 100) + 3630 * Math.pow(grain_moisture_pct / 100, 2);
      }
      break;
    case GrainType.Peanuts:
      return (grain_moisture_pct) => 322;
    case GrainType.PistachioNutHull:
      return (grain_moisture_pct) => 508.5;
    case GrainType.Rapeseed:
      return (grain_moisture_pct) => 669;
    case GrainType.RiceShortGrain:
    case GrainType.RiceMediumGrain:
    case GrainType.RiceLongGrain:
      return (grain_moisture_pct) => 579;
    case GrainType.Rye:
      switch (equation_type) {
        case 'Brusewitz':
          return (grain_moisture_pct) =>
            974.8 -
            2052 * (grain_moisture_pct / 100) +
            2850 * Math.pow(grain_moisture_pct / 100, 2);
      }
      break;
    case GrainType.Sorghum:
      switch (equation_type) {
        case 'Brusewitz':
          return (grain_moisture_pct) =>
            829.1 - 643 * (grain_moisture_pct / 100) + 660 * Math.pow(grain_moisture_pct / 100, 2);
      }
      break;
    case GrainType.Soybean:
      switch (equation_type) {
        case 'Brusewitz':
          return (grain_moisture_pct) =>
            734.5 - 219 * (grain_moisture_pct / 100) + 70 * Math.pow(grain_moisture_pct / 100, 2);
      }
    case GrainType.Sunflower:
      return (grain_moisture_pct) => 412;
    case GrainType.SunflowerConfectionary:
      return (grain_moisture_pct) => 309;
    case GrainType.WheatDurum:
    case GrainType.WheatHardRedWinter:
    case GrainType.WheatHardRedSpring:
    case GrainType.Buckwheat:
      switch (equation_type) {
        case 'Brusewitz':
          return (grain_moisture_pct) =>
            885.3 -
            1631 * (grain_moisture_pct / 100) +
            2640 * Math.pow(grain_moisture_pct / 100, 2);
        case 'Nelson':
          return (grain_moisture_pct) =>
            774.4 -
            703 * (grain_moisture_pct / 100) +
            18510 * Math.pow(grain_moisture_pct / 100, 2) -
            148960 * Math.pow(grain_moisture_pct / 100, 3) +
            311600 * Math.pow(grain_moisture_pct / 100, 4);
      }
      break;
    default:
      return null;
  }
};

export const calculateTestWeightUsingMoistureGrainType = (
  grain_moisture_pct,
  grain_type: GrainType | undefined
): number => {
  if (!grain_type) {
    return 1;
  }
  const grain_type_equation_type_map = {};
  grain_type_equation_type_map[GrainType.Barley] = 'Brusewitz';
  grain_type_equation_type_map[GrainType.Buckwheat] = 'Nelson';

  grain_type_equation_type_map[GrainType.Sunflower] = '';
  grain_type_equation_type_map[GrainType.SunflowerConfectionary] = '';

  grain_type_equation_type_map[GrainType.Canola] = '';
  grain_type_equation_type_map[GrainType.CanolaWestarArgentine] = '';
  grain_type_equation_type_map[GrainType.CanolaTobinPolish] = '';
  grain_type_equation_type_map[GrainType.Peanuts] = '';
  grain_type_equation_type_map[GrainType.Rapeseed] = '';
  grain_type_equation_type_map[GrainType.PistachioNutHull] = '';
  grain_type_equation_type_map[GrainType.Ddg] = '';
  grain_type_equation_type_map[GrainType.DdgSolubles_25] = '';
  grain_type_equation_type_map[GrainType.DdgSolubles_20] = '';
  grain_type_equation_type_map[GrainType.DdgSolubles_10] = '';
  grain_type_equation_type_map[GrainType.DdgSolubles_15] = '';
  grain_type_equation_type_map[GrainType.BeanWhite] = '';
  grain_type_equation_type_map[GrainType.BeanRed] = '';
  grain_type_equation_type_map[GrainType.BeanLentil] = '';
  grain_type_equation_type_map[GrainType.BeanPinto] = '';
  grain_type_equation_type_map[GrainType.BeanBlack] = '';
  grain_type_equation_type_map[GrainType.RiceShortGrain] = '';
  grain_type_equation_type_map[GrainType.RiceLongGrain] = 'Nelson';
  grain_type_equation_type_map[GrainType.RiceMediumGrain] = '';

  grain_type_equation_type_map[GrainType.Corn] = 'Nelson';
  grain_type_equation_type_map[GrainType.CornWaxy] = 'Nelson';
  grain_type_equation_type_map[GrainType.CornWhite] = 'Nelson';
  grain_type_equation_type_map[GrainType.CornYellow] = 'Nelson';
  grain_type_equation_type_map[GrainType.CornPopcorn] = 'Nelson';
  grain_type_equation_type_map[GrainType.CornPopcornWhite] = 'Nelson';
  grain_type_equation_type_map[GrainType.Oats] = 'Brusewitz';
  grain_type_equation_type_map[GrainType.Rye] = 'Brusewitz';
  grain_type_equation_type_map[GrainType.Sorghum] = 'Brusewitz';
  grain_type_equation_type_map[GrainType.Soybean] = 'Brusewitz';
  grain_type_equation_type_map[GrainType.WheatDurum] = 'Nelson';
  grain_type_equation_type_map[GrainType.WheatHardRedSpring] = 'Nelson';
  grain_type_equation_type_map[GrainType.WheatHardRedWinter] = 'Nelson';

  const equation_type = grain_type_equation_type_map[grain_type];
  if (equation_type === undefined) {
    return 1;
  }
  const equation = getEquationByGrainType(grain_type, equation_type);
  if (equation) {
    const kg_m3 = equation(grain_moisture_pct);
    return parseFloat((kg_m3 / 12.8718598).toFixed(2));
  }
  return 1;
};

const handleClickMoistPcntAndTestWeightLbs = (
  grain_type,
  setFieldValue,
  current_values,
  changed_field_name
) => {
  const { grain_moisture_pct } = current_values;
  const hasUserSelectedKgPerHectoliterUnit = getHasUserSelectedKgPerHectoliterUnit();
  if (changed_field_name === 'grain_moisture_pct' && grain_moisture_pct > 0) {
    const test_weight = hasUserSelectedKgPerHectoliterUnit
      ? UnitsConverter.convertPoundPerBuToKilogramPerHectoliter(
          calculateTestWeightUsingMoistureGrainType(grain_moisture_pct, grain_type)
        )
      : calculateTestWeightUsingMoistureGrainType(grain_moisture_pct, grain_type);
    setFieldValue('test_weight_in_lbs', test_weight);
    current_values['test_weight_in_lbs'] = test_weight;
  }

  const current_weight_in_lbs = convertWeightInLbsFromSelectedUnit(current_values['weight_in_lbs']);
  const current_test_weight_in_lbs_per_bu = convertTestWeightInLbsPerBuFromSelectedUnit(
    current_values['test_weight_in_lbs']
  );
  if (current_test_weight_in_lbs_per_bu > 0) {
    if (current_weight_in_lbs) {
      if (current_weight_in_lbs > 0) {
        const result = parseFloat(
          (current_weight_in_lbs / current_test_weight_in_lbs_per_bu).toFixed(2)
        );
        setFieldValue('volume', result);
        current_values['volume'] = result;
      } else {
        setFieldValue('volume', 0);
        current_values['volume'] = 0;
      }
    } else if (current_values['volume']) {
      const result_in_lbs = parseFloat(
        (current_values['volume'] * current_test_weight_in_lbs_per_bu).toFixed(2)
      );
      const weight = getWeightInSelectedUnit(result_in_lbs);
      setFieldValue('weight_in_lbs', weight);
      current_values['weight_in_lbs'] = weight;
    }
  } else if (current_values['test_weight_in_lbs'] <= 0) {
    setFieldValue('weight_in_lbs', 0);
    setFieldValue('volume', 0);
    current_values['weight_in_lbs'] = 0;
    current_values['volume'] = 0;
  }
};

const handleClickMoistPcntAndTestWeightLbsForSensorTicketForm = (
  grain_type,
  setFieldValue,
  current_values,
  changed_field_name
) => {
  const { grain_moisture_pct } = current_values;
  const hasUserSelectedKgPerHectoliterUnit = getHasUserSelectedKgPerHectoliterUnit();
  if (changed_field_name === 'grain_moisture_pct' && grain_moisture_pct > 0) {
    const test_weight = hasUserSelectedKgPerHectoliterUnit
      ? UnitsConverter.convertPoundPerBuToKilogramPerHectoliter(
          calculateTestWeightUsingMoistureGrainType(grain_moisture_pct, grain_type)
        )
      : calculateTestWeightUsingMoistureGrainType(grain_moisture_pct, grain_type);

    setFieldValue('test_weight_in_lbs', test_weight);
    current_values['test_weight_in_lbs'] = test_weight;
  }
  const current_test_weight_in_lbs_per_bu = convertTestWeightInLbsPerBuFromSelectedUnit(
    current_values['test_weight_in_lbs']
  );
  if (current_test_weight_in_lbs_per_bu > 0 && current_values['volume']) {
    const result_in_lbs = parseFloat(
      (current_values['volume'] * current_test_weight_in_lbs_per_bu).toFixed(2)
    );
    const weight = getWeightInSelectedUnit(result_in_lbs);
    setFieldValue('weight_in_lbs', weight);
    current_values['weight_in_lbs'] = weight;
  }
};

const handleClickWeightInLbs = (setFieldValue, current_values) => {
  if (current_values['test_weight_in_lbs']) {
    if (current_values['test_weight_in_lbs'] > 0) {
      const current_weight_in_lbs = convertWeightInLbsFromSelectedUnit(
        current_values['weight_in_lbs']
      );
      const current_test_weight_in_lbs_per_bu = convertTestWeightInLbsPerBuFromSelectedUnit(
        current_values['test_weight_in_lbs']
      );
      const result = parseFloat(
        (current_weight_in_lbs / current_test_weight_in_lbs_per_bu).toFixed(2)
      );
      setFieldValue('volume', result);
      current_values['volume'] = result;
    } else {
      setFieldValue('volume', 0);
      current_values['volume'] = 0;
    }
  } else if (current_values['volume']) {
    if (current_values['weight_in_lbs'] > 0) {
      const current_weight_in_lbs = convertWeightInLbsFromSelectedUnit(
        current_values['weight_in_lbs']
      );
      const test_weight_in_lbs = parseFloat(
        (current_values['volume'] / current_weight_in_lbs).toFixed(2)
      );
      const test_weight = getTestWeightInSelectedUnit(test_weight_in_lbs);
      setFieldValue('test_weight_in_lbs', test_weight);
      current_values['test_weight_in_lbs'] = test_weight;
    } else {
      setFieldValue('test_weight_in_lbs', 0);
      current_values['test_weight_in_lbs'] = 0;
    }
  }
};

const handleClickVolume = (setFieldValue, current_values) => {
  if (current_values['test_weight_in_lbs']) {
    const current_test_weight_in_lbs_per_bu = convertTestWeightInLbsPerBuFromSelectedUnit(
      current_values['test_weight_in_lbs']
    );
    const result_in_lbs = parseFloat(
      (current_values['volume'] * current_test_weight_in_lbs_per_bu).toFixed(2)
    );
    const weight = getWeightInSelectedUnit(result_in_lbs);
    setFieldValue('weight_in_lbs', weight);
    current_values['weight_in_lbs'] = weight;
  } else if (current_values['weight_in_lbs']) {
    if (current_values['volume'] > 0) {
      const current_weight_in_lbs = convertWeightInLbsFromSelectedUnit(
        current_values['weight_in_lbs']
      );
      const result_in_lbs = parseFloat(
        (current_weight_in_lbs / current_values['volume']).toFixed(2)
      );
      const test_weight = getTestWeightInSelectedUnit(result_in_lbs);
      setFieldValue('test_weight_in_lbs', test_weight);
      current_values['test_weight_in_lbs'] = test_weight;
    } else {
      setFieldValue('test_weight_in_lbs', 0);
      current_values['test_weight_in_lbs'] = 0;
    }
  }
};

// For Sensor Tickets Form
export const calculateTicketMetricsForSensorTicket = (
  grain_type,
  setFieldValue,
  current_values,
  changed_field_name
) => {
  switch (changed_field_name) {
    case 'grain_moisture_pct':
      handleClickMoistPcntAndTestWeightLbsForSensorTicketForm(
        grain_type,
        setFieldValue,
        current_values,
        'grain_moisture_pct'
      );
      break;
    case 'test_weight_in_lbs':
      handleClickMoistPcntAndTestWeightLbsForSensorTicketForm(
        grain_type,
        setFieldValue,
        current_values,
        'test_weight_in_lbs'
      );
      break;
    case 'weight_in_lbs':
      handleClickWeightInLbs(setFieldValue, current_values);
      break;
    case 'volume':
      handleClickVolume(setFieldValue, current_values);
      break;
    default:
      break;
  }
};

// For User Tickets Form
export const calculateTicketMetrics = (
  grain_type,
  setFieldValue,
  current_values,
  changed_field_name
) => {
  switch (changed_field_name) {
    case 'grain_moisture_pct':
      handleClickMoistPcntAndTestWeightLbs(
        grain_type,
        setFieldValue,
        current_values,
        'grain_moisture_pct'
      );
      break;
    case 'test_weight_in_lbs':
      handleClickMoistPcntAndTestWeightLbs(
        grain_type,
        setFieldValue,
        current_values,
        'test_weight_in_lbs'
      );
      break;
    case 'weight_in_lbs':
      handleClickWeightInLbs(setFieldValue, current_values);
      break;
    case 'volume':
      handleClickVolume(setFieldValue, current_values);
      break;
    default:
      break;
  }
};

export const CreateOrUpdateGrainTicketForm = withCreateGrainBinTicketHoc(
  withUpdateGrainBinTicketHoc(
    withDeleteGrainBinTicketHoc(
      ({
        grain_bin_id,
        grain_bin_ticket_id,
        createGrainBinTicket,
        updateGrainBinTicket,
        deleteGrainBinTicket,
        handleCloseForm,
        setUpdating,
        refetchGrainBin,
        refetchGrainBinTickets,
        volume,
        weight_in_lbs,
        test_weight_in_lbs,
        action_performed,
        grain_bin_ticket_epoch_time,
        grain_moisture_pct,
        notes,
        grain_type,
        grain_surface_shape,
      }: CreateOrUpdateGrainTicketFormProps) => {
        const classes = useStyles();
        const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
        const activeStoragePeriodContext = useContext(ActiveStoragePeriodContext);
        const activeStoragePeriod = activeStoragePeriodContext.activeStoragePeriod;
        const ticketDate = grain_bin_ticket_epoch_time
          ? DateTime.fromMillis(grain_bin_ticket_epoch_time.getTime()).toFormat('yyyy-MM-dd')
          : DateTime.local().toFormat('yyyy-MM-dd');
        const ticketTime = grain_bin_ticket_epoch_time
          ? DateTime.fromMillis(grain_bin_ticket_epoch_time.getTime()).toFormat('HH:mm')
          : DateTime.local().toFormat('HH:mm');
        const massUnitLabel = getMassUnitLabel();
        const testWeightLabel = getTestWeightLabel();

        const default_test_weight_in_lbs =
          grain_moisture_pct && grain_moisture_pct > 0
            ? calculateTestWeightUsingMoistureGrainType(grain_moisture_pct, grain_type)
            : undefined;
        const initial_test_weight_in_lbs = test_weight_in_lbs
          ? test_weight_in_lbs
          : default_test_weight_in_lbs;
        const default_weight_in_lbs =
          initial_test_weight_in_lbs && initial_test_weight_in_lbs > 0 && volume && volume > 0
            ? volume * initial_test_weight_in_lbs
            : undefined;
        const initial_weight_in_lbs = weight_in_lbs ? weight_in_lbs : default_weight_in_lbs;

        const initialValues = {
          ticket_date: ticketDate || '',
          ticket_time: ticketTime || '',
          volume: volume !== undefined ? volume : '',
          weight_in_lbs: initial_weight_in_lbs !== undefined ? initial_weight_in_lbs : '',
          test_weight_in_lbs:
            initial_test_weight_in_lbs !== undefined ? initial_test_weight_in_lbs : '',
          grain_ticket_action: action_performed || GrainTicketActionType.Added,
          grain_moisture_pct: grain_moisture_pct !== undefined ? grain_moisture_pct : '',
          notes: notes || '',
        };
        const getGrainSurfaceShapeByActionPerformed = (
          action: GrainTicketActionType | undefined
        ): GrainSurfaceShapeType => {
          return action && action === GrainTicketActionType.Removed
            ? GrainSurfaceShapeType.Inverted
            : GrainSurfaceShapeType.Peaked;
        };
        const defaultGrainSurfaceShape = grain_surface_shape
          ? grain_surface_shape
          : getGrainSurfaceShapeByActionPerformed(action_performed);
        const [grainSurfaceShape, setGrainSurfaceShape] = useState(defaultGrainSurfaceShape);
        const [isHighlightGrainMoistField, setIsHighlightGrainMoistField] = useState(false);
        const [isHighlightTestWeightField, setIsHighlightTestWeightField] = useState(false);
        const [isHighlightWeightField, setIsHighlightWeightField] = useState(false);
        const [isHighlightVolumeField, setIsHighlightVolumeField] = useState(false);

        const handleGrainSurfaceShapeChange = (
          event: React.MouseEvent<HTMLElement>,
          grainSurfaceShapeVal: GrainSurfaceShapeType
        ) => {
          if (grainSurfaceShapeVal !== null) setGrainSurfaceShape(grainSurfaceShapeVal);
        };

        const handleGrainActionChange = (action: GrainTicketActionType) => {
          const updatedGrainSurfaceShape = getGrainSurfaceShapeByActionPerformed(action);
          setGrainSurfaceShape(updatedGrainSurfaceShape);
        };

        const handleMoistureOnFocus = () => {
          setIsHighlightGrainMoistField(true);
          setIsHighlightTestWeightField(true);
          setIsHighlightVolumeField(true);
        };

        const handleMoistureOnBlur = () => {
          setIsHighlightGrainMoistField(false);
          setIsHighlightTestWeightField(false);
          setIsHighlightVolumeField(false);
        };

        const handleVolumeOnFocus = () => {
          setIsHighlightVolumeField(true);
          setIsHighlightWeightField(true);
        };
        const handleVolumeOnBlur = () => {
          setIsHighlightVolumeField(false);
          setIsHighlightWeightField(false);
        };

        const handleTestWeightOnFocus = () => {
          setIsHighlightTestWeightField(true);
          setIsHighlightVolumeField(true);
        };

        const handleTestWeightOnBlur = () => {
          setIsHighlightTestWeightField(false);
          setIsHighlightVolumeField(false);
        };

        const handleWeightOnFocus = () => {
          setIsHighlightWeightField(true);
          setIsHighlightVolumeField(true);
        };

        const handleWeightOnBlur = () => {
          setIsHighlightWeightField(false);
          setIsHighlightVolumeField(false);
        };

        const handleSubmit = async (values) => {
          const {
            ticket_date,
            ticket_time,
            volume,
            weight_in_lbs,
            test_weight_in_lbs,
            grain_ticket_action,
            grain_moisture_pct,
            notes,
          } = values;
          grainTicketFormValidationSchema.validateSync(values);
          console.log('values', {
            ticket_date,
            ticket_time,
            volume,
            weight_in_lbs,
            test_weight_in_lbs,
            grain_ticket_action,
            grain_moisture_pct,
            notes,
          });
          const epoch_time = DateTime.fromFormat(
            `${ticket_date} ${ticket_time}`,
            'yyyy-MM-dd HH:mm'
          ).toJSDate();
          console.log(
            'epoch_time',
            epoch_time,
            DateTime.fromFormat(`${ticket_date} ${ticket_time}`, 'yyyy-MM-dd HH:mm')
          );

          const isGrainTicketValidforSelectedStoragePeriod = activeStoragePeriod
            ? epoch_time.getTime() >= activeStoragePeriod.start_date.getTime() &&
              (activeStoragePeriod.end_date
                ? epoch_time.getTime() <= activeStoragePeriod.end_date.getTime()
                : true)
            : true;

          if (activeStoragePeriod && !isGrainTicketValidforSelectedStoragePeriod) {
            const getFormatedDateString = (date) =>
              DateTime.fromMillis(date.getTime()).toFormat('MM/dd/yyyy');
            const start_date_str = getFormatedDateString(activeStoragePeriod.start_date);
            const end_date_str = activeStoragePeriod.end_date
              ? getFormatedDateString(activeStoragePeriod.end_date)
              : 'Ongoing';
            throw new UiFormError(
              `Please specify the ticket date and ticket time within selected storage period(${start_date_str}-${end_date_str})`
            );
          }

          if (!grainSurfaceShape) {
            throw new UiFormError(`Please select valid Grain Surface Shape`);
          }

          let result;
          if (grain_bin_ticket_id) {
            result = await updateGrainBinTicket({
              grain_bin_ticket_id,
              grain_bin_id,
              epoch_time,
              volume,
              weight_in_lbs,
              test_weight_in_lbs,
              grain_moisture_pct,
              notes,
              action_performed: grain_ticket_action,
              grain_bin_storage_cycle_id: activeStoragePeriod
                ? activeStoragePeriod.grain_bin_storage_cycle_id
                : null,
              grain_surface_shape: grainSurfaceShape,
            });
          } else {
            result = await createGrainBinTicket({
              grain_bin_id,
              epoch_time,
              volume,
              weight_in_lbs,
              test_weight_in_lbs,
              grain_moisture_pct,
              notes,
              action_performed: grain_ticket_action,
              grain_bin_storage_cycle_id: activeStoragePeriod
                ? activeStoragePeriod.grain_bin_storage_cycle_id
                : null,
              grain_surface_shape: grainSurfaceShape,
            });
          }

          return result;
        };

        const handleSubmitSuccess = async (result) => {
          setUpdating(true);
          await refetchGrainBinTickets();
          console.log(
            grain_bin_ticket_id ? 'Grain Ticket Updated' : 'Grain Ticket Created',
            result
          );
          handleCloseForm();
          setUpdating(false);
        };

        const handleDelete = useCallback(async () => {
          setUpdating(true);
          try {
            if (grain_bin_ticket_id === undefined || grain_bin_ticket_id === null) {
              console.warn('No Grain Bin Ticket found.');
              return;
            }
            const res = await deleteGrainBinTicket({
              grain_bin_ticket_id,
              grain_bin_id,
            });
            await refetchGrainBinTickets();
            console.log('Ticket Deleted.', res);
            setUpdating(false);
            handleCloseForm();
            return res;
          } catch (err) {
            setUpdating(false);
            console.error('Delete failed: ', err);
            throw new Error();
          }
        }, []);

        return (
          <FormikWrapper<GrainBinTicketInput, GrainBinTicketFragmentFragment>
            initialValues={initialValues}
            validationSchema={grainTicketFormValidationSchema}
            onSubmit={handleSubmit}
            onSubmitSuccess={handleSubmitSuccess}
            render={({ values, setFieldValue, handleChange, validateField }) => {
              return (
                <BaseForm submitting_message={'Saving changes...'}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6} sm={6}>
                      <TextField
                        fullWidth
                        name="ticket_date"
                        label="Ticket Date"
                        type="date"
                        placeholder="MM/DD/YYYY"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        fullWidth
                        name="ticket_time"
                        label="Ticket Time"
                        type="time"
                        placeholder="HH:MM AM"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="notes"
                        type="text"
                        label="Notes"
                        placeholder="e.g. from field A.. , by John.."
                        autoComplete="off"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <SelectGrainTicketActionType
                        name="grain_ticket_action"
                        label="Action"
                        fullWidth
                        onChange={handleGrainActionChange}
                      />
                    </Grid>
                    {/* left section start*/}
                    <Grid item xs={5} sm={5}>
                      <Grid
                        container
                        style={{
                          paddingTop: 6,
                          paddingBottom: 20,
                        }}
                      >
                        <Grid item xs={12}>
                          <TextField
                            name="grain_moisture_pct"
                            type="number"
                            label="Grain Moisture(%)"
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onChange={async (e) => {
                              await handleChange(e);
                              const current_values = { ...values };
                              validateField('grain_moisture_pct');
                              current_values['grain_moisture_pct'] = parseFloat(
                                parseFloat(e.target.value).toFixed(2)
                              );
                              calculateTicketMetrics(
                                grain_type,
                                setFieldValue,
                                current_values,
                                'grain_moisture_pct'
                              );
                            }}
                            onFocus={handleMoistureOnFocus}
                            onBlur={handleMoistureOnBlur}
                            style={{
                              backgroundColor: isHighlightGrainMoistField
                                ? blue_shade_2
                                : undefined,
                            }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: 20 }}>
                          <TextField
                            name="volume"
                            type="number"
                            label="Volume(bushel)"
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            fullWidth
                            onChange={async (e) => {
                              await handleChange(e);
                              const current_values = { ...values };
                              validateField('volume');
                              current_values['volume'] = parseFloat(
                                parseFloat(e.target.value).toFixed(2)
                              );
                              calculateTicketMetrics(
                                grain_type,
                                setFieldValue,
                                current_values,
                                'volume'
                              );
                            }}
                            onFocus={handleVolumeOnFocus}
                            onBlur={handleVolumeOnBlur}
                            style={{
                              backgroundColor: isHighlightVolumeField ? blue_shade_2 : undefined,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* center section start*/}
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: '100px',
                      }}
                    >
                      <div>
                        <SmallRightArrowIcon />
                      </div>
                      <div>
                        <DoubleArrowIcon />
                      </div>
                    </Grid>

                    {/* right section start*/}
                    <Grid item xs={6} sm={6}>
                      <Grid
                        container
                        style={{
                          border: '1px solid lightgray',
                          borderRadius: 8,
                          padding: '6px 6px 20px 6px',
                        }}
                      >
                        <Grid item xs={12}>
                          <TextField
                            name="test_weight_in_lbs"
                            type="number"
                            label={`Test Weight(${testWeightLabel})`}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            fullWidth
                            onChange={async (e) => {
                              await handleChange(e);
                              const current_values = { ...values };
                              validateField('test_weight_in_lbs');
                              current_values['test_weight_in_lbs'] = parseFloat(
                                parseFloat(e.target.value).toFixed(2)
                              );
                              calculateTicketMetrics(
                                grain_type,
                                setFieldValue,
                                current_values,
                                'test_weight_in_lbs'
                              );
                            }}
                            onFocus={handleTestWeightOnFocus}
                            onBlur={handleTestWeightOnBlur}
                            style={{
                              backgroundColor: isHighlightTestWeightField
                                ? blue_shade_2
                                : undefined,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: 20 }}>
                          <TextField
                            name="weight_in_lbs"
                            type="number"
                            label={`Total Weight(${massUnitLabel})`}
                            fullWidth
                            onChange={async (e) => {
                              await handleChange(e);
                              const current_values = { ...values };
                              validateField('weight_in_lbs');
                              current_values['weight_in_lbs'] = parseFloat(
                                parseFloat(e.target.value).toFixed(2)
                              );
                              calculateTicketMetrics(
                                grain_type,
                                setFieldValue,
                                current_values,
                                'weight_in_lbs'
                              );
                            }}
                            onFocus={handleWeightOnFocus}
                            onBlur={handleWeightOnBlur}
                            style={{
                              backgroundColor: isHighlightWeightField ? blue_shade_2 : undefined,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      className={classes.grainSurfaceShapeContainer}
                    >
                      <Grid item xs={12} sm={12} style={{ marginTop: '5px' }}>
                        <Typography style={{ marginBottom: 5 }}>Grain Surface Shape</Typography>
                        <ToggleButtonGroup
                          value={grainSurfaceShape}
                          exclusive
                          size="medium"
                          onChange={handleGrainSurfaceShapeChange}
                          aria-label="text alignment"
                          className={classes.grainSurfaceShape}
                        >
                          <ToggleButton
                            value={GrainSurfaceShapeType.Peaked}
                            aria-label="Peaked"
                            className={classes.grainSurfaceShapeOptBtn}
                          >
                            <PeakedGrainSurfaceIcon />
                            <span className={classes.grainSurfaceShapeOptLabel}>Peaked</span>
                          </ToggleButton>
                          <ToggleButton
                            value={GrainSurfaceShapeType.HalfPeaked}
                            aria-label="Half Peaked"
                            className={classes.grainSurfaceShapeOptBtn}
                          >
                            <HalfPeakedGrainSurfaceIcon />
                            <span className={classes.grainSurfaceShapeOptLabel}>&#189; Peaked</span>
                          </ToggleButton>
                          <ToggleButton
                            value={GrainSurfaceShapeType.Level}
                            aria-label="Level"
                            className={classes.grainSurfaceShapeOptBtn}
                          >
                            <LevelGrainSurfaceIcon />
                            <span className={classes.grainSurfaceShapeOptLabel}>Level</span>
                          </ToggleButton>
                          <ToggleButton
                            value={GrainSurfaceShapeType.HalfInverted}
                            aria-label="Half Inverted"
                            className={classes.grainSurfaceShapeOptBtn}
                          >
                            <HalfInvertedGrainSurfaceIcon />
                            <span className={classes.grainSurfaceShapeOptLabel}>
                              &#189; Inverted
                            </span>
                          </ToggleButton>
                          <ToggleButton
                            value={GrainSurfaceShapeType.Inverted}
                            aria-label="Inverted"
                            className={classes.grainSurfaceShapeOptBtn}
                          >
                            <InvertedGrainSurfaceIcon />
                            <span className={classes.grainSurfaceShapeOptLabel}>Inverted</span>
                          </ToggleButton>
                          <ToggleButton
                            value={GrainSurfaceShapeType.SideDraw}
                            aria-label="Side Draw"
                            className={classes.grainSurfaceShapeOptBtn}
                          >
                            <SideDrawGrainSurfaceIcon />
                            <span className={classes.grainSurfaceShapeOptLabel}>Side Draw</span>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      className={classes.buttonsContainer}
                    >
                      <Grid item xs={3}>
                        <Button
                          variant="outlined"
                          fullWidth
                          style={{
                            margin: 0,
                            maxWidth: '100%',
                            maxHeight: '100%',
                            minWidth: '100%',
                            minHeight: '100%',
                          }}
                          onClick={handleCloseForm}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={grain_bin_ticket_id ? 7 : 9}>
                        <ButtonSubmit
                          variant="contained"
                          color="primary"
                          fullWidth
                          style={{
                            margin: 0,
                            maxWidth: '100%',
                            maxHeight: '100%',
                            minWidth: '100%',
                            minHeight: '100%',
                          }}
                          allow_pristine
                          prevent_submit_on_enter
                          allow_btn_disabled_for_validation={false}
                        >
                          Save Changes
                        </ButtonSubmit>
                      </Grid>
                      {grain_bin_ticket_id && (
                        <Grid item xs={2}>
                          <Button
                            style={{
                              margin: 0,
                              color: 'white',
                              backgroundColor: amber_red,
                              maxWidth: '100%',
                              maxHeight: '100%',
                              minWidth: '100%',
                              minHeight: '100%',
                            }}
                            onClick={() => {
                              setShowConfirmationModal(true);
                            }}
                          >
                            <DeleteIcon />
                          </Button>
                        </Grid>
                      )}
                      <ErrorBox />
                      <ConfirmationModal
                        showModal={showConfirmationModal}
                        confirmationMessage={
                          <>Are you sure you want to delete this Grain Bin Ticket ?</>
                        }
                        cancelBtnText="Cancel"
                        confirmBtbText="Delete"
                        handleCancel={() => setShowConfirmationModal(false)}
                        handleConfirm={() => {
                          handleDelete();
                          setShowConfirmationModal(false);
                        }}
                      />
                    </Grid>
                  </Grid>
                </BaseForm>
              );
            }}
          />
        );
      }
    )
  )
);
