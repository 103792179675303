import { DialogContent, Typography } from '@material-ui/core';
import React from 'react';
import { black_shade_2 } from '../../../../../core/src/style';
import { BaseDialog } from '../../util';

const BinEstimateMoreInfo: React.FunctionComponent<{
  showMoreInfo: boolean;
  setShowMoreInfo: (value: React.SetStateAction<boolean>) => void;
}> = ({ showMoreInfo, setShowMoreInfo }) => {
  return (
    <BaseDialog title="More Info" open={showMoreInfo} handleClose={() => setShowMoreInfo(false)}>
      <DialogContent
        style={{ textAlign: 'start', fontSize: 14, color: black_shade_2, paddingTop: 0 }}
      >
        <p style={{ marginTop: 0, marginBottom: 15 }}>
          Bin Simulation is a beta feature still undergoing active R&D.
        </p>
        <p style={{ marginBottom: 15 }}>
          This chart displays the computed estimate of the side-view cross-section of the
          Temperature or Moisture distribution of the grain in your bin. The bottom of the chart
          represents the floor of the bin, the sides represent the bin walls, and the top represents
          the eave line of the bin. If bin fill is less than 100%, there's an additional horizontal
          line that shows the current grain level within the chart.
        </p>
        <p style={{ marginBottom: 15 }}>
          All results shown are estimates based on Amber's Grain Simulation Model, historical
          weather, fan runs, and your inputs such as grain level and bin/fan setup. The simulation
          will update as your storage period continues, and if any changes are made to past inputs,
          the simulation will recompute to reflect these changes.
        </p>
        <p style={{ marginBottom: 15 }}>
          Results are only as accurate as the inputs and should only be interpreted as a "best
          guess". For most accurate results, ensure that all inputs such as grain tickets are up to
          date.
        </p>
        <p style={{ marginBottom: 15 }}>
          The data is most effective for understanding general trends such as how far a temperature
          or moisture front has moved rather than showing an exact moisture percentage. We recommend
          taking periodic samples of the actual grain moisture to understand exact moisture.
        </p>

        <p style={{ marginBottom: 4 }}>This chart will not show any hotspot activity.</p>
        <p style={{ fontWeight: 600, marginTop: 4, marginBottom: 15 }}>
          Refer to CO2 Chart for all grain spoilage indication.
        </p>
        <p>Please email support@amber.ag with any feedback.</p>
      </DialogContent>
    </BaseDialog>
  );
};

export default BinEstimateMoreInfo;
