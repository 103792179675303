import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { DateTime } from 'luxon';
import React from 'react';
import { GrainBinEstimate } from '../../../../../core/src/api';
import { TargetMoistureIcon, TargetTempIcon } from '../../../../../core/src/media';
import {
  formatNumber,
  getTemperatureUnitLabelWithDegreeSuffix,
} from '../../../../../core/src/util';
import { black_shade_10, black_shade_2, light_gray2, light_gray_4 } from '../../../style';
import DividerLine from '../../util/DividerLine';
import { grainSurfaeShapeTexts } from '../manage-grain-tickets/CreateOrUpdateGrainTicketForm';

const useStyles = makeStyles({
  root: {
    width: 264,
    backgroundColor: light_gray2,
    borderRadius: 8,
  },
  title: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  infoItem: {
    marginBottom: 10,
  },
  inputInfoItem: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputInfoUnavailableItem: {
    border: '1.5px solid #FF0000',
    borderRadius: '8px',
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  infoLabel: {
    color: light_gray_4,
    textTransform: 'uppercase',
    fontSize: 12,
    margin: 0,
    marginBottom: 2,
    letterSpacing: 0,
    textAlign: 'start',
  },
  naText: {
    color: '#FF0000',
    textDecoration: 'underline',
  },
  infoValue: {
    color: black_shade_2,
    fontSize: 14,
    margin: 0,
    letterSpacing: 0.14,
  },
  inputValuesContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  editInptsBtn: {
    minWidth: 37,
    width: 37,
    height: 37,
    padding: 10,
    border: `1px solid ${black_shade_10}`,
    backgroundColor: '#fff',
    borderRadius: 8,
  },
});

const ForecastUnavailableTemperatureGrid = () => {
  const classes = useStyles();
  const tempSuffix = getTemperatureUnitLabelWithDegreeSuffix();

  return (
    <>
      <Grid container style={{ marginTop: '10px' }}>
        <Grid item xs={12} className={classes.inputInfoUnavailableItem}>
          <p className={classes.infoLabel}>CHART - TARGET TEMPERATURE</p>
          <div className={classes.inputValuesContainer}>
            <TargetTempIcon style={{ marginRight: 25 }} />
            <p className={classes.infoValue}>
              {' '}
              <span className={classes.naText}>{'N/A'}</span>
              {tempSuffix}
            </p>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.inputInfoUnavailableItem} style={{ marginBottom: 0 }}>
          <p className={classes.infoLabel}>CHART - TARGET MOISTURE</p>
          <div className={classes.inputValuesContainer}>
            <TargetMoistureIcon style={{ marginRight: 25 }} />
            <p className={classes.infoValue}>
              {' '}
              <span className={classes.naText}>{'N/A'}</span> %
            </p>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const BinEstimateInputParamsCard = ({
  grainBinEstimateMetaData,
  openInputParamsModal,
}: {
  grainBinEstimateMetaData: GrainBinEstimate;
  openInputParamsModal: () => void;
}) => {
  const tempSuffix = getTemperatureUnitLabelWithDegreeSuffix();
  const classes = useStyles();
  const {
    storage_period,
    grain_bin_level_percent,
    grain_bin_level_bu,
    grain_surface_shape,
    cfm_per_bushel,
    total_aeration_runs,
    total_hrs_runtime,
    target_moisture_percent,
    target_temperature_f,
    is_bin_forecast_unavailable,
  } = grainBinEstimateMetaData;
  const storageCycleName = storage_period ? storage_period.storage_cycle_name : '';
  const storagePeriodStartDate: string = storage_period
    ? DateTime.fromMillis(new Date(storage_period.start_epoch).getTime()).toFormat('MM/dd/yyyy')
    : '';
  const grainLevelPercent = grain_bin_level_percent !== undefined ? grain_bin_level_percent : null;
  const grainLevelBu = grain_bin_level_bu !== undefined ? grain_bin_level_bu : null;
  const grainSurfaeShape =
    grain_surface_shape === undefined || grain_surface_shape === null
      ? '-'
      : grainSurfaeShapeTexts[grain_surface_shape];
  const targetMoisturePercentStr =
    target_moisture_percent !== undefined && target_moisture_percent !== null
      ? Number(target_moisture_percent)
      : 'N/A';
  const targetTemperatureStr =
    target_temperature_f !== undefined && target_temperature_f !== null
      ? Number(target_temperature_f)
      : 'N/A';
  return (
    <Card raised className={classes.root}>
      <CardHeader
        title={
          <div className={classes.title}>
            <div style={{ fontSize: 17, marginRight: 8 }}>Input Parameters</div>
          </div>
        }
        action={
          <Button className={classes.editInptsBtn} onClick={openInputParamsModal}>
            <Edit style={{ width: 20, height: 20, color: 'black' }} />
          </Button>
        }
      />
      <Divider />
      <CardContent style={{ paddingBottom: 10 }}>
        <Grid container style={{ textAlign: 'start' }}>
          <Grid item xs={12} className={classes.infoItem}>
            <p className={classes.infoLabel}>STORAGE PERIOD</p>
            {storageCycleName && <p className={classes.infoValue}>{storageCycleName}</p>}
            {storage_period &&
              storagePeriodStartDate &&
              storage_period.is_ongoing_storage_period && (
                <p className={classes.infoValue}>{storagePeriodStartDate} - Ongoing </p>
              )}
          </Grid>
          <Grid item xs={12} className={classes.infoItem}>
            <p className={classes.infoLabel}>GRAIN LEVEL</p>
            <p className={classes.infoValue}>
              {grainLevelPercent !== null && grainLevelBu !== null
                ? `${grainLevelPercent}%  fill (${formatNumber(grainLevelBu)} bu)`
                : '-'}
            </p>
            <p className={classes.infoValue}>{grainSurfaeShape}</p>
          </Grid>
          <Grid item xs={12} className={classes.infoItem}>
            <p className={classes.infoLabel}>AIRFLOW</p>
            <p className={classes.infoValue}>
              {cfm_per_bushel ? `${cfm_per_bushel.toFixed(2)} CFM/bu` : '-'}{' '}
            </p>
          </Grid>
          <Grid item xs={12} className={classes.infoItem}>
            <p className={classes.infoLabel}>FAN RUNS</p>
            <p className={classes.infoValue}>
              {total_hrs_runtime}hrs in {total_aeration_runs} runs{' '}
            </p>
          </Grid>
        </Grid>
        <DividerLine />
        {/* Logic outlining the temperature and moisture display */}
        <>
          {is_bin_forecast_unavailable ? (
            <ForecastUnavailableTemperatureGrid />
          ) : (
            <>
              <Grid container style={{ marginTop: '10px' }}>
                <Grid item xs={12} className={classes.inputInfoItem}>
                  <p className={classes.infoLabel}>CHART - TARGET TEMPERATURE</p>
                  <div className={classes.inputValuesContainer}>
                    <TargetTempIcon style={{ marginRight: 25 }} />
                    <p className={classes.infoValue}>
                      <span>
                        {targetTemperatureStr}
                        {tempSuffix}
                      </span>
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} className={classes.inputInfoItem} style={{ marginBottom: 0 }}>
                  <p className={classes.infoLabel}>CHART - TARGET MOISTURE</p>
                  <div className={classes.inputValuesContainer}>
                    <TargetMoistureIcon style={{ marginRight: 25 }} />
                    <p className={classes.infoValue}>{targetMoisturePercentStr} %</p>
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </>
      </CardContent>
    </Card>
  );
};

export default BinEstimateInputParamsCard;
