import { Grid, makeStyles } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { FunctionComponent } from 'react';
import { GrainBinEstimate } from '../../../../../core/src/api';
import { black_shade_2, light_gray2, light_gray_4 } from '../../../../../core/src/style';
import {
  formatNumber,
  formatPercent,
  formatTemp,
  GRAIN_TYPE_LABELS,
} from '../../../../../core/src/util';
import { fan_model_type_to_string } from '../grain-fan-model/GrainFanModelSelector';
import { grainSurfaeShapeTexts } from '../manage-grain-tickets/CreateOrUpdateGrainTicketForm';

const useStyles = makeStyles({
  infoItem: {
    width: '100%',
    marginBottom: 10,
    backgroundColor: light_gray2,
    borderRadius: 8,
    padding: 4,
    maxWidth: 178,
    height: 'fit-content',
  },
  infoItemLeftCol: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 20,
    width: 178,
  },
  infoItemRightCol: {
    display: 'flex',
    flexDirection: 'column',
    width: 178,
  },
  infoValue: {
    color: black_shade_2,
    fontSize: 14,
    margin: 0,
    letterSpacing: 0.14,
  },
  infoLabel: {
    color: light_gray_4,
    textTransform: 'uppercase',
    fontSize: 12,
    margin: 0,
    marginBottom: 2,
    letterSpacing: 0,
    textAlign: 'start',
  },
  infoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'start',
  },
});

export const GrainBinInfo: FunctionComponent<{
  grainBinEstimateMetaData: GrainBinEstimate;
}> = ({ grainBinEstimateMetaData }) => {
  const classes = useStyles();
  const {
    storage_period,
    grain_bin_level_percent,
    grain_bin_level_bu,
    grain_surface_shape,
    cfm_per_bushel,
    total_aeration_runs,
    total_hrs_runtime,
    bin_grain_type,
    bin_height_ft,
    bin_diameter_ft,
    current_weather,
    last_grain_ticket_date,
    fan_model,
    number_of_fans_connected_to_bin,
  } = grainBinEstimateMetaData;
  const storageCycleName = storage_period ? storage_period.storage_cycle_name : '';
  const storagePeriodStartDate: string = storage_period
    ? DateTime.fromMillis(new Date(storage_period.start_epoch).getTime()).toFormat('MM/dd/yyyy')
    : '';
  const grainLevelPercent = grain_bin_level_percent !== undefined ? grain_bin_level_percent : null;
  const grainLevelBu = grain_bin_level_bu !== undefined ? grain_bin_level_bu : null;
  const grainSurfaeShape =
    grain_surface_shape === undefined || grain_surface_shape === null
      ? 'N/A'
      : grainSurfaeShapeTexts[grain_surface_shape];
  const grainType =
    bin_grain_type && GRAIN_TYPE_LABELS[bin_grain_type] ? GRAIN_TYPE_LABELS[bin_grain_type] : 'N/A';
  const binDimString = `${bin_height_ft}ft Ht. | ${bin_diameter_ft}ft Dia.`;
  const lastGrainTicketDate = last_grain_ticket_date
    ? `Last Ticket: ${DateTime.fromMillis(new Date(last_grain_ticket_date).getTime()).toFormat(
        'MM/dd/yyyy'
      )}`
    : 'N/A';
  const currentWeatherStr = current_weather
    ? `${formatTemp(current_weather.temp_f)} · ${formatPercent(current_weather.humidity_rh)}RH`
    : 'N/A';

  const noOfFansString = `(${number_of_fans_connected_to_bin}X)`;
  return (
    <div className={classes.infoContainer}>
      <div className={classes.infoItemLeftCol}>
        <div className={classes.infoItem}>
          <p className={classes.infoLabel}>GRAIN TYPE</p>
          <p className={classes.infoValue}>{grainType}</p>
        </div>
        <div className={classes.infoItem}>
          <p className={classes.infoLabel}>STORAGE PERIOD</p>
          {storageCycleName && <p className={classes.infoValue}>{storageCycleName}</p>}
          {storage_period && storagePeriodStartDate && storage_period.is_ongoing_storage_period && (
            <p className={classes.infoValue}>{storagePeriodStartDate} - Ongoing </p>
          )}
        </div>
        <div className={classes.infoItem}>
          <p className={classes.infoLabel}>GRAIN TICKET</p>
          <p className={classes.infoValue}>{lastGrainTicketDate}</p>
        </div>
        <div className={classes.infoItem}>
          <p className={classes.infoLabel}>GRAIN LEVEL</p>
          <p className={classes.infoValue}>
            {grainLevelPercent !== null && grainLevelBu !== null
              ? `${grainLevelPercent}%  fill (${formatNumber(grainLevelBu)} bu)`
              : '-'}
          </p>
          <p className={classes.infoValue}>{grainSurfaeShape}</p>
        </div>
        <div className={classes.infoItem}>
          <p className={classes.infoLabel}>CURRENT WEATHER</p>
          <p className={classes.infoValue}>{currentWeatherStr}</p>
        </div>
      </div>
      <div className={classes.infoItemRightCol}>
        <div className={classes.infoItem}>
          <p className={classes.infoLabel}>BIN DIMENSIONS</p>
          <p className={classes.infoValue}>{binDimString}</p>
        </div>
        <div className={classes.infoItem}>
          <p className={classes.infoLabel}>FAN SETUP {noOfFansString}</p>
          {fan_model ? (
            <>
              <p className={classes.infoValue}>{fan_model.make}</p>
              <p className={classes.infoValue}>
                {fan_model.fan_horsepower} hp - {fan_model_type_to_string[fan_model.fan_model_type]}
              </p>
              <p className={classes.infoValue}>Model: {fan_model.fan_brand_name}</p>
            </>
          ) : (
            <>N/A</>
          )}
        </div>
        <div className={classes.infoItem}>
          <p className={classes.infoLabel}>AIRFLOW</p>
          <p className={classes.infoValue}>
            {cfm_per_bushel ? `${cfm_per_bushel.toFixed(2)} CFM/bu` : '-'}{' '}
          </p>
        </div>
        <div className={classes.infoItem}>
          <p className={classes.infoLabel}>FAN RUNS</p>
          <p className={classes.infoValue}>
            {total_hrs_runtime}hrs in {total_aeration_runs} runs{' '}
          </p>
        </div>
      </div>
    </div>
  );
};
