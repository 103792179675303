import {
  Backdrop,
  DialogContent,
  DialogTitle,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { ReactElement } from 'react';

const useStyles = makeStyles({
  backdrop: {
    color: 'white',
    zIndex: 1,
    position: 'absolute',
  },
  root: {
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    backgroundColor: 'white',
    borderRadius: 4,
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'black',
  },
  subtitle: {
    padding: '0px 10px',
    color: 'grey',
    fontSize: 12,
  },
});

export type BlockDialogSpinnerProps = {
  open: boolean;
  title: string;
  subTitle?: ReactElement;
  hideSpinner?: boolean;
};
export const BlockDialogSpinner: React.FunctionComponent<BlockDialogSpinnerProps> = (props) => {
  const classes = useStyles();
  return (
    <div style={{ position: 'relative' }}>
      <Backdrop className={classes.backdrop} open={props.open}>
        <div className={classes.root}>
          <DialogTitle style={{ paddingBottom: 0, paddingTop: 10 }}>{props.title}</DialogTitle>

          <DialogContent style={{ paddingBottom: !props.hideSpinner ? 16 : 0 }}>
            {!props.hideSpinner && <LinearProgress color="secondary" />}
          </DialogContent>

          {props.subTitle && (
            <Typography
              variant="subtitle2"
              className={classes.subtitle}
              style={{ paddingBottom: 16 }}
            >
              {props.subTitle}
            </Typography>
          )}
        </div>
      </Backdrop>
      {props.children}
    </div>
  );
};
