import { blue, green, red, yellow } from '@material-ui/core/colors';
import { fade } from '@material-ui/core/styles';

export const white = '#ffffff';
export const black = '#000000';
export const fullWhite = 'rgba(255, 255, 255, 1)';

export const amber_dark_grey = '#242A33';
export const amber_medium_grey = '#a2a4a9';
export const amber_alternate_grey = '#888D98';
export const amber_grey = '#5f636b';
export const amber_grey_accent = '#747881';
export const amber_medium_light_grey = '#ecedf1';
export const amber_light_grey = '#EBEBEB';
export const light_gray1 = '#e3dddd';
export const light_gray2 = '#F7F8F9';
export const light_gray_3 = '#EFF1F4';
export const light_gray_4 = '#B3B3B3';
export const light_gray_5 = '#c8c8c8';
export const light_gray_6 = '#808080';
export const light_gray_7 = '#CCCCCC';
export const light_gray_8 = '#EFF1F4C2';
export const light_gray_9 = '#E6E6E6';
export const light_gray_10 = '#F5F5F5';
export const light_gray_11 = '#f2f1f4';
export const gray_shade_1 = '#DFE3E8';
export const gray_shade_2 = '#CFD0D2';
export const gray_shade_3 = '#F4F4F7';
export const gray_shade_4 = '#CFD0D4';
export const gray_shade_5 = '#babfc7';
export const gray_shade_6 = '#757575';
export const black_shade_1 = ' #242A33';
export const black_shade_2 = '#333333';
export const black_shade_3 = '#707070';
export const black_shade_4 = '#999999';
export const black_shade_5 = '#4D4D4D';
export const black_shade_6 = '#1B1B1B';
export const black_shade_7 = '#d1cccc';
export const black_shade_8 = '#000000DE';
export const black_shade_9 = '#212121';
export const black_shade_10 = '#717172';
export const light_gray_shade_1 = '#7A7F89';
export const amber_amber = '#e69f24';
export const amber_fade = fade(amber_amber, 0.75);
export const amber_very_faded_black = fade(black, 0.12);
export const amber_faded_black = fade(black, 0.87);
export const amber_faded_white = fade(white, 0.87);
export const amber_blue = blue['500'];
export const amber_red = red['500'];
export const red_shade_1 = '#FDE2DD';
export const red_shade_2 = '#FFD2D2';
export const red_shade_3 = '#f44336';
export const red_shade_4 = '#FDE2DD';
export const red_shade_5 = '#F44336';
export const amber_error_red = '#f44336';
export const amber_green = green['500'];
export const green_shade_1 = '#4CAF50';
export const amber_yellow = yellow['500'];
export const yellow_shade_1 = '#FFC07F';
export const yellow_shade_2 = '#FAF6E6';
export const yellow_shade_3 = '#FEDD00';
export const yellow_shade_4 = '#F6E6B9';
export const yellow_shade_5 = '#F6A63F';
export const amber_faded_green = fade(amber_green, 0.33);
export const amber_light_green = 'rgba(190, 247, 191,.2)';
export const amber_faded_blue = fade(amber_blue, 0.33);
export const amber_brown = '#A18219';
export const brown_shade_1 = '#663C00';
export const brown_shade_2 = '#720F14';
export const amber_maroon = '#9D2B16';
export const maroon_shade_1 = '#A92656';
export const maroon_shade_2 = '#AD2F5C';
export const amber_sand = '#FFE793';
export const amber_link = '#1976d2';
export const blue_shade_1 = '#2C98F0';
export const blue_shade_2 = '#D0E9FF';
export const blue_shade_3 = '#494CAE';
export const blue_shade_4 = '#495057';
export const blue_shade_5 = '#4338CA';
export const orange_shade_1 = '#DC8F3F';

// colorblind-safe sets of colors for line charts
export const amber_line_color_sets = {
  1: ['#e69f24'],
  2: ['#e69f24', '#252525'],
  3: ['#cccccc', '#969696', '#525252'],
  4: ['#cccccc', '#969696', '#636363', '#252525'],
  5: ['#d9d9d9', '#969696', '#737373', '#525252', '#252525'],
  6: ['#d9d9d9', '#bdbdbd', '#969696', '#737373', '#525252', '#252525'],
  7: ['#d9d9d9', '#bdbdbd', '#969696', '#737373', '#525252', '#252525', '#272527'],
};

// 19 unique colors for barge
export const barge_cover_color_set = [
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#034900',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#e6194b',
  '#3cb44b',
  '#ffe119',
  '#808080',
  '#000000',
];

// helper function to return colors for lines in multi-line line charts
export function getColorForLine(length: number, index: number): string {
  // if more colors than handled, return single color for all
  if (length > Object.keys(amber_line_color_sets).length) {
    return amber_amber;
  }
  return amber_line_color_sets[length][index];
}

// temperature gradient colors
export type TempGradientColor = {
  color: string;
  temp_lower_limit: number;
  temp_limit_text: string;
};

// repurporsed colors sourced from http://web-tech.ga-usa.com/2012/05/creating-a-custom-hot-to-cold-temperature-color-gradient-for-use-with-rrdtool/index.html
// order is important; searching function below relies on it
export const temp_gradient_colors: TempGradientColor[] = [
  {
    color: '#FF0a00',
    temp_lower_limit: 120,
    temp_limit_text: '120\u00b0+',
  },
  {
    color: '#FF3c00',
    temp_lower_limit: 110,
    temp_limit_text: '110\u00b0',
  },
  {
    color: '#FF6e00',
    temp_lower_limit: 100,
    temp_limit_text: '100\u00b0',
  },
  {
    color: '#FFa000',
    temp_lower_limit: 90,
    temp_limit_text: '90\u00b0',
  },
  {
    color: '#FFd200',
    temp_lower_limit: 80,
    temp_limit_text: '80\u00b0',
  },
  {
    color: '#fdff00',
    temp_lower_limit: 70,
    temp_limit_text: '70\u00b0',
  },
  {
    color: '#3eff00',
    temp_lower_limit: 60,
    temp_limit_text: '60\u00b0',
  },
  {
    color: '#00ff83',
    temp_lower_limit: 50,
    temp_limit_text: '50\u00b0',
  },
  {
    color: '#00d4ff',
    temp_lower_limit: 40,
    temp_limit_text: '40\u00b0',
  },
  {
    color: '#0084ff',
    temp_lower_limit: 30,
    temp_limit_text: '30\u00b0',
  },
  {
    color: '#0032ff',
    temp_lower_limit: 20,
    temp_limit_text: '20\u00b0',
  },
  {
    color: '#0100ff',
    temp_lower_limit: 10,
    temp_limit_text: '10\u00b0',
  },
  {
    color: '#0500ff',
    temp_lower_limit: Number.MIN_SAFE_INTEGER,
    temp_limit_text: 'Below 10\u00b0',
  },
];

export const getColorForTemp = (temp: number): string => {
  if (isNaN(temp)) {
    return amber_grey;
  }
  return (
    temp_gradient_colors.find(({ temp_lower_limit }) => temp_lower_limit <= temp) ||
    temp_gradient_colors[0]
  ).color;
};
