import { MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, memo } from 'react';
import * as yup from 'yup';

import { GrainType } from '../../../api/graphql';
import { formatGrainType } from '../../../util';
import { Select, SelectProps } from './Select';

export const yup_grain_type = yup
  .string()
  .oneOf([
    null,
    GrainType.Buckwheat,
    GrainType.Barley,
    GrainType.Canola,
    GrainType.CanolaTobinPolish,
    GrainType.CanolaWestarArgentine,
    GrainType.Corn,
    GrainType.CornYellow,
    GrainType.CornWhite,
    GrainType.CornWaxy,
    GrainType.CornPopcorn,
    GrainType.CornPopcornWhite,
    GrainType.RiceLongGrain,
    GrainType.RiceMediumGrain,
    GrainType.RiceShortGrain,
    GrainType.Ddg,
    GrainType.DdgSolubles_10,
    GrainType.DdgSolubles_20,
    GrainType.DdgSolubles_25,
    GrainType.Rapeseed,
    GrainType.Soybean,
    GrainType.Sunflower,
    GrainType.SunflowerConfectionary,
    GrainType.WheatDurum,
    GrainType.WheatHardRedSpring,
    GrainType.WheatHardRedWinter,
    GrainType.WheatWhite,
    GrainType.WheatSoftRedWinterWheat,
    GrainType.Oats,
    GrainType.Rye,
    GrainType.Rye,
    GrainType.Sorghum,
    GrainType.RiceLongGrain,
    GrainType.RiceMediumGrain,
    GrainType.RiceShortGrain,
    GrainType.PistachioNutHull,
    GrainType.BeanRed,
    GrainType.BeanPinto,
    GrainType.BeanBlack,
    GrainType.BeanWhite,
    GrainType.BeanLentil,
    GrainType.Peanuts,
  ]) as yup.StringSchema<GrainType | null>;

const useStyles = makeStyles({
  item: { paddingLeft: 32 },
  itemHeading: { textDecoration: 'underline', fontSize: 'large' },
});

export const SelectGrainType: FunctionComponent<SelectProps> = memo((props) => {
  const classes = useStyles();
  return (
    <Select {...props}>
      <MenuItem value="General" disabled={true} className={classes.itemHeading}>
        General
      </MenuItem>

      <MenuItem value={GrainType.Buckwheat}>{formatGrainType(GrainType.Buckwheat)}</MenuItem>
      <MenuItem value={GrainType.Barley}>{formatGrainType(GrainType.Barley)}</MenuItem>
      <MenuItem value={GrainType.Oats}>{formatGrainType(GrainType.Oats)}</MenuItem>
      <MenuItem value={GrainType.Peanuts}>{formatGrainType(GrainType.Peanuts)}</MenuItem>
      <MenuItem value={GrainType.PistachioNutHull}>
        {formatGrainType(GrainType.PistachioNutHull)}
      </MenuItem>
      <MenuItem value={GrainType.Rapeseed}>{formatGrainType(GrainType.Rapeseed)}</MenuItem>
      <MenuItem value={GrainType.Rye}>{formatGrainType(GrainType.Rye)}</MenuItem>
      <MenuItem value={GrainType.Sorghum}>{formatGrainType(GrainType.Sorghum)}</MenuItem>
      <MenuItem value={GrainType.Soybean}>{formatGrainType(GrainType.Soybean)}</MenuItem>

      <MenuItem value="Beans" disabled={true} className={classes.itemHeading}>
        Beans
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.BeanRed}>
        {formatGrainType(GrainType.BeanRed)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.BeanPinto}>
        {formatGrainType(GrainType.BeanPinto)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.BeanBlack}>
        {formatGrainType(GrainType.BeanBlack)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.BeanLentil}>
        {formatGrainType(GrainType.BeanLentil)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.BeanWhite}>
        {formatGrainType(GrainType.BeanWhite)}
      </MenuItem>

      <MenuItem value="Canola" disabled={true} className={classes.itemHeading}>
        Canola
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.Canola}>
        {formatGrainType(GrainType.Canola)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.CanolaTobinPolish}>
        {formatGrainType(GrainType.CanolaTobinPolish)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.CanolaWestarArgentine}>
        {formatGrainType(GrainType.CanolaWestarArgentine)}
      </MenuItem>

      <MenuItem value="Corn" disabled={true} className={classes.itemHeading}>
        Corn
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.Corn}>
        {formatGrainType(GrainType.Corn)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.CornYellow}>
        {formatGrainType(GrainType.CornYellow)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.CornWhite}>
        {formatGrainType(GrainType.CornWhite)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.CornWaxy}>
        {formatGrainType(GrainType.CornWaxy)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.CornPopcorn}>
        {formatGrainType(GrainType.CornPopcorn)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.CornPopcornWhite}>
        {formatGrainType(GrainType.CornPopcornWhite)}
      </MenuItem>

      <MenuItem value="DDG" disabled={true} className={classes.itemHeading}>
        DDG
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.DdgSolubles_10}>
        {formatGrainType(GrainType.DdgSolubles_10)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.Ddg}>
        {formatGrainType(GrainType.Ddg)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.DdgSolubles_20}>
        {formatGrainType(GrainType.DdgSolubles_20)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.DdgSolubles_25}>
        {formatGrainType(GrainType.DdgSolubles_25)}
      </MenuItem>

      <MenuItem value="Rice" disabled={true} className={classes.itemHeading}>
        Rice
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.RiceLongGrain}>
        {formatGrainType(GrainType.RiceLongGrain)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.RiceMediumGrain}>
        {formatGrainType(GrainType.RiceMediumGrain)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.RiceShortGrain}>
        {formatGrainType(GrainType.RiceShortGrain)}
      </MenuItem>

      <MenuItem value="Sunflower" disabled={true} className={classes.itemHeading}>
        Sunflower
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.Sunflower}>
        {formatGrainType(GrainType.Sunflower)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.SunflowerConfectionary}>
        {formatGrainType(GrainType.SunflowerConfectionary)}
      </MenuItem>

      <MenuItem value="Wheat" disabled={true} className={classes.itemHeading}>
        Wheat
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.WheatDurum}>
        {formatGrainType(GrainType.WheatDurum)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.WheatHardRedSpring}>
        {formatGrainType(GrainType.WheatHardRedSpring)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.WheatHardRedWinter}>
        {formatGrainType(GrainType.WheatHardRedWinter)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.WheatSoftRedWinterWheat}>
        {formatGrainType(GrainType.WheatSoftRedWinterWheat)}
      </MenuItem>
      <MenuItem className={classes.item} value={GrainType.WheatWhite}>
        {formatGrainType(GrainType.WheatWhite)}
      </MenuItem>
    </Select>
  );
});
