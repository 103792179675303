import { Divider, Grid, InputAdornment, makeStyles, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import React, { FunctionComponent, useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  GrainBinEstimateParameters,
  withSaveBinEstimateTargetValuesHoc,
  WithSaveBinEstimateTargetValuesHocChildProps,
} from '../../../../../core/src/api';
import { TargetMoistureIcon, TargetTempIcon } from '../../../../../core/src/media';
import { amber_dark_grey, amber_grey, light_gray2 } from '../../../../../core/src/style';
import { getTemperatureUnitLabelWithDegreeSuffix } from '../../../../../core/src/util';
import { ColorBarSVGOptions } from '../../../api/rest/bin-colorbar-svg-download';
import { BaseForm, FormikWrapper } from '../../util/form2/BaseForm';
import { Checkbox } from '../../util/form2/Checkbox';
import { ErrorBox } from '../../util/form2/ErrorBox';
import { SubmitButtonsGroup } from '../../util/form2/SubmitButtonsGroup';
import { GrainConditionInput } from '../GrainConditionInput';
import { ColorBar } from './ColorBar';

const useStyles = makeStyles({
  confirmationBox: {
    width: '100%',
    marginTop: 16,
    marginBottom: 16,
    backgroundColor: light_gray2,
    borderRadius: 8,
    padding: 10,
    textAlign: 'start',
  },
  infoText: {
    fontSize: 15,
    fontWeight: 600,
  },
  checkboxStyles: {
    marginTop: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
});

const validationSchema = yup.object().shape({
  has_confirmed_sim_inputs: yup
    .boolean()
    .required('Confirmation Checkbox must be checked')
    .oneOf([true], 'Confirmation Checkbox must be checked'),
  target_mcwb_percent: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required()
    .label('Chart - Target Moisture')
    .positive()
    .min(0)
    .max(100),
  target_temperature_f: yup
    .number()
    .typeError('A number is required')
    .required()
    .transform((value) => (isNaN(value) ? undefined : value))
    .label('Chart - Target Temperature'),
});

export const BinSimulationInputsForm = withSaveBinEstimateTargetValuesHoc(
  ({
    colorBarSVGOptions,
    grain_bin_id,
    target_temperature_f,
    target_mcwb_percent,
    saveBinEstimateTargetValues,
    onCancel,
    refetchGrainBinEstimates,
  }: {
    grain_bin_id: number;
    colorBarSVGOptions: ColorBarSVGOptions | null;
    target_temperature_f: number | string;
    target_mcwb_percent: number | string;
    onCancel: () => void;
    refetchGrainBinEstimates: () => Promise<any>;
  } & WithSaveBinEstimateTargetValuesHocChildProps) => {
    const classes = useStyles();
    const [target_temperature_val, setTargetTemperature] = useState(target_temperature_f);
    const [target_mcwb_percent_val, setMositureTemperature] = useState(target_mcwb_percent);

    const initialValues = {
      target_mcwb_percent: target_mcwb_percent_val,
      target_temperature_f: target_temperature_val,
      has_confirmed_sim_inputs: false,
    };

    const confirmation_checkbox_label = (
      <>
        All results shown are estimates based on Amber's Grain Forecast Model and the above inputs.
        Results are only as accurate as the inputs and should only be interpreted as a "best guess".
        <br />
        <span style={{ fontWeight: 600 }}>
          Refer to CO2 Chart for all grain spoilage indication.
        </span>
      </>
    );
    const tempUnit = getTemperatureUnitLabelWithDegreeSuffix();

    const handleSubmit = async (values: {
      target_temperature_f: number;
      target_mcwb_percent: number;
      has_confirmed_sim_inputs: boolean;
    }): Promise<any> => {
      console.log('my values', values);
      const {
        has_confirmed_sim_inputs,
        target_mcwb_percent,
        target_temperature_f,
      } = validationSchema.validateSync(values);
      if (has_confirmed_sim_inputs) {
        try {
          const results = await saveBinEstimateTargetValues({
            grain_bin_id,
            target_mcwb_percent,
            target_temperature_f,
          });
          console.log('saveBinEstimateTargetValues results', results);
          return results;
        } catch (error) {
          console.error(error);
        }
      }
    };
    const handleSubmitSuccess = async () => {
      onCancel();
      refetchGrainBinEstimates();
    };
    return (
      <FormikWrapper<
        any,
        {
          target_temperature_f: number;
          target_mcwb_percent: number;
          has_confirmed_sim_inputs: boolean;
        }
      >
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnChange={true}
        render={({ validateForm, setValues, values: {}, errors, touched }) => {
          const targetTempError = errors['target_temperature_f'];
          const touchedtargetTempError = touched['target_temperature_f'];
          const HasTargetTempError = Boolean(targetTempError && touchedtargetTempError);

          const targetMoistError = errors['target_mcwb_percent'];
          const touchedTargetMoistError = touched['target_mcwb_percent'];
          const HasTargetMoisError = Boolean(targetMoistError && touchedTargetMoistError);

          const hasConfirmationError = errors['has_confirmed_sim_inputs'];

          console.log('errors', errors);
          return (
            <BaseForm submitting_message={'Updating...'} style={{ padding: 0 }}>
              <Grid container>
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: 'start',
                      fontWeight: 600,
                      fontSize: 17,
                      color: amber_dark_grey,
                    }}
                  >
                    Chart Configuration Options
                  </Typography>
                </Grid>
                <Grid item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <div
                    style={{
                      width: '50%',
                      marginRight: 20,
                      maxWidth: 176,
                      display: 'flex',
                      visibility: 'visible',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <GrainConditionInput
                      title="Chart - Target Temperature"
                      name="target_temperature_f"
                      GrainConditionIcon={TargetTempIcon}
                      hasError={HasTargetTempError}
                      inputError={''}
                      containerStyles={{ width: '100%' }}
                      endAdornment={<InputAdornment position="end">{tempUnit}</InputAdornment>}
                      handleInputChange={(value) => {
                        if (!isNaN(value)) {
                          setValues({ target_temperature_f: value });
                          setTargetTemperature(value);
                        }
                      }}
                    />

                    {colorBarSVGOptions && (
                      <ColorBar
                        ticksOnly={typeof target_temperature_val !== 'number'}
                        containerStyles={{}}
                        value={
                          typeof target_temperature_val === 'number' ? target_temperature_val : 0
                        }
                        isMobile={false}
                        useSmallColorBar={true}
                        isMoisture={false}
                        colorBarSVGOptions={colorBarSVGOptions}
                      />
                    )}
                  </div>

                  <div
                    style={{
                      width: '50%',
                      marginRight: 20,
                      maxWidth: 176,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <GrainConditionInput
                      title="Chart - Target Moisture"
                      name="target_mcwb_percent"
                      GrainConditionIcon={TargetMoistureIcon}
                      hasError={HasTargetMoisError}
                      inputError={''}
                      containerStyles={{ width: '100%' }}
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      handleInputChange={(value) => {
                        if (!isNaN(value)) {
                          setMositureTemperature(value);
                        }
                      }}
                    />
                    {colorBarSVGOptions && (
                      <ColorBar
                        ticksOnly={typeof target_mcwb_percent_val !== 'number'}
                        containerStyles={{}}
                        value={
                          typeof target_mcwb_percent_val === 'number' ? target_mcwb_percent_val : 0
                        }
                        isMobile={false}
                        useSmallColorBar={true}
                        isMoisture={true}
                        colorBarSVGOptions={colorBarSVGOptions}
                      />
                    )}
                  </div>
                </Grid>
                <Grid item style={{ width: '100%', marginTop: 20 }}>
                  <Divider />
                  <div className={classes.confirmationBox}>
                    <Typography className={classes.infoText}>
                      Please ensure below points before updating Bin Simulation inputs.
                    </Typography>
                    <Typography className={classes.infoText}>I understand that:</Typography>
                    <div className={classes.checkboxStyles}>
                      <Checkbox
                        style={{
                          margin: 0,
                        }}
                        name="has_confirmed_sim_inputs"
                        label={''}
                      />
                      <div
                        style={{
                          maxWidth: 378,
                          fontSize: 13,
                          color: amber_grey,
                          marginLeft: 5,
                        }}
                      >
                        {confirmation_checkbox_label}
                      </div>
                    </div>
                  </div>
                </Grid>
                <ErrorBox />
                {hasConfirmationError && (
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                      <span style={{ color: red['500'] }}>
                        {errors['has_confirmed_sim_inputs']}
                      </span>
                    </div>
                  </Grid>
                )}
                <SubmitButtonsGroup
                  onCancel={onCancel}
                  cancelText="Cancel"
                  submitButtonText="Update Inputs"
                />
              </Grid>
            </BaseForm>
          );
        }}
      />
    );
  }
);
