import { Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { GrainBinEstimate } from '../../../../../core/src/api';
import { ColorBarSVGOptions } from '../../../api/rest/bin-colorbar-svg-download';
import { BinEstimateChartDataType } from './BinEstimateCard';
import { BinEstimatePlot } from './BinEstimatePlot';

const BinEstimate: FunctionComponent<{
  grain_bin_id: number;
  colorBarSVGOptions: ColorBarSVGOptions | null;
  estimate_type_label: string;
  is_mobile: boolean;
  chartData: BinEstimateChartDataType | null;
  grainBinEstimateMetaData: GrainBinEstimate | null;
  openInputParamsModal: () => void;
}> = ({
  is_mobile,
  colorBarSVGOptions,
  estimate_type_label,
  chartData,
  grainBinEstimateMetaData,
  openInputParamsModal,
}) => {
  return (
    <>
      <Grid
        style={{ position: 'relative', display: 'flex', flexDirection: 'column', width: '100%' }}
      >
        <BinEstimatePlot
          is_mobile={is_mobile}
          colorBarSVGOptions={colorBarSVGOptions}
          estimate_type_label={estimate_type_label}
          openInputParamsModal={openInputParamsModal}
          grainBinEstimateMetaData={grainBinEstimateMetaData}
          chartData={chartData}
          noEstimatesFoundMsg={`${estimate_type_label} estimates not present`}
          lastUpdatedDate={
            grainBinEstimateMetaData && grainBinEstimateMetaData.result_updated_at
              ? grainBinEstimateMetaData.result_updated_at
              : null
          }
        />
      </Grid>
    </>
  );
};

export default BinEstimate;
