import {
  Button,
  Grid,
  Link,
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BinGrainModelPrerequisitesInfoFragmentFragment } from '../../../../../core/src/api';
import { amber_green, amber_link, red_shade_3 } from '../../../../../core/src/style';
import NoPlotPlaceholder from '../../../../../site-public-webapp/media/noplotholder.png';
import { pushNav } from '../../../action';
import { LaunchLinkIcon } from '../../../media';
import { BlockBackDrop } from '../../util';
import { SummaryCard } from '../summary-card';
import BinEstimateMoreInfo from './BinEstimateMoreInfo';
import { BinEstimateTabPanel } from './BinEstimateTabPanel';

const useStyles = makeStyles({
  tab: {
    width: '50%',
    fontSize: 14,
    maxWidth: '50%',
  },
  detailsTable: {
    maxWidth: 300,
    '& .MuiTableCell-root': {
      padding: 6,
    },
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableHeadCell: {
    fontWeight: 600,
  },
  img: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
});

export const BinEstimateStaticCard: React.FunctionComponent<{
  binEstimatePrerequisites: BinGrainModelPrerequisitesInfoFragmentFragment;
}> = ({ binEstimatePrerequisites }) => {
  const dispatch = useDispatch();
  const goToManageGrainTickets = () => dispatch(pushNav({ path: `/grain_bin/tickets` }));
  const goToConfigureDevices = () => dispatch(pushNav({ path: `/grain_bin/devices` }));
  const goToStoragePeriods = () => dispatch(pushNav({ path: `/grain_bin/storage_periods` }));

  const classes = useStyles();
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
  const TEMPERATURE_TAB_VALUE = 0;
  const estimatesTabs = [{ label: 'Temperature' }, { label: 'Moisture' }];
  const prerequisites_with_status = [
    {
      id: 'active_fan_controller',
      name: <>Fan Controller</>,
      status: binEstimatePrerequisites.has_active_fan_controller,
      gotoLink: goToConfigureDevices,
    },
    {
      id: 'active_hub',
      name: <>Hub</>,
      status: binEstimatePrerequisites.has_active_hub,
      gotoLink: goToConfigureDevices,
    },
    {
      id: 'active_ongoing_storage_period',
      name: <>Active Ongoing Storage Period</>,
      status: binEstimatePrerequisites.has_active_ongoing_storage_period,
      gotoLink: goToStoragePeriods,
    },
    {
      id: 'has_active_storage_period_in_year',
      name: <>Active Ongoing Storage Period duration is less than 1 year</>,
      status: binEstimatePrerequisites.has_active_storage_period_in_year,
      gotoLink: goToStoragePeriods,
    },
    {
      id: 'active_grain_tickets',
      name: <>Grain Tickets within Active Ongoing Storage Period</>,
      status: binEstimatePrerequisites.has_active_grain_tickets,
      gotoLink: goToManageGrainTickets,
    },
    {
      id: 'acceptable_grain_type',
      name: (
        <>
          Computable Grain Types
          <br />
          (Contact Support if unavailable)
        </>
      ),
      status: binEstimatePrerequisites.has_acceptable_grain_type,
      gotoLink: goToStoragePeriods,
    },
    {
      id: 'valid_fan_airflow',
      name: <>Valid Fan Airflow Computation</>,
      status: binEstimatePrerequisites.has_valid_fan_airflow,
      gotoLink: goToConfigureDevices,
    },
  ];
  const infoContent = (
    <>
      <Typography variant="h6">Bin Simulation Prerequisites</Typography>
      <Grid item xs={12} style={{ marginTop: 4 }}>
        <Table className={classes.detailsTable} style={{ padding: 6 }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>Check</TableCell>
              <TableCell className={classes.tableHeadCell}>Pass/Fail</TableCell>
              <TableCell className={classes.tableHeadCell}>Fix</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prerequisites_with_status.map(({ id, name, status, gotoLink }) => (
              <TableRow key={id}>
                <TableCell>{name}</TableCell>
                <TableCell>
                  <div className={classes.tableCell}>
                    {status ? (
                      <CheckCircleIcon style={{ color: amber_green }} />
                    ) : (
                      <CancelIcon style={{ color: red_shade_3 }} />
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <div className={classes.tableCell}>
                    {status ? (
                      'OK'
                    ) : (
                      <Link
                        style={{
                          color: amber_link,
                          textDecoration: 'underline',
                          display: 'inline-flex',
                          alignItems: 'center',
                        }}
                        onClick={() => gotoLink && gotoLink()}
                      >
                        <LaunchLinkIcon width={20} height={18} fill={amber_link} />
                      </Link>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </>
  );
  return (
    <SummaryCard
      title="Bin Simulation"
      title_badge="BETA"
      header_action={
        <Button style={{ maxHeight: 26 }} onClick={() => setShowMoreInfo(true)}>
          MORE INFO
        </Button>
      }
      contentStyle={{ padding: 5 }}
    >
      <BlockBackDrop infoContent={infoContent}>
        <Tabs
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          value={TEMPERATURE_TAB_VALUE}
          onChange={() => {}}
          aria-label="bin simulation"
        >
          {estimatesTabs.map(({ label }) => (
            <Tab key={label} className={classes.tab} label={label} />
          ))}
        </Tabs>
        <BinEstimateTabPanel value={TEMPERATURE_TAB_VALUE} index={0}>
          <div style={{ maxWidth: 380, width: '100%', height: '90%' }}>
            <img className={classes.img} src={NoPlotPlaceholder} alt="No plot placeholder" />
          </div>
        </BinEstimateTabPanel>
      </BlockBackDrop>
      <BinEstimateMoreInfo showMoreInfo={showMoreInfo} setShowMoreInfo={setShowMoreInfo} />
    </SummaryCard>
  );
};
