import { Backdrop, DialogContent, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';

const useStyles = makeStyles({
  backdrop: {
    color: 'white',
    zIndex: 1,
    position: 'absolute',
  },
  root: {
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    backgroundColor: 'white',
    borderRadius: 4,
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'black',
    marginLeft: 15,
    marginRight: 15,
  },
  subtitle: {
    padding: '0px 10px',
    color: 'grey',
    fontSize: 12,
  },
});

export type BlockBackDropProps = {
  infoContent: ReactElement;
};
export const BlockBackDrop: React.FunctionComponent<BlockBackDropProps> = (props) => {
  const classes = useStyles();
  return (
    <div style={{ position: 'relative' }}>
      <Backdrop className={classes.backdrop} open={true}>
        <div className={classes.root}>
          <DialogContent style={{ paddingBottom: 16 }}>{props.infoContent}</DialogContent>
        </div>
      </Backdrop>
      {props.children}
    </div>
  );
};
